<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              Privacy Policy
            </h3>


            <p>Last updated September 29, 2023</p>
            <p>
              Thank you for choosing to be part of our community at the <FPF /> ,
              doing business as Food Contact eXplorer (“FCX”, “we”, “us”, “our”).
              We are committed to protecting your personal information and your right to privacy.
              If you have any questions or concerns about this privacy notice, or our practices with regards to your
              personal information, please contact us at <FPFEmail />.
            </p>
            <p>
              When you visit our website <a href="/">https://fcx.foodpackagingforum.org/</a> (the
              “Website”), and more generally, use any of our services (the “Services”, which include the Website), we
              appreciate that you are trusting us with your personal information. We take your privacy very seriously.
              In this privacy notice, we seek to explain to you in the clearest way possible what information we
              collect, how we use it and what rights you have in relation to it. We hope you take some time to read
              through it carefully, as it is important. If there are any terms in this privacy notice that you do not
              agree with, please discontinue use of our Services immediately.
            </p>
            <p>
              This privacy notice applies to all information collected through our Services (which, as described above,
              includes our Website), as well as, any related services, sales, marketing or events.
            </p>
            <p>
              Please read this privacy notice carefully as it will help you understand what we do with the information
              that we collect.
            </p>
            <h4>TABLE OF CONTENTS</h4>
            <ol>
              <li><a href="#1" /> WHAT INFORMATION DO WE COLLECT? </li>
              <li><a href="#2" /> HOW DO WE USE YOUR INFORMATION?</li>
              <li><a href="#3" /> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
              <li><a href="#4" /> WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
              <li><a href="#5" /> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
              <li><a href="#6" /> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
              <li><a href="#7" /> HOW LONG DO WE KEEP YOUR INFORMATION?</li>
              <li><a href="#8" /> HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
              <li><a href="#9" /> DO WE COLLECT INFORMATION FROM MINORS?</li>
              <li><a href="#10" /> WHAT ARE YOUR PRIVACY RIGHTS?</li>
              <li><a href="#11" /> CONTROLS FOR DO-NOT-TRACK FEATURES</li>
              <li><a href="#12" /> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
              <li><a href="#13" /> DO WE MAKE UPDATES TO THIS NOTICE?</li>
              <li><a href="#14" /> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
              <li><a href="#15" /> HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
            </ol>

            <h4 id="1">
              1. WHAT INFORMATION DO WE COLLECT?
            </h4>
            <h5>Personal information you disclose to us</h5>
            <p><b><i>In Short:</i></b><i>&nbsp; We collect personal information that you provide to us.</i></p>
            <p>
              We collect personal information that you voluntarily provide to us when you express an interest in
              obtaining information about us or our products and Services, when you participate in activities on the
              Website or otherwise when you contact us.
            </p>
            <p>
              The personal information that we collect depends on the context of your interactions with us and the
              Website, the choices you make and the products and features you use. The personal information we collect
              may include the following:
            </p>
            <p>
              Personal Information Provided by You. We collect names; email addresses; affiliations; and other similar
              information.
            </p>
            <p>
              All personal information that you provide to us must be true, complete and accurate, and you must notify
              us of any changes to such personal information.
            </p>
            <h5>Information automatically collected</h5>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; Some information — such as your Internet Protocol (IP) address and/or
                browser and device characteristics — is collected automatically when you visit our Website.</i>
            </p>
            <p>
              We automatically collect certain information when you visit, use or navigate the Website. This
              information does not reveal your specific identity (like your name or contact information) but may include
              device and usage information, such as your IP address, browser and device characteristics, operating
              system, language preferences, referring URLs, device name, country, location, information about how and
              when you use our Website and other technical information. This information is primarily needed to maintain
              the security and operation of our Website, and for our internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies and similar technologies. You can find
              out more about this in our Cookie Notice:  <router-link :to="{ name: 'cookie' }">https://fcx.foodpackagingforum.org/#/cookie</router-link>.
            </p>
            <p>
              The information we collect includes:
            </p>
            <ul>
              <li aria-level="1">
                <i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage and
                performance information our servers automatically collect when you access or use our Website and which
                we record in log files. Depending on how you interact with us, this log data may include your IP
                address, device information, browser type and settings and information about your activity in the
                Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device event information (such as system
                activity, error reports (sometimes called ‘crash dumps’) and hardware settings).
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                <i>Device Data.</i> We collect device data such as information about your computer,
                phone, tablet or other device you use to access the Website. Depending on the device used, this device
                data may include information such as your IP address (or proxy server), device and application
                identification numbers, location, browser type, hardware model Internet service provider and/or mobile
                carrier, operating system and system configuration information.<br><br>
              </li>
              <li aria-level="1">
                <i>Location Data.</i> We collect location data such as information about your device’s
                location, which can be either precise or imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Website. For example, we may use GPS and other
                technologies to collect geolocation data that tells us your current location (based on your IP address).
                You can opt out of allowing us to collect this information either by refusing access to the information
                or by disabling your Location setting on your device. Note however, if you choose to opt out, you may
                not be able to use certain aspects of the Services.
              </li>
            </ul>

            <h4 id="2">
              2. HOW DO WE USE YOUR INFORMATION?
            </h4>

            <p>
              <b><i>In Short:</i></b><i>&nbsp; We process your information for purposes based on legitimate business
                interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your
                consent.</i>
            </p>
            <p>
              We use personal information collected via our Website for a variety of business purposes described below.
              We process your personal information for these purposes in reliance on our legitimate business interests,
              in order to enter into or perform a contract with you, with your consent, and/or for compliance with our
              legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed
              below.
            </p>
            <p>We use the information we collect or receive:</p>
            <ul>
              <li aria-level="1">
                To facilitate account creation and logon process. If you choose to link your account
                with us to a third-party account (such as your Google or Facebook account), we use the information you
                allowed us to collect from those third parties to facilitate account creation and logon process for the
                performance of the contract.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To post testimonials. We post testimonials on our Website that may contain personal
                information. Prior to posting a testimonial, we will obtain your consent to use your name and the
                content of the testimonial. If you wish to update, or delete your testimonial, please contact us at
                <FPFEmail /> and be sure to include your name, testimonial location, and contact information.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Request feedback. We may use your information to request feedback and to contact you
                about your use of our Website.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To enable user-to-user communications. We may use your information in order to enable
                user-to-user communications with each user’s consent.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To manage user accounts. We may use your information for the purposes of managing our
                account and keeping it in working order.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To send administrative information to you. We may use your personal information to send
                you product, service and new feature information and/or information about changes to our terms,
                conditions, and policies.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To protect our Services. We may use your information as part of our efforts to keep our
                Website safe and secure (for example, for fraud monitoring and prevention).
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To enforce our terms, conditions and policies for business purposes, to comply with
                legal and regulatory requirements or in connection with our contract.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To respond to legal requests and prevent harm. If we receive a subpoena or other legal
                request, we may need to inspect the data we hold to determine how to respond.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Fulfill and manage your orders. We may use your information to fulfill and manage your
                orders, payments, returns, and exchanges made through the Website.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Administer prize draws and competitions. We may use your information to administer
                prize draws and competitions when you elect to participate in our competitions.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To deliver and facilitate delivery of services to the user. We may use your information
                to provide you with the requested service.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To respond to user inquiries/offer support to users. We may use your information to
                respond to your inquiries and solve any potential issues you might have with the use of our Services.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                To send you marketing and promotional communications. We and/or our third-party
                marketing partners may use the personal information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. For example, when expressing an interest in obtaining
                information about us or our Website, subscribing to marketing or otherwise contacting us, we will
                collect personal information from you. You can opt-out of our marketing emails at any time (see the
                “WHAT ARE YOUR PRIVACY RIGHTS?” below).
              </li>
              <li aria-level="1">
                Deliver targeted advertising to you. We may use your information to develop and display
                personalized content and advertising (and work with third parties who do so) tailored to your interests
                and/or location and to measure its effectiveness. For more information see our Cookie Notice: <a
                  href="https://fcx.foodpackagingforum.org/#/cookie"
                >https://fcx.foodpackagingforum.org/#/cookie</a>.
              </li>
            </ul>

            <h4 id="3">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We only share information with your consent, to comply with laws, to
                provide you with services, to protect your rights, or to fulfill business obligations.</i>
            </p>
            <p>We may process or share your data that we hold based on the following legal basis:</p>
            <ul>
              <li aria-level="1">
                Consent: We may process your data if you have given us specific consent to use your
                personal information for a specific purpose.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Legitimate Interests: We may process your data when it is reasonably necessary to
                achieve our legitimate business interests.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Performance of a Contract: Where we have entered into a contract with you, we may
                process your personal information to fulfill the terms of our contract.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Legal Obligations: We may disclose your information where we are legally required to do
                so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or
                legal process, such as in response to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement requirements).
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Vital Interests: We may disclose your information where we believe it is necessary to
                investigate, prevent, or take action regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any person and illegal activities, or as
                evidence in litigation in which we are involved.
              </li>
            </ul>
            <p>
              More specifically, we may need to process your data or share your personal information in the following
              situations:
            </p>
            <ul>
              <li aria-level="1">
                Business Transfers. We may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                portion of our business to another company.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Vendors, Consultants and Other Third-Party Service Providers. We may share your data
                with third-party vendors, service providers, contractors or agents who perform services for us or on our
                behalf and require access to such information to do that work. Examples include: payment processing,
                data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow
                selected third parties to use tracking technology on the Website, which will enable them to collect data
                on our behalf about how you interact with our Website over time. This information may be used to, among
                other things, analyze and track data, determine the popularity of certain content, pages or features,
                and better understand online activity. Unless described in this notice, we do not share, sell, rent or
                trade any of your information with third parties for their promotional purposes. We have contracts in
                place with our data processors, which are designed to help safeguard your personal information. This
                means that they cannot do anything with your personal information unless we have instructed them to do
                it. They will also not share your personal information with any organization apart from us. They also
                commit to protect the data they hold on our behalf and to retain it for the period we instruct.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Affiliates. We may share your information with our affiliates, in which case we will
                require those affiliates to honor this privacy notice. Affiliates include our parent company and any
                subsidiaries, joint venture partners or other companies that we control or that are under common control
                with us.
              </li>
              <li aria-level="1">
                Business Partners. We may share your information with our business partners to offer
                you certain products, services or promotions.
              </li>
            </ul>

            <h4 id="4">
              4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We only share information with the following categories of third
                parties.</i>
            </p>
            <p>
              We only share and disclose your information with the following categories of third parties. If we have
              processed your data based on your consent and you wish to revoke your consent, please contact us using the
              contact details provided in the section below titled “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?“.
            </p>
            <ul>
              <li aria-level="1">
                Cloud Computing Services
              </li>
              <li aria-level="1">
                Communication &amp; Collaboration Tools
              </li>
              <li aria-level="1">
                Data Analytics Services
              </li>
              <li aria-level="1">
                Data Storage Service Providers
              </li>
              <li aria-level="1">
                Finance &amp; Accounting Tools
              </li>
              <li aria-level="1">
                Payment Processors
              </li>
              <li aria-level="1">
                Performance Monitoring Tools
              </li>
              <li aria-level="1">
                Sales &amp; Marketing Tools
              </li>
              <li aria-level="1">
                Website Hosting Service Providers
              </li>
              <li aria-level="1">
                Product Engineering &amp; Design Tools
              </li>
            </ul>
            <h4 id="5">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We may use cookies and other tracking technologies to collect and store
                your information.</i>
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
              information. Specific information about how we use such technologies and how you can refuse certain
              cookies is set out in our Cookie Notice: <router-link :to="{ name: 'cookie' }">https://fcx.foodpackagingforum.org/#/cookie</router-link>.
            </p>

            <h4 id="6">
              6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We may transfer, store, and process your information in countries other
                than your own.</i>
            </p>
            <p>
              Our servers are located in Switzerland. If you are accessing our Website from outside Switzerland, please
              be aware that your information may be transferred to, stored, and processed by us in our facilities and by
              those third parties with whom we may share your personal information (see “WILL YOUR INFORMATION BE SHARED
              WITH ANYONE?” above), in Switzerland, United States, and other countries.
            </p>
            <p>
              If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries
              may not necessarily have data protection laws or other similar laws as comprehensive as those in your
              country. We will however take all necessary measures to protect your personal information in accordance
              with this privacy notice and applicable law.
            </p>
            <p>European Commission’s Standard Contractual Clauses:</p>
            <p>
              We have implemented measures to protect your personal information, including by using the European
              Commission’s Standard Contractual Clauses for transfers of personal information between our group
              companies and between us and our third-party providers. These clauses require all recipients to protect
              all personal information that they process originating from the EEA or UK in accordance with European data
              protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have
              implemented similar appropriate safeguards with our third-party service providers and partners and further
              details can be provided upon request.
            </p>
            <h4 id="7">
              7. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy notice unless otherwise required by law.</i>
            </p>
            <p>
              We will only keep your personal information for as long as it is necessary for the purposes set out in
              this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
              accounting or other legal requirements).
            </p>
            <p>
              When we have no ongoing legitimate business need to process your personal information, we will either
              delete or anonymize such information, or, if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will securely store your personal information and
              isolate it from any further processing until deletion is possible.
            </p>

            <h4 id="8">
              8. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We aim to protect your personal information through a system of
                organizational and technical security measures.</i>
            </p>
            <p>
              We have implemented appropriate technical and organizational security measures designed to protect the
              security of any personal information we process. However, despite our safeguards and efforts to secure
              your information, no electronic transmission over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
              unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal,
              or modify your information. Although we will do our best to protect your personal information,
              transmission of personal information to and from our Website is at your own risk. You should only access
              the Website within a secure environment.
            </p>

            <h4 id="9">
              9. DO WE COLLECT INFORMATION FROM MINORS?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; We do not knowingly collect data from or market to children under 18
                years of age.</i>
            </p>
            <p>
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Website,
              you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent
              to such minor dependent’s use of the Website. If we learn that personal information from users less than
              18 years of age has been collected, we will deactivate the account and take reasonable measures to
              promptly delete such data from our records. If you become aware of any data we may have collected from
              children under age 18, please contact us at <FPFEmail />.
            </p>

            <h4 id="10">
              10. WHAT ARE YOUR PRIVACY RIGHTS?
            </h4>
            <p>
              <b><i>In Short: </i></b><i>&nbsp;In some regions, such as the European Economic Area (EEA) and United
                Kingdom (UK), you have rights that allow you greater access to and control over your personal information.
                You may review, change, or terminate your account at any time.</i>
            </p>
            <p>
              In some regions (like the EEA and UK), you have certain rights under applicable data protection laws.
              These may include the right (i) to request access and obtain a copy of your personal information, (ii) to
              request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv)
              if applicable, to data portability. In certain circumstances, you may also have the right to object to the
              processing of your personal information. To make such a request, please use the contact details provided
              below. We will consider and act upon any request in accordance with applicable data protection laws.
            </p>
            <p>
              If we are relying on your consent to process your personal information, you have the right to withdraw
              your consent at any time. Please note however that this will not affect the lawfulness of the processing
              before its withdrawal, nor will it affect the processing of your personal information conducted in
              reliance on lawful processing grounds other than consent.
            </p>
            <p>
              If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal
              information, you also have the right to complain to your local data protection supervisory authority. You
              can find their contact details here: <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                rel="nofollow noopener"
                target="_blank"
              >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
            </p>
            <p>
              If you are a resident in Switzerland, the contact details for the data protection authorities are
              available here: <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                rel="nofollow noopener"
                target="_blank"
              >https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
            </p>
            <p>
              Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer,
              you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
              remove cookies or reject cookies, this could affect certain features or services of our Website. To
              opt-out of interest-based advertising by advertisers on our Website visit <a
                href="http://www.aboutads.info/choices/"
                rel="nofollow noopener"
                target="_blank"
              >http://www.aboutads.info/choices/</a>.
              For further information, please see our Cookie Notice: <a
                href="https://fcx.foodpackagingforum.org/#/cookie"
              >https://fcx.foodpackagingforum.org/#/cookie</a>.
            </p>

            <h4 id="11">
              11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h4>
            <p>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
              (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your
              online browsing activities monitored and collected. At this stage no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
              browser signals or any other mechanism that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must follow in the future, we will inform you
              about that practice in a revised version of this privacy notice.&nbsp;
            </p>

            <h4 id="12">
              12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; Yes, if you are a resident of California, you are granted specific
                rights regarding access to your personal information.</i>
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
              California residents to request and obtain from us, once a year and free of charge, information about
              categories of personal information (if any) we disclosed to third parties for direct marketing purposes
              and the names and addresses of all third parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a registered account with the Website,
              you have the right to request removal of unwanted data that you publicly post on the Website. To request
              removal of such data, please contact us using the contact information provided below, and include the
              email address associated with your account and a statement that you reside in California. We will make
              sure the data is not publicly displayed on the Website, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g. backups, etc.).
            </p>
            <p>CCPA Privacy Notice</p>
            <p>The California Code of Regulations defines a “resident” as:</p>
            <p>
              (1) every individual who is in the State of California for other than a temporary or transitory purpose
              and
            </p>
            <p>
              (2) every individual who is domiciled in the State of California who is outside the State of California
              for a temporary or transitory purpose
            </p>
            <p>All other individuals are defined as “non-residents.”</p>
            <p>
              If this definition of “resident” applies to you, we must adhere to certain rights and obligations
              regarding your personal information.
            </p>

            <h5>What categories of personal information do we collect?</h5>
            <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <br>
                    <p>Category</p>
                  </td>
                  <td>
                    <br>
                    <p>Examples</p>
                  </td>
                  <td>
                    <br>
                    <p>Collected</p>
                  </td>
                </tr>
                <tr>
                  <td><p>A. Identifiers</p></td>
                  <td>
                    <p>
                      Contact details, such as real name, alias, postal address, telephone or mobile contact number,
                      unique personal identifier, online identifier, Internet Protocol address, email address and account
                      name
                    </p>
                  </td>
                  <td>
                    <br>
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td><p>B. Personal information categories listed in the California Customer Records statute</p></td>
                  <td>
                    <p>
                      Name, contact information, education, employment, employment history and financial
                      information
                    </p>
                  </td>
                  <td>
                    <br>
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td><p>C. Protected classification characteristics under California or federal law</p></td>
                  <td><p>Gender and date of birth</p></td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>D. Commercial information</p></td>
                  <td><p>Transaction information, purchase history, financial details and payment information</p></td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>E. Biometric information</p></td>
                  <td><p>Fingerprints and voiceprints</p></td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>F. Internet or other similar network activity</p></td>
                  <td>
                    <p>
                      Browsing history, search history, online behavior, interest data, and interactions with our and
                      other websites, applications, systems and advertisements
                    </p>
                  </td>
                  <td>
                    <br>
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td><p>G. Geolocation data</p></td>
                  <td><p>Device location</p></td>
                  <td>
                    <br>
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td><p>H. Audio, electronic, visual, thermal, olfactory, or similar information</p></td>
                  <td>
                    <p>Images and audio, video or call recordings created in connection with our business activities</p>
                  </td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>I. Professional or employment-related information</p></td>
                  <td>
                    <p>
                      Business contact details in order to provide you our services at a business level, job title as
                      well as work history and professional qualifications if you apply for a job with us
                    </p>
                  </td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>J. Education Information</p></td>
                  <td><p>Student records and directory information</p></td>
                  <td>
                    <br>
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td><p>K. Inferences drawn from other personal information</p></td>
                  <td>
                    <p>
                      Inferences drawn from any of the collected personal information listed above to create a profile
                      or summary about, for example, an individual’s preferences and characteristics
                    </p>
                  </td>
                  <td>
                    <br>
                    <p>YES</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <p>
              We may also collect other personal information outside of these categories instances where you interact
              with us in-person, online, or by phone or mail in the context of:
            </p>
            <ul>
              <li aria-level="1">
                Receiving help through our customer support channels;
              </li>
              <li aria-level="1">
                Participation in customer surveys or contests; and
              </li>
              <li aria-level="1">
                Facilitation in the delivery of our Services and to respond to your inquiries.
              </li>
            </ul>

            <h5>How do we use and share your personal information?</h5>
            <p>The <FPF /> collects and shares your personal information through:</p>
            <ul>
              <li aria-level="1">
                Targeting cookies/Marketing cookies
              </li>
              <li aria-level="1">
                Social media cookies
              </li>
              <li aria-level="1">
                Beacons/Pixels/Tags
              </li>
              <li aria-level="1">
                Social media plugins: Twitter. We use social media features, such as a ‘Like’ button,
                and widgets, such as a ‘Share’ button on our Website. Such features may process your Internet Protocol
                (IP) address and track which page you are visiting on our Website. We may place a cookie to enable the
                feature to work correctly. If you are logged in on a certain social media platform and you interact with
                a widget or button belonging to that social media platform, this information may be recorded to your
                profile of such social media platform. To avoid this, you should log out from that social media platform
                before accessing or using the Website. Social media features and widgets may be hosted by a third party
                or hosted directly on our Website. Your interactions with these features are governed by the privacy
                notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of
                this plugin and consequently the transfer of personal information to the corresponding social media
                service. We have no control over the essence and extent of these transmitted data as well as their
                additional processing.
              </li>
            </ul>
            <p>
              More information about our data collection and sharing practices can be found in this privacy notice and
              our Cookie Notice: <router-link :to="{ name: 'cookie' }">https://fcx.foodpackagingforum.org/#/cookie</router-link>.
            </p>
            <p>
              You may contact us by email at <FPFEmail />, or by referring to the contact details at the bottom
              of this document.
            </p>
            <p>
              If you are using an authorized agent to exercise your right to opt-out we may deny a request if the
              authorized agent does not submit proof that they have been validly authorized to act on your behalf.
            </p>

            <h5>Will your information be shared with anyone else?</h5>
            <p>
              We may disclose your personal information with our service providers pursuant to a written contract
              between us and each service provider. Each service provider is a for-profit entity that processes the
              information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business purposes, such as for undertaking internal
              research for technological development and demonstration. This is not considered to be “selling” of your
              personal data.
            </p>
            <p>
              The <FPF /> has disclosed the following categories of personal information to
              third parties for a business or commercial purpose in the preceding twelve (12) months:
            </p>
            <ul>
              <li aria-level="1">
                Category A. Identifiers, such as contact details, like your real name, alias, postal
                address, telephone or mobile contact number, unique personal identifier, online identifier, Internet
                Protocol address, email address and account name.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Category B. Personal information, as defined in the California Customer Records law,
                such as your name, contact information, education, employment, employment history and financial
                information.
              </li>
            </ul>
            <ul>
              <li aria-level="1">
                Category K. Inferences drawn from any of the personal information listed above to
                create a profile or summary about, for example, an individual’s preferences and characteristics.
              </li>
            </ul>
            <p>
              The categories of third parties to whom we disclosed personal information for a business or commercial
              purpose can be found under “WHO WILL YOUR INFORMATION BE SHARED WITH?“.
            </p>
            <p>
              The <FPF /> has not sold any personal information to third parties for a business
              or commercial purpose in the preceding twelve (12) months. <FPF /> will not sell
              personal information in the future belonging to website visitors, users and other consumers.
            </p>

            <h5>Your rights with respect to your personal data</h5>
            <p>Right to request deletion of the data – Request to delete</p>
            <p>
              You can ask for the deletion of your personal information. If you ask us to delete your personal
              information, we will respect your request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her
              right to free speech, our compliance requirements resulting from a legal obligation or any processing that
              may be required to protect against illegal activities.
            </p>
            <p>Right to be informed – Request to know</p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
              <li aria-level="1">
                whether we collect and use your personal information;
              </li>
              <li aria-level="1">
                the categories of personal information that we collect;
              </li>
              <li aria-level="1">
                the purposes for which the collected personal information is used;
              </li>
              <li aria-level="1">
                whether we sell your personal information to third parties;
              </li>
              <li aria-level="1">
                the categories of personal information that we sold or disclosed for a business
                purpose;
              </li>
              <li aria-level="1">
                the categories of third parties to whom the personal information was sold or disclosed
                for a business purpose; and
              </li>
              <li aria-level="1">
                the business or commercial purpose for collecting or selling personal information.
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide or delete consumer information that is
              de-identified in response to a consumer request or to re-identify individual data to verify a consumer
              request.
            </p>
            <p>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
            <p>We will not discriminate against you if you exercise your privacy rights.</p>
            <p>Verification process</p>
            <p>
              Upon receiving your request, we will need to verify your identity to determine you are the same person
              about whom we have the information in our system. These verification efforts require us to ask you to
              provide information so that we can match it with information you have previously provided us. For
              instance, depending on the type of request you submit, we may ask you to provide certain information so
              that we can match the information you provide with the information we already have on file, or we may
              contact you through a communication method (e.g. phone or email) that you have previously provided to us.
              We may also use other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to verify your identity or authority to
              make the request. To the extent possible, we will avoid requesting additional information from you for the
              purposes of verification. If, however, we cannot verify your identity from the information already
              maintained by us, we may request that you provide additional information for the purposes of verifying
              your identity, and for security or fraud-prevention purposes. We will delete such additionally provided
              information as soon as we finish verifying you.
            </p>
            <p>Other privacy rights</p>
            <ul>
              <li aria-level="1">
                you may object to the processing of your personal data
              </li>
              <li aria-level="1">
                you may request correction of your personal data if it is incorrect or no longer
                relevant, or ask to restrict the processing of the data
              </li>
              <li aria-level="1">
                you can designate an authorized agent to make a request under the CCPA on your behalf.
                We may deny a request from an authorized agent that does not submit proof that they have been validly
                authorized to act on your behalf in accordance with the CCPA.
              </li>
              <li aria-level="1">
                you may request to opt-out from future selling of your personal information to third
                parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible,
                but no later than 15 days from the date of the request submission.
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by email at <FPFEmail />, or by referring to the
              contact details at the bottom of this document. If you have a complaint about how we handle your data, we
              would like to hear from you.&nbsp;&nbsp;
            </p>

            <h4 id="13">
              13. DO WE MAKE UPDATES TO THIS NOTICE?
            </h4>
            <p>
              <b><i>In Short:</i></b><i>&nbsp; Yes, we will update this notice as necessary to stay compliant with
                relevant laws.</i>
            </p>
            <p>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated
              “Revised” date and the updated version will be effective as soon as it is accessible. If we make material
              changes to this privacy notice, we may notify you either by prominently posting a notice of such changes
              or by directly sending you a notification. We encourage you to review this privacy notice frequently to be
              informed of how we are protecting your information.
            </p>

            <h4 id="14">
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h4>
            <p>
              If you have questions or comments about this notice, you may email us at <FPFEmail /> or by post
              to:
            </p>
            <FPFAddress />

            <h4 id="15">
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h4>
            <p>
              Based on the applicable laws of your country, you may have the right to request access to the personal
              information we collect from you, change that information, or delete it in some circumstances. To request
              to review, update, or delete your personal information, please email: <FPFEmail />.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FPFEmail from "@/components/Fcx/static/FPFEmail.vue";
import FPFAddress from "@/components/Fcx/static/FPFAddress.vue";
import FPF from "@/components/Fcx/static/FPF.vue";

export default {
  name: 'Privacy',
  bodyClass: 'static-page',
  components: {
    FPF,
    FPFAddress,
    FPFEmail

  },
  data() {
    return {}
  }
}
</script>
<style>
</style>

<template>
  <a href="https://www.minderoo.org" target="_blank" rel="noopener">Minderoo Foundation</a>
</template>
<script>
export default {
  name: 'Minderoo',
}
</script>

<style scoped>

</style>
<template>
  <div class="container wrapper ecommerce-page">
    <section class="header">
      <div class="row mb-3">
        <div class="col-12 col-md-6 float-left">
          <Breadcrumb></Breadcrumb>
        </div>
        <div class="col-12 col-md-6 float-right text-right">
          <button_group>
            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click.native="modals.citations = true"
              title="Cite Search in different styles"
            >
              <i class="fa fa-quote-right" />
            </n-button>
            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click.native="modals.downloads = true"
              title="Download search results"
            >
              <i class="fa fa-download" />
            </n-button>
            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click="saveSearch"
              title="Save current search to local storage"
            >
              <i class="fa fa-star" aria-hidden="true" />
            </n-button>
          </button_group>
        </div>
      </div>
    </section>
    <section class="main">
      <div id="list" class="container">
        <div class="row">
          <div class="col-12 col-lg-8">
            <h2 class="section-title">
              Explore food contact chemicals
            </h2>
          </div>
          <div class="col-12 col-lg-4 text-right">
            <el-select
              :required="true"
              v-model="sortVal"
              class="select-outline"
              placeholder="Select Sorting"
              @change="prepareSort"
            >
              <el-option
                v-for="option in sortOptions"
                :key="option.label"
                class="select-primary"
                :value="option.value"
                :label="option.label"
              />
            </el-select>
          </div>
          <div class="col-12 mb-2">
            {{MastersCountX}} substances <span v-if="SelectedCasIds && SelectedCasIds.length > 0"> | Selected Chemicals ({{SelectedCasIds.length}}): {{SelectedCasIds.toString()}}</span>
          </div>
        </div>
        <div class="row">
          <!-- Filter -->
          <div class="col-md-4 col-lg-3">
            <div class="collapse-panel">
              <div class="card-body">
                <div class="card card-refine card-plain">
                  <h4 class="card-title">
                    Refine
                    <button
                      class="btn btn-default btn-icon btn-neutral pull-right"
                      rel="tooltip"
                      title="Reset Filter"
                      @click="resetFilter"
                    >
                      <i class="arrows-1_refresh-69 now-ui-icons" />
                    </button>
                  </h4>
                  <fg-input
                    :label="NOTES.fulltext.title"
                    labelClasses="text-primary"
                    v-model="Fulltext"
                    @change="prepareFulltext"
                    :note="NOTES.fulltext"
                  ></fg-input>


                  <!--
                  <FilterFacet2
                    v-model="QueryObject"
                    :selection="FILTERS.fcmInventories2"
                    field="fcm_inventory"
                    label="FCM Inventories"
                    @input="buildFilterQuery"
                  ></FilterFacet2>
                  -->

                  <FilterFacet
                    v-model="QueryObject"
                    field="cas.id"
                    :label="NOTES.casId.title"
                    placeholder="Enter CAS"
                    @input="buildFilterQuery"
                    type="multi"
                    :allow-create="true"
                    :foldable="false"
                    :note="NOTES.casId"
                  ></FilterFacet>


                  <h6
                    class="mt-4 mb-0 text-primary"
                  >
                    Material
                  </h6>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.fcmInventories"
                    field="fcm_inventory"
                    :label="NOTES.materialInventory.title"
                    placeholder="Select inventories"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.materialInventory"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.detectedFCAs"
                    field="facets.detectedFCA"
                    :label="NOTES.materialFCAs.title"
                    placeholder="Select FCA"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.materialFCAs"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.detectedFCMMains"
                    field="facets.detectedFcmMain"
                    :label="NOTES.detectedFCMMain.title"
                    placeholder="Select material"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.detectedFCMMain"
                  ></FilterFacet>
                  <!-- only group of FCMMain (not used anymore instead detectedFCMMains -->
                  <!--
                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.detectedMaterialGroups"
                    field="facets.detectedMaterialGroup"
                    label="NOTES.detectedMaterialGroup.title"
                    placeholder="Select material"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.detectedMaterialGroup"
                  ></FilterFacet>
                  -->

                  <h6
                    class="mt-4 mb-0 text-primary"
                  >
                    Data Source
                  </h6>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.sourceNames"
                    field="facets.sourceId"
                    :label="NOTES.sourceName.title"
                    placeholder="Select name"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.sourceName"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.sourceTypes"
                    field="facets.sourceType"
                    :label="NOTES.sourceType.title"
                    placeholder="Select type of source"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.sourceType"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.sourceRegions"
                    field="facets.sourceRegion"
                    :label="NOTES.sourceRegion.title"
                    placeholder="Select regions"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.sourceRegion"
                  ></FilterFacet>

                  <!--
                  <CascaderFacet
                    v-model="QueryObject"
                    :selection="FILTERS.regionOptions"
                    field="facets.sourceRegion"
                    label="Regulatory region"
                    placeholder="Select regions"
                    type="single"
                    :foldable="false"
                    @input="buildFilterQuery"
                  ></CascaderFacet>
                  -->

                  <!-- currently projectId from source is used, but it can be also refProjectId or projectId by extractions -->

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.sourceProjects"
                    field="facets.projectId"
                    :label="NOTES.fpfProject.title"
                    placeholder="Select project"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.fpfProject"
                  ></FilterFacet>


                  <h6
                    class="mt-4 mb-0 text-primary"
                  >
                    Chemical properties
                  </h6>
                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.chemGroups"
                    field="chemFamily"
                    :label="NOTES.chemGroup.title"
                    placeholder="Select groups"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.chemGroup"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.chemFunctions"
                    field="chemFunction"
                    :label="NOTES.chemFunction.title"
                    placeholder="Select functions"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.chemFunction"
                  ></FilterFacet>

                  <FilterFacet
                    v-model="QueryObject"
                    :selection="FILTERS.ghsSignalWords"
                    field="facets.ghsSignalWord"
                    :label="NOTES.ghsSignalWord.title"
                    placeholder="Select name"
                    @input="buildFilterQuery"
                    type="multi"
                    :foldable="true"
                    :note="NOTES.ghsSignalWord"
                  ></FilterFacet>

                  <!--
                  <collapse>
                    <collapse-item
                      no-icon
                      class="card-header"
                    >
                      <h6
                        slot="title"
                        class="mb-0 text-primary"
                      >
                        Data Source properties
                        <i class="now-ui-icons arrows-1_minimal-down" />
                      </h6>
                    </collapse-item>
                  </collapse>
                  -->

                  <!--

                                    <FilterFacet2
                                      v-model="QueryObject"
                                      :selection="FILTERS.chemGroups2"
                                      field="chemFamily"
                                      label="Chemical Family"
                                      @input="buildFilterQuery"
                                    ></FilterFacet2>


                                    <FilterFacet
                                      v-model="QueryObject"
                                      :selection="FILTERS.chemGroups"
                                      type="multiple"
                                      field="chemFamily"
                                      label="Chemical Family"
                                      @input="buildFilterQuery"
                                    ></FilterFacet>
                                    -->
                </div>
              </div>
            </div>
          </div>

          <!-- Catalog -->
          <div class="col-md-8 col-lg-9">
            <div class="row">
              <div
                v-for="(item, $index) in List"
                :key="$index"
                class="col-12"
              >
                <ListItem :item-obj="item" @onSelectItem="handlerSelectItem"></ListItem>
              </div>

            </div>
            <infinite-loading :identifier="InfiniteId" spinner="waveDots" @infinite="infiniteHandler">
              <div slot="no-more" class="text-primary">
                No more entries
              </div>
              <div slot="no-results" class="text-primary">
                <h5>
                  No results found for current filter configuration<br>
                  <button class="btn btn-default btn-neutral" @click="resetFilter">Reset Filter</button>
                </h5>


                <!--
                <a :href="$router.resolve({name:'catalog', query: {q: '', s: ''}}).href" class="hover13">
                  Reset filter.
                </a>
                -->
              </div>
            </infinite-loading>
          </div>
        </div>


      </div>
    </section>

    <!-- modals -->
    <modal
      :show.sync="modals.citations"
      modalClasses="fcx-modal"
    >
      <h4
        slot="header"
        class="title title-up"
      >
        Citations
      </h4>
      <n-button
        type="fcx-orange"
        @click.native="modals.citations = false"
        @click="citeSearch('apa')"
        title="Cite chemical details in APA style and copy to clipboard"
      >
        Cite APA
      </n-button>

      <n-button
        type="fcx-orange"
        @click.native="modals.citations = false"
        @click="citeSearch('ama')"
        title="Cite chemical details in AMA style and copy to clipboard"
      >
        Cite AMA
      </n-button>

      <n-button
        type="fcx-orange"
        @click.native="modals.citations = false"
        @click="citeSearch('mla')"
        title="Cite chemical details in MLA style and copy to clipboard"
      >
        Cite MLA
      </n-button>

      <template slot="footer">
        <n-button
          type="btn-default"
          @click.native="modals.citations = false"
        >
          Close
        </n-button>
      </template>
    </modal>

    <modal
      :show.sync="modals.downloads"
      modalClasses="fcx-modal"
    >
      <h4
        slot="header"
        class="title title-up"
      >
        Downloads
      </h4>
      <h6>Export CAS List (name + cas)</h6>
      <n-button
        native-type="submit"
        type="fcx-orange"
        @click="downloadCasList('csv')"
        :icon="false"
        title="Download CAS List (name + cas) as CSV file (Comma-Separated Values)"
      >
        <i class="fa fa-download" />
        Export (CSV)
      </n-button>

      <n-button
        native-type="submit"
        type="fcx-orange"
        @click="downloadCasList('json')"
        :icon="false"
        title="Download CAS List (name + cas) as JSON file"
      >
        <i class="fa fa-download" />
        Export (JSON)
      </n-button>
      <hr>
      <h6> Export Sources List (limited to 100 entries)</h6>

      <n-button
        native-type="submit"
        type="fcx-orange"
        @click="downloadSourcesList('csv')"
        :icon="false"
        title="Download Sources List (sources + cas) as CSV file (Comma-Separated Values)"
      >
        <i class="fa fa-download" />
        Export (CSV)
      </n-button>

      <n-button
        native-type="submit"
        type="fcx-orange"
        @click="downloadSourcesList('json')"
        :icon="false"
        title="Download Sources List (sources + cas) as JSON file"
      >
        <i class="fa fa-download" />
        Export (JSON)
      </n-button>

      <div v-if="SelectedCasIds.length > 0">

        <n-button
          native-type="submit"
          type="fcx-orange"
          @click="downloadSelectedSourcesList('csv')"
          :icon="false"
          title="Download Selected Sources List as CSV file (Comma-Separated Values)"
        >
          <i class="fa fa-download" />
          Export {{SelectedCasIds.length}} Selection  (CSV)
        </n-button>


        <n-button
          native-type="submit"
          type="fcx-orange"
          @click="downloadSelectedSourcesList('json')"
          :icon="false"
          title="Download Selected Sources List as JSON file"
        >
          <i class="fa fa-download" />
          Export {{SelectedCasIds.length}} Selection  (JSON)
        </n-button>
      </div>


      <hr>
      <FCXDisclaimer class="mt-2"/>

      <template slot="footer">
        <n-button
          type="btn-default"
          @click.native="modals.downloads = false"
        >
          Close
        </n-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import {Select, Option, Popover, Carousel, CarouselItem, Tooltip} from 'element-ui';
  import ListItem from "../components/ListItem";
  import InfiniteLoading from "vue-infinite-loading";
  import {Button, Checkbox, FormGroupInput, Modal, Collapse, CollapseItem} from "@/components";
  import FgInput from "@/components/Inputs/formGroupInput";
  import Breadcrumb from "../components/Sections/Breadcrumb";
  import FilterFacet from "@/components/Fcx/FilterFacet.vue";
  import infiniteScroll from "element-ui/packages/infinite-scroll";
  //import FilterFacet2 from "@/components/Fcx/FilterFacet2.vue";
  import NButton from "@/components/Button.vue";
  import {citationMixin} from "@/js/mixins/citation-mixin";
  import Constants from "@/js/const";
  import CascaderFacet from "@/components/Fcx/CascaderFacet.vue";
  import FCXDisclaimer from "@/components/Fcx/static/FCXDisclaimer.vue";

  export default {
    name: "Catalog",
    bodyClass: 'ecommerce-page',
    components: {
      FCXDisclaimer,
      CascaderFacet,
      Modal,
      NButton,
      //FilterFacet2,
      FilterFacet,
      Breadcrumb,
      FgInput,

      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Select.name]: Select,
      [Option.name]: Option,
      [Popover.name]: Popover,
      [Tooltip.name]: Tooltip,
      InfiniteLoading,
      ListItem,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem
    },
    mixins: [citationMixin],
    props: {
      q: {
        type: String,
        required: false,
        default: ''
      },
      s: {
        type: String,
        required: false,
        default: '' // not shown in browser uri
      }
    },
    data() {
      return {
        modals: {
          citations: false,
          downloads: false
        },
        list: [],
        selectedCasIds: [],
        infiniteId: 0,
        fulltext: "",
        limit: 10,
        skip: 0,
        queryObject: {},
        sortObject: {
          "name": 1
        },
        sortOptions: [
          {
            value: '{"name":1}',
            label: "Sorting: Name asc"
          },
          {
            value: '{"name":-1}',
            label: "Sorting: Name desc"
          },
          {
            value: '{"cas.id":1}',
            label: "Sorting: CAS asc"
          },
          {
            value: '{"cas.id":-1}',
            label: "Sorting: CAS desc"
          }
        ],
        sortVal: "",
      };
    },
    mounted() {
      /*
      this.Query = this.$route.query.q;
      this.Sort = this.$route.query.s;
      Logger.debug("mounted Catalog", this.q, this.Query, this.Sort);
      */
      // TODO set fields based on current filter
      //this.QueryObject = {};
      if (this.Query) {
        this.QueryObject = JSON.parse(this.Query);
        // parse
        if(this.QueryObject.$text) {
          this.Fulltext = this.QueryObject.$text.$search;
        } else {
          this.Fulltext = "";
        }
      }
      if (this.Sort) {
        this.SortObject = JSON.parse(this.Sort);
        this.sortVal = this.Sort;
      }

      Logger.debug("this.$route.query", this.$route.query);

      /*
      Logger.debug("this.fulltext", this.$route.query.filter)
      if(this.fulltext) {
        let value =  this.fulltext;
        let filter = {
          $text: { $search: value}
        };
        this.query = filter;
      }
      */
    },
    beforeRouteUpdate(to, from, next) {
      // called when the route that renders this component has changed, but this component is reused in the new route.
      // For example, given a route with params `/users/:id`, when we navigate between `/users/1` and `/users/2`,
      // the same `UserDetails` component instance will be reused, and this hook will be called when that happens.
      // Because the component is mounted while this happens, the navigation guard has access to `this` component instance.

      Logger.debug("beforeRouteUpdate", to, from, this.$route.query.q);
      //this.Fulltext = this.$route.query.q;
      //this.Query = to.query.q;
      //this.Sort = to.query.s;

      // TODO set fields based on current filter

      this.QueryObject = {};
      if (this.Query) {
        this.QueryObject = JSON.parse(to.query.q);
      }
      this.SortObject = {};
      if (this.Sort) {
        this.SortObject = JSON.parse(to.query.s);
        this.sortVal = to.query.s;
      }

      //whenever no. changes the infiniteHandler will be called automatically.
      this.List = [];
      this.Skip = 0;
      this.InfiniteId += 1;
      // whenever route is changed clean selected items
      this.cleanSelectedMaster();
      next();
    },
    beforeRouteEnter(to, from, next) {
      Logger.debug("beforeRouteEnter", to, from);
      // presets query and sort if not set but not shown in uri.
      // this resolve the issue that the user cannot click on filter the first time and also to save a search with empty.
      to.query.q ??= '{}';
      to.query.s ??= '{"name":1}';
      next();
    },
    computed: {
      FILTERS() {
        return Constants.filters;
      },
      NOTES() {
        return Constants.notes;
      },
      SelectedCasIds: {
        get() {
          return this.selectedCasIds;
        },
        set(value) {
          this.selectedCasIds = value;
        }
      },
      List: {
        get() {
          return this.list;
        },
        set(value) {
          this.list = value;
        }
      },
      Skip: {
        get() {
          return this.skip;
        },
        set(value) {
          this.skip = value;
        }
      },
      Limit: {
        get() {
          return this.limit;
        },
        set(value) {
          this.limit = value;
        }
      },
      InfiniteId: {
        get() {
          return this.infiniteId;
        },
        set(value) {
          this.infiniteId = value;
        }
      },
      Fulltext: {
        get() {
          return this.fulltext;
        },
        set(value) {
          this.fulltext = value;
        }
      },
      Query: {
        get() {
          return this.q;
        },
        /*
        set(value) {
          this.q = value;
        }
        */
      },
      Sort: {
        get() {
          return this.s;
        },
        /*
        set(value) {
          this.s = value;
        }
        */
      },
      QueryObject: {
        get() {
          return this.queryObject;
        },
        set(value) {
          this.queryObject = value;
        }
      },
      SortObject: {
        get() {
          return this.sortObject;
        },
        set(value) {
          this.sortObject = value;
        }
      },
    },
    methods: {
      // TODO currently if the results < limit, data will be loaded twice
      infiniteHandler($state) {
       Logger.debug("infiniteHandler", $state, this.Query, this.Sort, this.Limit, this.Skip);
        let payload = {
          limit: this.Limit,
          sk: this.Skip,
          q: this.Query || undefined, //JSON.stringify(this.query),
          s: this.Sort || undefined, //JSON.stringify(this.sort)
          f: JSON.stringify({"name":1, "cas.id":1, "_id":0, "slug": 1, "chemFamily": 1, "chemFunction": 1}) // query selection
        };
       Logger.debug("payload infiniteHandler", payload);
        // ?q=%7B%22name%22%3A%22Test-7%22%7D&t=find&sk=0&l=5
        // http://localhost:8080/#/catalog?q=%7B%22name%22%3A%22Trimellitic%20anhydride%22%7D

        // get search results only for the first page
        if(payload.sk === 0) {
          this.$store
            .dispatch("GET_MASTERS_COUNT", {...payload, t: "count"})
            .then(items => {
             Logger.debug("GET_MASTERS_COUNT", items);
            })
            .catch(error => {
              Logger.error("error GET_MASTERS_COUNT", error);
            });
        }

        this.$store
          .dispatch("GET_MASTERS", payload)
          .then(items => {
           Logger.debug("GET_MASTERS", items);
            if (items.length) {
              this.Skip += this.Limit;
              this.List.push(...items);
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(error => {
            Logger.error("error GET_MASTERS", error);
          });
      },
      prepareSort() {
        // sort val is a filter string
        this.SortObject = JSON.parse(this.sortVal);
        this.buildFilterQuery();
      },
      prepareFulltext() {
        //Logger.debug("prepareFulltext", this.Fulltext);
        // sort val is a filter string
        if(this.Fulltext) {
          this.QueryObject.$text = { $search: this.Fulltext};
        } else {
          delete this.QueryObject.$text;
        }
        this.buildFilterQuery()
      },
      buildFilterQuery() {
        // get all filter attribs
        const newQuery = JSON.stringify(this.QueryObject),
          newSort = JSON.stringify(this.SortObject);
        Logger.debug("buildFilterQuery", this.QueryObject, this.Query)
        /*
        // build query
        this.List = [];
        this.Skip = 0;
        //this.InfiniteId += 1
        */
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            q: newQuery,
            s: newSort
          }
        });

        // set query
      },
      resetFilter() {
        this.QueryObject = {};
        this.SortObject = {
          "name": 1
        };
        this.Fulltext = "";
        this.buildFilterQuery();
      },
      handlerSelectItem(item) {
        const casId = item.cas && item.cas.id;
        // casId does not exist and item should be selected
        if(!casId && item.isSelected) {
          Logger.warn("Current item with name " + item.name + " to select has no casId");
          this.$notify({
            title: "No CAS ID",
            message: "Current item with name " + item.name + " to select has no casId",
            type: "warning",
            timeout: 3000,
            horizontalAlign: "right",
            verticalAlign: "top",
            icon: 'nc-icon nc-app',
          });
          return
        }

        if(this.SelectedCasIds.length >= 1) {
          Logger.warn("Limit of 100 elements for source list is reached - SelectedCasIds");
        }

        // item was augmented by ListItem component => not good style
        if(item.isSelected) {
          this.addSelectedMaster(casId);
        } else {
          this.removeSelectedMaster(casId);
        }
      },
      isItemSelected(item) {
        return this.SelectedCasIds.includes(item.cas.id);
      },
      addSelectedMaster(casId) {
        this.SelectedCasIds.push(casId);
      },
      removeSelectedMaster(casId) {
        const i = this.SelectedCasIds.map(item => item.casId).indexOf(casId);
        this.SelectedCasIds.splice(i, 1);
      },
      cleanSelectedMaster() {
        this.SelectedCasIds = [];
        // this is only relevant if the route is redundant (i.e.NavigationDuplicated: Avoided redundant navigation to current..)
        // then List will not be updated therefore isSelect not set to false again
        this.List = this.List.map((obj)=> {
          if(obj.isSelected) {
            obj.isSelected= false;
          }
          return obj
        });
      },
      saveSearch() {
        const query = this.$router.currentRoute.query;
        Logger.debug("saveSearch", query)

        let queryString = query.q || "{}",
          sortString = query.s || "{}";

        const queryObj = {
          q: JSON.parse(queryString),
          s: JSON.parse(sortString)
        }
        try {
          this.$store.commit("addSavedSearch", queryObj);
          this.$notify({
            title: "Saved",
            message: "Saved search to local storage.",
            type: "success",
            timeout: 3000,
            horizontalAlign: "right",
            verticalAlign: "top",
            icon: 'nc-icon nc-app',
          });
        } catch (e) {
          this.$notify({
            title: "Error",
            message: e.message,
            type: "danger",
            timeout: 3000,
            horizontalAlign: "right",
            verticalAlign: "top",
            icon: 'nc-icon nc-app',
          });
        }
      },
      downloadCasList(format) {
        this.LoadingX = true;
        let payload = {
          //limit: this.Limit,
          //sk: this.Skip,
          q: this.Query || undefined, //JSON.stringify(this.query),
          s: this.Sort || undefined, //JSON.stringify(this.sort)
          format
        };
       Logger.debug("payload downloadCasList", payload);
        // ?q=%7B%22name%22%3A%22Test-7%22%7D&t=find&sk=0&l=5
        // http://localhost:8080/#/catalog?q=%7B%22name%22%3A%22Trimellitic%20anhydride%22%7D
        this.$store
          .dispatch("EXPORT_CAS_MASTERS", payload)
          .then(items => {
           Logger.debug("EXPORT_CAS_MASTERS", items);
          })
          .catch(error => {
           Logger.debug("error downloadCasList", error);
          })
          .finally(() => {
            this.LoadingX = false;
          });
      },
      downloadSourcesList(format) {
        this.LoadingX = true;
        let payload = {
          q: this.Query || undefined, //JSON.stringify(this.query),
          s: this.Sort || undefined, //JSON.stringify(this.sort)
          format
        };

        Logger.debug("payload downloadSourcesList", payload);

        this.$store
          .dispatch("EXPORT_SOURCES_MASTERS", payload)
          .then(items => {
            Logger.debug("EXPORT_SOURCES_MASTERS", items);
          })
          .catch(error => {
            Logger.debug("error downloadSourcesList", error);
          })
          .finally(() => {
            this.LoadingX = false;
          });
      },


      downloadSelectedSourcesList(format) {
        this.LoadingX = true;
        //{"cas.id":{"$in":["j9908098"]}}
        const customQueryObj = {
          "cas.id": {
            "$in": this.SelectedCasIds
          }
        };
        const customQueryObjString = JSON.stringify(customQueryObj);

        let payload = {
          q: customQueryObjString || undefined, //JSON.stringify(this.query),
          s: this.Sort || undefined, //JSON.stringify(this.sort)
          format
        };
        Logger.debug("payload downloadSourcesList", payload);

        this.$store
          .dispatch("EXPORT_SOURCES_MASTERS", payload)
          .then(items => {
            Logger.debug("EXPORT_SOURCES_MASTERS", items);
          })
          .catch(error => {
            Logger.debug("error downloadSourcesList", error);
          })
          .finally(() => {
            this.LoadingX = false;
          });
      },

    },
  }
</script>
<style scoped>
    .ecommerce-page.container {
      margin-top: 100px;
    }
    .starter-page {
        min-height: calc(100vh - 79px)
    }
</style>

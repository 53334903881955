<template>
  <span><router-link to="/data-description" target="_blank">Data Description</router-link> page</span>
</template>
<script>
export default {
  name: 'FCXDataDescription',
}
</script>

<style scoped>

</style>

<template>
  <a href="https://www.foodpackagingforum.org/fccmigex" target="_blank" rel="noopener">Migrating and Extractable Food Contact Chemicals (FCCmigex)</a>
</template>
<script>
export default {
  name: 'FCCMigex',
}
</script>

<style scoped>

</style>

<template>
  <a href="https://www.foodpackagingforum.org/fccdb" target="_blank" rel="noopener">Food Contact Chemicals database (FCCdb)</a>
</template>
<script>
export default {
  name: 'FCCDB',
}
</script>

<style scoped>

</style>

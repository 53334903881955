<template>
  <footer
    class="footer"
    :class="{[`footer-${type}`]: type}"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav>
            <ul>

              <li v-if="CurrentLocalUserX && CurrentLocalUserX.role === 'admin'">
                <a href="https://fpf-data-ecosystem-backoffice-y7j8k.ondigitalocean.app/">
                  FCX Backoffice
                </a>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/about"
                >
                  About us
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/data-description"
                >
                  Data Description
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/contact"
                >
                  Contact
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/terms"
                >
                  Terms & Conditions
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/imprint"
                >
                  Imprint
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/privacy"
                >
                  Privacy Policy
                </router-link>
              </li>
              <li>
                <router-link
                  class="navbar-brand"
                  to="/cookie"
                >
                  Cookie Policy
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="origin">
            <img class="pr-2" src="/img/logo/FPF-logo-signet.png" height="30" alt="">
            &copy;{{ year }} Powered by <FPF />
          </div>
          <div class="copyright">
            v{{ Version }} | Coded with
            <i class="fa fa-heart heart"></i> by <entreprenerds />.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  import FPF from "@/components/Fcx/static/FPF.vue";
  import Entreprenerds from "@/components/Fcx/static/Entreprenerds.vue";
  import {updateMixin} from "@/js/mixins/update-mixin";

  export default {
    components: {Entreprenerds, FPF},
    props: {
      backgroundColor: String,
      type: String
    },
    mixins: [updateMixin],
    data(){
      return {
        year: new Date().getFullYear()
      }
    },
    created: function() {

    },
    computed: {
      Version() {
        return process.env.VUE_APP_VERSION;
      },
    }
  }
</script>
<style lang="scss">
.footer {
  .origin {
    float: left;
    font-size: 0.8571em;
    line-height: 3.5;
  }
}

</style>

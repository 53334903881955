// predefined locations aka names in local storage.
const STORAGE_LOCATION = {
  savedSearch: "user-saved-search",
};

class SavedSearchService {
  getLocalSavedSearch() {
    const savedSearch = JSON.parse(localStorage.getItem(STORAGE_LOCATION.savedSearch));
    //console.log("getLocalSavedSearch", savedSearch, typeof savedSearch)
    return savedSearch;
  }

  addLocalSavedSearch(newSavedSearch) {
    let savedSearch = JSON.parse(localStorage.getItem(STORAGE_LOCATION.savedSearch));
    if (savedSearch) {
        savedSearch.push(newSavedSearch);
    }
    else {
        savedSearch = [newSavedSearch];
    }
    localStorage.setItem(STORAGE_LOCATION.savedSearch, JSON.stringify(savedSearch));
  }
  setLocalSavedSearch(savedSearch) {
    localStorage.setItem(STORAGE_LOCATION.savedSearch,  JSON.stringify(savedSearch));
  }


  /**
   * Remove savedSearch from local storage.
   */
  removeLocalSavedSearch() {
    localStorage.removeItem(STORAGE_LOCATION.savedSearch);
  }
}

export default new SavedSearchService();
import axios from "axios";
import qs from "query-string";
import genericApi from "@/js/api/generic";
const XLSX = require('xlsx');

export default {
  endpointRootUrl: "/api/master",
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocument(documentId) {
    return genericApi.getDocument(this.endpointRootUrl, documentId);
  },

  getDocumentBySlug(slug) {
    return genericApi.getDocumentByField(this.endpointRootUrl, "slug", slug);
  },

  /**
   * Get result for user or for all users
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocuments(data) {
    return genericApi.getDocuments(this.endpointRootUrl, data);
  },

  exportCasData(data) {
    return genericApi.export(this.endpointRootUrl + "/export/cas", data);
    /*
    let axiosConfig = {};
    let qsResult = qs.stringify(data);
    qsResult = qsResult ? "?" + qsResult : "";
    Logger.debug(this.endpointRootUrl + " api: getDocuments", data, qsResult);
    return axios
      .get(this.endpointRootUrl + "/export/cas" + qsResult, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug(this.endpointRootUrl + " api: getDocuments resp", response);
        return response.data;
      });
     */
  },
  exportSourcesData(data) {
    return genericApi.export(this.endpointRootUrl + "/export/sources", data);
  },

  /**
   *Obsolete
   * @returns {Promise<AxiosResponse<T>>}
   */
  downloadResults(reqData) {
    let axiosConfig = {};
    Logger.debug("downloadResults", data);
    let data = JSON.parse(JSON.stringify(reqData));
    Logger.debug("downloadResults", data);
    data.query = JSON.stringify(data.query);
    data.filter = JSON.stringify(data.filter);
    Logger.debug("downloadResults2", data);
    let qsResult = qs.stringify(data),
      projectId = data.projectId;
    Logger.debug("qsResult", qsResult);
    qsResult = qsResult ? "?" + qsResult: "";
    Logger.debug("getResultsOffset", data, qsResult);

    return axios
      .get(this.endpointRootUrl + "/export/project/" + projectId + "/" + qsResult, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug("exportResults", response);
        let filename = "results-export";
        if (reqData && reqData.format === "json") {
          let jsonString = JSON.stringify(response.data);
          const url = window.URL.createObjectURL(new Blob([jsonString]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename + ".json");
          document.body.appendChild(link);
          link.click();
        } else if (reqData && reqData.format ==="xls") {
          /* this line is only needed if you are not adding a script tag reference */
          //if(typeof XLSX == 'undefined') XLSX = require('xlsx');

          //flatten json
          let flatObj = this.flattenArray(response.data, reqData.config.excluded);
          //Logger.debug("flatObj", flatObj);

          /* make the worksheet */
          let options = {
              header: reqData.config.selected
            },
            ws = XLSX.utils.json_to_sheet(flatObj, options); // function changes options

          /* add to workbook */
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "results");

          /* generate an XLSX file */
          XLSX.writeFile(wb, filename + ".xlsx");
        }
      });
  },

 // TODO duplicate in global mixin => move to lib.js
  flattenArray: function(data, remFieldsArray) {
    let retArray = [];
    for (let i = 0, len = data.length; i < len; i++) {
      for (let j=0, lenRem = remFieldsArray.length; j < lenRem; j++) {
        delete data[i][remFieldsArray[j]];
      }
      retArray.push(this.flattenObj(data[i]));
    }
    return retArray;
  },
  // TODO duplicate in global mixin => move to lib.js
  flattenObj: function(data) {
    var result = {};
    function recurse (cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for(var i=0, l=cur.length; i<l; i++)
          recurse(cur[i], prop + "[" + i + "]");
        if (l == 0)
          result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop+"."+p : p);
        }
        if (isEmpty && prop)
          result[prop] = {};
      }
    }
    recurse(data, "");
    return result;
  }
};

<template>
  <div class="fcx-detail">
    <h4>Health & Hazards</h4>
    <div class="row">
      <div class="col-12 mb-4">
        <h6>
          This substance is
          <span :class="{'text-danger': SubstanceCategoryX===2,'text-warning': SubstanceCategoryX===1, 'text-info': SubstanceCategoryX===0}">
            {{ SubstanceTextX }}
          </span>
          based on the following information
        </h6>
        <cite>Rating system further described on <FCXDataDescription/></cite>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>Included in authoritative hazard lists? - {{ IsSubstancePriorityHazardousX | boolToSpeech }}</h6>
        <ul>
          <li v-if="!IsSubstancePriorityHazardousX">
            no sources available
          </li>
          <li
            v-for="(item,index) in SubstancesPriorityHazardousX"
            :key="index"
          >
            <DataComponent :value="Extractions[item]" />
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>GHS Signal Words</h6>
        <DataComponent
          :value="GHS_Lists.GHS_signal_word"
          :show-label="false"
          :show-value="true"
        />
        <br>
        <h6>
          <DataComponent
              class="d-inline-block"
              :value="GHS_Lists.GHS_pictogram"
              :show-label="false"
              :show-value="false"
          >GHS Pictograms</DataComponent>
        </h6>
        <DataPictogramComponent :value="GHS_Lists.GHS_pictogram" />
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>Included in non-authoritative hazard lists? - {{ IsSubstancePotentialConcernX | boolToSpeech }}</h6>
        <ul>
          <li v-if="!IsSubstancePotentialConcernX">
            no sources available
          </li>
          <li
            v-for="(item,index) in SubstancesPotentialConcernX"
            :key="index"
          >
            <DataComponent :value="Extractions[item]" />
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-6 mb-2">
      </div>

      <div class="col-12 mb-2">
        <h6>Known health effects? - {{ HasSubstanceHealthEffectsX | boolToSpeech }}</h6>
        <ul>
          <li v-if="!HasSubstanceHealthEffectsX">
            no sources available
          </li>
          <li
            v-for="(item,index) in SubstancesHealthEffectsX"
            :key="index"
          >
            <!-- show here values instead of names -->
            <DataComponent
              :value="Extractions[item]"
            />
          </li>
        </ul>
      </div>

      <div class="col-12 mb-2">
        <h6>on ChemSec SIN list? - {{ IsSinListed | boolToSpeech }}</h6>
        <ul v-if="IsSinListed">
          <li>
            <DataComponent
              class="d-inline-block"
              :value="SIN_Lists.SIN_listed"
            />
          </li>
        </ul>
      </div>

      <div class="col-12 text-sm-left">
        <cite>FPF is in the middle of a research project to gather more information about which FCCs have been measured in humans and their effects on health. Read more: <a
          target="_blank"
          rel="noopener"
          href="https://www.foodpackagingforum.org/fcch"
        >https://www.foodpackagingforum.org/fcch</a></cite>
        <!--
        <br>
        For more in depth information on which chemicals have been measured in humans, visit: <a target="_blank" rel="noopener" href="https://www.foodpackagingforum.org/fcchumon">https://www.foodpackagingforum.org/fcchumon</a>.
        <br>
        For more in depth information on which chemicals have been measured in humans, visit: <a target="_blank" rel="noopener" href="https://www.foodpackagingforum.org/fcchelix">https://www.foodpackagingforum.org/fcchelix</a>.
        -->
      </div>
    </div>
  </div>
</template>

<script>
import DataComponent from "@/components/Fcx/DataComponent.vue";
import DataPictogramComponent from "@/components/Fcx/DataPictogramComponent.vue";
import FCXDataDescription from "@/components/Fcx/static/FCXDataDescription.vue";

export default {
  name: "DetailHealth",
  components: {FCXDataDescription, DataPictogramComponent, DataComponent},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    Extractions() {
      return this.localInput.extractions || {};
    },
    Lists() {
      return this.localInput.lists || {};
    },
    GHS_Lists() {
      return this.Lists.GHS || {};
    },
    SIN_Lists() {
      return this.Lists.SIN || {};
    },
    IsSinListed() {
      return this.Lists.SIN && this.Lists.SIN.SIN_listed;
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>

</style>

import axios from "axios";
import genericApi from "./generic";

export default {
  endpointRootUrl: "/api/users",

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getUser(userId) {
    return genericApi.getDocument(this.endpointRootUrl, userId);
  },

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getCurrentUser() {
    let axiosConfig = {};
    Logger.trace("getCurrentUser");
    return axios
      .get(this.endpointRootUrl + "/current", axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.trace("getCurrentUser resp", response);
        return response.data; //without data due to the fact that vuetable is consuming this...
      });
  },

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getUsers(data) {
    return genericApi.getDocuments(this.endpointRootUrl, data);
  },

  deleteUser(userId) {
    return genericApi.deleteDocument(this.endpointRootUrl, userId);
  },

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  login(data) {
    let axiosConfig = {};
    Logger.trace("login ", data);
    return axios
      .post(this.endpointRootUrl + "/login", data, axiosConfig)
      .then(response => {
        Logger.trace("login response", response);
        // returns obj instead of array...
        let token = response.data.token;
        axios.defaults.headers.common['Authorization'] = token;
        return response.data;
      });
  },

  /**
   * signUps user and if successful login as well
   * @param data
   * @returns {Promise<{user: *, token: *}>}
   */
  signUp(data) {
    let axiosConfig = {};
    Logger.trace("signUp ", data);
    return axios
      .post(this.endpointRootUrl + "/", data, axiosConfig)
      .then(response => {
        Logger.trace("signUp response", response);
        let token = response.headers['x-auth-token'];
        axios.defaults.headers.common['Authorization'] = token;
        let retObj = {
          token: token,
          user: response.data
        };
        return retObj;
      });
  },
  forgotPassword(data) {
    let axiosConfig = {};
    Logger.trace("forgotPassword ", data);
    return axios
      .post(this.endpointRootUrl + "/forgotpassword", data, axiosConfig)
      .then(response => {
        Logger.trace("forgotPassword response", data);
        return response.data;
      });
  },
  resetPassword(data) {
    let axiosConfig = {};
    Logger.trace("resetPassword ", data);
    return axios
      .post(this.endpointRootUrl + "/resetpassword", data, axiosConfig)
      .then(response => {
        Logger.trace("resetPassword response", response);
        return response.data;
      });
  },
  logout() {
    let axiosConfig = {};
    Logger.trace("logout ");
    return axios
      .post(this.endpointRootUrl + "/logout", {}, axiosConfig)
      .then(response => {
        Logger.trace("logout response");
        delete axios.defaults.headers.common['Authorization']
        return response.data;
      });
  }

};

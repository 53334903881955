<template>
  <a href="mailto:fcx@fp-forum.org">fcx@fp-forum.org</a>
</template>
<script>
export default {
  name: 'FPFEmail',
}
</script>

<style scoped>

</style>
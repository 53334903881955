<template>
  <div class="disclaimer">
    Please be advised, we cannot guarantee the real-time currency of the data available on FCX.
    Food contact chemical-related information may change over time due to evolving regulations, scientific advancements, and new research findings.
    <br><br>
    Before relying on any data obtained from FCX, we strongly encourage you to refer to our <FCXDataDescription /> for specific information on when the data was last updated.
  </div>
</template>
<script>
import FCXDataDescription from "@/components/Fcx/static/FCXDataDescription.vue";

export default {
  name: 'FCXDisclaimer',
  components: {FCXDataDescription},
}
</script>

<style scoped>

</style>

<template>
  <div class="dataComponent" v-if="localInput">
    <label v-popover:popover1 title="Click for additional information">
      <!-- default slot name given by component inside html -->
      <span v-if="$slots.default">
        <slot></slot>
      </span>
      <span v-else>
        <span v-if="ShowLabel">{{ CurrentLabel }}</span>
        <span v-if="ShowLabel && ShowValue && CurrentLabel && CurrentValue">:</span>
        <span v-if="ShowValue">
          <span v-if="IsDataTypeArray">
            {{CurrentValue.join(", ")}}
            <!--
            <ul>
              <li v-for="(item,index) in CurrentValue" :key="index">{{item}}</li>
            </ul>
            -->
          </span>
          <span v-else-if="IsDataTypeCSV">{{CurrentValue}}</span>
          <span v-else>{{CurrentValue}}</span>
        </span>
        <span v-if="ShowNotes">
          {{ DataNotes }}
        </span>

        <!-- before popover was opened by info icon | now by click on label i.e. shown text (label, value etc.)-->
        <!--
        <span
          v-popover:popover1
          class="fcx-popover-info"
          type="primary"
          size="sm"
          icon
        >
          <sup><i class="fa fa-info-circle" /></sup>
        </span>
        -->
      </span>
      <!-- suffix -->
      <ExternalLink v-if="ShowSuffix && DataUri" role="button" class="btn btn-link btn-secondary btn-sm m-0 p-0 pl-1" :link="DataUri" title="Link to external reference"><sup><i class="fas fa-external-link-alt" /></sup></ExternalLink>
    </label>

    <el-popover
      ref="popover1"
      popper-class="popover popover-primary fcx-popover"
      placement="right"
      width="200"
      trigger="click"
    >
      <h3 class="popover-header">
        <span class="fcx-info">Details</span>
        {{CurrentLabel}}
      </h3>
      <div class="popover-body">
        <div  v-if="Findings">
          <h6>Findings</h6>
          <dl class="ml-1 mb-2" :key="index" v-for="(item,index) in Findings">
            <dt>
              <span v-if="item.label">{{item.label}}</span>
              <span v-else>{{item.name}}</span>
              <span v-if="DevModeX && item.type"> ({{item.type}})</span>
            </dt>
            <dd>
              {{item.values}}
            </dd>
          </dl>
        </div>
        <div v-else>
          <dl class="ml-1">
            <dt>Value <span v-if="DevModeX && DataType">({{DataType}})</span></dt>
            <dd>
              <span v-if="IsDataTypeArray">
                <ul>
                  <li v-for="(item,index) in CurrentValue" :key="index">{{item}}</li>
                </ul>
              </span>
              <span v-else-if="IsDataTypeCSV">{{CurrentValue}}</span>
              <span v-else>{{CurrentValue}}</span>
            </dd>
          </dl>
        </div>
        <!--
        <el-table v-if="CurrentValueKeys && DataType === 'csv'" :data="CurrentValue">
          <el-table-column :key="idx" v-for="(el,idx) in CurrentValueKeys"  :property="el.toString()" :label="el"></el-table-column>
        </el-table>
        -->


        <div v-if="DataNotes">
          <hr>
          <dl class="ml-1">
            <dt>Notes</dt>
            <dd><cite>{{DataNotes}}</cite></dd>
          </dl>
        </div>

        <div>
          <hr>
          <h6>Source</h6>

          <dl class="ml-1">
            <dt v-if="DevModeX && DataRefSourceId">ID</dt>
            <dd v-if="DevModeX && DataRefSourceId">{{DataRefSourceId}}</dd>

            <dt v-if="DevModeX">Key</dt>
            <dd v-if="DevModeX">{{DataKey}}</dd>

            <dt>Name</dt>
            <dd>{{DataName}}</dd>

            <dt v-if="DataSourceType">Type</dt>
            <dd v-if="DataSourceType">{{DataSourceType}}</dd>

            <dt v-if="DataAuthors">Authors</dt>
            <dd v-if="DataAuthors">{{DataAuthors}}</dd>

            <dt v-if="DataJournalTitle">Journal</dt>
            <dd v-if="DataJournalTitle">{{DataJournalTitle}}</dd>

            <dt v-if="DataPublicationDate">Publication Date</dt>
            <dd v-if="DataPublicationDate">{{DataPublicationDate}}</dd>
          </dl>

        </div>

        <div v-if="DataUri">
          <hr>
          <dl>
            <dt>Ext. Reference</dt>
            <dd>Additional information <a target="_blank" rel="noopener" :href="DataUri">here</a></dd>
          </dl>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {Button} from "@/components";
import {Table, TableColumn,Popover} from "element-ui";
import ExternalLink from "@/components/Fcx/ExternalLink.vue";

export default {
  name: "DataComponent",
  mixins: [],
  components: {
    ExternalLink,
    [Popover.name]: Popover,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    showValue: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: false
    },
    showSuffix: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      gridData: [
        { "ToxDB": "120", "PubMedArticles": "144", "PubchemDataSources": "93", "CPDatCount": "37" }
      ]
    };
  },
  mounted() {

  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    // properties
    Type() {
      return this.type;
    },
    ShowLabel() {
      return this.showLabel;
    },
    ShowValue() {
      return this.showValue;
    },
    ShowNotes() {
      return this.showNotes;
    },
    ShowSuffix() {
      return this.showSuffix;
    },
    IsListType() {
      return this.Type === 'list';
    },
    Findings() {
      return this.localInput.findings;
    },
    // Data localInput
    DataName() {
      return this.localInput.name;
    },
    DataLabel() {
      return this.localInput.label;
    },
    DataType() {
      return this.localInput.type ;
    },
    DataValues() {
      return this.localInput.values ;
    },
    DataRefSourceId() {
      return this.localInput.refSourceId;
    },
    DataKey() {
      return this.localInput.key;
    },
    DataUri() {
      return this.localInput.uri;
    },
    DataSourceType() {
      return this.localInput.sourceType;
    },
    DataAuthors() {
      return this.localInput.authors;
    },
    DataJournalTitle() {
      return this.localInput.journalTitle;
    },
    DataPublicationDate() {
      return this.localInput.publicationDate;
    },
    DataNotes() {
      return this.localInput.notes;
    },
    // computed
    IsDataTypeArray() {
      return this.DataType === 'array';
    },
    IsDataTypeCSV() {
      return this.DataType === 'csv';
    },
    CurrentLabel() {
      return this.DataLabel || this.DataName; // either dataLabel or dataName
    },
    CurrentValue() {
      if(this.DataType === 'number') {
        return Number(this.DataValues);
      }
      else if (this.DataType === 'string') {
        return String(this.DataValues);
      }
      else if(this.DataType === 'array') {
        const ar = this.DataValues.split(";");
        return ar;
      }
      else if(this.DataType === 'csv') {
        const csvVal = this.DataValues;
        const lines = csvVal.split("\n");
        const headers = lines[0].split(";");
        lines.splice(0, 1);

        //console.log("lines", lines)
        //console.log("headers", headers)

        // transform to json
        let ret = [];
        for (let i = 0; i < lines.length; i++) {
          const data = lines[i].split(";");
          let dataObj = {};
          for(let j=0; j <headers.length; j++) {
            dataObj[headers[j]] = data[j]
          }
          ret.push(dataObj);
        }
        //console.log("ret", ret, Object.keys(ret[0]))
        return ret;
      }
      else {
        return this.DataValues;
      }
    },
    CurrentValueKeys() {
      return Object.keys(this.CurrentValue[0]);
    }
  },
  methods: {}
};

</script>
<style scoped lang="scss">
.dataComponent {
  label {
    cursor: help;
  }
}

</style>

<template>
  <transition name="fade">
    <!-- Page Loader -->
    <div v-show="LoadingX" class="loader_wrapper inner align-items-center text-center">
      <div class="load7 load-wrapper">
        <div class="loading_img"></div>
        <div class="loader"> Loading... {{LoadingX}} </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <!-- Page Loader Ends -->
  </transition>
</template>

<script>

export default {
  name: "PageLoader",
  components: {

  },
  mounted: function() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*
  .loader_wrapper.inner .load-wrapper .loading_img {
    background: url("../assets/logo.png") no-repeat center center;
    height: 70px;
  }
   */
</style>

<template>
  <a href="https://www.foodpackagingforum.org" target="_blank" rel="noopener">Food Packaging Forum Foundation</a>
</template>
<script>
export default {
  name: 'FPF',
}
</script>

<style scoped>

</style>
<template>
  <div class="fcx-detail">
    <h4>
      Migration & Extraction <ExternalLink class="btn btn-link m-0 p-0" link="https://www.foodpackagingforum.org/fccmigex"> <i class="fas fa-external-link-alt" /></ExternalLink>
    </h4>
    <div class="row">
      <div class="col-12 mb-2">
        <h6>Measured transferring from FCMs? - {{IsDetected | boolToSpeech}}</h6>
        <div class="row">
          <div class="col-12 col-md-6 mb-2">
            <strong>Primary Material</strong>
            <br>
            <ul>
              <li v-if="PrimaryFCMs.length === 0">no material available</li>
              <li :key="index" v-for="(item,index) in PrimaryFCMs">{{ item }}</li>
            </ul>
          </div>
          <div class="col-12 col-md-6 mb-2">
            <strong>Secondary Material</strong>
            <br>
            <ul>
              <li v-if="SecondaryFCMs.length === 0">no material available</li>
              <li :key="index" v-for="(item,index) in SecondaryFCMs">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!--
      <div class="col-12 col-md-6 mb-2">
        <h6>Measured in the human body? - N/A</h6>
      </div>
      -->
      <div class="col-12 mb-3">
        <h6>Statistics</h6>
        <cite>coming soon</cite>
      </div>

      <div class="col-12 mb-2">
        <h6>Sources</h6>
        <div v-if="!localInput.extractions.migex">
          no sources available
        </div>
        <table v-else class="table table-responsive table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" style="min-width: 250px">Name</th>
              <th scope="col" style="min-width: 250px">Authors</th>
              <th scope="col">Journal</th>
              <th scope="col">Year</th>
              <th scope="col">URI</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(col, index) in localInput.extractions.migex">
              <th scope="row">{{ index }}</th>
              <td>{{ col.name }}</td>
              <td>{{ col.authors }}</td>
              <td>{{ col.journalTitle }}</td>
              <td>{{ col.publicationDate }}</td>
              <td><ExternalLink :link="col.uri"/></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 text-sm-left mt-3">
        For more in depth information, visit: <ExternalLink link="https://www.foodpackagingforum.org/fccmigex" />.
      </div>
    </div>
  </div>
</template>

<script>
//import { Bar } from 'vue-chartjs';

//import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


import ExternalLink from "@/components/Fcx/ExternalLink.vue";

export default {
  name: "DetailResearch",
  components: {
    ExternalLink

  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      primaryFCMs: [],
      secondaryFCMs: [],
      detected: false
    };
  },
  mounted() {
    this.generate();
  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    PrimaryFCMs: {
      get() {
        return this.primaryFCMs;
      },
      set(PrimaryFCMs) {
        this.primaryFCMs = PrimaryFCMs;
      }
    },
    SecondaryFCMs: {
      get() {
        return this.secondaryFCMs;
      },
      set(SecondaryFCMs) {
        this.secondaryFCMs = SecondaryFCMs;
      }
    },
    IsDetected: {
      get() {
        return this.detected;
      },
      set(IsDetected) {
        this.detected = IsDetected;
      }
    },
  },
  methods: {
    generate() {
      const migexObj = this.localInput.extractions.migex || [];
      const migexArray = JSON.parse(JSON.stringify(migexObj));

      //let category = 0;
      // iterate over extractions keys
      for(let extractionEntry of migexArray) {
        const entryArray = extractionEntry.values;
        for(let entry of entryArray) {
          const detected = entry.detected.toLowerCase();
          // only add if detected
          if(detected === "yes") {
            this.IsDetected = true;
            //if(entry.detected)
            const primMaterial = entry.fcm_main,
              secMaterial = entry.fcm_focus;
            //TODO use sets instead
            if (primMaterial && !this.PrimaryFCMs.includes(primMaterial)) {
              this.PrimaryFCMs.push(primMaterial);
            }
            if (secMaterial && !this.SecondaryFCMs.includes(secMaterial)) {
              this.SecondaryFCMs.push(secMaterial);
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
table {
  font-size: 12px;
}
</style>

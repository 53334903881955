
<template>
  <div class="fcx-filter-facet">
    <collapse v-if="IsFoldable">
      <collapse-item
        :itemId="field"
        :open="IsOpen"
        no-icon
        class="card-header"
      >
        <span
          slot="title"
          class="mb-0 text-primary"
        >
          {{label}}
          <span
            v-if="note"
            v-popover:popover1
            class="fcx-note"
            type="primary"
            size="sm"
            icon
          >
            <sup><i class="fa fa-info-circle" /></sup>
          </span>

          <i class="now-ui-icons arrows-1_minimal-down" />
        </span>
        <el-select
          :required="true"
          v-model="val"
          class="select-primary"
          :multiple="!TypeIsSingle"
          clearable
          filterable
          :allow-create="allowCreate"
          default-first-option
          :placeholder="placeholder"
          @change="updateFacet"
        >
          <el-option
            v-for="entry in selection"
            :key="entry.label"
            class="select-primary"
            :value="entry.value"
            :label="entry.label"
          />
        </el-select>
        <!--
        val {{val}}<br>
        preset {{preset}}<br>
        field: {{field}}<br>
        localInput: {{localInput}}<br>
        selection: <pre>{{selection}}</pre>
        -->

      </collapse-item>
    </collapse>
    <div v-else>
      <span
        slot="title"
        class="mb-2 text-primary"
      >
        {{label}}
        <span
          v-if="note"
          v-popover:popover2
          class="fcx-note"
          type="primary"
          size="sm"
          icon
        >
          <sup><i class="fa fa-info-circle" /></sup>
        </span>
      </span>

      <el-select
        :required="true"
        v-model="val"
        class="select-secondary"
        clearable
        filterable
        :allow-create="allowCreate"
        default-first-option
        :multiple="!TypeIsSingle"
        :placeholder="placeholder"
        @change="updateFacet"
      >
        <el-option
          v-for="entry in selection"
          :key="entry.label"
          class="select-primary"
          :value="entry.value"
          :label="entry.label"
        />
      </el-select>
    </div>
    <div v-if="note">
      <!-- popover for foldable facet -->
      <el-popover
        ref="popover1"
        popper-class="popover popover-primary fcx-popover"
        placement="right"
        width="200"
        trigger="hover"
      >
        <h3 class="popover-header">
          {{note.title}}
        </h3>
        <div class="popover-body" v-html="note.content" />
      </el-popover>

      <!-- popover for non-foldable facet -->
      <el-popover
        ref="popover2"
        popper-class="popover popover-primary fcx-popover"
        placement="right"
        width="200"
        trigger="hover"
      >
        <h3 class="popover-header">
          {{note.title}}
        </h3>
        <div class="popover-body"  v-html="note.content" />
      </el-popover>
    </div>
  </div>
</template>


<script>
import {Collapse, CollapseItem, } from "@/components";
import {Select, Option, Popover} from 'element-ui';

export default {
  name: 'FilterFacet',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Popover.name]: Popover,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  props: {
    // add or type
    value: {
      type: Object,
      required: false,
      default: function() {
        return {}
      }
    },
    preset: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      default: 'single'
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select entry"
    },
    field: {
      type: String,
      required: true
    },
    selection: {
      type: Array,
      required: false,
      default: () => []
    },
    foldable: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    note: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      val: ""
    }
  },
  watch: {
    // if localInput changed by the URI it will be preset here
    localInput: {
      handler: function (val, oldVal) {
        Logger.debug("localInput changed", val, oldVal);
        if(this.localInput[this.field]) {
          if(this.TypeIsSingle) {
            // this.val = string
            this.val = this.localInput[this.field];
          } else {
            // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
            this.val = this.localInput[this.field].$in;
            if(!this.val) {
              this.val = [];
            }
          }
        } else {
          if(this.TypeIsSingle) {
            this.val = "";
          } else {
            // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
            this.val = [];
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    Logger.debug("mounted filter facet", this.field, this.localInput,  this.preset);
    if(this.preset) {
      this.val = this.preset;
    }
  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    TypeIsSingle() {
      return this.type === 'single';
    },
    IsFoldable() {
      return this.foldable;
    },
    IsOpen() {
      if(this.IsFoldable) {
        if(this.TypeIsSingle) {
          return !!this.val;
        } else {
          return this.val && this.val.length > 0;
        }
      }
      return false;
    }
  },
  methods: {
    updateFacet() {
      Logger.debug("updateFacet", this.selection);
      // check if changed

      // delete field from localInput
      //delete this.localInput[this.field];
      const cpObject = JSON.parse(JSON.stringify(this.localInput));

      // is val single and set at all?
      if(this.TypeIsSingle && this.val) {
        // this.val = string
        cpObject[this.field] = this.val;
      } else if(!this.TypeIsSingle && this.val && this.val.length > 0) { // multi and at least one array elem
        // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
        cpObject[this.field] = { $in: this.val };
      } else {
        delete cpObject[this.field];
      }
      this.localInput = cpObject;
    }
  }
}
</script>

<style scoped lang="scss">
.fcx-note {
  display: inline-block;
}
// icon for fcx-note should not be rotated in the case of un/folding
.card-collapse .card .card-header a[data-toggle=collapse] .fcx-note i {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
</style>

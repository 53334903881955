<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              Terms & Conditions
            </h3>

            <p>

              The Food Contact Explorer (“FCX”, “we”, “us”, “our”) is a product of the <FPF /> (FPF).
              FPF is a registered charitable organization under Swiss law.
            </p>

            <h4>Summary&nbsp;</h4>
            <p>
              By accessing material on the FCX website and tool (“the Services”), you:&nbsp;
            </p>
            <p>Acknowledge that FPF owns the copyright in or has permission to use all materials displayed on
              the website <router-link :to="{ name: 'home' }">https://fcx.foodpackagingforum.org</router-link> (“the Website”). You are
              authorized to read and use this information for your use. You are not authorized to share the materials on
              FCX with others by copying or sending it to them without appropriate reference. You can also tell
              colleagues where they can find this material for themselves. The materials displayed on the Website are
              protected by copyright law.
            </p>
            <p>Agree that if you choose to use the communications tools provided, including email and list servers, you
              give FPF full and unrestricted permission to use and republish any materials that you post to the
              Website in any way FPF deems fit, without compensation to you. You may not post anything to the
              Services that is unlawful, obscene, defamatory, or offensive. You may not use this site to exploit any
              commercial venture with which you are involved, including mass email solicitations (spamming) of any
              nature. You understand that if FPF, in its sole discretion, believes you are abusing the
              privileges in any manner, FPF will delete your postings and may terminate without notice or
              liability your right to access the Services.
            </p>
            <p>Understand that THE CONTENTS OF FCX ONLINE PLATFORM AND YOUR ABILITY TO ACCESS THE SERVICES IS
              PROVIDED “AS IS.” FCX DOES NOT WARRANT OR GUARANTEE THAT ANY FILES THAT YOU DOWNLOAD FROM ITS
              SERVICES ARE VIRUS FREE. You are encouraged to use virus-checking software.
              You may find links from FCX to sites maintained by others.
              FCX has no responsibility for the contents on linked sites.
            </p>
            <p>Understand that THE CONTENTS OF FCX ONLINE PLATFORM AND YOUR ABILITY TO ACCESS THE SERVICES IS
              PROVIDED “AS IS.” FPF DOES NOT WARRANT OR GUARANTEE THAT ANY FILES ARE ERROR FREE. Data provided
              in the Services&nbsp; is the result of internal and external data collection and/or modelling, and may
              contain errors. The user of the data is the only responsible of any decisions or action taken using these
              data. FPF cannot be held responsible for use or mis-use of the data even in the case of errors in
              the datasets.
            </p>
            <p>Agree, as a condition of being granted the right to access and use the Services, that FCX is not
              liable to you for anything that happens to you as a result of using any of the
              Services.&nbsp;<b>&nbsp;</b></p>

            <h4>Web Links to the Website&nbsp;</h4>
            <p>Web links to the Website are generally allowed but must not give rise to false associations. FCX
              has adopted this policy on linking to the Website:
            </p>
            <ul>
              <li aria-level="1">Links may not use the FCX logo without the prior permission of FPF.&nbsp;</li>
              <li aria-level="1">Links may not state or imply that FCX endorses any product or service of the
                linking organization or that there is any relationship between the organizations without the prior
                permission of FPF.&nbsp;
              </li>
              <li aria-level="1">The link must attach to the FCX server. FCX content may not be
                mirrored or stored on any other site without prior permission.&nbsp;
              </li>
              <li aria-level="1">FCX content may not be framed on a linking site. The link must exit the
                linking site or open the FCX content in a new window.&nbsp;
              </li>
              <li aria-level="1">All references to FCX must accurately identify the <FPF /> and the funding sources.&nbsp;&nbsp;</li>
              <li aria-level="1">All links must be to the front page of the relevant topic. Deep links to specific
                content are not permitted (specific content pages may expire or be deleted without notice).&nbsp;
              </li>
            </ul>
            <p>For more information or to seek permission to link, contact: <FPFEmail /></p>

            <h4>Terms of Service</h4>
            <p>By accessing materials on any FCX online platform, you:</p>
            <p>Acknowledge that you understand that this site is owned and operated by FPF and that it contains
              copyrighted material for which FPF owns the copyright or has
              permission to use. You are authorized to download material from this Website for your use only as
              long as you keep intact all copyright and other proprietary notices and do not sell the material. You may
              not modify, copy, reproduce, publish, upload, post, transmit, or distribute any material from this
              Website, including any programming code and software in any manner, without the express written permission
              of FPF. Your rights are personal and may not be assigned or transferred by you to anyone else.
              You are solely responsible for all access to the site originating with your identification and any
              password needed for site access.</p>
            <p>You agree that you do not acquire any ownership rights by downloading any material.</p>

            <h4>Communication Tools&nbsp;</h4>
            <p>The Services may include email lists and discussion boards. FPF does not control the messages,
              information, or files delivered to the online communities. As a condition of use of any communication tool
              on this Website you may not:</p>
            <ul>
              <li aria-level="1">restrict or inhibit any other user from using them.</li>
              <li aria-level="1">post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene,
                vulgar, pornographic, profane, or indecent information of any kind, including, without limitation, any
                transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to
                civil liability, or otherwise violate any local, state, provincial, national, or international law.
              </li>
              <li aria-level="1">post or transmit any information, software, or other material that violates or
                infringes upon the rights of others, including material that is an invasion of the privacy or publicity
                rights or that is protected by copyright, trademark, or other proprietary right, or derivative works
                with respect thereto without first obtaining permission from the owner or right holder.
              </li>
              <li aria-level="1">post or transmit any information, software, or other material that contains a virus or
                other harmful component.&nbsp;
              </li>
            </ul>

            <h4>Termination&nbsp;</h4>
            <p>This agreement is effective until terminated. You may terminate at any time simply by no longer using the
              Services. FPF may terminate the Website or any of its Services at any time without notice.
              Additionally, FPF may terminate your right to access the Services for violation of any of
              these terms of service by you at any time without notice. In the event of termination, you will no longer
              be authorized to access the terminated Services. The disclaimers and limitations of liabilities based on
              prior use of the Website as set forth in this agreement shall survive.</p>

            <h4>Use of Likeness</h4>
            <p>By participating in FCX Virtual Events (webinars, live sessions etc.), you acknowledge and agree
              to grant FPF the right to record, film, livestream, photograph, or capture your likeness in any
              media now available or hereafter developed and to distribute, broadcast, use, or otherwise globally to
              disseminate, in perpetuity, such media without any further approval from you or any payment to you.
              This grant to FPF includes, but is not limited to, the right to edit such media,
              the right to use the media alone or together with other information,
              and the right to allow others to use or disseminate the media.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FPFEmail from "@/components/Fcx/static/FPFEmail.vue";
import FPF from "@/components/Fcx/static/FPF.vue";

export default {
  name: 'Terms',
  bodyClass: 'static-page',
  components: {FPF, FPFEmail},
  data() {
    return {}
  }
}
</script>
<style>
</style>

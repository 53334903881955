import axios from "axios";
import qs from "querystring";

export default {
  endpointRootUrl: "/api/support",

  contactRequest(data) {
    let axiosConfig = {};
    Logger.debug("support contactRequest", data);

    return axios
      .post(this.endpointRootUrl + "/contact", data, axiosConfig)
      .then(response => {
        Logger.debug("support contactRequest resp", data);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  /**
   * Same as in results.api
   * @param sourceId
   * @param data
   * @returns {Promise<unknown>|Promise<AxiosResponse<any>>}
   */
  issueRequest(data) {
    let axiosConfig = {};
    Logger.debug("support issueRequest", data);

    return axios
      .post(this.endpointRootUrl + "/request/issue", data, axiosConfig)
      .then(response => {
        Logger.debug("support issueRequest resp", data);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  }
};

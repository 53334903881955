

<template>
  <div class="dataComponent" v-if="value">
    <span :key="idx" v-for="(entry,idx) in CurrentValue">
      <img
        v-if="idx%2==0"
        :src="'/img/pages/' + entry + '.jpg'"
        width="100px"
        height="100px"
        style="margin: 5px;"
        :title="entry + ' ' + CurrentValue[idx+1]"
      >
    </span>
  </div>
</template>

<script>

export default {
  name: "DataPictogramComponent",
  mixins: [],
  components: {

  },
  props: {
    value: {
      type: [Object, String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {};
  },
  mounted() {

  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    CurrentType() {
      return this.localInput.type ;
    },
    CurrentName() {
      return this.localInput.name;
    },
    CurrentLabel() {
      return this.localInput.label || this.CurrentName;
    },
    CurrentValue() {
      let retArray = [];
      if(this.CurrentType === 'array') {
        retArray = this.localInput.values.split(";");
      }
      return retArray; //this.localInput.values; //no array
    }
  },
  methods: {}
};

</script>
<style scoped lang="scss">
</style>
<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              Page Not Found
            </h3>
            <p>
              Oh chemical, where art thou.<br>Oops! It seems that this page does not exist.
              <br>
              You can navigate back to
              <router-link to="/">
                main page
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  bodyClass: 'static-page',
  components: {

  },
  data() {
    return {}
  }
}
</script>
<style>
</style>

<template>
  <div class="container detail" v-if="CurrentMasterX && CurrentMasterX.slug">
    <section class="header d-print-none">
      <div class="row mb-3">
        <div class="col-12 col-md-6 float-left">
          <Breadcrumb :suffix="CurrentMasterX.slug"></Breadcrumb>
        </div>
        <div class="col-12 col-md-6 float-right text-right">
          <button_group>

            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click="printPage"
              :title="'Print detail page of ' + CurrentMasterX.name"
            >
              <i class="fa fa-print" />
            </n-button>

            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click.native="modals.citations = true"
              title="Cite chemical details in different styles"
            >
              <i class="fa fa-quote-right" />
            </n-button>
            <n-button
              type="fcx-orange"
              class="mt-0 mb-0 btn-icon"
              @click.native="modals.downloads = true"
              title="Downloads"
            >
              <i class="fa fa-download" />
            </n-button>
          </button_group>
          <!--
          Tags
          <el-tag
            v-for="(tag,index) in Tags"
            :key="index"
            size="small"
            type="primary"
            :closable="false"
          >
            {{ tag }}
          </el-tag>
          -->
        </div>
      </div>
    </section>

    <section class="tabs mb-5">
      <div class="row">
        <div class="col-12 ml-auto mr-auto">
          <!--
          <p class="category">
          </p>
          -->
          <!-- Tabs with Background on Card -->
          <div class="card">
            <!-- only for print -->
            <h2 class="d-print-block d-none">Food Contact Xplorer</h2>
            <div class="fcx-print-header d-print-block d-none">FCX is a resource of the Food Packaging Forum Foundation</div>
            <div class="fcx-print-footer d-print-block d-none">FCX is a resource of the Food Packaging Forum Foundation</div>

            <div class="tab-header" data-background-color="primary">
              <div class="row">
                <div class="col-12 col-md-8">
                  <h4>
                    {{ CurrentMasterX.name }}<br>
                    {{ CAS }}
                  </h4>
                </div>
                <div class="col-12 col-md-4 text-right">
                  <badge :key="index" class="ml-1" type="outline-fcx-blue" v-for="(tag,index) in ChemFamily">
                    {{ tag }}
                  </badge>

                  <badge :key="index" class="ml-1" type="outline-fcx-yellow" v-for="(tag,index) in ChemFunction">
                    {{ tag }}
                  </badge>
                </div>
              </div>
            </div>
            <tabs
              type="neutral"
              tab-nav-wrapper-classes="card-header d-print-none"
              tab-content-classes="card-body"
              data-background-color="primary"
              tabContentClasses="p-3"
            >

              <tab-pane class="d-print-block">
                <template slot="label">
                  Overview
                </template>
                <DetailOverview :value="CurrentMasterX"></DetailOverview>
              </tab-pane>

              <tab-pane class="d-print-block">
                <template slot="label">
                  Inventories
                </template>
                <DetailInventory :value="CurrentMasterX"></DetailInventory>
              </tab-pane>

              <tab-pane class="d-print-block">
                <template slot="label">
                  Migration & Extraction
                </template>
                <DetailResearch :value="CurrentMasterX"></DetailResearch>
              </tab-pane>

              <tab-pane class="d-print-block">
                <template slot="label">
                  Health & Hazards
                </template>
                <DetailHealth :value="CurrentMasterX"></DetailHealth>
              </tab-pane>

              <tab-pane class="d-print-block">
                <template slot="label" class="float-right">
                  Other
                </template>
                <DetailOther :value="CurrentMasterX"></DetailOther>
              </tab-pane>

              <tab-pane v-if="DevModeX">
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90" /> Dev
                </template>
                <DetailDev :value="CurrentMasterX"></DetailDev>
              </tab-pane>

              <template v-slot:footer >
                <div class="row m-3">
                  <div class="col-12 col-md-6">
                    <small>
                      Id: {{ CurrentMasterX._id }}
                    </small>
                  </div>
                  <div class="col-12 col-md-6 text-right">
                    <small>
                      Created: {{ CurrentMasterX.createdAt | timestampToString }} |
                      Updated: {{ CurrentMasterX.updatedAt | timestampToString }}
                    </small>
                  </div>
                </div>
              </template>
            </tabs>
          </div>
          <!-- End Tabs on plain Card -->
        </div>
      </div>

      <modal
        :show.sync="modals.citations"
        modalClasses="fcx-modal"
      >
        <h4
          slot="header"
          class="title title-up"
        >
          Citations
        </h4>
        <n-button
          type="fcx-orange"
          @click.native="modals.citations = false"
          @click="citeDetail('apa')"
          title="Cite chemical details in APA style and copy to clipboard"
        >
          Cite APA
        </n-button>

        <n-button
          type="fcx-orange"
          @click.native="modals.citations = false"
          @click="citeDetail('ama')"
          title="Cite chemical details in AMA style and copy to clipboard"
        >
          Cite AMA
        </n-button>

        <n-button
          type="fcx-orange"
          @click.native="modals.citations = false"
          @click="citeDetail('mla')"
          title="Cite chemical details in MLA style and copy to clipboard"
        >
          Cite MLA
        </n-button>
        <!--
        <hr>
        <n-button
          native-type="submit"
          type="secondary"
          @click="downloadCitation('tsv')"
          :icon="false"
          title="Download citations as TSV file (Tab Separated Values)"
        >
          <i class="fa fa-download" />
          Export (TSV)
        </n-button>
        <n-button
          native-type="submit"
          type="secondary"
          @click="downloadCitation('csv')"
          :icon="false"
          title="Download citations as CSV file (Semicolon Separated Values)"
        >
          <i class="fa fa-download" />
          Export (CSV)
        </n-button>
        <n-button
          native-type="submit"
          type="secondary"
          @click="downloadCitation('json')"
          :icon="false"
          title="Download citations as JSON file"
        >
          <i class="fa fa-download" />
          Export (JSON)
        </n-button>
        -->

        <template slot="footer">
          <n-button
            type="btn-default"
            @click.native="modals.citations = false"
          >
            Close
          </n-button>
        </template>
      </modal>

      <modal
        :show.sync="modals.downloads"
        modalClasses="fcx-modal"
      >
        <h4
          slot="header"
          class="title title-up"
        >
          Downloads
        </h4>

        <h6>Export Sources</h6>
        <n-button
          native-type="submit"
          type="fcx-orange"
          @click="downloadSourceList('tsv')"
          :icon="false"
          title="Download all sources as TSV file (Tab Separated Values)"
        >
          <i class="fa fa-download" />
          Export (TSV)
        </n-button>
        <n-button
          native-type="submit"
          type="fcx-orange"
          @click="downloadSourceList('csv')"
          :icon="false"
          title="Download all sources as CSV file (Comma-Separated Values)"
        >
          <i class="fa fa-download" />
          Export (CSV)
        </n-button>
        <n-button
          native-type="submit"
          type="fcx-orange"
          @click="downloadSourceList('json')"
          :icon="false"
          title="Download all sources as JSON file"
        >
          <i class="fa fa-download" />
          Export (JSON)
        </n-button>

        <hr>
        <FCXDisclaimer class="mt-2"/>

        <template slot="footer">
          <n-button
            type="btn-default"
            @click.native="modals.downloads = false"
          >
            Close
          </n-button>
        </template>
      </modal>
    </section>
  </div>
  <div v-else class="container no-detail">
    <section class="header d-print-none">
      <div class="row mb-3">
        <div class="col-12">
          <Breadcrumb></Breadcrumb>
        </div>
      </div>
    </section>
    <section class="tabs mb-5">
      <div class="row">
        <div class="col-12 ml-auto mr-auto">
          <h2>Substance was not found</h2>
          <router-link class="nav-link" :to="{ name: 'catalog' }">
            <font-awesome-icon
              icon="chart-line"
              class="left-icon square"
            ></font-awesome-icon>
            Back to catalog
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {Card, Tabs, TabPane, DropDown, Modal, Badge} from '@/components';
import Breadcrumb from "../components/Sections/Breadcrumb";
import NButton from "@/components/Button";
import Comment from "@/components/Comments/Comment";
import {Tag} from 'element-ui';
import {citationMixin} from "@/js/mixins/citation-mixin";
import DetailOverview from "@/pages/detail/DetailOverview.vue";
import DetailInventory from "@/pages/detail/DetailInventory.vue";
import DetailResearch from "@/pages/detail/DetaiResearch.vue";
import DetailHealth from "@/pages/detail/DetailHealth.vue";
import DetailOther from "@/pages/detail/DetailOther.vue";
import DetailDev from "@/pages/detail/DetailDev.vue";
import FCXDisclaimer from "@/components/Fcx/static/FCXDisclaimer.vue";
const NA = "n/a";

export default {
  name: "Detail",
  bodyClass: 'detail-page',
  components: {
    FCXDisclaimer,
    DetailDev,
    DetailOther,
    DetailHealth,
    DetailResearch,
    DetailInventory,
    DetailOverview,
    Modal,
    Badge,
    DropDown,
    [Tag.name]: Tag,
    Comment,
    NButton,
    Breadcrumb,
    Card,
    Tabs,
    TabPane
  },
  mixins: [citationMixin],
  data() {
    return {
      modals: {
        citations: false,
        downloads: false
      },
      options: {
        carousel: true
      },
      index: null,
      commentsPage: 1
    };
  },
  mounted() {
   Logger.debug("this.CurrentMasterX", this.CurrentMasterX);
  },
  computed: {
    CAS: function() {
      let id = NA;
      const cas = this.CurrentMasterX.cas;
      const cfsan = this.CurrentMasterX.cfsan;
      return cas.id || cfsan.id || NA;
    },
    FCMInventory() {
      if (this.CurrentMasterX.fcm_inventory && this.CurrentMasterX.fcm_inventory.length > 0) {
        return this.CurrentMasterX.fcm_inventory;
      }
      return [NA];
    },
    ChemFamily: function() {
      return this.CurrentMasterX.chemFamily;
    },
    ChemFunction: function() {
      return this.CurrentMasterX.chemFunction;
    },
    Comments() {
      if (this.CurrentMasterX.comments && this.CurrentMasterX.comments.length > 0) {
        return this.CurrentMasterX.comments;
      }
      return [NA];
    },
    SinFindings() {
      return this.CurrentMasterX?.extractions?.sin?.findings
    }

  },
  // FIXME not working at m
  beforeRouteUpdate(to, from, next) {
    let catalogItemId = to.params.id;
    this.$store.dispatch("GET_MASTER", catalogItemId).then(
      response => {
        // the above state is not available here, since it
        // it is resolved asynchronously in the store action
       Logger.debug("beforeEnter", response);
        if (response) {
          next();
        } else {
          next({ name: "home" });
        }
      },
      error => {
        // handle error here
        next({ name: "home" });
      }
    );
  },
  methods: {
    printPage() {
      window.print();
    },
    /**
     * download source list atm. only S fields
     * @param formatType
     */
    downloadSourceList(formatType) {
      if(!formatType) {
        formatType = "tsv";
      }
      const
        exportHeaderLine = [
          "Reference Type",
          "Author",
          "Year",
          "Title",
          "URL"
        ];

      let exportData = "";

      const exportDataLines = [];

      const addExportEntry = (entry, format) => {
        if(format === "json") {
          exportDataLines.push({
            "Reference Type": entry.sourceType,
            "Author": entry.authors,
            "Year": entry.publicationDate,
            "Title": entry.name,
            "URL": entry.uri
          });
        } else {
          exportDataLines.push({
            sourceType: entry.sourceType,
            author: entry.authors,
            year: entry.publicationDate,
            name: entry.name,
            uri: entry.uri
          });
        }
      };

      // iterate over all extractions
      for (const [key, entry] of Object.entries(this.CurrentMasterX.extractions)) {
        // TODO get rid of this check if we want to export all fields
        // migex special case because it is an array within the extractions
        if(key === "migex") {
          for(let migexEntry of entry) {
            addExportEntry(migexEntry, formatType);
          }
        } else {
          addExportEntry(entry, formatType);
        }
      }
      Logger.debug("exportDataLines json", formatType, exportDataLines);

      if(formatType === "json") {
        exportData = JSON.stringify(exportDataLines);
      } else {
        let delimiter = "\t";
        if(formatType === "csv") {
          delimiter = ",";
          // enforce delimiter
          exportData = "sep=" + delimiter + "\n";
        }
        // add header line
        exportData += exportHeaderLine.join(delimiter) + "\n";
        // add content lines
        for(let sourceEntry of exportDataLines) {
          exportData +=  '"' + sourceEntry.sourceType + '"' + delimiter +
            '"' + sourceEntry.author + '"' + delimiter +
            '"' + sourceEntry.year + '"' + delimiter +
            '"' + sourceEntry.name + '"' + delimiter +
            '"' + sourceEntry.uri + '"' +  "\n";
        }
      }
      this.download(exportData, "FCX-export-source-list-" + this.CurrentMasterX.slug + "." + formatType);
    },
  }
};
</script>

<style scoped lang="scss">

.detail-page {
  .tab-header {
    padding: 5px 0.7rem;
    h4 {
      margin: 0px;
      font-size: 1.2em;
    }
  }
  .container {
    margin-top: 100px;
    @media print {
      /* styles here */
      margin-top: 0px;
    }
  }
  .no-detail{
    height: 70vh;
  }
}

.footnote {
  font-size: 12px;
  font-style: italic;
}

.container.detail::v-deep .nav-item .nav-link,
.container.detail::v-deep .nav-item .nav-link.active {
  border-radius: 0.1875rem!important;
}

.container.detail::v-deep .card .nav-tabs {
  border-radius: 0px!important;
}


@media print {
  div.fcx-print-footer {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  div.fcx-print-header {
    position: fixed;
    top: 0;
    right: 0;
  }
}


</style>

<template>
  <a href="https://docs.google.com/document/d/1UEDlhzGMAPytChWKkGPB1H7CsrxNQZJrxSFIswSiJRA/edit?usp=drive_link" target="_blank" rel="noopener">FCX user guide</a>
</template>
<script>
export default {
  name: 'FCXUserGuide',
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./SavedSearch.vue?vue&type=template&id=50d633a2&scoped=true&"
import script from "./SavedSearch.vue?vue&type=script&lang=js&"
export * from "./SavedSearch.vue?vue&type=script&lang=js&"
import style0 from "./SavedSearch.vue?vue&type=style&index=0&id=50d633a2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d633a2",
  null
  
)

export default component.exports
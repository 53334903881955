<template>
  <div class="container detail" v-if="SavedSearchX">
    <section class="header">
      <div class="row mb-3">
        <div class="col-12 col-md-6 float-left">
          <Breadcrumb></Breadcrumb>
        </div>
        <div class="col-12 col-md-6 float-right text-right">
          <n-button class="btn-danger btn-sm" @click="clearEntries" v-if="SavedSearchX && SavedSearchX.length >0">Clear</n-button>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="row">
        <div class="col-12 ml-auto mr-auto">
          <dl v-if="SavedSearchX && SavedSearchX.length >0">
            <template v-for="(entry,idx) in SavedSearchX" >
              <dt :key="idx">
                Search #{{idx+1}}
              </dt>
              <dd :key="idx">
                {{entry}}
                <span class="float-right">
                  <n-button class="btn-icon btn-info btn-sm m-0" @click="openEntry(idx)"><i class="fa fa-link" aria-hidden="true"></i></n-button> &nbsp;
                  <n-button class="btn-icon btn-danger btn-sm m-0" @click="removeEntry(idx)"><i class="fa fa-trash" aria-hidden="true"></i></n-button>
                </span>
              </dd>
            </template>
          </dl>
          <dl v-else>
            <dt>
              No saved searches yet. Save some first in the <router-link class="" :to="{ name: 'catalog' }">Catalog</router-link>.
            </dt>
            <dd></dd>
          </dl>

          <!--
          <p class="category">
            {{ typeof SavedSearchX }} <pre>{{ SavedSearchX }}</pre>
          </p>
          -->
        </div>
      </div>
      <div class="row">

      </div>
    </section>
  </div>
  <div v-else class="container">
    <section class="details mb-5">
      <div class="row mb-3">
        <div class="col-12">
          <h1>No saved searches</h1>
          <p>
            No saved searches.
          </p>
        </div>
        <div class="col-12">
          <router-link class="nav-link" :to="{ name: 'catalog' }">
            <font-awesome-icon
              icon="chart-line"
              class="left-icon square"
            ></font-awesome-icon>
            Back to Catalog
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {Card, Tabs, TabPane, DropDown} from '@/components';
import Breadcrumb from "../components/Sections/Breadcrumb";
import NButton from "@/components/Button";
const NA = "n/a";

export default {
  name: "Detail",
  bodyClass: 'detail-page',
  mixins: [],
  components: {
    NButton,
    Breadcrumb,
  },
  data() {
    return {
      test: [1,2]
    };
  },
  mounted() {
    Logger.debug("this.CurrentMasterX", this.SavedSearchX);
  },
  computed: {

  },
  methods: {
    openEntry(idx) {
      Logger.debug("openEntry", idx);
      const query = this.SavedSearchX[idx];
      this.$router.push({ name: 'catalog',
        query: {
          q: JSON.stringify(query.q),
          s: JSON.stringify(query.s)
        }
      });
    },
    clearEntries() {
      Logger.debug("clearEntries");

      this.$store.commit("clearSavedSearch");
      this.$notify({
        title: "Clear",
        message: "Cleared all searches.",
        type: "success",
        timeout: 3000,
        horizontalAlign: "right",
        verticalAlign: "top",
        icon: 'nc-icon nc-app',
      });
    },
    removeEntry(idx) {
      Logger.debug("removeEntry", idx);


      this.$store.commit("removeSavedSearch", this.SavedSearchX[idx]);
      this.$notify({
        title: "Removed",
        message: "Removed search to local storage.",
        type: "success",
        timeout: 3000,
        horizontalAlign: "right",
        verticalAlign: "top",
        icon: 'nc-icon nc-app',
      });
    }
  }
};
</script>

<style scoped lang="scss">
.detail-page .container {
  margin-top: 100px;
}

dt {
  float: left;
  //padding-right: 20px;
  min-width: 100px;
}
dd {
  margin-bottom: 0.7rem;
}
dd:after {
  content: "\a";
  white-space: pre;
  margin-bottom: 10px;
}
/*
// same line
dl {
  width: 100%;
  overflow: hidden;
  background: #dbe0da;
  padding: 0;
  margin: 0
}
dt {
  float: left;
  width: 50%;
  background: #8b9fe0;
  padding: 0;
  margin: 0
}
dd {
  float: left;
  width: 50%;
  background: #dbe0da;
  padding: 0;
  margin: 0
}
*/
</style>

<template>
  <div
    class="page-header header-filter"
    filter-color="orange"
  >
    <div
      class="page-header-image"
      style="background-image: url('img/background/FCX-bg.jpg')"
    />
    <div class="content-center">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card
            type="login"
            plain
          >
            <div
              slot="header"
              class="logo-container d-none d-md-block"
            >
              <img
                v-lazy="'img/now-logo.png'"
                alt=""
              >
            </div>

            <form @submit.prevent="resetPassword">
              <fg-input
                v-model="form.password"
                :required="true"
                minlength="5"
                maxlength="255"
                type="password"
                class="no-border input-lg"
                addon-left-icon="now-ui-icons text_caps-small"
                placeholder="Password..."
              />
              <div class="card-footer text-center">
                <n-button
                  native-type="submit"
                  class="btn btn-primary btn-round btn-lg btn-block"
                >
                  Reset password
                </n-button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, FormGroupInput } from '@/components';
  export default {
    name: 'ResetPasswordPage',
    bodyClass: 'login-page',
    components: {
      Card,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          id: "",
          token: "",
          password: ""
        }
      }
    },
    mounted() {
      Logger.debug("$route.params.id", this.$route.params.id, this.$route.params.token)
      this.form.id = this.$route.params.id;
      this.form.token = this.$route.params.token;
    },
    methods: {
      resetPassword() {
        this.LoadingX = true;
        this.$store
          .dispatch("RESET_PASSWORD", this.form)
          .then(val => {
            this.$notify({
              title: "Reset password success",
              message: "You can login with your new password.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            this.$router.push({name: 'login'});
          })
          .catch(error => {
            Logger.debug("error", error);
            this.$notify({
              title: "Reset password failed",
              message:'Token is invalid. Please try again.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    }
  }
</script>
<style>
</style>

<template>
  <div class="fcx-detail">
    <h4>Overview</h4>
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <h6>Included in regulatory or industry lists for FCMs? - {{HasFCMInventory | boolToSpeech}}</h6>
        <ul>
          <li v-if="!HasFCMInventory">no sources available</li>
          <li :key="index" v-for="(item,index) in FCMInventory">{{ item }}</li>
        </ul>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>

          <DataComponent
            class="d-inline-block"
            :value="GHS_Lists.GHS_pictogram"
            :show-label="false"
            :show-value="false"
          >GHS Pictograms</DataComponent>
        </h6>
        <DataPictogramComponent :value="GHS_Lists.GHS_pictogram"></DataPictogramComponent>
      </div>

      <div class="col-12 mb-2">
        <h6>Measured transferring from FCMs? - {{IsDetected | boolToSpeech}}</h6>

        <ul>
          <li v-if="!HasMaterialGroups">
            no MaterialGroups available
          </li>
          <li
            v-for="(item,index) in MaterialGroups"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
        <!-- listing of chemMaterials instead of fcmMain and fcmFocus in research tab-->
      </div>

      <!--
      <div class="col-12 mb-2">
        <h6>Measured in the human body? - N/A</h6>
      </div>
      -->
      <div class="col-12 mb-2">
        <h6>Known health effects? - {{ HasSubstanceHealthEffectsX | boolToSpeech }}</h6>
        <ul>
          <li v-if="!HasSubstanceHealthEffectsX">
            no sources available
          </li>

          <li
            v-for="(item,index) in SubstancesHealthEffectsX"
            :key="index"
          >
            <!-- show here values instead of names -->
            <DataComponent :value="Extractions[item]" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DataComponent from "@/components/Fcx/DataComponent.vue";
import DataPictogramComponent from "@/components/Fcx/DataPictogramComponent.vue";

export default {
  name: "DetailOverview",
  components: {DataPictogramComponent, DataComponent},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sourcesHealthEffects: [],
      materialGroups: [],
      detected: false
    };
  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    Extractions() {
      return this.localInput.extractions;
    },
    Lists() {
      return this.localInput.lists || {};
    },
    GHS_Lists() {
      return this.Lists.GHS || {};
    },
    MaterialGroups: {
      get() {
        return this.materialGroups;
      },
      set(MaterialGroups) {
        this.materialGroups = MaterialGroups;
      }
    },
    IsDetected: {
      get() {
        return this.detected;
      },
      set(IsDetected) {
        this.detected = IsDetected;
      }
    },
    FCMInventory() {
      return this.localInput.fcm_inventory;
    },
    HasFCMInventory() {
      return this.FCMInventory && this.FCMInventory.length > 0;
    },
    HasMaterialGroups() {
      return this.MaterialGroups && this.MaterialGroups.length > 0;
    },
  },
  mounted() {
    this.generateMigex();
  },
  methods: {
    generateMigex() {
      const migexObj = this.Extractions.migex || [];
      const migexArray = JSON.parse(JSON.stringify(migexObj));

      //let category = 0;
      // iterate over extractions keys
      for(let extractionEntry of migexArray) {
        const entryArray = extractionEntry.values;
        for(let entry of entryArray) {
          const detected = entry.detected.toLowerCase(),
            materialGroup = entry.material_group;
          // only add if detected
          if(detected === "yes") {
            this.IsDetected = true;
            if (materialGroup && !this.MaterialGroups.includes(materialGroup)) {
              this.MaterialGroups.push(materialGroup);
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>

</style>

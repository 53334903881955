<template>
  <nav aria-label="breadcrumb">
    <!--
    crumbs {{ crumbs }}<br />
    breadcrumbList {{ breadcrumbList }}
    -->
    <ol class="breadcrumb">
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{ linked: !!breadcrumb.link }"
      >
        <span class="separator" v-if="idx>0">&nbsp;{{Separator}}&nbsp;</span>  {{ breadcrumb.name }}
      </li>
      <!--
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item"><a href="#">Library</a></li>
      <li class="breadcrumb-item active" aria-current="page">Data</li>
      -->
    </ol>
  </nav>
</template>

<script>
//https://medium.com/@pratheekhegde/displaying-application-breadcrumbs-in-vue-js-85456dc8a370
export default {
  name: "Breadcrumb",
  props: {
    separator: {
      type: String,
      required: false,
      default: "|"
    }
  },
  data() {
    return {
      breadcrumbList: []
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
    Separator() {
      return this.separator;
    }
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) {
        this.$router.push(this.breadcrumbList[pRouteTo].link);
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    }
  }
};
</script>

<style scoped lang="scss">
li {
  cursor: pointer;
/*
  &:after {
    padding: 0px 10px;
    content: "|";
  }
  &:last-child:after {
    content: "";
  }

 */
}
.separator {
  // actually use the color of .breadcrumb
  color: white;
  &:hover {
    color: white;
  }
}
</style>

import axios from "axios";
import qs from "query-string";

export default {
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocument(endpointRootUrl, documentId) {
    let axiosConfig = {};
    if (!documentId) {
      documentId = "";
    }
    Logger.debug(endpointRootUrl + " api: getDocument by documentId", documentId);
    return axios
      .get(endpointRootUrl + "/" + documentId, axiosConfig)
      .then(response => {
        // Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug(endpointRootUrl + " api: getResults resp", response);
        return response.data;
      });
  },

  getDocumentByField(endpointRootUrl, documentFieldName, documentFieldValue) {
    let axiosConfig = {};
    if (!documentFieldName || !documentFieldValue) {
      throw new Error("documentFieldName or documentFieldValue not given");
    }
    Logger.debug(endpointRootUrl + " api: getDocumentByField by documentFieldName", documentFieldName, " and documentFieldValue", documentFieldValue);
    return axios
      .get(endpointRootUrl + "/" + documentFieldName + "/" + documentFieldValue, axiosConfig)
      .then(response => {
        // Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug(endpointRootUrl + " api: getResults resp", response);
        return response.data;
      });
  },

  /**
   * Get result for user or for all users
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  getDocuments(endpointRootUrl, data) {
    let axiosConfig = {};
    let qsResult = qs.stringify(data);
    qsResult = qsResult ? "?" + qsResult : "";
    Logger.debug(endpointRootUrl + " api: getDocuments", data, qsResult);
    return axios
      .get(endpointRootUrl + qsResult, axiosConfig)
      .then(response => {
        //Logger.trace("api: contact success", response);
        // returns obj instead of array...
        Logger.debug(endpointRootUrl + " api: getDocuments resp", response);
        return response.data;
      });
  },

  deleteDocument(endpointRootUrl, documentId) {
    Logger.debug(endpointRootUrl + "api: deleteDocument", documentId);
    let axiosConfig = {};
    return axios
      .delete(endpointRootUrl + "/" + documentId, axiosConfig)
      .then(response => {
        Logger.debug(endpointRootUrl + "api: deleteDocument resp", response);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  export(endpointRootUrl, reqData) {

    //set default reqData format
    reqData = reqData || {
      "format": "json",
    };

    //set filename
    const splitArray = endpointRootUrl.split("/");
    const file = splitArray[splitArray.length - 1];
    const filename = 'FCX-export-' + file + (reqData.format === "json" ? ".json" : ".csv");
    Logger.debug("export", filename, reqData);


    let axiosConfig = {
      headers: {
        Accept: reqData.format === "json" ? "application/json" : "text/csv",
        // CORS policy: Request header field contentdisposition
        //contentDisposition: `attachment; filename="${filename}"`
      },
      responseType: "blob", // not for json
      timeout: 30000
      //responseType: 'stream'
    };

    //set query string
    let qsResult = qs.stringify(reqData);
    qsResult = qsResult ? "?" + qsResult : "";
    Logger.debug("export qsResult", qsResult);
    return axios
      .get(endpointRootUrl + qsResult, axiosConfig)
      .then(response => {

        //Logger.trace("api: contact success", response);
        // create file link in browser's memory
        //let jsonString = JSON.stringify(response.data);
        //let blob = new Blob([jsonString], { type:"application/json" })
        let blob = new Blob([response.data], {type: "application/json"})
        //let blob = new Blob([response.data], { type:"text/csv" })
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  },

  // TODO updateDocument
  // TODO post/create document
}

<template>
  <div class="wrapper contact-page">
    <!--
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg45.jpg')"
      />
    </div>
    -->

    <div class="main">
      <div class="section contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-7 ml-auto mr-auto">
              <h2 class="title">
                Send us a message
              </h2>
              <p>
                We value your input!
                Whether you have questions, comments, or suggestions, please don't hesitate to contact us. Your feedback helps us improve FCX.<br><br>
                Please include a subject line indicating your primary concern:
              </p>
              <ul>
                <li>
                  <strong>General Inquiries</strong>: Have questions about the FCX database and tool? Feel free to ask us anything related to our platform.
                </li>
                <li>
                  <strong>Technical Support</strong>: Encountering technical issues or need assistance with the FCX website?
                </li>
                <li>
                  <strong>Media and Press Inquiries</strong>: Journalists, interview requests, or press-related matters regarding FCX can be directed here.
                </li>
                <li>
                  <strong>Collaboration, Partnerships, Support</strong>: We're open to collaboration with organizations that share our mission. Interested in supporting our work? Reach out to explore opportunities.
                </li>
              </ul>
              <form
                id="contact-form"
                role="form"
                method="post"
                ref="contactForm"
                @submit.prevent="submitForm"
              >
                <div class="form-group">
                  <label>Name</label>
                  <fg-input
                    :required="true"
                    minlength="3"
                    maxlength="50"
                    v-model="form.name"
                    placeholder="Your Name..."
                    addon-left-icon="now-ui-icons users_circle-08"
                  />
                </div>

                <div class="form-group">
                  <label>Email</label>
                  <fg-input
                    :required="true"
                    minlength="5"
                    maxlength="255"
                    v-model="form.email"
                    placeholder="Your Email..."
                    addon-left-icon="now-ui-icons ui-1_email-85"
                  />
                </div>

                <div class="form-group">
                  <label>Subject</label>
                  <el-select
                    v-model="form.subject"
                    ref="subject"
                    class="fcx-select"
                    placeholder="Select Subject"
                  >
                    <!--class="now-ui select-secondary"-->
                    <div class="input-group-prepend" slot="prefix">
                      <span class="el-input__suffix-inner">
                        <i class="el-input__icon now-ui-icons business_briefcase-24"></i>
                      </span>
                    </div>
                    <el-option
                      v-for="option in SUBJECT_OPTIONS"
                      :key="option.label"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                    />
                  </el-select>
                </div>

                <div class="form-group">
                  <label>Message</label>
                  <textarea
                    id="message"
                    name="message"
                    class="form-control"
                    rows="6"
                    v-model="form.message"
                    :required="true"
                    minlength="10"
                    maxlength="1000"
                  />
                </div>
                <div class="submit text-center">
                  <n-button
                    type="primary"
                    native-type="submit"
                    round
                  >
                    Contact Us
                  </n-button>
                </div>
              </form>
            </div>
            <div class="col-md-5 ml-auto mr-auto">
              <info-section
                type="primary"
                class="mt-5"
                icon="now-ui-icons location_pin"
              >
                <h4 class="info-title">

                </h4>
                <FPFAddress />
              </info-section>

              <info-section
                type="primary"
                icon="now-ui-icons tech_mobile"
              >
                <h4 class="info-title"></h4>
                <p>
                  <FPFPhone /><br>
                  <FPFEmail />
                </p>
              </info-section>

              <info-section
                type="primary"
                icon="business_briefcase-24 now-ui-icons"
              >
                <h4 class="info-title">

                </h4>
                <p>
                  <router-link to="/imprint">
                    Legal Information
                  </router-link>
                </p>
              </info-section>

              <info-section
                type="primary"
                icon="fa fa-heart"
              >
                <h4 class="info-title"></h4>
                <p>
                  <ExternalLink link="https://www.foodpackagingforum.org/support-us">Support our work</ExternalLink>
                </p>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Button, InfoSection, FormGroupInput } from '@/components';
  import {Select, Option} from 'element-ui';
  import FPFAddress from "@/components/Fcx/static/FPFAddress.vue";
  import FPFPhone from "@/components/Fcx/static/FPFPhone.vue";
  import FPFEmail from "@/components/Fcx/static/FPFEmail.vue";
  import supportAPI from "@/js/api/support.js";
  import Constants from "@/js/const";
  import ExternalLink from "@/components/Fcx/ExternalLink.vue";
  export default {
    name: 'ContactUs',
    bodyClass: 'contact-page',
    components: {
      ExternalLink,
      FPFEmail,
      FPFPhone,
      FPFAddress,
      InfoSection,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        form: {
          name: '',
          email: '',
          subject: 'general',
          message: ''
        }
      }
    },
    computed: {
      SUBJECT_OPTIONS() {
        return Constants.forms.subjectOptions;
      },
    },
    methods: {
      resetForm() {
        this.form = {
          name: '',
          email: '',
          subject: 'general',
          message: ''
        }
      },
      submitForm() {
        Logger.debug(this.form);
         this.LoadingX = true;
        supportAPI.contactRequest(this.form)
          .then(val => {
            this.$notify({
              title: "Contact request email success",
              message: "Please check your email inbox.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            this.$refs.contactForm.reset();
            this.resetForm();
          })
          .catch(error => {
            Logger.debug("error", error);
            this.$notify({
              title: "Contact request failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    }
  }
</script>
<style lang="scss">
  .contact-page {
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
</style>

<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              About us
            </h3>

            <h4>
              The FCX Project
            </h4>
            <p>
              <img
                v-lazy="'img/logo/FPF-logo-signet.png'"
                class="fpf-img left"
                alt=""
              >
              Since 2012, the Food Packaging Forum (FPF) has been compiling publicly available information on food contact chemicals (FCCs).
              We make this information freely available through publishing scientific publications, fact sheets, news articles, and dashboards.
              To help make this complex data landscape even more accessible, we have developed the Food Contact eXplorer platform
              where all the publicly available information on this universe of FCCs can be easily accessed and navigated.
              <br>
              Using the FCX, everyone can find comprehensive information about known FCCs including the material type they are used in,
              evidence for their migration, regulation, toxicity and health-related information,
              as well as future features with related efforts being made by food brands and retailers.
              Everything is provided with links to the original references that offer even more detailed information.
            </p>

            <h4>
              Technical Details
            </h4>
            <p>
              Food Contact eXplorer (FCX) is being developed and hosted by the <FPF />,
              a registered non-profit foundation under Swiss law.
              Technical development of the tool is carried out in cooperation with <entreprenerds />.
              The FCX data comes from a variety of peer-reviewed, governmental, and industry sources compiled by
              the scientists of the Food Packaging Forum and provided with references to their original source.
            </p>
            <p>
              Development of FCX is being made possible through the unrestricted donations made to the
              <FPF /> and thanks to additional financial support of the <Minderoo />.
            </p>

            <h3>Disclaimer</h3>
            <p>
              While we strive for FCX to always contain accurate, relevant, and up-to-date information,
              the resource is provided “as is”.
              We can neither guarantee the real-time currency of the data available on the FCX platform nor that all
              information is error-free. Food contact chemical-related information may change over time due to evolving regulations,
              scientific advancements, and new research findings.
              FCX and its operators cannot be held responsible for use or mis-use of the data even in the case of errors in the datasets.
            </p>
            <p>
              Before relying on any data obtained from FCX, we strongly encourage you to refer to our
              <FCXDataDescription /> for specific information on when sources were last updated.
              Staying informed about the currency of the information
              is essential to making well-informed decisions related to food contact chemicals.
              Please also see the <router-link to="/terms" target="_blank">Terms and Conditions</router-link> you agree to when accessing FCX as well as the <router-link to="privacy" target="_blank">Privacy Policy</router-link>.
            </p>


            <h3>User Guide and FAQ</h3>
            <p>
              New to FCX and not sure how to start? Take a look at this introductory video that walks through the many features
              of the platform and how to find the information you’re looking for.
              More details are available in the <FCXUserGuide />.
              <!--
              <strong>TODO YOUTUBE VIDEO</strong>
              -->
            </p>

            <h4>1. What is FCX and its purpose?</h4>
            <p>
              The Food Contact eXplorer (FCX) is an online platform developed by the Food Packaging Forum (FPF)
              to provide easily navigable information about food contact chemicals (FCCs).
              It consolidates data on the Universe of known FCCs based on publicly accessible scientific literature,
              regulatory publications, and industry publications to offer stakeholders comprehensive information on FCCs including:
              <br><br>
              <ul>
                <li>the <strong>material type</strong> they it may be used in</li>
                <li>scientific evidence for their <strong>migration</strong> from food contact materials</li>
                <li>information on how they are <strong>regulated</strong></li>
                <li>data on identified <strong>hazards and health effects</strong></li>
                <li>information on <strong>initiatives by food brands and retailers</strong> to address them</li>
              </ul>
            </p>

            <h4>2. What information is included in the FCX platform?</h4>
            <p>
              FCX is built upon the Food Packaging Forum’s ongoing <FCCH /> project, consisting of four interconnected parts:<br><br>
              <img
                v-lazy="'img/pages/fcx-fcc-universe.png'"
                class="about-img right"
                alt=""
              >
              <ul>
                <li>
                  <strong><FCCDB /></strong>:
                  A compilation of regulated and publicly listed chemicals that are intentionally used in food contact materials.
                </li>
                <li>
                  <strong><FCCMigex /></strong>:
                  Information on chemicals measured by scientists to migrate or be extractable from food contact materials.
                </li>
                <li>
                  <strong>Food Contact Chemicals Monitored in Humans (FCChumon, in development)</strong>:
                  Data on chemicals listed in the FCCdb and FCCmigex that have been measured in humans.
                </li>
                <li>
                  <strong>Food Contact Chemicals Health Impact Matrix (FCChelix, coming soon)</strong>:
                  Details about the health effects associated with FCCs found in humans.
                </li>
              </ul>
            </p>

            <h4>3. Is FCX information accessible to the public?</h4>
            <p>
              Yes, FCX is designed to make the comprehensive and valuable information on food contact chemicals available
              to all users at no cost.
            </p>

            <h4>4. How old is the data in FCX and will it be updated?</h4>
            <p>
              At the time of FCX’s launch (February 2024), the regulatory information included was last updated in November 2020,
              while the data on migration and extraction was last updated in October 2022.
              After completing initial platform development, the information within FCX will be updated regularly.
              While we aim to keep it up-to-date, we cannot guarantee accuracy, and the platform is provided for your use “as is”.
              Check the <FCXDataDescription /> for more information on when sources were last updated.
              It is important for you to also check the original referenced source(s) of included data.
            </p>

            <h4>5. Can I contribute to FCX?</h4>
            <p>
              Do you know of a regulation or important source of information that is currently missing?
              Did you find a bug?
              Is there a critical feature that would make your work easier?
              Then we would love to hear about it via our <router-link to="/contact">contact form</router-link>.
              If you are interested in making a financial contribution to help keep the platform running and expanding in the future,
              then we would of course love to hear from you too!
            </p>

            <h4>6. How can FCX benefit professionals in the food packaging and research industries?</h4>
            <p>
              FCX is a comprehensive resource for professionals working in the food packaging industry,
              offering a quick overview into material safety, regulations, migration, toxicity,
              and health effects related to many known food contact chemicals.
              Using the FCX platform can be a starting point for informed decision-making,
              regulatory compliance, and product safety enhancement.
              We make all the original sources available so that you can dive deeper into specific topics important for your work.
            </p>

            <h4>7. Are the sources of information on FCX reliable?</h4>
            <p>
              Yes, the information provided on FCX is carefully sourced from a wide array of government,
              industry, and scientific reports (see also question #2).
              It is important to note that we try to update the included information regularly but we still cannot guarantee accuracy,
              and the platform is provided for your use “as is”.
              We will transparently report when we last checked for updates across the included data sources (see the <FCXDataDescription />),
              but it is still important for you to check and verify with the original source.
              <br><br>
              It is important to note that information for some chemicals are withheld from the public as confidential business information by regulators or by manufacturers.
              Additional information on certain chemicals may therefore exist but cannot be included in FCX because the information is not publicly accessible.
            </p>

            <h4>8. How can I get help if I have questions or issues while using FCX?</h4>
            <p>
              For any questions, issues, or assistance while using FCX, you can connect with us using our
              <router-link to="/contact">contact form</router-link>.
              We're here to help and ensure your experience on the platform is productive.
            </p>

            <h4>9. How is FCX different from the Food Packaging Forum's main website?</h4>
            <p>
              While the Food Packaging Forum website also provides freely available information on FCCs,
              FCX is a specialized platform tailored to bring together multiple Food Packaging Forum resources into a single place.
              FCX offers organized and easy-to-access data on FCCs, making it simple for users to find relevant material,
              migration details, regulations, toxicity information, health effects, and brand and retailer commitments.
            </p>

            <h4>10. Who pays for FCX?</h4>
            <p>
              The resource is free for everyone to access.
              Development of FCX is being made possible through the unrestricted donations made to the Food Packaging Forum
              (<a
                rel="noopener"
                href="https://www.foodpackagingforum.org/about-us/funding"
                target="_blank"
              >see all FPF donors here</a>)
              and through additional financial support from the <Minderoo />.
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FPF from "@/components/Fcx/static/FPF.vue";
import Minderoo from "@/components/Fcx/static/Minderoo.vue";
import Entreprenerds from "@/components/Fcx/static/Entreprenerds.vue";
import FCXUserGuide from "@/components/Fcx/static/FCXUserGuide.vue";
import FCXDataDescription from "@/components/Fcx/static/FCXDataDescription.vue";
import FCCDB from "@/components/Fcx/static/FCCDB.vue";
import FCCMigex from "@/components/Fcx/static/FCCMigex.vue";
export default {
  name: 'About',
  bodyClass: 'static-page',
  components: {
    FCCMigex,
    FCCDB,
    FCXDataDescription,
    FCXUserGuide,
    Entreprenerds,
    Minderoo,
    FPF
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss">
.about-img {
  max-width: 250px;
  top: -2px;
  position: relative;
  &.left {
    float: left;
  }
  &.right {
    float: right;
  }
}
.fpf-img {
  max-width: 65px;
  top: 5px;
  margin: 0px 10px 10px 0px;
  position: relative;
  float: left;
}
</style>

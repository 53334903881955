<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              About this cookie policy
            </h3>
            <p>
              This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e,
              the information we collect using cookies and how that information is used, and how to control the cookie
              preferences. For further information on how we use, store, and keep your personal data secure, see our
              Privacy Policy.
            </p>
            <p>
              You can at any time change or withdraw your consent from the Cookie Declaration on our website<br>Learn
              more about who we are, how you can contact us, and how we process personal data in our Privacy Policy.<br>Your
              consent applies to the following domains: fcx.foodpackagingforum.org.
            </p>
            <hr>
            <p>
              Your current state: <strong>{{CookieStatusX || 'No consent given'}}</strong>
              <a
                class="pl-3"
                style="cursor:pointer;text-decoration:underline;"
                @click="manageCookieConsent"
              >Manage your consent</a>
            </p>
            <hr>
            <h4>What are cookies ?</h4>
            <p>
              Cookies are small text files that are used to store small pieces of information. They are stored on
              your device when the website is loaded on your browser. These cookies help us make the website function
              properly, make it more secure, provide better user experience, and understand how the website performs
              and to analyze what works and where it needs improvement.
            </p>

            <h4>How do we use cookies ?</h4>
            <p>
              As most of the online services, our website uses first-party and third-party cookies for several
              purposes. First-party cookies are mostly necessary for the website to function the right way, and they
              do not collect any of your personally identifiable data.
            </p>
            <p>
              The third-party cookies used on our website are mainly for understanding how the website performs,
              how you interact with our website, keeping our services secure, providing advertisements that are
              relevant to you, and all in all providing you with a better and improved user experience and help
              speed up your future interactions with our website.
            </p>

            <h4>What types of cookies do we use ?</h4>
            <p>
              Essential: Some cookies are essential for you to be able to experience the full functionality of
              our site. They allow us to maintain user sessions and prevent any security threats. They do not collect
              or store any personal information. For example, these cookies allow you to log-in to your account and
              add products to your basket, and checkout securely.
            </p>
            <p>
              Statistics: These cookies store information like the number of visitors to the website, the number of
              unique visitors, which pages of the website have been visited, the source of the visit, etc. These
              data help us understand and analyze how well the website performs and where it needs improvement.
            </p>
            <p>
              Marketing: Our website displays advertisements. These cookies are used to personalize the
              advertisements that we show to you so that they are meaningful to you. These cookies also help us keep
              track of the efficiency of these ad campaigns.<br>The information stored in these cookies may also be
              used by the third-party ad providers to show you ads on other websites on the browser as well.
            </p>
            <p>
              Functional: These are the cookies that help certain non-essential functionalities on our website.
              These functionalities include embedding content like videos or sharing content of the website on
              social media platforms.
            </p>
            <p>
              Preferences: These cookies help us store your settings and browsing preferences like language
              preferences so that you have a better and efficient experience on future visits to the website.<br>
            </p>
            <p>The below list details the cookies used in our website.</p>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>
                    Cookie
                  </th>
                  <th>
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    _ga
                  </td>
                  <td>
                    This cookie is installed by Google Analytics. The cookie is used
                    to calculate visitor, session, campaign data and keep track of site usage for the site’s analytics
                    report. The cookies store information anonymously and assign a randomly generated number to
                    identify unique visitors.
                  </td>
                </tr>
                <tr>
                  <td>
                    _gat_gtag_UA_200777203_1
                  </td>
                  <td>
                    This cookie is set by Google and is used to distinguish users.
                  </td>
                </tr>
                <tr>
                  <td>
                    _gid
                  </td>
                  <td>
                    This cookie is installed by Google Analytics. The cookie is used
                    to store information of how visitors use a website and helps in creating an analytics report of
                    how the website is doing. The data collected including the number visitors, the source where they
                    have come from, and the pages visted in an anonymous form.
                  </td>
                </tr>
                <tr>
                  <td>
                    __cf_bm
                  </td>
                  <td>
                    This cookie is installed by Cloudflare.<br>
                    Cloudflare’s bot products identify and mitigate automated traffic to protect your site from bad bots. Cloudflare places the __cf_bm cookie on end-user devices that access customer sites protected by Bot Management or Bot Fight Mode. The __cf_bm cookie is necessary for these bot solutions to function properly.
                    <br>
                    This cookie expires after 30 minutes of continuous inactivity by the end user. The cookie contains information related to the calculation of Cloudflare’s proprietary bot score and, when Anomaly Detection is enabled on Bot Management, a session identifier. The information in the cookie (other than time-related information) is encrypted and can only be decrypted by Cloudflare.
                    <br>
                    A separate __cf_bm cookie is generated for each site that an end user visits, as Cloudflare does not track users from site to site or from session to session. The __cf_bm cookie is generated independently by Cloudflare, and does not correspond to any user ID or other identifiers in a customer’s web application
                  </td>
                </tr>
                <tr>
                  <td>
                    viewed_cookie_policy
                  </td>
                  <td>
                    The cookie is set by the GDPR Cookie Consent plugin and is used
                    to store whether or not user has consented to the use of cookies. It does not store any personal
                    data.
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>How can I control the cookie preferences ?</h4>
            <p>
              Should you decide to change your preferences later through your browsing session, you can click on
              the “Cookie Policy” link in the footer or <a style="cursor:pointer;text-decoration:underline;" @click="manageCookieConsent">here</a>.
              This will display the consent notice again
              enabling you to change your preferences or withdraw your consent entirely.
            </p>
            <p>
              In addition to this, different browsers provide different methods to block and delete cookies used by
              websites. You can change the settings of your browser to block/delete the cookies. To find out more
              about how to manage and delete cookies, visit wikipedia.org, www.allaboutcookies.org.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Cookie',
  bodyClass: 'static-page',
  components: {},
  methods: {
    manageCookieConsent() {
      // TODO for now perfect but in general events might be better than direct method calls
      this.$parent.$refs.cookiePanel.removeCookie();
    }
  }
}
</script>
<style>
</style>

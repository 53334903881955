<template>
  <div
    id="headers"
    class="cd-section"
  >
    <!--     *********     HEADER 2      *********      -->
    <div class="header-2">
      <div class="page-header header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/background/FCX-bg.jpg')"
        />

        <div class="fcx-arrow-down icon icon-primary" v-show="isArrowVisible">
          <i class="fas fa-chevron-down"></i>
        </div>

        <div class="content-center">
          <div class="container">
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto text-center">
                <h1 class="title d-none d-md-block">
                  <span style="font-style:bold">Food</span> Contact eXplorer
                </h1>
                <h4 class="d-none d-md-block text-black">The universe of food contact chemicals. In one place.</h4>

              </div>
              <div class="col-md-10 ml-auto mr-auto">
                <div
                  class="card card-raised card-form-horizontal card-plain"
                  data-background-color
                >
                  <div class="card-body">
                    <form
                      method=""
                      action=""
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="input-group mb-3">
                            <input
                              id="search"
                              v-model="searchFilter"
                              type="text"
                              class="form-control"
                              placeholder="Search for a chemical by name"
                              aria-label="Search for a chemical by name"
                              aria-describedby="basic-addon2"
                            >
                            <div class="input-group-append">
                              <n-button
                                v-if="CurrentLocalUserX.email"
                                native-type="submit"
                                type="primary"
                                @click="search"
                                :icon="true"
                              >
                                <i class="fa fa-search" />
                              </n-button>
                              <nav-link
                                v-else
                                round
                                block
                                type="primary"
                                class="nav-link btn btn-primary"
                                to="/login"
                              >
                                Login/Register
                              </nav-link>

                              <n-button
                                class="ml-3"
                                v-if="CurrentLocalUserX.email"
                                native-type="submit"
                                type="primary"
                                @click="explore"
                              >
                                Explore all
                              </n-button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-8 ml-auto mr-auto text-center">

                <h5 class="description rellax-text">
                  Food contact materials are complex. And information about their use, chemical composition, safety,
                  and related regulatory and industry developments are scattered across thousands of different sources around the world.
                  The Food Contact eXplorer (FCX) was created by a team of independent scientists to help make navigating all of this information easier.
                  <br><br>
                  More than just a database, FCX is a comprehensive platform that brings together years of meticulous research
                  and data collection on food contact chemicals to help stakeholders make better, science-based decisions.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END HEADER 2      *********      -->
    <div class="cd-section" id="features" >
      <!--     *********     FEATURES 5      *********      -->
      <div class="features-5">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <h2 class="title">Quick Facts of FCX </h2>
              <div class="info info-horizontal">
                <div class="icon icon-primary icon-circle">
                  <i class="fa fa-question"></i>
                </div>
                <div class="description">
                  <h4 class="info-title">What is Food Contact eXplorer (FCX)?</h4>
                  <p>
                    FCX is an advanced online platform that provides a comprehensive understanding
                    of the available information on 15,000+ food contact chemicals (FCCs).
                    It consolidates information from the universe of known FCCs,
                    offering easily navigable insights into chemicals and materials, migration, regulations, health effects, and more.
                  </p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="icon icon-primary icon-circle">
                  <i class="fa fa-lightbulb"></i>
                </div>
                <div class="description">
                  <h4 class="info-title">Why is FCX valuable?</h4>
                  <p>
                    FCX brings together data from comprehensive resources to enhance transparency and promote well-informed
                    decisions by all stakeholders including industry, regulatory bodies, and civil society.
                  </p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="icon icon-primary icon-circle">
                  <i class="fa fa-cog"></i>
                </div>
                <div class="description">
                  <h4 class="info-title">How does FCX work?</h4>
                  <p>
                    FCX operates as a centralized hub for information on food contact chemicals.
                    Through intuitive navigation, users can explore chemical data, access relevant research
                    and regulations, and gain a holistic perspective on the world of food contact chemicals.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="tablet-container">
                <img v-lazy="'img/pages/fcx-3d_quick_facts.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--     *********    END FEATURES 5      *********      -->

    </div>
  </div>
</template>
<script>
import {Card, NavLink} from '@/components';
import { Carousel, CarouselItem, Tooltip } from 'element-ui';
import Rellax from "rellax";
import FgInput from "@/components/Inputs/formGroupInput";
import NButton from "@/components/Button";

export default {
  name: 'Home',
  bodyClass: 'presentation-page', //changed due to demo
  components: {
    Card,
    NavLink,
    NButton,
    FgInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      searchFilter: "",
      isArrowVisible: true,
    }
  },
  mounted() {

    if (window.innerWidth >= 991 && window.innerHeight >= 768) {

      setTimeout(function() {
        let rellax = new Rellax('.rellax', {
          center: true
        });
      }, 5000);

      //let rellaxHeader = new Rellax('.rellax-header');
      let rellaxText = new Rellax('.rellax-text');
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    scrollHandler(e) {
      this.isArrowVisible = window.scrollY > 100 ? false : true
    },
    search() {
      let filterString = undefined;
      // set fulltext search is string is given
      if(this.searchFilter) {
        const filter ={
            $text: { $search: this.searchFilter}
          };
        filterString = JSON.stringify(filter);
      }
      this.$router.push({ name: 'catalog', query: { q: filterString, s: undefined }});
    },
    explore() {
      this.$router.push({ name: 'catalog', query: { q: undefined, s: undefined }});
    }
  }
}
</script>

<style scoped lang="scss">
.page-header-image {
  z-index: 1!important;
}

@media (max-width: 990px) {
  .header-2 .page-header .card {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media (max-height: 540px) {
  .header-2 .page-header .card {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}


.fcx-arrow-down {
  position: absolute;
  z-index: 99;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  font-size: 40px;
  animation: bounce 2s infinite;
  /*
  bottom: -50px;
  left: 50%;
  margin-left: -20px;
  //margin-bottom: -50px;
  font-size: 40px;
  animation: bounce 2s infinite;

   */
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(+10px);
  }
  100% {
    transform: translateY(0);
  }
}

</style>

/*!

 =========================================================
 * Vue Now UI Kit Pro - v1.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-now-ui-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import router from "./router";
//plugins
import NowUIKit from './plugins/now-ui-kit';
import VueNotify from "vue-notifyjs";


Vue.config.productionTip = false;
Vue.use(NowUIKit);
Vue.use(VueNotify)


import "./registerServiceWorker";

import "./js/setup/js-logger-init.js";
//import "./js/setup/setup-api.js";
import setupInterceptors from "./js/setup/setup-interceptors.js";
setupInterceptors(store, router);

import { globalMixin } from "./js/mixins/global-mixin";
Vue.mixin(globalMixin);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import axios from "axios";
import qs from "querystring";
import genericApi from "@/js/api/generic";

export default {
  endpointRootUrl: "/api/projects",
  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getProject(projectId) {
    return genericApi.getDocument(this.endpointRootUrl, projectId);
  },

  /**
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  getProjects(data) {
    return genericApi.getDocuments(this.endpointRootUrl, data);
  },
  postProject(data) {
    let axiosConfig = {};
    Logger.debug("post postProject", data);
    return axios
      .post(this.endpointRootUrl + "/", data, axiosConfig)
      .then(response => {
        Logger.debug("postProject resp", data);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  updateProject(data) {
    Logger.debug("updateProject", data);
    let newProject = JSON.parse(JSON.stringify(data));
    const projectId = newProject._id;
    delete newProject._id;
    delete newProject.id;
    delete newProject.__v;
    delete newProject.updatedAt;
    delete newProject.createdAt;


    let axiosConfig = {};
    return axios
      .put(this.endpointRootUrl + "/" + projectId, newProject, axiosConfig)
      .then(response => {
        Logger.debug("updateProject resp", response);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  },

  deleteProject(projectId) {
    Logger.debug("deleteProject", projectId);
    let axiosConfig = {};
    return axios
      .delete(this.endpointRootUrl + "/" + projectId, axiosConfig)
      .then(response => {
        Logger.debug("deleteProject resp", response);
        //Logger.trace("user api: login", response);
        // returns obj instead of array...
        return response.data;
      });
  }
};

import Vue from "vue";
import Router from "vue-router";
import store from "./store";

// main pages
import Home from "./pages/Home.vue";
import Catalog from "./pages/Catalog.vue";
import Detail from "./pages/Detail.vue";
import SavedSearch from "@/pages/SavedSearch.vue";

// auth pages
import SignUp from "./pages/SignUpPage.vue";
import SuccessSignUp from "./pages/static/SuccessSignUp.vue";
import Login from "./pages/LoginPage.vue";
import ForgotPassword from "./pages/ForgotPasswordPage.vue";
import ResetPassword from "./pages/ResetPasswordPage.vue";
// layout
import FPFNavbar from "@/layout/FPFNavbar";
import FPFFooter from "@/layout/FPFFooter";
//import MainNavbar from "@/layout/MainNavbar";
import MainFooter from "@/layout/MainFooter";

// static pages
import About from "@/pages/static/About";
import ContactUs from "@/pages/static/ContactUs";
import Cookie from "@/pages/static/Cookie";
import FAQ from "@/pages/static/FAQ";
import Imprint from "@/pages/static/Imprint";
import Intro from "@/pages/static/Intro";
import Privacy from "@/pages/static/Privacy";
import Terms from "@/pages/static/Terms";
import NotFound from "@/pages/static/NotFound.vue";
import MainNavbar from "@/layout/MainNavbar.vue";
import DataDescription from "@/pages/static/DataDescription.vue";

//import router from "@/router";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  Logger.debug("ifNotAuthenticated", store.getters.isAuthenticated);
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  Logger.debug("ifAuthenticated", store.getters.isAuthenticated);
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      components: {default: Home, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      },
      meta: {
        breadcrumb: [{ name: "Home" }]
      }
    },
    {
      path: "/catalog",
      name: "catalog",
      components: {
        default: Catalog, header: FPFNavbar, footer: FPFFooter
      },
      beforeEnter: ifAuthenticated,
      props: {
        default: route => ({ q: route.query.q, s: route.query.s }),
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      },
      meta: {
        breadcrumb: [
          { name: "Home", link: "/" },
          { name: "Catalog", link: "#" }
        ]
      }
    },
    {
      path: "/saved-search",
      name: "saved-search",
      components: {
        default: SavedSearch, header: FPFNavbar, footer: FPFFooter
      },
      beforeEnter: ifAuthenticated,
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      },
      meta: {
        breadcrumb: [
          { name: "Home", link: "/" },
          { name: "Saved Search", link: "" }
        ]
      }
    },
    {
      name: "detail",
      path: "/detail/:slug",
      components: {default: Detail, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      },
      beforeEnter: (to, from, next) => {
        //console.debug("beforeEnter", to);
        let targetSlug = to.params.slug;
        //console.debug("targetSlug", targetSlug, from, to);
        // set
        to.meta.breadcrumb[to.meta.breadcrumb.length - 1].name = "Details (" + targetSlug + ")";
        //to.meta.breadcrumb[to.meta.breadcrumb.length - 1].link = to.fullPath;
        // hack for filter just add this hidden url part...
        store.dispatch("GET_MASTER_BY_SLUG", targetSlug).then(
          response => {
            // the above state is not available here, since it
            // it is resolved asynchronously in the store action
            //console.debug("beforeEnter", response);
            if (response) {
              next();
            } else {
              next({ name: "home" });
            }
          },
          error => {
            // handle error here
            next({ name: "home" });
          }
        );
      },
      meta: {
        breadcrumb: [
          { name: "Home", link: "/" },
          { name: "Catalog", link: "/catalog" },
          { name: "Details", link: "" }
        ]
      }
    },
    // Test purpose
    {
      name: "detail-with-id",
      path: "/detailid/:id",
      components: {default: Detail, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      },
      beforeEnter: (to, from, next) => {
        Logger.debug("beforeEnter", to);
        let targetId = to.params.id;
        Logger.debug("targetId", targetId, from);
        store.dispatch("GET_MASTER", targetId).then(
          response => {
            // the above state is not available here, since it
            // it is resolved asynchronously in the store action
           Logger.debug("beforeEnter", response);
            if (response) {
              next();
            } else {
              next({ name: "home" });
            }
          },
          error => {
            // handle error here
            next({ name: "home" });
          }
        );
      },
      meta: {
        breadcrumb: [
          { name: "Home", link: "/" },
          { name: "Catalog", link: "/catalog" },
          { name: "Details", link: "#" }
        ]
      }
    },
    {
      path: "/landing",
      name: "landing",
      components: {default: Headers, header: FPFNavbar, footer: MainFooter},
      props: {
        header: {transparent: true, colorOnScroll: 450},
        footer: {type: 'default'}
      }
    },

    // static pages
    {
      path: "/about",
      name: "about",
      components: {
        default: About,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/contact",
      name: "contact",
      components: {
        default: ContactUs,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/cookie",
      name: "cookie",
      components: {
        default: Cookie,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/faq",
      name: "faq",
      components: {
        default: FAQ,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/imprint",
      name: "imprint",
      components: {
        default: Imprint,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/data-description",
      name: "data-description",
      components: {
        default: DataDescription,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/intro",
      name: "intro",
      components: {
        default: Intro,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://www.foodpackagingforum.org/privacy-policy")
      }
      /*
      components: {
        default: Privacy,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
      */
    },
    {
      path: "/terms",
      name: "terms",
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://www.foodpackagingforum.org/privacy-policy")
      }
      /*
      components: {
        default: Terms,
        header: FPFNavbar,
        footer: FPFFooter
      },
      props: {
        header: {transparent: false},
        footer: {backgroundColor: 'black'}
      }
      */
    },

    // authentication routes
    {
      path: "/login",
      name: "login",
      beforeEnter: ifNotAuthenticated,
      components: {default: Login, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      beforeEnter: ifNotAuthenticated,
      components: {default: ForgotPassword, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: '/resetpassword/:id/:token',
      name: 'resetPassword',
      beforeEnter: ifNotAuthenticated,
      components: {default: ResetPassword, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/signup",
      name: "signup",
      components: {default: SignUp, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: "/success-signup",
      name: "successSignup",
      components: {default: SuccessSignUp, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    },
    {
      path: '*',
      components: {default: NotFound, header: FPFNavbar, footer: FPFFooter},
      props: {
        header: {transparent: true},
        footer: {backgroundColor: 'black'}
      }
    }
  ],
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});
router.beforeEach((to, from, next) => {
  Logger.debug("store isDataLoaded:", store.getters.isDataLoaded);
  Logger.debug("store isAuthenticated:", store.getters.isAuthenticated);
  Logger.debug("router.beforeEach", to, from, next);

  if (store.getters.isAuthenticated && !store.getters.isDataLoaded) {
    store.commit("setLoading", true);
    // avoid multiple refreshes - only check is user can be fetched
    store
      .dispatch("GET_CURRENT_USER")
      .then(user => {
        Logger.debug("current user found:", user);

        Promise.all([
          store.dispatch("GET_CONFIG")
        ])
          .then(() => {
            /*
            // FIXME does not work with logger the global one is defined in js-logger-init
            // FIXME is not used yet - apply if consts filters etc. are fetched from server
            const devMode = store.getters.getDevMode; //process.env.NODE_ENV !== 'production';
            const debug = store.getters.getDebug;
            // trace if in devMode if not only warn
            Logger.setLevel(Logger.ERROR);
            //Logger.setLevel((devMode || debug) ? Logger.WARN : Logger.ERROR);
            //Logger.setLevel(Logger.OFF);
            console.warn("Loglevel:", devMode || debug,  Logger.getLevel());
            */
            // using "finally" so even if there are errors, it stops "loading"
            Logger.trace("DISPATCHER DATA successful");
            //Logger.trace("store", store);
            // set defaultProject in settings as currentProject
            next();
          })
          .catch(error => {
            Logger.error("ROUTER DISPATCHER Loading all Error");
            throw error;
          })
      })
      .catch(error => {
        Logger.error("ROUTER DISPATCHER GET_CURRENT_USER Error");
      })
      .finally(() => {
        Logger.info("DISPATCHER GET_CONFIG in router finished");
        store.commit("setLoading", false);
      });
  } else {
    Logger.trace("no store dispatched");
    //afterLoading(to, from, next);
    //loadLanguageAsync(lang).then(afterLoading(to, from, next));
    //return ifNotAuthenticated(to, from, next);
    store.commit("setLoading", false);
    next();
  }
});


export default router;



<template>
  <a :target="localTarget" rel="noopener" :href="localLink">
    <slot>{{localText}}</slot>
  </a>
</template>

<script>
export default {
  name: "ExternalLink",
  props: {
    link: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    target: {
      type: String,
      required: false,
      default: "_blank"
    },
  },
  computed: {
    localLink: {
      get() {
        // transform if string => obj
        return this.link;
      },
      set(localLink) {
        this.$emit("input", localLink);
      }
    },
    localText: {
      get() {
        // transform if string => obj
        if(this.text) {
          return this.text;
        }
        return this.localLink;
      },
      set(localText) {
        this.$emit("input", localText);
      }
    },
    localTarget: {
      get() {
        // transform if string => obj
        return this.target;
      },
      set(localTarget) {
        this.$emit("input", localTarget);
      }
    },
  },
}
</script>



<style scoped>

</style>

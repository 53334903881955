<template>
  <div class="fcx-detail">
    <h4>More Information</h4>
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <dl>
          <dt>CASID</dt>
          <dd>{{CAS}}</dd>
          <dt>CFSANID</dt>
          <dd>{{CFSAN}}</dd>

          <dt>Chemical Groups</dt>
          <dd>{{ChemGroup || NA}}</dd>

          <dt>Chemical Functions</dt>
          <dd>{{ChemFunction || NA}}</dd>

          <dt>URI</dt>
          <dd><a
                v-if="PublicUrl"
                class=""
                target="_blank"
                rel="noopener nofollow"
                :href="PublicUrl"
              >{{ PublicUrl }}</a>
            <span v-else>{{NA}}</span>
          </dd>
        </dl>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <dl>
          <dt>Synonyms</dt>
          <dd>
            <ul v-if="Synonyms">
              <li :key="index" v-for="(item,index) in Synonyms">{{ item }}</li>
            </ul>
            <div v-else>
              {{NA}}
            </div>
          </dd>

          <dt>Duplicates</dt>
          <dd>
            <ul v-if="Duplicates">
              <li :key="index" v-for="(item,index) in Duplicates">{{ item }}</li>
            </ul>
            <div v-else>
              {{NA}}
            </div>
          </dd>
        </dl>
      </div>

      <!--
      <el-tag
        class="pull-right text-right"
        size="small"
        type="primary"
        :closable="false"
      >
        Status: {{ localInput.status }}
      </el-tag>
      -->
    </div>
  </div>
</template>

<script>
const NA = "n/a";
export default {
  name: "DetailOther",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {

  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    NA() {
      return NA;
    },
    Name() {
      return this.localInput.name;
    },
    CAS: function() {
      let id = NA;
      const cas = this.localInput.cas;
      if(cas && cas.id) {
        let valid =  cas.valid?"valid":"invalid";
        id = cas.id + " (" + valid + ")";
      }
      return id;
    },
    CFSAN: function() {
      let id = NA;
      const cfsan = this.localInput.cfsan;
      //console.log("cfsan", cfsan)
      if(cfsan && cfsan.id) {
        let valid =  cfsan.valid?"valid":"invalid";
        id = cfsan.id + " (" + valid + ")";
      }
      return id;

    },
    ChemGroup: function() {
      const array = this.localInput.chemFamily.concat(this.localInput.chemFamily2);
      return array.join(",");
    },
    ChemFunction: function() {
      const array = this.localInput.chemFunction.concat(this.localInput.chemFunction2);
      return array.join(",");
    },
    Tags: function() {
      return this.localInput.tags;
    },
    Synonyms() {
      return this.localInput.synonyms;
    },
    Duplicates() {
      if (this.localInput.duplicates && this.localInput.duplicates.length > 0) {
        return this.localInput.duplicates;
      }
      return [NA];
    },
    FCMInventory() {
      if (this.localInput.fcm_inventory && this.localInput.fcm_inventory.length > 0) {
        return this.localInput.fcm_inventory;
      }
      return [NA];
    },
    PublicUrl() {
      return this.localInput.uri;
    },
  },
  methods: {

  }
};
</script>

<style scoped>

</style>

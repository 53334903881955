<template>
  <div class="fcx-detail">
    <h4>
      Inventories <ExternalLink class="btn btn-link m-0 p-0" link="https://www.foodpackagingforum.org/fccdb"> <i class="fas fa-external-link-alt" /></ExternalLink>
    </h4>
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <h6>Included in regulatory or industry lists for FCMs? - {{HasFCMInventory | boolToSpeech}}</h6>
        <ul>
          <li v-if="!HasFCMInventory">no sources available</li>
          <li :key="index" v-for="(item,index) in FCMInventory">{{ item }}</li>
        </ul>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>Source Regions</h6>
        <ul>
          <li v-if="!HasSourceRegions">none</li>
          <li :key="index" v-for="(item,index) in SourceRegions">{{ item }}</li>
        </ul>
      </div>


      <div class="col-12 mb-2">
        <h6>Regulations</h6>
        <ul>
          <li v-if="!HasSourcesRegulation">no sources available</li>
          <li :key="index" v-for="(item,index) in SourcesRegulation">
            <DataComponent :value="Extractions[item]"/>
          </li>
        </ul>
      </div>

      <div class="col-12 mb-2">
        <h6>Government reports/lists</h6>
        <ul>
          <li v-if="!HasSourcesGovernment">no sources available</li>
          <li :key="index" v-for="(item,index) in SourcesGovernment">
            <DataComponent :value="Extractions[item]"/>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-6 mb-2">
        <h6>Industry lists</h6>
        <ul>
          <li v-if="!HasSourcesIndustry">no sources available</li>
          <li :key="index" v-for="(item,index) in SourcesIndustry">
            <DataComponent :value="Extractions[item]"/>
          </li>
        </ul>
      </div>

      <div class="col-12 text-sm-left mt-3">
        For more in depth information, visit: <ExternalLink link="https://www.foodpackagingforum.org/fccdb" />.
      </div>
      <!--
      <div class="col-12 col-md-6 mb-2">
        <h6>Production volumes</h6>
        <ul>
          <li v-if="ReachRegisteredTonnage">
            <DataComponent :value="ReachRegisteredTonnage"/>
          </li>
          <li v-else>
            n/a
          </li>
        </ul>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import DataComponent from "@/components/Fcx/DataComponent.vue";
import ExternalLink from "@/components/Fcx/ExternalLink.vue";

export default {
  name: "DetailInventory",
  components: {ExternalLink, DataComponent},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    Extractions() {
      return this.localInput.extractions;
    },
    Lists() {
      return this.localInput.lists;
    },
    SourceRegions() {
      return this.localInput.facets.sourceRegion;
    },
    HasSourceRegions() {
      return this.SourceRegions && this.SourceRegions.length > 0;
    },
    FCMInventory() {
      return this.localInput.fcm_inventory;
    },
    HasFCMInventory() {
      return this.FCMInventory && this.FCMInventory.length > 0;
    },
    SourcesRegulation() {
      return this.Lists.regulations;
    },
    HasSourcesRegulation() {
      return this.SourcesRegulation && this.SourcesRegulation.length > 0;
    },
    SourcesGovernment() {
      return this.Lists.governments;
    },
    HasSourcesGovernment() {
      return this.SourcesGovernment && this.SourcesGovernment.length > 0;
    },
    SourcesIndustry() {
      return this.Lists.reports;
    },
    HasSourcesIndustry() {
      return this.SourcesIndustry && this.SourcesIndustry.length > 0;
    },
    /*
    ReachRegisteredTonnage() {
      return this.Extractions && this.Extractions.REACH_registered_tonnage;
    }
    */
  },
  methods: { }
};
</script>

<style scoped>

</style>

<template>
  <div class="fcx-detail">
    <h4>devMode / Admins</h4>
    <div>
      <h6>Meta</h6>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <dl>
            <dt>_id</dt>
            <dd>{{ localInput._id }}</dd>
            <dt>slug</dt>
            <dd>{{ localInput.slug }}</dd>
            <dt>RefSheetId</dt>
            <dd>{{localInput.meta.extId}}</dd>
            <dt>refChemId</dt>
            <dd>{{ localInput.refChemId }}</dd>
            <dt>ProjectId</dt>
            <dd>{{ localInput.refProjectId }}</dd>
            <dt>UserId</dt>
            <dd>{{ localInput.refUserId }}</dd>
            <dt>Activity</dt>
            <dd>{{ localInput.activity }}</dd>
          </dl>
        </div>
      </div>

      <n-button
        native-type="submit"
        type="primary"
        @click="downloadJson"
        :icon="false"
      >
        <i class="fa fa-download" />
        Download JSON DEV
      </n-button>

      <hr>
      <h6>Facets</h6>
      <ol>
        <li :key="index" v-for="(item,index) in localInput.facets">
          <span class="font-weight-bold">{{ index }}: {{ item }}</span>
        </li>
      </ol>

      <h6>All sources</h6>
      <ol>
        <li :key="index" v-for="(item,index) in localInput.extractions">
          <template v-if="index !== 'migex'">
            <span class="font-weight-bold">{{ item.name }} ({{ item.key }})</span>: {{ item.content }}
            <DataComponent
              :value="item"
            ></DataComponent>
          </template>
        </li>
      </ol>

      <h6>Migex sources</h6>
      <ol>
        <li :key="index" v-for="(item,index) in localInput.extractions.migex">
          <span class="font-weight-bold">{{ item.name }} ({{ item.key }})</span>: {{ item.content }}
          <DataComponent
            :value="item"
          ></DataComponent>
        </li>
      </ol>

      <h6>MasterX</h6>
      <pre>{{ localInput }}</pre>
    </div>
  </div>
</template>

<script>
import NButton from "@/components/Button.vue";
import {citationMixin} from "@/js/mixins/citation-mixin";
import DataComponent from "@/components/Fcx/DataComponent.vue";

export default {
  name: "DetailDev",
  components: {DataComponent, NButton},
  mixins: [citationMixin],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {

  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    }
  },
  methods: {

  }
};
</script>

<style scoped>

</style>

<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              Data Description
            </h3>
            <p>
              FCX brings together many different data sources. This involves needing to understand lots of acronyms,
              some technical vocabulary, and tracking different versions of publications. To help you make sense of it all, this page provides:
            </p>
            <ol>
              <li>
                A glossary of terms used within this platform and the wider field of food contact chemical research and regulation (see: glossary). And
              </li>
              <li>
                A description of the rating system used on the Health & Hazards tab of the chemical profiles (see: hazard rating). And
              </li>
              <li>
                The sources of information we used to develop FCX and when we last updated or checked for updates for each source (see: sources and updates).
              </li>
            </ol>

            <h4>
              Glossary
            </h4>

            <table class="table table-responsive table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Acronym</th>
                  <th scope="col" >Explanation</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(col, index) in GLOSSARY">
                  <th scope="row">{{ col.acronym }}</th>
                  <td>
                    <span v-if="col.uri">
                      <a
                        :href="col.uri"
                        rel="noopener"
                        target="_blank"
                      >
                        {{ col.explanation }}
                      </a>
                    </span>
                    <span v-else>
                      {{ col.explanation }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>
              Hazard rating
            </h4>
            <p>
              The “priority hazardous substance” and “substance of potential concern” rating system was developed by, and is explained fully in, <ExternalLink link="https://www.sciencedirect.com/science/article/pii/S0160412020321802?via%3Dihub">Groh et al, 2021</ExternalLink>.<br><br>

              In short, a “priority hazardous substance” has (i) classifications for health and environmental hazards
              under the globally harmonized system for classification and labeling of chemicals (GHS),
              (ii) has known endocrine disruption or persistence related hazards, and/or (iii)
              is included on selected EU- or US-relevant regulatory lists of hazardous chemicals.<br><br>

              A “substance of potential concern” is based on non-authoritative, predictive hazard data (e.g., by in silico modeling or literature analysis).
            </p>

            <h4>
              Sources and updates
            </h4>
            <p>
              The information included in FCX has been gathered from many different global data sources.
              This section lists all the sources included in FCX, which version(s) of those sources is/are included, and when we last checked for an update.
              <br><br>
              Please be advised that while we strive to provide accurate and relevant information,
              FCX is provided “as is”, and we cannot guarantee the real-time currency of the included data.
              Food contact chemical-related information may change over time due to evolving regulations,
              scientific advancements, and new research findings.
              Please also see the <router-link to="/terms" target="_blank">Terms and Conditions</router-link> you agree to when accessing FCX as well as the <router-link to="privacy" target="_blank">Privacy Policy</router-link>.
            </p>

            <h5><FCCDB />;</h5>
            <p>
              Chemical lists and hazards information from government, industry, and academic reports.
              The table includes the ID used in the original FCCdb spreadsheets published with the peer-reviewed paper, when available.
            </p>

            <h5><FCCMigex />;</h5>
            <p>
              Peer-reviewed studies and gray literature with evidence for chemicals moving out of commercial food contact materials/ articles.
              FCCmigex currently includes references available online by October 2022. Over 1300 references are included in FCCmigex,
              too many to list here. To see them all, please go to the <ExternalLink link="https://www.foodpackagingforum.org/fccmigex">FCCmigex</ExternalLink> dashboard.
            </p>

            <h5>Chemical groups and functions;</h5>
            <p>
              Sources of information used to develop the Chemical Group and Chemical Function tags and filters.
              Please note: a chemical may be marked with a certain function but that does not necessarily mean it is used
              for that function within food contact materials. It may perform another function or be non-intentionally added.
            </p>

            <template v-for="(dataEntry, index) in DataDescriptionArray">
              <h5 :key="dataEntry.title">{{dataEntry.title}}</h5>
              <table :key="dataEntry.title" class="table table-responsive table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th v-if="dataEntry.key === 'chemicalLists'" scope="col">
                      FCCdb
                      <br>
                      IDs
                    </th>
                    <th scope="col" >Version<br>included</th>
                    <th scope="col" >FCX<br>Check</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="'i'+index" v-for="(col, index) in DATA_DESCRIPTION_CONTENT[dataEntry.key]">
                    <th scope="row">
                      <ExternalLink :link="col.uri">
                        {{col.name}}
                      </ExternalLink>
                    </th>
                    <td v-if="dataEntry.key === 'chemicalLists'">
                      {{col.meta.extKey}}
                    </td>
                    <td>
                      {{col.publicationDate}}
                    </td>
                    <td>
                      {{col.version}}
                    </td>
                  </tr>

                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FPF from "@/components/Fcx/static/FPF.vue";
import Minderoo from "@/components/Fcx/static/Minderoo.vue";
import Entreprenerds from "@/components/Fcx/static/Entreprenerds.vue";
import FCCDB from "@/components/Fcx/static/FCCDB.vue";
import FCCMigex from "@/components/Fcx/static/FCCMigex.vue";
import ExternalLink from "@/components/Fcx/ExternalLink.vue";

import Glossary from "@/assets/data/glossary.json";
import DataDescriptionContent from "@/assets/data/dataDescriptionContent.json";
export default {
  name: 'DataDescription',
  bodyClass: 'static-page',
  components: {
    ExternalLink,
    FCCMigex,
    FCCDB,
    Entreprenerds,
    Minderoo,
    FPF
  },
  data() {
    return {
      DataDescriptionArray: [
        {
          title: "Chemical Lists",
          key: "chemicalLists",
          columns: []
        },
        {
          title: "Hazard & Usage",
          key: "hazard",
          columns: []
        },
        {
          title: "Chemical Properties: Group",
          key: "chemGroups",
          columns: []
        },
        {
          title: "Chemical Properties: Function",
          key: "chemFunctions",
          columns: []
        }
      ]
    }
  },
  computed: {
    GLOSSARY() {
      return Glossary;
    },
    DATA_DESCRIPTION_CONTENT() {
      return DataDescriptionContent;
    }
  },
}
</script>
<style lang="scss">
.about-img {
  max-width: 250px;
  top: -2px;
  position: relative;
  float: right;
}
</style>

<template>
  <a href="https://entre.prenerds.com" target="_blank" rel="noopener">entrepre.nerds</a>
</template>
<script>
export default {
  name: 'Entreprenerds'
}
</script>

<style scoped>

</style>
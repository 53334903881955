
<template>
  <div class="fcx-filter-facet">
    <collapse v-if="IsFoldable">
      <collapse-item
        :itemId="field"
        :open="IsOpen"
        no-icon
        class="card-header"
      >
        <span
          slot="title"
          class="mb-0 text-primary"
        >
          {{label}}
          <i class="now-ui-icons arrows-1_minimal-down" />
        </span>
        <el-cascader
          @change="updateFacet"
          :placeholder="placeholder"
          v-model="val"
          :options="selection"
          :props="{ multiple: !TypeIsSingle, checkStrictly: true }"
        >
        </el-cascader>
        <!--
        val {{val}}<br>
        preset {{preset}}<br>
        field: {{field}}<br>
        localInput: {{localInput}}<br>
        selection: <pre>{{selection}}</pre>
        -->

      </collapse-item>
    </collapse>
    <div v-else>
      <span
        slot="title"
        class="mb-2 text-primary"
      >
        {{label}}
      </span>
      val {{val}}<br>
      preset {{preset}}<br>
      field: {{field}}<br>
      localInput: {{localInput}}<br>
      <el-cascader
        @change="updateFacet"
        :placeholder="placeholder"
        v-model="val"
        :options="selection"
        :props="{ multiple: !TypeIsSingle, checkStrictly: true }"
      >
      </el-cascader>
    </div>
  </div>

</template>


<script>

import {Collapse, CollapseItem, } from "@/components";
import {Cascader} from 'element-ui';

export default {
  name: 'CascaderFacet',
  components: {
    [Cascader.name]: Cascader,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  props: {
    // add or type
    value: {
      type: Object,
      required: false,
      default: function() {
        return {}
      }
    },
    preset: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      default: 'single'
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select entry"
    },
    field: {
      type: String,
      required: true
    },
    selection: {
      type: Array,
      required: false,
      default: () => []
    },
    foldable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      val: ""
    }
  },
  watch: {
    // if localInput changed by the URI it will be preset here
    localInput: {
      handler: function (val, oldVal) {
        Logger.debug("localInput changed", val, oldVal);
        if(this.localInput[this.field]) {
          if(this.TypeIsSingle) {
            // this.val = string
            this.val = this.localInput[this.field];
          } else {
            // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
            this.val = this.localInput[this.field].$in;
            if(!this.val) {
              this.val = [];
            }
          }
        } else {
          if(this.TypeIsSingle) {
            this.val = "";
          } else {
            // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
            this.val = [];
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    Logger.debug("mounted filter facet", this.field, this.localInput,  this.preset);
    if(this.preset) {
      this.val = this.preset;
    }
  },
  computed: {
    localInput: {
      get() {
        // transform if string => obj
        return this.value;
      },
      set(localInput) {
        this.$emit("input", localInput);
      }
    },
    TypeIsSingle() {
      return this.type === 'single';
    },
    IsFoldable() {
      return this.foldable;
    },
    IsOpen() {
      if(this.IsFoldable) {
        if(this.TypeIsSingle) {
          return !!this.val;
        } else {
          return this.val && this.val.length > 0;
        }
      }
      return false;
    }
  },
  methods: {
    updateFacet() {
      Logger.debug("updateFacet", this.selection);
      // check if changed

      // delete field from localInput
      //delete this.localInput[this.field];
      const cpObject = JSON.parse(JSON.stringify(this.localInput));

      // is val single and set at all?
      if(this.TypeIsSingle && this.val) {
        // this.val = string
        Logger.debug("updateFacet this.val", this.val);
        // this.val is array while the last one is the set element
        let lastVal = this.val[this.val.length - 1];
        cpObject[this.field] = lastVal;
      } else if(!this.TypeIsSingle && this.val && this.val.length > 0) { // multi and at least one array elem
        // this.val = array e.g. { "fcm_inventory": {$in:["plastics"]} }
        cpObject[this.field] = { $in: this.val };
      } else {
        delete cpObject[this.field];
      }
      this.localInput = cpObject;
    }
  }
}
</script>

<style scoped>

</style>
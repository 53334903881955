import API from "@/js/api/user.js";
import authAPI from "@/js/api/auth.js";
import tokenService from "@/js/services/token-service.js";
import savedSearchService from "@/js/services/saved-search-service.js";


const defaultState = {
  users: [],
  currentUser: {}, // in contrary to user it is the db one.
  token: tokenService.getLocalAccessToken(),
  refreshToken: tokenService.getLocalRefreshToken(),
  user: tokenService.getLocalUser() || {}, // local storage
  savedSearch: savedSearchService.getLocalSavedSearch() || [], // local storage
  status: ""
};

const actions = {
  FORGOT_PASSWORD: (context, data) => {
    Logger.debug("USER ACTION FORGOT_PASSWORD", data);
    context.state.loading = true;
    context.commit("authRequest");
    return API.forgotPassword(data)
      .then(response => {
        Logger.debug("USER ACTION FORGOT_PASSWORD success", response);
        // commit mutation
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("USER ACTION FORGOT_PASSWORD Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  RESET_PASSWORD: (context, data) => {
    Logger.debug("USER ACTION RESET_PASSWORD", data);
    context.state.loading = true;
    context.commit("authRequest");
    return API.resetPassword(data)
      .then(response => {
        Logger.debug("USER ACTION RESET_PASSWORD success", response);
        // commit mutation
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("USER ACTION RESET_PASSWORD Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  LOGIN: (context, data) => {
    Logger.debug("USER ACTION LOGIN", data);
    context.state.loading = true;
    context.commit("authRequest");
    return API.login(data)
      .then(response => {
        Logger.debug("USER ACTION LOGIN success", response);
        // commit mutation
        context.commit("login", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("USER ACTION LOGIN Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  SIGN_UP: (context, data) => {
    Logger.debug("USER ACTION SIGN_UP", data);
    context.state.loading = true;
    // context.commit("authRequest"); // used for direct login
    return API.signUp(data)
      .then(response => {
        Logger.debug("USER ACTION SIGN_UP success", response);
        // commit mutation
        // context.commit("login", response); // used for direct login
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("USER ACTION SIGN_UP Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  LOGOUT: (context) => {
    Logger.debug("USER ACTION LOGOUT");
    context.state.loading = true;
    return API.logout()
      .then(response => {
        Logger.debug("USER ACTION LOGOUT success", response);
        // commit mutation
        context.commit("logout");
        // you are logged out :)
        //dispatch(USER_REQUEST)
        return response;
      })
      .catch(error => {
        //Logger.error("GET_FRONTEND_USER Error ", error);
        //context.commit("logout", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  REFRESH: (context, data) => {
    Logger.debug("USER ACTION REFRESH", data);
    context.state.loading = true;
    context.commit("authRequest");
    return authAPI.refresh(data)
      .then(response => {
        Logger.debug("USER ACTION REFRESH success", response);
        // commit mutation
        context.commit("refresh", response);
        return response;
      })
      .catch(error => {
        Logger.debug("USER ACTION REFRESH Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        context.commit("setState", error);
        throw error;
      })
      .finally(() => {
        context.state.loading = false;
      });
  },
  GET_CURRENT_USER(context) {
    Logger.info("GET_CURRENT_USER");
    return API.getCurrentUser()
      .then(response => {
        Logger.debug("USERS ACTION GET_CURRENT_USER success", response);
        // commit mutation
        context.commit("setCurrentUser", response);
        return response;
      })
      .catch(error => {
        Logger.debug("USERS ACTION GET_CURRENT_SETTINGS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },

  GET_USER(context, data) {
    Logger.debug("GET_USER", data);
    return API.getUser(data)
      .then(response => {
        Logger.debug("USER ACTION GET_USER success", response);
        // commit mutation
        //context.commit("setCurrentProject", response);
        return response;
      })
      .catch(error => {
        Logger.debug("USER ACTION GET_USER error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },

  GET_USERS: (context, data) => {
    Logger.debug("GET_USERS", data);
    return API.getUsers(data)
      .then(response => {
        Logger.debug("USERS ACTION GET_USERS success", response);
        // commit mutation
        context.commit("setUsers", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
        Logger.debug("USERS ACTION GET_USERS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_CURRENT_USERS: (context, data) => {
    Logger.debug("GET_CURRENT_USERS", data);
    return API.getCurrentUsers()
      .then(response => {
        Logger.debug("USERS ACTION GET_CURRENT_USERS success", response);
        // commit mutation
        context.commit("setUsers", response);
        return response;
      })
      .catch(error => {
        Logger.debug("USERS ACTION GET_CURRENT_USERS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  EXPORT_USERS(context, data) {
   Logger.debug("EXPORT_USERS", data);
    return API.export(data)
      .then(response => {
       Logger.debug("USERS ACTION EXPORT_USERS success", response);
        // commit mutation

        return response;
      })
      .catch(error => {
       Logger.debug("USERS ACTION EXPORT_USERS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  IMPORT_USERS(context, data) {
   Logger.debug("IMPORT_USERS", data);
    return API.import(data)
      .then(response => {
       Logger.debug("USERS ACTION IMPORT_USERS success", response);
        // commit mutation
        return response;
      })
      .catch(error => {
       Logger.debug("USERS ACTION IMPORT_USERS error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  /*
    GET_USER(context, config) {
      return API.getFrontendUser()
        .then(response => {
          // commit mutation
          context.commit("setFrontendUser", response);
        })
        .catch(error => {
          Logger.error("GET_FRONTEND_USER Error ", error);
          throw error;
        })
        .finally(() => {
          //this.state.loading = false;
        });
    },
    CHANGE_NAME(context, data) {
      return API.changeName(data)
        .then(response => {
          // commit mutation
          Logger.trace("CHANGE_NAME success ", data, response);
          this.state.user.user.name = data.name;
          //context.commit('setUser', newUser)
        })
        .catch(error => {
          Logger.error("CHANGE_NAME Error ", error);
          throw error;
        });
    },
    CHANGE_EMAIL(context, data) {
      return API.changeEmail(data)
        .then(response => {
          // commit mutation
          Logger.trace("CHANGE_EMAIL success ", data, response);
          this.state.user.user.email = data.email;
          //context.commit('setUser', prof)
        })
        .catch(error => {
          Logger.error("CHANGE_EMAIL Error ", error);
          throw error;
        });
    },
    CHECK_EMAIL(context, data) {
      return API.checkEmail(data)
        .then(response => {
          // commit mutation
          Logger.trace("CHECK_EMAIL success ", response);
          //context.commit('setUser', prof)
          return response;
        })
        .catch(error => {
          Logger.error("CHECK_EMAIL Error ", error);
          throw error;
        });
    },
    CHANGE_PASSWORD(context, data) {
      return API.changePassword(data)
        .then(response => {
          // commit mutation
          Logger.trace("CHANGE_PASSWORD success ", response);
          //context.commit('setUser', prof)
        })
        .catch(error => {
          Logger.error("CHANGE_PASSWORD Error ", error);
          throw error;
        });
    }
   */
};

const mutations = {
  setState(state, payload) {
    state.status = payload;
  },
  // db
  setCurrentUser(state, payload) {
    state.currentUser = payload;
  },
  //local storage
  setUser(state, payload) {
    state.user = payload;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setRefreshToken(state, payload) {
    state.refreshToken = payload;
  },
  login: (state, payload) => {
    state.status = "success";
    state.token = payload.token;
    state.refreshToken = payload.refreshToken;

    tokenService.setLocalAccessToken(state.token); // store the token in localstorage
    tokenService.setLocalRefreshToken(state.refreshToken); // store the token in localstorage

    //delete user.password;
    state.user = {
      _id: payload.user._id,
      name: payload.user.name,
      email: payload.user.email,
      organisation: payload.user.organisation,
      // new
      role: payload.user.role
    };
    tokenService.setLocalUser(state.user); // store user data in localstorage
  },
  logout: state => {
    state.status = "";
    state.token = "";
    state.refreshToken = "";
    state.user = {};
    state.currentUser = {};
    tokenService.removeLocalEntries();
  },
  refresh: (state, payload) => {
    state.status = "success";
    state.token = payload.token;
    tokenService.setLocalAccessToken(state.token); // store the token in localstorage
  },

  authRequest: (state) => {
    state.status = 'loading'
  },
  authError: (state) => {
    state.status = 'error';
  },
  addSavedSearch: (state, payload) => {
    if(!state.savedSearch) {
        state.savedSearch = [];
    }
    // check if already exists TODO bad performance | add name to saved search and only check for name
    let payloadString = JSON.stringify(payload);
    let found = false;
    for(let entry of state.savedSearch) {
      const entryString = JSON.stringify(entry);
      found = entryString === payloadString;
      if(found) {
        throw Error("Saved search does already exist");
      }
    }
    // end

    state.savedSearch.push(payload);
    savedSearchService.setLocalSavedSearch(state.savedSearch);
  },
  removeSavedSearch(state, savedSearch) {
    state.savedSearch.splice(state.savedSearch.indexOf(savedSearch), 1);
    savedSearchService.setLocalSavedSearch(state.savedSearch);
  },
  clearSavedSearch(state) {
    state.savedSearch = [];
    savedSearchService.removeLocalSavedSearch();
  }
};

const getters = {
  // db
  currentUser: state => {
    return state.currentUser;
  },
  // local storage
  user: state => {
    return state.user;
  },
  users: state => {
    return state.users;
  },
  token: state => {
    return state.token;
  },
  refreshToken: state => {
    return state.refreshToken;
  },
  isAuthenticated: state => {
    return !!state.token;
  },
  isDataLoaded: state => {
    const obj = state.currentUser;
    if (
      obj && // null and undefined check
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    ) {
      return false;
    }
    return true;
  },
  authState: state => {
    return state.status;
  },
  savedSearch: state => {
    return state.savedSearch;
  }
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};



<template>
  <div
    class="page-header header-filter"
    filter-color="orange"
  >
    <div
      class="page-header-image"
      style="background-image: url('img/background/FCX-bg.jpg')"
    />
    <div class="content-center">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card
            type="login"
            plain
          >
            <div
              slot="header"
              class="logo-container d-none d-md-block"
            >
              <img
                v-lazy="'img/logo/FCX-Logo-signet.png'"
                alt=""
              >
            </div>

            <form @submit.prevent="forgotPassword">
              <fg-input
                v-model="form.email"
                :required="true"
                minlength="5"
                maxlength="255"
                class="no-border input-lg"
                addon-left-icon="now-ui-icons ui-1_email-85"
                placeholder="Email..."
              />
              <div class="card-footer text-center">
                <n-button
                  native-type="submit"
                  class="btn btn-primary btn-round btn-lg btn-block"
                >
                  Forgot password
                </n-button>
              </div>
            </form>
            <template slot="raw-content">
              <div class="pull-left">
                <h6>
                  <router-link
                    class="link footer-link"
                    to="/signup"
                  >
                    <i class="fa fa-mobile" /> Register
                  </router-link>
                  <router-link
                    class="link footer-link"
                    to="/login"
                  >
                    <i class="fa fa-user" /> Login
                  </router-link>
                </h6>
              </div>
              <div class="pull-right">
                <h6>
                  <router-link
                    class="link footer-link"
                    to="/contact"
                  >
                    <i class="fa fa-question-circle" /> Need Help?
                  </router-link>

                </h6>
              </div>
            </template>

          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button, FormGroupInput } from '@/components';
  import MainFooter from '@/layout/MainFooter';
  export default {
    name: 'ForgotPasswordPage',
    bodyClass: 'login-page',
    components: {
      Card,
      MainFooter,
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput
    },
    data() {
      return {
        form: {
          email: ""
        }
      }
    },
    methods: {
      forgotPassword() {
        // handle login here
        let payloadObj = {
          email: this.form.email
        };
        this.LoadingX = true;
        this.$store
          .dispatch("FORGOT_PASSWORD", payloadObj)
          .then(val => {
            this.$notify({
              title: "Reset password email success",
              message: "Please check your email inbox and confirm resetting your password.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            this.$router.push({name: 'login'});
          })
          .catch(error => {
            Logger.debug("error", error);
            this.$notify({
              title: "Reset password email failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    }
  }
</script>
<style>
</style>

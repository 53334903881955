<template>
  <a href="tel:+41 44 515 52 55">+41 44 515 52 55</a>
</template>
<script>
export default {
  name: 'FPFPhone',
}
</script>

<style scoped>

</style>
<template>
  <p>
    Food Contact eXplorer (FCX)<br>
    c/o Food Packaging Forum Foundation<br>
    Staffelstrasse 10<br>
    8045 Zurich<br>
    Switzerland
  </p>
</template>
<script>
export default {
  name: 'FPFAddress',
}
</script>

<style scoped>

</style>
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// modules
import configModule from "./modules/config";
import userModule from "./modules/user";
import projectModule from "./modules/project";
import masterModule from "./modules/master";
// https://stackoverflow.com/questions/41572974/how-to-load-all-server-side-data-on-initial-vue-js-vue-router-load
const mainStore = new Vuex.Store({
  modules: {
    config: configModule,
    user: userModule,
    project: projectModule,
    master: masterModule
  },
  state: {
    loading: true, // global,
    cookieStatus: null
  },
  mutations: {
    setLoading(state, newLoading) {
      state.loading = newLoading;
    },
    setCookieStatus(state, newStatus) {
      state.cookieStatus = newStatus;
    }
  },
  actions: {

  },
  getters: {
    loading: state => {
      return state.loading;
    },
    cookieStatus: state => {
      return state.cookieStatus;
    },
  }
});

export default mainStore;

export default {
  text: {
    na: "N/A",
    noSources: "no sources available"
  },
  forms: {
    subjectOptions: [
      {
        value: "general",
        label: "General Inquiry"
      },
      {
        value: "tech",
        label: "Technical Support"
      },
      {
        value: "media",
        label: "Media and Press Inquiry"
      },
      {
        value: "partner",
        label: "Collaboration, Partnership, Support"
      },
    ],
    organisationTypes: [
      {
        value: "governmental_body",
        label: "Governmental Body"
      },
      {
        value: "civil_society_organization",
        label: "Civil Society Organization"
      },
      {
        value: "private_consumer",
        label: "Private Consumer"
      },
      {
        value: "food_service_restaurant",
        label: "Food Service/Restaurant"
      },
      {
        value: "food_packaging_manufacturer",
        label: "Food Packaging Manufacturer"
      },
      {
        value: "food_packaging_distributor",
        label: "Food Packaging Distributor"
      },
      {
        value: "academic_researcher",
        label: "Academic Researcher"
      },
      {
        value: "industry_consultant",
        label: "Industry Consultant"
      },
      {
        value: "chemical_manufacturer",
        label: "Chemical Manufacturer"
      },
      {
        value: "other",
        label: "Other"
      }
    ]
  },
  filters: {
    // TODO move these to own lib and/or export from server
    // q={"facets.sources": "S14"}&t=find&s={"name": 1}&sk=0&l=5
    fcmInventories: [
      {
        "label": "Plastics",
        "value": "Plastics"
      },
      {
        "label": "Coatings",
        "value": "Coatings"
      },
      {
        "label": "Rubber",
        "value": "Rubber"
      },
      {
        "label": "Silicones",
        "value": "Silicones"
      },
      {
        "label": "Ion-Exchange Resins",
        "value": "Ion-Exchange Resins"
      },
      {
        "label": "Paper and Board",
        "value": "Paper and Board"
      },
      {
        "label": "Cellophane",
        "value": "Cellophane"
      },
      {
        "label": "Textiles",
        "value": "Textiles"
      },
      {
        "label": "Cork and Wood",
        "value": "Cork and Wood"
      },
      {
        "label": "Adhesives",
        "value": "Adhesives"
      },
      {
        "label": "Colorants",
        "value": "Colorants"
      },
      {
        "label": "Printing Inks",
        "value": "Printing Inks"
      },
      {
        "label": "Wax",
        "value": "Wax"
      },
      {
        "label": "Inorganics",
        "value": "Inorganics"
      },
      {
        "label": "A&I Materials",
        "value": "A&I Materials"
      },
      {
        "label": "Other uses",
        "value": "Other uses"
      }

    ],
    chemGroups: [
      {
        "label": "PAA",
        "value": "PAA"
      },
      {
        "label": "PFAS",
        "value": "PFAS"
      },
      {
        "label": "BADGE derivatives",
        "value": "BADGE derivatives"
      },
      {
        "label": "CHLORINATED PARAFFINS",
        "value": "CHLORINATED PARAFFINS"
      },
      {
        "label": "BENZOPHENONES",
        "value": "BENZOPHENONES"
      },
      {
        "label": "BISPHENOLS",
        "value": "BISPHENOLS"
      },
      {
        "label": "PAH",
        "value": "PAH"
      },
      {
        "label": "n-ALKANES",
        "value": "n-ALKANES"
      },
      {
        "label": "METALS",
        "value": "METALS"
      },
      {
        "label": "OLIGOMERS",
        "value": "OLIGOMERS"
      },
      {
        "label": "PARABENS",
        "value": "PARABENS"
      },
      {
        "label": "PHTHALATES",
        "value": "PHTHALATES"
      }
    ],
    chemFunctions: [
      {
        "label": "FLOURESCENT BRIGHTENER",
        "value": "FLOURESCENT BRIGHTENER"
      },
      {
        "label": "FLAME RETARDANTS",
        "value": "FLAME RETARDANTS"
      },
      {
        "label": "PHOTOINITIATORS",
        "value": "PHOTOINITIATORS"
      },
      {
        "label": "SURFACTANTS",
        "value": "SURFACTANTS"
      },
      {
        "label": "ANTIOXIDANTS",
        "value": "ANTIOXIDANTS"
      },
      {
        "label": "PIGMENTS",
        "value": "PIGMENTS"
      },
      {
        "label": "NIAS",
        "value": "NIAS"
      },
      {
        "label": "UV FILTERS",
        "value": "UV FILTERS"
      },
      {
        "label": "PLASTICIZERS",
        "value": "PLASTICIZERS"
      },
      {
        "label": "MONOMERS",
        "value": "MONOMERS"
      }
    ],
    detectedFCAs: [
      {
        "label": "Single-use",
        "value": "Single-use"
      },
      {
        "label": "Repeat-use",
        "value": "Repeat-use"
      },
      {
        "label": "Unclear/unknown",
        "value": "Unclear/unknown"
      }],

    detectedFCMMains: [
      {
        "label": "Wood",
        "value": "Wood"
      },
      {
        "label": "PET",
        "value": "PET"
      },
      {
        "label": "Rec PET",
        "value": "Rec PET"
      },
      {
        "label": "Rec paper & board",
        "value": "Rec paper & board"
      },
      {
        "label": "Paper & board",
        "value": "Paper & board"
      },
      {
        "label": "PA",
        "value": "PA"
      },
      {
        "label": "Multilayer plastic",
        "value": "Multilayer plastic"
      },
      {
        "label": "PU",
        "value": "PU"
      },
      {
        "label": "Unclear/unknown",
        "value": "Unclear/unknown"
      },
      {
        "label": "PE (HDPE, LDPE)",
        "value": "PE (HDPE, LDPE)"
      },
      {
        "label": "PS",
        "value": "PS"
      },
      {
        "label": "Glass",
        "value": "Glass"
      },
      {
        "label": "Plastic",
        "value": "Plastic"
      },
      {
        "label": "PP",
        "value": "PP"
      },
      {
        "label": "Metal",
        "value": "Metal"
      },
      {
        "label": "Rubber",
        "value": "Rubber"
      },
      {
        "label": "Cork",
        "value": "Cork"
      },
      {
        "label": "Silicone",
        "value": "Silicone"
      },
      {
        "label": "PLA",
        "value": "PLA"
      },
      {
        "label": "PVC",
        "value": "PVC"
      },
      {
        "label": "Multimaterial multilayer",
        "value": "Multimaterial multilayer"
      },
      {
        "label": "Melamine",
        "value": "Melamine"
      },
      {
        "label": "Other",
        "value": "Other"
      },
      {
        "label": "PC",
        "value": "PC"
      },
      {
        "label": "Aluminum",
        "value": "Aluminum"
      },
      {
        "label": "Steel",
        "value": "Steel"
      },
      {
        "label": "Mixed material",
        "value": "Mixed material"
      }
    ],
    detectedMaterialGroups: [
      {
        "label": "Plastics",
        "value": "Plastics"
      },
      {
        "label": "Paper & Board",
        "value": "Paper & Board"
      },
      {
        "label": "Metal",
        "value": "Metal"
      },
      {
        "label": "Glass & Ceramic",
        "value": "Glass & Ceramic"
      },
      {
        "label": "Multi-materials",
        "value": "Multi-materials"
      },
      {
        "label": "Other",
        "value": "Other"
      }
    ],
    sourceNames: [
      {
        "label": "Assessing direct analysis in real-time-mass spectrometry (DART-MS) for the rapid identification of additives in food packaging",
        "value": "65b91c8606feb083f1b95d74"
      },
      {
        "label": "Simultaneous chromatographic analysis of photoinitiators and amine synergists in food contact materials",
        "value": "65b91c7d06feb083f1b95ba9"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 2,4-diamino-6-hydroxypyrimidine, CAS No. 56-06-4, for use in food contact materials",
        "value": "65b91da306feb083f1b99d63"
      },
      {
        "label": "Bisphenol A and Bisphenol S release in milk under household conditions from baby bottles marketed in Italy",
        "value": "65b91e3d06feb083f1b9bdb1"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, 5-norbornene-2,3-dicarboxylic anhydride, CAS No 826-62-0, for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91e4b06feb083f1b9c0bb"
      },
      {
        "label": "Quantitative analysis of residual solvents in food packaging printed films by capillary gas chromatography with multiple headspace extraction",
        "value": "65b91d0306feb083f1b97829"
      },
      {
        "label": "PVC plasticizers/additives migrating from the gaskets of metal closures into oily food: Swiss market survey June 2005",
        "value": "65b91c8406feb083f1b95d10"
      },
      {
        "label": "Solid-phase microextraction followed by gas chromatography-mass spectrometry for the determination of ink photo-initiators in packed milk",
        "value": "65b91cd106feb083f1b96d93"
      },
      {
        "label": "Safety assessment of the substance diethyl 3,5-bis(1,1-dimethylethyl)-4-hydroxyphenyl methyl phosphonate, for use in food contact materials",
        "value": "65b91e8f06feb083f1b9ce14"
      },
      {
        "label": "Substances identified as endocrine disruptors at EU level",
        "value": "65b9114d0729b5ae4f62a05f"
      },
      {
        "label": "Identification and Migration Studies of Photolytic Decomposition Products of UV-Photoinitiators in Food Packaging",
        "value": "65b91c7206feb083f1b95912"
      },
      {
        "label": "Safety assessment of the substance isobutane, for use in food contact materials",
        "value": "65b91e2906feb083f1b9b977"
      },
      {
        "label": "Mercosur 2012 List for food contact plastics monomers and other starting substances",
        "value": "65b9114c0729b5ae4f62a01f"
      },
      {
        "label": "Determination of Selected Perfluorinated Acids (PFCAs) and Perfluorinated Sulfonates (PFASs) in Food Contact Materials Using LC-MS/MS",
        "value": "65b91d1e06feb083f1b97e5a"
      },
      {
        "label": "Study on the influence of temperature, storage time and packaging type on di-n-butylphthalate and di(2-ethylhexyl)phthalate release into packed meals",
        "value": "65b91cc606feb083f1b96b38"
      },
      {
        "label": "Element residues in food contact plastics and their migration into food simulants, measured by inductively-coupled plasma-mass spectrometry",
        "value": "65b91ded06feb083f1b9ad59"
      },
      {
        "label": "Food packaging from recycled papers: chemical, physical, optical properties and heavy metal migration",
        "value": "65b91df806feb083f1b9af90"
      },
      {
        "label": "Trinkhalme aus Papier",
        "value": "65b91e8b06feb083f1b9cd27"
      },
      {
        "label": "Determination of UV stabilizers in PET bottles by high performance-size exclusion chromatography",
        "value": "65b91cb806feb083f1b9681a"
      },
      {
        "label": "Evidence for unique and ubiquitous environmental sources of 3,3'-dichlorobiphenyl (PCB 11)",
        "value": "65b91d2b06feb083f1b9817f"
      },
      {
        "label": "Machine learning directed discrimination of virgin and recycled poly (ethylene terephthalate) based on non-targeted analysis of volatile organic compounds",
        "value": "65b91c6906feb083f1b95757"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance fatty acids, C16-18 saturated, hexaesters with dipentaerythritol for use in food contact materials",
        "value": "65b91ce606feb083f1b97212"
      },
      {
        "label": "Metals release from stainless steel knives in simulated food contact",
        "value": "65b91dfc06feb083f1b9b059"
      },
      {
        "label": "Further insights into the mechanism of migration from the PVC gaskets of metal closures into oily foods in glass jars",
        "value": "65b91e4506feb083f1b9bf7b"
      },
      {
        "label": "Release of N-nitrosamines and N-nitrosatable substances from baby bottle teats and rubber kitchen tools in Korea",
        "value": "65b91c7406feb083f1b95961"
      },
      {
        "label": "Development and Validation of a UHPLC-qTOF MS Method for the Determination of Sorbitol-Based Nuclear Clarifying Agents in Food Simulants after Migration from Food Contact Materials",
        "value": "65b91c7206feb083f1b9590e"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance perfluoromethyl perfluorovinylether, CAS No 1187-93-5, for use in food contact materials",
        "value": "65b91ccd06feb083f1b96cb2"
      },
      {
        "label": "Effects of consumer use practices on nanosilver release from commercially available food contact materials",
        "value": "65b91e0606feb083f1b9b27b"
      },
      {
        "label": "Changes in the specific migration characteristics of packaging?food simulant combinations caused by ionizing radiation: Effect of food simulant",
        "value": "65b91e3206feb083f1b9bb54"
      },
      {
        "label": "Migration of oligomers from PET: determination of diffusion coefficients and comparison of experimental versus modelled migration",
        "value": "65b91d1206feb083f1b97b86"
      },
      {
        "label": "Leaching of aluminium from aluminium dishes and packages",
        "value": "65b91ded06feb083f1b9ad2d"
      },
      {
        "label": "An evaluation of the migration of antimony from polyethylene terephthalate (PET) plastic used for bottled drinking water",
        "value": "65b91e0d06feb083f1b9b3f5"
      },
      {
        "label": "Screening for Chemicals in Paper and Board Packaging for Food Use: Chemometric Approach and Estimation of Migration",
        "value": "65b91c7c06feb083f1b95b62"
      },
      {
        "label": "Excitation-emission fluorescence-kinetic third-order/four-way data: Determination of bisphenol A and nonylphenol in food-contact plastics",
        "value": "65b91c8a06feb083f1b95e5c"
      },
      {
        "label": "Migration from the gaskets of lids into oily foods: first results on polyadipates",
        "value": "65b91ca506feb083f1b963ed"
      },
      {
        "label": "Phthalate and novel plasticizer concentrations in food items from U.S. fast food chains: a preliminary analysis",
        "value": "65b91c8406feb083f1b95d17"
      },
      {
        "label": "Optimization of a headspace solid-phase microextraction and gas chromatography/mass spectrometry procedure for the determination of aromatic amines in water and in polyamide spoons",
        "value": "65b91c7706feb083f1b95a33"
      },
      {
        "label": "Development and validation of a multi-analyte GC-MS method for the determination of 84 substances from plastic food contact materials",
        "value": "65b91c8b06feb083f1b95eab"
      },
      {
        "label": "Search for a more adequate test to predict the long-term migration from the PVC gaskets of metal lids into oily foods in glass jars",
        "value": "65b91ca506feb083f1b963cd"
      },
      {
        "label": "Analysis of Low Molecular Weight Components in disposable food contact materials",
        "value": "65b91ceb06feb083f1b97315"
      },
      {
        "label": "New method for determination of epichlorohydrin in epoxy-coated cans by oxolane derivatization and gas chromatography-mass spectrometry",
        "value": "65b91c9806feb083f1b9611a"
      },
      {
        "label": "Mercosur 2019 List of additives for food contact plastics",
        "value": "65b9114c0729b5ae4f62a021"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance bis(2,6-diisopropylphenyl) carbodiimide for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91d4306feb083f1b98712"
      },
      {
        "label": "Characterisation of Elastomers as Food Contact Materials-Part 1: Quantification of Extractable Compounds, Swelling of Elastomers in Food Simulants and Release of Elements",
        "value": "65b91ded06feb083f1b9ad3c"
      },
      {
        "label": "Determination of the Specific Migration of Lead and Cadmium from Ceramicware in Contact with Foodstuff",
        "value": "65b91df606feb083f1b9af3b"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, silver zeolite A (silver zinc sodium ammonium alumino silicate), silver content 2 - 5 %, for use in food contact materials",
        "value": "65b91e0506feb083f1b9b22e"
      },
      {
        "label": "Migration of antimony from PET trays into food simulant and food: determination of Arrhenius parameters and comparison of predicted and measured migration data",
        "value": "65b91e0c06feb083f1b9b3c2"
      },
      {
        "label": "Development of an LC-MS/MS method for studying migration characteristics of acetaldehyde in polyethylene terephthalate (PET)-packed mineral water",
        "value": "65b91e2806feb083f1b9b943"
      },
      {
        "label": "ANALYSIS OF DI-N-BUTYL PHTHALATE (DBP) RESIDUE IN COMMERCIAL FOOD PACKAGING FROM RECYCLED PAPER AND ITS MIGRATION INTO FOOD SIMULANT",
        "value": "65b91e6506feb083f1b9c530"
      },
      {
        "label": "Study on the Migration Process of Food Packaging Materials",
        "value": "65b91c7106feb083f1b958c7"
      },
      {
        "label": "Investigation into the migration potential of coating materials from cookware products",
        "value": "65b91c6c06feb083f1b95802"
      },
      {
        "label": "HPLC method for determining ethylenediamine migration from epoxy-amine food packaging coatings into EU food simulants",
        "value": "65b91c9906feb083f1b9614c"
      },
      {
        "label": "Simultaneous detection of eight phenols in food contact materials after electrochemical assistance solid-phase microextraction based on amino functionalized carbon nanotube/polypyrrole composite",
        "value": "65b91c9606feb083f1b960d0"
      },
      {
        "label": "Targeted and untargeted gas chromatography-mass spectrometry analysis of honey samples for determination of migrants from plastic packages",
        "value": "65b91c6e06feb083f1b95845"
      },
      {
        "label": "Hydrocarbon contaminants in pizza boxes from the Italian market",
        "value": "65b91cd606feb083f1b96e85"
      },
      {
        "label": "Migration of nonylphenol from plastic containers to water and a milk surrogate",
        "value": "65b91cff06feb083f1b97783"
      },
      {
        "label": "A fast SALLE GC-MS/MS multi-analyte method for the determination of 75 food packaging substances in food simulants",
        "value": "65b91c6d06feb083f1b9582c"
      },
      {
        "label": "Safety assessment of the substance poly((R)-3-hydroxybutyrate-co-(R)-3-hydroxyhexanoate) for use in food contact materials",
        "value": "65b91c9b06feb083f1b961ea"
      },
      {
        "label": "Bisphenol A (BPA) and its source in foods in Japanese markets",
        "value": "65b91d1706feb083f1b97cce"
      },
      {
        "label": "Determination of Phthalate Residues in Different Types of Yogurt by Gas Chromatography-Mass Spectrometry and Estimation of Yogurt-Related Intake of Phthalates",
        "value": "65b91cc006feb083f1b969d4"
      },
      {
        "label": "Isotope Internal Standard Method for Determination of Four Acrylamide Compounds in Food Contact Paper Products and Food Simulants by Ultra-High Performance Liquid Chromatography Tandem Mass Spectrometry",
        "value": "65b91d7106feb083f1b991b4"
      },
      {
        "label": "Recycled paper-paperboard for food contact materials: contaminants suspected and migration into foods and food simulant",
        "value": "65b91cc006feb083f1b969c9"
      },
      {
        "label": "Effect of ionising radiation treatment on the specific migration characteristics of packaging-food simulant combinations: effect of type and dose of radiation",
        "value": "65b91e3206feb083f1b9bb5f"
      },
      {
        "label": "Determination of partition and diffusion coefficients of components of two rubber adhesives in different multilayer materials",
        "value": "65b91c6a06feb083f1b9578f"
      },
      {
        "label": "Rapid determination of nineteen chlorophenols in wood, paper, cardboard, fruits, and fruit juices by gas chromatography/mass spectrometry",
        "value": "65b91e7106feb083f1b9c7b9"
      },
      {
        "label": "Study of the migration of benzophenone from printed paperboard packages to cakes through different plastic films",
        "value": "65b91cd106feb083f1b96d7b"
      },
      {
        "label": "Detection of di-n-butyl phthalate and di-2-ethylhexyl phthalate in packages of meat products",
        "value": "65b91cc706feb083f1b96b88"
      },
      {
        "label": "Quantification of Plasticizers in Poly(vinyl chloride) Commodities by High-Performance Thin-Layer Chromatography",
        "value": "65b91ca506feb083f1b963d7"
      },
      {
        "label": "Detection of migration of phthalates from agglomerated cork stoppers using HPLC-MS/MS",
        "value": "65b91ca606feb083f1b96419"
      },
      {
        "label": "Concentrations and Dietary Exposure to Organophosphate Esters in Foodstuffs from Albany, New York, United States",
        "value": "65b91cbd06feb083f1b96923"
      },
      {
        "label": "Spectro-analytical Research of Selected Heavy Metals (Cu, Cd, Cr, and Pb) in Four Different Single-use Plastics Commonly in Contact with Food from Sokoto, Nigeria",
        "value": "65b91df806feb083f1b9af9a"
      },
      {
        "label": "Antioxidants and their migration into food simulants on irradiated LLDPE film",
        "value": "65b91c7606feb083f1b959e0"
      },
      {
        "label": "Affect of additives on the migration of substances originating from colourants used in food contact plastics",
        "value": "65b91c6a06feb083f1b9575f"
      },
      {
        "label": "Migration study of chemical additives from low density polyethylene (LDPE) into dahi",
        "value": "65b91c7806feb083f1b95a6b"
      },
      {
        "label": "Gemessene Gehalte an Styrol-Oligomeren in Lebensmittelsimulanzien: Gesundheitliche Risiken sind unwahrscheinlich, Stellungnahme 023/2016",
        "value": "65b91c9c06feb083f1b96212"
      },
      {
        "label": "Determination of 10 photo-initiator residues in food plastic packaging by gel permeation chromatography extraction coupled with gas chromatography-mass spectrometry",
        "value": "65b91c7e06feb083f1b95bcc"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, N,N-bis(2-hydroxyethyl)dodecanamide, CAS No. 120-40-1, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91cab06feb083f1b9652b"
      },
      {
        "label": "Multiple headspace-solid phase microextraction for the determination of migrants coming from a self-stick label in fresh sausage",
        "value": "65b91cde06feb083f1b9703c"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, Methacrylic acid, 2,3-epoxypropyl ester, copolymer with acrylic and/or methacrylic acid alkyl (C1-C4) esters, for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91c9806feb083f1b96122"
      },
      {
        "label": "Specific migration of di-(2-ethylhexyl)adipate (DEHA) from plasticized PVC film: results from an enforcement campaign",
        "value": "65b91c8206feb083f1b95c92"
      },
      {
        "label": "Migration of phthalates from plastic packages to convenience foods and its cumulative health risk assessments",
        "value": "65b91cbf06feb083f1b9698a"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, (polyethylene terephthalate, hydroxylated polybutadiene, pyromellitic anhydride) copolymer, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91c9806feb083f1b96132"
      },
      {
        "label": "Characterization of odorants from baby bottles by headspace solid phase microextraction coupled to gas chromatography-olfactometry-mass spectrometry",
        "value": "65b91c9b06feb083f1b961c4"
      },
      {
        "label": "Determination of the migration of 20 phthalate esters in fatty food packaged with different materials by solid-phase extraction and UHPLC-MS/MS",
        "value": "65b91cc506feb083f1b96b1f"
      },
      {
        "label": "Freisetzung von Blei aus Kaffee- und Espressomaschinen, Aktualisierte Stellungnahme Nr. 003/2014",
        "value": "65b91df806feb083f1b9af85"
      },
      {
        "label": "Sensitive detection of benzophenone-type ultraviolet filters in plastic food packaging materials by sheathless capillary electrophoresis�electrospray ionization�tandem mass spectrometry",
        "value": "65b91cf306feb083f1b974b1"
      },
      {
        "label": "Tetramethylsuccinonitrile in polyvinyl chloride products for food and its release into food-simulating solvents",
        "value": "65b91d6906feb083f1b98fb9"
      },
      {
        "label": "Plasticizers in Brazilian food-packaging materials acquired on the retail market",
        "value": "65b91c8306feb083f1b95ced"
      },
      {
        "label": "Taste and odour development in water in polyethylene containers exposed to direct sunlight",
        "value": "65b91cd306feb083f1b96de2"
      },
      {
        "label": "Contaminants in the paper-based food packaging materials used in the Czech Republic",
        "value": "65b91c6906feb083f1b9574d"
      },
      {
        "label": "Identification of 8-nonenal as an important contributor to \"plastic\" off-odor in polyethylene packaging",
        "value": "65b91d7e06feb083f1b99475"
      },
      {
        "label": "Di(2-ethylhexyl) adipate plasticizer triggers hepatic, brain, and cardiac injury in rats: Mitigating effect of Peganum harmala oil",
        "value": "65b91c8506feb083f1b95d52"
      },
      {
        "label": "Migration of formaldehyde and melamine monomers from kitchen- and tableware made of melamine plastic",
        "value": "65b91c9e06feb083f1b962a4"
      },
      {
        "label": "Determination of phthalate acid esters plasticizers in plastic by ultrasonic solvent extraction combined with solid-phase microextraction using calix[4]arene fiber",
        "value": "65b91cc206feb083f1b96a62"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 4-acrylamido-4-methyl-2-pentanone, CAS No 2873-97-4, for use in food contact materials",
        "value": "65b91d5806feb083f1b98bd3"
      },
      {
        "label": "Usfda assessment of exposure to 2,3,7,8-TCDD and 2,3,7,8-TCDF from foods contacting bleached paper products",
        "value": "65b91d1c06feb083f1b97e16"
      },
      {
        "label": "Determination of styrene content in Gorgonzola PDO cheese by headspace solid phase micro-extraction (HS-SPME) and gas-chromatography mass-spectrometry (GC-MS)",
        "value": "65b91c7106feb083f1b958cb"
      },
      {
        "label": "Migration of aniline from polyamide cooking utensils into food simulants",
        "value": "65b91c9106feb083f1b95fc1"
      },
      {
        "label": "Determination of perfluoroalkyl substances in food packaging in Taiwan using ultrasonic extraction and ultra-performance liquid chromatography/tandem mass spectrometry",
        "value": "65b91d2c06feb083f1b981c7"
      },
      {
        "label": "Safety assessment of the substance ground sunflower seed hulls, for use in food contact materials",
        "value": "65b91cb706feb083f1b967e8"
      },
      {
        "label": "Analysis of isophthalaldehyde in migration samples from polyethylene terephthalate packaging",
        "value": "65b91dc206feb083f1b9a422"
      },
      {
        "label": "A radiotracer technique for the migration of inorganic contaminants into dry food from packaging made from recycled paper and board",
        "value": "65b91def06feb083f1b9adb0"
      },
      {
        "label": "Method for assessing lead, cadmium, mercury and arsenic in high-density polyethylene packaging and study of the migration into yoghurt and simulant",
        "value": "65b91df606feb083f1b9af1f"
      },
      {
        "label": "Determination of vinylidene chloride in Saran films by electron capture gas-solid chromatography and confirmation by mass spectrometry",
        "value": "65b91e2906feb083f1b9b97f"
      },
      {
        "label": "Kinetic models of migration of melamine and formaldehyde from melamine kitchenware with data of liquid chromatography",
        "value": "65b91ca006feb083f1b962e9"
      },
      {
        "label": "Potential silver nanoparticles migration from commercially available polymeric baby products into food simulants",
        "value": "65b91e0506feb083f1b9b25b"
      },
      {
        "label": "Evaluation of retail fresh meat packagings covered with stretch films of plasticized PVC and non-PVC alternatives",
        "value": "65b91c8106feb083f1b95c83"
      },
      {
        "label": "Estudio de migración de fluorocarbonos (PFOAs...) en materiales de envase para alimentos Migration study of perfluorocarbonated compounds (PFOAs...) in food packaging materials",
        "value": "65b91d1e06feb083f1b97e74"
      },
      {
        "label": "Safety assessment of the substance (butadiene, styrene, methyl methacrylate, butyl acrylate) copolymer cross-linked with divinylbenzene or 1,3-butanediol dimethacrylate for use in food contact materials",
        "value": "65b91c6e06feb083f1b9583d"
      },
      {
        "label": "Alkylphenol and bisphenol A levels in rubber products",
        "value": "65b91c8906feb083f1b95e22"
      },
      {
        "label": "Development, validation and comparison of three detection methods for 9 volatile methylsiloxanes in food-contact silicone rubber products",
        "value": "65b91c9a06feb083f1b961b2"
      },
      {
        "label": "Diffusion behaviour of additives in polypropylene in correlation with polymer properties",
        "value": "65b91ccf06feb083f1b96d08"
      },
      {
        "label": "Analysis of UV ink photoinitiators in packaged food by fast liquid chromatography at sub-ambient temperature coupled to tandem mass spectrometry",
        "value": "65b91c7e06feb083f1b95bbb"
      },
      {
        "label": "Effect of storage on the levels of phthalates in high-density polyethylene (HDPE) film-packaged drinking water",
        "value": "65b91cc806feb083f1b96bb7"
      },
      {
        "label": "Potential exposure to bisphenol a from food contact use of epoxy coated cans",
        "value": "65b91e4006feb083f1b9be75"
      },
      {
        "label": "Migration analysis of Cr, Ni, Al, Fe, Mn, Cu, Zn, and Mo in internet-bought food serving stainless-steel utensils by ICP-MS and XRF",
        "value": "65b91deb06feb083f1b9acd1"
      },
      {
        "label": "Chemometrics-assisted cyclodextrin-enhanced excitation-emission fluorescence spectroscopy for the simultaneous green determination of bisphenol A and nonylphenol in plastics",
        "value": "65b91d4706feb083f1b98816"
      },
      {
        "label": "Migration of polyurethane adhesives used in flexible food packaging",
        "value": "65b91c7606feb083f1b95a10"
      },
      {
        "label": "Migration of di(2-ethylhexyl) adipate and acetyltributyl citrate plasticizers from food-grade PVC film into sweetened sesame paste (halawa tehineh): kinetic and penetration study",
        "value": "65b91c8306feb083f1b95cdd"
      },
      {
        "label": "Polyphenylsulfone (PPSU) for baby bottles: a comprehensive assessment on polymer-related non-intentionally added substances (NIAS)",
        "value": "65b91ce606feb083f1b9720c"
      },
      {
        "label": "Lead in plastics – Recycling of legacy material and appropriateness of current regulations",
        "value": "65b91df306feb083f1b9aea8"
      },
      {
        "label": "PBT and/or vPvB status given by the concluded assessment by the Member State",
        "value": "65b9114d0729b5ae4f62a073"
      },
      {
        "label": "Migration of cyclic monomer and oligomers from polyamide 6 and 66 food contact materials into food and food simulants: direct food contact",
        "value": "65b91c9006feb083f1b95f9b"
      },
      {
        "label": "Ion mobility quadrupole time-of-flight mass spectrometry for the identification of non-intentionally added substances in UV varnishes applied on food contact materials. A safety by design study",
        "value": "65b91c7b06feb083f1b95b40"
      },
      {
        "label": "Structural changes and plasticizer migration of starch-based food packaging material contacting with milk during microwave heating",
        "value": "65b91c7c06feb083f1b95b5c"
      },
      {
        "label": "CoE List for cork materials in food contact",
        "value": "65b9114d0729b5ae4f62a049"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, N, N '-bis(2,2,6,6-tetramethyl-4-piperidinyl) isophthalamide, CAS No 42774-15-2, for use in food contact materials",
        "value": "65b91d8406feb083f1b995cc"
      },
      {
        "label": "Determination of the migration of plastic additives and non-intentionally added substances into food simulants and the assessment of health risks from convenience food packaging",
        "value": "65b91c8b06feb083f1b95eba"
      },
      {
        "label": "Selected-ion monitoring of 4-vinyl-1-cyclohexene in acrylonitrile?butadiene?styrene polymer products and food simulants",
        "value": "65b91c6a06feb083f1b95784"
      },
      {
        "label": "Migration of plasticizers from PVC gaskets of lids for glass jars into oily foods: Amount of gasket material in food contact, proportion of plasticizer migrating into food and compliance testing by simulation",
        "value": "65b91c8406feb083f1b95d09"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, Perfluoro{acetic acid, 2- (5-methoxy-1,3-dioxolan-4-yl)oxy }, ammonium salt, CAS No 1190931-27-1, for use in food contact materials EFSA Panel on on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91cce06feb083f1b96cdb"
      },
      {
        "label": "Atmospheric Solids Analysis Probe (ASAP) and Atmospheric Pressure Gas Chromatography (APGC) coupled to Quadrupole Time of Flight Mass Spectrometry (QTOF-MS) as alternative techniques to trace aromatic markers of mineral oils in food packaging",
        "value": "65b91cd606feb083f1b96e81"
      },
      {
        "label": "Comprehensive analysis of photoinitiators and primary aromatic amines in food contact materials using liquid chromatography High-Resolution Mass Spectrometry",
        "value": "65b91c7706feb083f1b95a2a"
      },
      {
        "label": "Exposure Assessment of Sb2O3 in PET Food Contact Materials",
        "value": "65b91ced06feb083f1b97386"
      },
      {
        "label": "Migration of dihydroxyalkylamines from polypropylene coffee capsules to Tenax and coffee by salt-assisted liquid-liquid extraction and liquid chromatography-mass spectrometry",
        "value": "65b91c7a06feb083f1b95af7"
      },
      {
        "label": "Determination of adhesive acrylates in recycled polyethylene terephthalate by fabric phase sorptive extraction coupled to ultra performance liquid chromatography - mass spectrometry",
        "value": "65b91d0e06feb083f1b97ab4"
      },
      {
        "label": "Emissions of selected monoaromatic hydrocarbons as a factor affecting the removal of single-use polymer barbecue and kitchen utensils from everyday use",
        "value": "65b91c6b06feb083f1b957c6"
      },
      {
        "label": "Rapid desorption electrospray ionization-high resolution mass spectrometry method for the analysis of melamine migration from melamine tableware",
        "value": "65b91c9e06feb083f1b9629e"
      },
      {
        "label": "Detection and quantification analysis of chemical migrants in plastic food contact products",
        "value": "65b91c8106feb083f1b95c7a"
      },
      {
        "label": "Methods for the determination of heavy metals in indocalamus leaves after different preservation treatment using inductively-coupled plasma mass spectrometry",
        "value": "65b91df406feb083f1b9aec6"
      },
      {
        "label": "Migration studies from paper and board food packaging materials. 1. Compositional analysis",
        "value": "65b91c7306feb083f1b95943"
      },
      {
        "label": "Non-target screening of (semi-)volatiles in food-grade polymers by comparison of atmospheric pressure gas chromatography quadrupole time-of-flight and electron ionization mass spectrometry",
        "value": "65b91d1306feb083f1b97bc9"
      },
      {
        "label": "Migration of plasticizers from printing inks into foods",
        "value": "65b91cc606feb083f1b96b59"
      },
      {
        "label": "EFSA Open Food Tox database",
        "value": "65b9114d0729b5ae4f62a07b"
      },
      {
        "label": "Migration testing of plastics and microwave-active materials for high-temperature food-use applications",
        "value": "65b91c8506feb083f1b95d32"
      },
      {
        "label": "Identification of extractable substances from rubber nettings used to package meat products",
        "value": "65b91c8606feb083f1b95d9f"
      },
      {
        "label": "Migration of epsilon-caprolactam from nylon cooking utensils: validation of a liquid chromatography-ultraviolet detection method",
        "value": "65b91c9106feb083f1b95fbe"
      },
      {
        "label": "Development of an analytical method for the determination of mineral oil aromatic hydrocarbons (MOAH) from printing inks in food packaging",
        "value": "65b91cd806feb083f1b96f01"
      },
      {
        "label": "Migration of metals from ceramic food contact materials. 2: Migration kinetics under various conditions and the influence of conventional thermal heating and microwave heating on migration",
        "value": "65b91ded06feb083f1b9ad39"
      },
      {
        "label": "Synthesis of an organic-inorganic hybrid absorbent for in-tube solid-phase microextraction of bisphenol A",
        "value": "65b91e3e06feb083f1b9bdec"
      },
      {
        "label": "SURVEY OF BOTTLED DRINKING-WATER SOLD IN CANADA .2. SELECTED VOLATILE ORGANIC-COMPOUNDS",
        "value": "65b91ca906feb083f1b964ad"
      },
      {
        "label": "MIGRATION TESTING OF LOW TEMPERATURE SURFACE HARDENED STAINLESS STEELS",
        "value": "65b91df206feb083f1b9ae41"
      },
      {
        "label": "Identification of Photoinitiators, Including Novel Phosphine Oxides, and Their Transformation Products in Food Packaging Materials and Indoor Dust in Canada",
        "value": "65b91c7d06feb083f1b95ba3"
      },
      {
        "label": "Contents of Ag and other metals in food-contact plastics with nanosilver or Ag ion and their migration into food simulants",
        "value": "65b91df506feb083f1b9aeef"
      },
      {
        "label": "A method to determine volatile contaminants in polyethylene terephthalate (PET) packages by HDC-GC-FID and its application to post-consumer materials",
        "value": "65b91c7106feb083f1b958bb"
      },
      {
        "label": "LEAD AND CADMIUM CONTAMINATION IN DAIRY-PRODUCTS AND ITS REPERCUSSION ON TOTAL DIETARY-INTAKE",
        "value": "65b91df706feb083f1b9af79"
      },
      {
        "label": "Testing migration from the PVC gaskets in metal closures into oily foods",
        "value": "65b91ca506feb083f1b963ea"
      },
      {
        "label": "Migration and characterisation of nanosilver from food containers by AF(4)-ICP-MS",
        "value": "65b91e0606feb083f1b9b26b"
      },
      {
        "label": "Evidence of bad recycling practices: BFRs in children's toys and food-contact articles",
        "value": "65b91cbf06feb083f1b9696f"
      },
      {
        "label": "Estrogenic activity, selected plasticizers and potential health risks associated with bottled water in South Africa",
        "value": "65b91c8606feb083f1b95d6d"
      },
      {
        "label": "Analytical screening studies on irradiated food packaging",
        "value": "65b91c7106feb083f1b958c4"
      },
      {
        "label": "Investigation of the migration of bisphenols from baby bottles and sippy cups",
        "value": "65b91ce806feb083f1b9727c"
      },
      {
        "label": "Observation of Emerging Photoinitiator Additives in Household Environment and Sewage Sludge in China",
        "value": "65b91c7d06feb083f1b95ba0"
      },
      {
        "label": "Semicarbazide is a minor thermal decomposition product of azodicarbonamide used in the gaskets of certain food jars",
        "value": "65b91dab06feb083f1b99f2a"
      },
      {
        "label": "Method for Analysis and Study of Migration of Lead, Cadmium, Mercury and Arsenic from Polypropylene Packaging into Ice Cream and Simulant",
        "value": "65b91df606feb083f1b9af24"
      },
      {
        "label": "Determination of oligomers in virgin and recycled polyethylene terephthalate (PET) samples by UPLC-MS-QTOF",
        "value": "65b91d1206feb083f1b97b7b"
      },
      {
        "label": "Status of the PCDD and PCDF contamination of commercial milk caused by milk cartons",
        "value": "65b91d1c06feb083f1b97e1c"
      },
      {
        "label": "Analysis of phthalate plasticizer migration from PVDC packaging materials to food simulants using molecular dynamics simulations and artificial neural network",
        "value": "65b91cbf06feb083f1b9699f"
      },
      {
        "label": "Time-dependent migration of elements from plastic-packaging material into food",
        "value": "65b91df906feb083f1b9afd3"
      },
      {
        "label": "Mutagenic according to Bossuyt et al. 2017",
        "value": "65b9114d0729b5ae4f62a081"
      },
      {
        "label": "Release of dioctyl phthalate (DOP) from polyvinyl chloride (PVC) in apple packaging",
        "value": "65b91ccb06feb083f1b96c2e"
      },
      {
        "label": "Artificial Neural Network Prediction and Mechanism Analysis for Migration of Environmental Contaminant Cyclic Organosiloxane Oligomer from Silicone Rubber",
        "value": "65b91d9906feb083f1b99b02"
      },
      {
        "label": "Sensitive detection of glyoxal by cluster-mediated CH(2)Br(2)(+) chemical ionization time-of-flight mass spectrometry",
        "value": "65b91c9906feb083f1b9616d"
      },
      {
        "label": "Characterisation and potential migration of silver nanoparticles from commercially available polymeric food contact materials",
        "value": "65b91d0906feb083f1b9794e"
      },
      {
        "label": "Ausmaß der Migration von Druckfarbenbestandteilen aus Verpackungsmaterialien in Lebensmittel",
        "value": "65b91c7b06feb083f1b95b0c"
      },
      {
        "label": "Simultaneous determination and assessment of 4-nonylphenol, bisphenol A and triclosan in tap water, bottled water and baby bottles",
        "value": "65b91c8906feb083f1b95e3c"
      },
      {
        "label": "Purity of recycled fibre-based materials",
        "value": "65b91cd506feb083f1b96e74"
      },
      {
        "label": "Siloxane in baking moulds, emission to indoor air and migration to food during baking with an electric oven",
        "value": "65b91c9a06feb083f1b96199"
      },
      {
        "label": "Analytical strategy based on the combination of gas chromatography coupled to time-of-flight and hybrid quadrupole time-of-flight mass analyzers for non-target analysis in food packaging",
        "value": "65b91c7406feb083f1b9597e"
      },
      {
        "label": "Representativeness of extracts of offset paper packaging and analysis of the main odor-active compounds",
        "value": "65b91cf806feb083f1b975c0"
      },
      {
        "label": "Migration Characteristics under Long-term Storage and a Combination of UV and Heat Exposure of Poly (Amide)/Poly (Ethylene) Composite Films for Food Packaging",
        "value": "65b91c9106feb083f1b95fab"
      },
      {
        "label": "Swiss food contact regulation",
        "value": "65b9114c0729b5ae4f62a017"
      },
      {
        "label": "Scientific Opinion of the Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91d0806feb083f1b97939"
      },
      {
        "label": "Analytical screening of polyadipates and other plasticisers in poly(vinyl chloride) gasket seals and in fatty food by gas chromatography?mass spectrometry",
        "value": "65b91c8406feb083f1b95d13"
      },
      {
        "label": "Identification and quantification of odours from oxobiodegradable polyethylene oxidised under a free radical flow by headspace solid-phase microextraction followed by gas chromatography-olfactometry-mass spectrometry",
        "value": "65b91c8606feb083f1b95d83"
      },
      {
        "label": "Release of Melamine and Formaldehyde from Melamine-Formaldehyde Plastic Kitchenware",
        "value": "65b91c9f06feb083f1b962cb"
      },
      {
        "label": "Survey of phthalate levels in Italian oily foods contained in glass jars with PVC gaskets",
        "value": "65b91cc006feb083f1b969e0"
      },
      {
        "label": "Migration of nonylphenol and plasticizers from polyvinyl chloride stretch film into food simulants, rapeseed oil, and foods",
        "value": "65b91d4806feb083f1b98826"
      },
      {
        "label": "Tracing the melamine migration from three-piece tin cans into food simulants during coating process",
        "value": "65b91c9f06feb083f1b962b7"
      },
      {
        "label": "CoE List for silicones in food contact",
        "value": "65b9114d0729b5ae4f62a03f"
      },
      {
        "label": "The occurrence of phthalate ester and di-2-ethylhexyl adipate plasticizers in Canadian packaging and food sampled in 1985-1989: a survey",
        "value": "65b91c8206feb083f1b95c99"
      },
      {
        "label": "Characterisation of silver release from nanoparticle-treated baby products",
        "value": "65b91e0506feb083f1b9b24c"
      },
      {
        "label": "EuPIA Inventory List 2013 for printing inks for non-food contact surface of food contact articles",
        "value": "65b9114c0729b5ae4f62a033"
      },
      {
        "label": "Analysis of Volatile Organic Compounds in Disposable Food Packages at Three types of Temperature",
        "value": "65b91c6a06feb083f1b9579d"
      },
      {
        "label": "Migration Studies from Food Contact Natural Biomaterials in High Temperature Applications",
        "value": "65b91c8d06feb083f1b95f00"
      },
      {
        "label": "Substances under evaluation for endocrine disruption under an EU legislation",
        "value": "65b9114d0729b5ae4f62a061"
      },
      {
        "label": "Analysis of diglycolic acid in food packaging, over the counter products, direct additive carboxymethyl cellulose, and retail foods",
        "value": "65b91ca906feb083f1b964c8"
      },
      {
        "label": "Polystyrene cups and containers: styrene migration",
        "value": "65b91c6d06feb083f1b95831"
      },
      {
        "label": "Development and validation of an HPLC method with fluorescence detection for the determination of fluorescent whitening agents migrating from plastic beverage cups",
        "value": "65b91c8806feb083f1b95dee"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, 2,2,4,4-tetramethylcyclobutane-1,3-diol (TMCD), CAS No 3010-96-6, for use in food contact materials",
        "value": "65b91d5d06feb083f1b98d2f"
      },
      {
        "label": "Odor-active compounds in cardboard",
        "value": "65b91c8a06feb083f1b95e6e"
      },
      {
        "label": "Migration kinetics of mineral oil hydrocarbons from recycled paperboard to dry food: monitoring of two real cases",
        "value": "65b91d7a06feb083f1b99391"
      },
      {
        "label": "Identification and quantification of estrogenic compounds in recycled and virgin paper for household use as determined by an in vitro yeast estrogen screen and chemical analysis",
        "value": "65b91c8006feb083f1b95c49"
      },
      {
        "label": "Migration from can coatings: part 3. Synthesis, identification and quantification of migrating epoxy-based substances below 1000 Da",
        "value": "65b91d1306feb083f1b97bb8"
      },
      {
        "label": "BPA, BADGE and analogues: A new multi-analyte LC-ESI-MS/MS method for their determination and their in vitro (anti)estrogenic and (anti)androgenic properties",
        "value": "65b91ce806feb083f1b97274"
      },
      {
        "label": "China National Food Safety Standard, GB 9685-2016 (Plus)",
        "value": "65b9114c0729b5ae4f62a01d"
      },
      {
        "label": "Liquid chromatography-tandem mass spectrometry determination of N-nitrosamines released from rubber or elastomer teats and soothers",
        "value": "65b91c7406feb083f1b9595e"
      },
      {
        "label": "Migration of formaldehyde and acetaldehyde into mineral water in polyethylene terephthalate (PET) bottles",
        "value": "65b91d8e06feb083f1b9982c"
      },
      {
        "label": "Procedure optimization of type 304 and 420B stainless steels release in acetic acid",
        "value": "65b91dfc06feb083f1b9b056"
      },
      {
        "label": "Rapid assessment of silver nanoparticle migration from food containers into food simulants using a qualitative method",
        "value": "65b91e0506feb083f1b9b257"
      },
      {
        "label": "Diffusion coefficient of antimony leaching from polyethylene terephthalate bottles into beverages",
        "value": "65b91e0b06feb083f1b9b395"
      },
      {
        "label": "Migration of heavy metals from recycled polyethylene terephthalate during storage and microwave heating",
        "value": "65b91df306feb083f1b9ae91"
      },
      {
        "label": "Epoxidized soy bean oil (ESBO) migrating from the gaskets of lids into food packed in glass jars",
        "value": "65b91e4506feb083f1b9bf84"
      },
      {
        "label": "Determination of perfluorinated alkyl acids in corn, popcorn and popcorn bags before and after cooking by focused ultrasound solid?liquid extraction, liquid chromatography and quadrupole-time of flight mass spectrometry",
        "value": "65b91d5006feb083f1b98a2d"
      },
      {
        "label": "Simultaneous determination of seven acrylates in food contact paper products by GC/MS and modified QuEChERS",
        "value": "65b91c7f06feb083f1b95c11"
      },
      {
        "label": "Migrating Levels of Toxic Heavy Metals in Locally Made Food Packaging Containers",
        "value": "65b91df606feb083f1b9af2f"
      },
      {
        "label": "Migration of nonylphenol from food-grade plastic is toxic to the coral reef fish species Pseudochromis fridmani",
        "value": "65b91d4806feb083f1b9882c"
      },
      {
        "label": "Determination of potential migrants from commercial amber polyethylene terephthalate bottle wall",
        "value": "65b91c6906feb083f1b95728"
      },
      {
        "label": "Migration of Ti and Zn from Nanoparticle Modified LDPE Films into Food Simulants",
        "value": "65b91e0a06feb083f1b9b340"
      },
      {
        "label": "Overall and specific migration from multilayer high barrier food contact materials - kinetic study of cyclic polyester oligomers migration",
        "value": "65b91c8106feb083f1b95c58"
      },
      {
        "label": "Determination of potential migrants in polycarbonate containers used for microwave ovens by high-performance liquid chromatography with ultraviolet and fluorescence detection",
        "value": "65b91ca306feb083f1b9637f"
      },
      {
        "label": "The migration of NIAS from ethylene-vinyl acetate corks and their identification using gas chromatography mass spectrometry and liquid chromatography ion mobility quadrupole time-of-flight mass spectrometry",
        "value": "65b91ca406feb083f1b9639d"
      },
      {
        "label": "Migration of lubricants from food packagings: Screening for lipid classes and quantitative estimation using normal-phase liquid chromatographic separation with evaporative light scattering detection",
        "value": "65b91cb506feb083f1b96773"
      },
      {
        "label": "Migration of selected hydrocarbon contaminants into dry pasta packaged in direct contact with recycled paperboard",
        "value": "65b91d7b06feb083f1b993cc"
      },
      {
        "label": "QUALITATIVE LIQUID-CHROMATOGRAPHIC ATMOSPHERIC-PRESSURE CHEMICAL-IONIZATION MASS-SPECTROMETRIC ANALYSIS OF POLYETHYLENE TEREPHTHALATE OLIGOMERS",
        "value": "65b91d1206feb083f1b97b8d"
      },
      {
        "label": "Metal Release and Corrosion Resistance of Different Stainless Steel Grades in Simulated Food Contact",
        "value": "65b91df106feb083f1b9ae29"
      },
      {
        "label": "Detection, Identification and Size Distribution of Silver Nanoparticles (AgNPs) in Milk and Migration Study for Breast Milk Storage Bags",
        "value": "65b91e0506feb083f1b9b234"
      },
      {
        "label": "Study on migration of melamine from food packaging materials on markets",
        "value": "65b91c9f06feb083f1b962a7"
      },
      {
        "label": "Determination of polymer additives-antioxidants and ultraviolet (UV) absorbers by high-performance liquid chromatography coupled with UV photodiode array detection in food simulants",
        "value": "65b91ccf06feb083f1b96d27"
      },
      {
        "label": "Effect of recycled content and rPET quality on the properties of PET bottles, part II: Migration",
        "value": "65b91c6d06feb083f1b95822"
      },
      {
        "label": "Results of Proficiency Test Specific Migration on Food Contact Materials October 2021",
        "value": "65b91c7706feb083f1b95a47"
      },
      {
        "label": "From pigments to coloured napkins: comparative analyses of primary aromatic amines in cold water extracts of printed tissues by LC-HRMS and LC-MS/MS",
        "value": "65b91c7506feb083f1b959b8"
      },
      {
        "label": "The effects of gamma-irradiation on compositional changes in plastic packaging films",
        "value": "65b91c7606feb083f1b959ea"
      },
      {
        "label": "Migration from food contact plastics. Part I. Establishment and aims of the PIRA project",
        "value": "65b91c6806feb083f1b9570a"
      },
      {
        "label": "Single ion monitoring of butadiene in plastics and foods by coupled mass spectrometry?automatic headspace gas chromatography",
        "value": "65b91c9806feb083f1b9612b"
      },
      {
        "label": "Alkylphenols and phthalates in bottled waters",
        "value": "65b91cc806feb083f1b96bc7"
      },
      {
        "label": "Zeolitic imidazolate framework-8 coated Fe3O4@SiO2 composites for magnetic solid-phase extraction of bisphenols",
        "value": "65b91d0f06feb083f1b97ada"
      },
      {
        "label": "Migration kinetics of primary aromatic amines from polyamide kitchenware: Easy and fast screening procedure using fluorescence",
        "value": "65b91c7706feb083f1b95a27"
      },
      {
        "label": "22nd list of substances for food contact materials Scientific Opinion of the Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91c9a06feb083f1b961b8"
      },
      {
        "label": "Safety assessment of the substance benzophenone-3,3 ',4,4 '-tetracarboxylic dianhydride, for use in food contact materials",
        "value": "65b91d4106feb083f1b98684"
      },
      {
        "label": "Migration of titanium dioxide from PET/TiO2 composite film for polymer-laminated steel",
        "value": "65b91cf806feb083f1b975d3"
      },
      {
        "label": "Assessing Chemical Migration from Plastic Food Packaging into Food Simulant by Gas and Liquid Chromatography with High-Resolution Mass Spectrometry",
        "value": "65b91c6806feb083f1b95707"
      },
      {
        "label": "Liquid chromatography-Orbitrap Tribrid high-resolution mass spectrometry using data dependent-tandem mass spectrometry with triple stage fragmentation as a screening tool to perform identification and risk assessment of unknown substances in food contact epoxy resin",
        "value": "65b91c7406feb083f1b95988"
      },
      {
        "label": "Multiresidual LC-MS analysis of plasticizers used in PVC gaskets of lids and assessment of their migration into food sauces",
        "value": "65b91c7c06feb083f1b95b58"
      },
      {
        "label": "High-performance liquid chromatographic determination of 2,4- and 2,6-toluenediamine in aqueous extracts",
        "value": "65b91e4a06feb083f1b9c088"
      },
      {
        "label": "Application of ionic liquid-based dispersive liquid phase microextraction for highly sensitive simultaneous determination of three endocrine disrupting compounds in food packaging",
        "value": "65b91d0906feb083f1b97983"
      },
      {
        "label": "Total Fluorine Measurements in Food Packaging: How Do Current Methods Perform?",
        "value": "65b91d0c06feb083f1b97a16"
      },
      {
        "label": "Geschirr aus Keramik: BfR empfiehlt niedrigere Freisetzungsmengen für Blei und Cadmium",
        "value": "65b91df406feb083f1b9aecd"
      },
      {
        "label": "Are bioplastics and plant-based materials safer than conventional plastics? In vitro toxicity and chemical composition",
        "value": "65b91d9406feb083f1b999bc"
      },
      {
        "label": "Determination of aluminium leaching into various baked meats with different types of foils by ICP-MS",
        "value": "65b91ded06feb083f1b9ad45"
      },
      {
        "label": "Identification of key odorant compounds in starch-based polymers intended for food contact materials",
        "value": "65b91c7206feb083f1b95918"
      },
      {
        "label": "Children's exposure to Di(2-ethylhexyl)phthalate and dibutylphthalate plasticizers from school meals",
        "value": "65b91cc606feb083f1b96b3c"
      },
      {
        "label": "Non-intentionally added substances in PET bottled mineral water during the shelf-life",
        "value": "65b91c8d06feb083f1b95efa"
      },
      {
        "label": "Food Safety and Mineral Oil Contaminated Paperboard Packaging: an Analytical Challenge and a Migration Study",
        "value": "65b91d7a06feb083f1b99388"
      },
      {
        "label": "Migration of engineered nanoparticles from packaging into food products",
        "value": "65b91e0506feb083f1b9b22a"
      },
      {
        "label": "Toxicity testing and chemical analyses of recycled fibre-based paper for food contact",
        "value": "65b91c6a06feb083f1b95775"
      },
      {
        "label": "A sulphuric acid-impregnated silica gel clean-up procedure for the determination of n-alkanes migration from paraffin based paper packaging into cheddar cheese",
        "value": "65b91cbb06feb083f1b9689f"
      },
      {
        "label": "Bisphenol A exposure assessment from olive oil consumption",
        "value": "65b91e4306feb083f1b9bf0c"
      },
      {
        "label": "Measurement and modelling of migration from paper and board into foodstuffs and dry food simulants",
        "value": "65b91cd706feb083f1b96ec6"
      },
      {
        "label": "Microwave heating causes rapid degradation of antioxidants in polypropylene packaging, leading to greatly increased specific migration to food simulants as shown by ESI-MS and GC-MS",
        "value": "65b91d3e06feb083f1b985c6"
      },
      {
        "label": "A Comparison of the Migration of \"Spiked' and \"Intrinsic' Substances from Paper and Board into Raisins and into Tenax as a Food Simulant",
        "value": "65b91d7b06feb083f1b993b0"
      },
      {
        "label": "Visualizing BPA by molecularly imprinted ratiometric fluorescence sensor based on dual emission nanoparticles",
        "value": "65b91e3f06feb083f1b9be13"
      },
      {
        "label": "Contamination test of metal and non-metal elements from copper gas pipe to food gases",
        "value": "65b91def06feb083f1b9ad98"
      },
      {
        "label": "Positively charged covalent organic framework and its application in the dispersive solid-phase extraction of ultraviolet-filters from food packaging material migrants",
        "value": "65b91cf306feb083f1b974a5"
      },
      {
        "label": "Effects of fast food packaging plasticizers and their metabolites on steroid hormone synthesis in H295R cells",
        "value": "65b91c8406feb083f1b95d1b"
      },
      {
        "label": "Rapid identification of phthalates in blood bags and food packaging using ToF-SIMS",
        "value": "65b91cc606feb083f1b96b49"
      },
      {
        "label": "Analysis of antioxidants extracted from polypropylene by supercritical fluid extraction",
        "value": "65b91cdd06feb083f1b9700d"
      },
      {
        "label": "Scientific opinion on the safety evaluation of the substance, 1,3-bis(isocyanatomethyl) benzene, CAS No. 3634-83-1 for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91d0906feb083f1b97971"
      },
      {
        "label": "Quality and safety aspects of reusable plastic food packaging materials: influence of reuse on intrinsic properties",
        "value": "65b91c6906feb083f1b9572e"
      },
      {
        "label": "Identification of potential migrants in epoxy phenolic can coatings",
        "value": "65b91cad06feb083f1b96591"
      },
      {
        "label": "Addendum to Food Additives and Contaminants 2002, 19, 492-501 migration of monomers from liquid crystalline poly(p-hydroxybenzoic acid-co-2-hydroxy-6-naphthoic acid)",
        "value": "65b91d1706feb083f1b97caa"
      },
      {
        "label": "CoE List for packaging inks applied to the non-food contact surface of food packaging",
        "value": "65b9114c0729b5ae4f62a031"
      },
      {
        "label": "Study on migration of hydrocarbon contaminants from food contact materials in food",
        "value": "65b91cd606feb083f1b96e89"
      },
      {
        "label": "Occurrence of bisphenol-F-diglycidyl ether (BFDGE) in fish canned in oil",
        "value": "65b91d1706feb083f1b97cc3"
      },
      {
        "label": "Analysis of TCDD and TCDF on the ppq-level in milk and food sources",
        "value": "65b91d1c06feb083f1b97e0c"
      },
      {
        "label": "Survey of formaldehyde, acetaldehyde and oligomers in polyethylene terephthalate food-packaging materials",
        "value": "65b91d8d06feb083f1b99829"
      },
      {
        "label": "Metal release from stainless steel 316L in whey protein - And simulated milk solutions under static and stirring conditions",
        "value": "65b91df206feb083f1b9ae61"
      },
      {
        "label": "BfR streicht Anthrachinon aus den BfR-Empfehlungen f?r Lebensmittelverpackungen, Stellungnahme Nr. 005/2013",
        "value": "65b91e5206feb083f1b9c21e"
      },
      {
        "label": "Isolation and Quantification of Polyamide Cyclic Oligomers in Kitchen Utensils and Their Migration into Various Food Simulants",
        "value": "65b91d8206feb083f1b99556"
      },
      {
        "label": "Investigation of migration from decorative shrink sleeves",
        "value": "65b91c6c06feb083f1b957fb"
      },
      {
        "label": "CoE List for rubber materials in food contact",
        "value": "65b9114d0729b5ae4f62a03d"
      },
      {
        "label": "Exposure to lead and cadmium released from ceramics and glassware intended to come into contact with food",
        "value": "65b91df406feb083f1b9aed4"
      },
      {
        "label": "Effect of NaCl on antimony and phthalate compounds leached from PET bottles",
        "value": "65b91cc306feb083f1b96a85"
      },
      {
        "label": "Migration from polycarbonate packaging to food simulants during microwave heating",
        "value": "65b91c9c06feb083f1b96219"
      },
      {
        "label": "Analysis of melamine migration from melamine food contact articles",
        "value": "65b91c9f06feb083f1b962cf"
      },
      {
        "label": "Analysis of specific migration of monomers and oligomers from polyethylene terephthalate bottles and trays according to the testing methods as prescribed in the legislation of the EU and Asian countries",
        "value": "65b91c6906feb083f1b9572b"
      },
      {
        "label": "Univariate data analysis versus multivariate approach in liquid chromatography. An application for melamine migration from food contact materials",
        "value": "65b91ca006feb083f1b962e6"
      },
      {
        "label": "1H-NMR for the safety control of food packaging materials: analysis of extracts from polyolefin samples",
        "value": "65b91cba06feb083f1b9683b"
      },
      {
        "label": "Identification of suspected hazardous chemical contaminants in recycled pastry packaging",
        "value": "65b91cc906feb083f1b96bd4"
      },
      {
        "label": "Optimum pH for the determination of bisphenols and their corresponding diglycidyl ethers by gas chromatography?mass spectrometry. Migration kinetics of bisphenol A from polycarbonate glasses",
        "value": "65b91d1706feb083f1b97cd7"
      },
      {
        "label": "Migration of components from cork stoppers to food: challenges in determining inorganic elements in food simulants",
        "value": "65b91dee06feb083f1b9ad88"
      },
      {
        "label": "Analysis of PBT and PET cyclic oligomers in extracts of coffee capsules and food simulants by a HPLC-UV/FLD method",
        "value": "65b91d1206feb083f1b97b90"
      },
      {
        "label": "The effect of temperature and time on the migration of lead metal (Pb) ions in canned orange juice drink using atomic absorption spectrophotometry analysis",
        "value": "65b91df406feb083f1b9aeca"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, phosphorous acid, mixed 2,4-bis(1,1-dimethylpropyl)phenyl and 4-(1,1dimethylpropyl)phenyltriesters, CAS No. 939402-02-5, for use in food contact materials",
        "value": "65b91c7f06feb083f1b95bec"
      },
      {
        "label": "An evaluation of analytical techniques for determination of lead, cadmium, chromium, and mercury in food-packaging materials",
        "value": "65b91df406feb083f1b9aede"
      },
      {
        "label": "Compounds responsible for off-odors in several samples composed by polypropylene, polyethylene, paper and cardboard used as food packaging materials",
        "value": "65b91c6b06feb083f1b957a4"
      },
      {
        "label": "EuPIA List 2013 of photoinitiators for printing inks and varnishes",
        "value": "65b9114c0729b5ae4f62a035"
      },
      {
        "label": "Quantitative analysis of styrene monomer in polystyrene and foods including some preliminary studies of the uptake and pharmacodynamics of the monomer in rats",
        "value": "65b91c6c06feb083f1b95810"
      },
      {
        "label": "Migration analysis of epoxidized soybean oil and other plasticizers in commercial lids for food packaging by gas chromatography-mass spectrometry",
        "value": "65b91ca506feb083f1b963e3"
      },
      {
        "label": "The influence of different closures on volatile composition of a white wine",
        "value": "65b91c8606feb083f1b95d98"
      },
      {
        "label": "Migration of antimony from PET bottles into beverages: determination of the activation energy of diffusion and migration modelling compared with literature data",
        "value": "65b91e0b06feb083f1b9b36e"
      },
      {
        "label": "Green construction of Fe3O4@GC submicrocubes for highly sensitive magnetic dispersive solid-phase extraction of five phthalate esters in beverages and plastic bottles",
        "value": "65b91cee06feb083f1b9739c"
      },
      {
        "label": "JEQFA/EDQM 2009 List of food contact wax materials",
        "value": "65b9114d0729b5ae4f62a053"
      },
      {
        "label": "BIOBASED MATERIALS USED IN FOOD CONTACT APPLICATIONS: AN ASSESSMENT OF THE MIGRATION POTENTIAL (PROJECT A03070)",
        "value": "65b91c7d06feb083f1b95b71"
      },
      {
        "label": "Effect of microwave heating on the migration of dioctyladipate and acetyltributylcitrate plasticizers from food-grade PVC and PVDC/PVC films into olive oil and water",
        "value": "65b91c8506feb083f1b95d62"
      },
      {
        "label": "Determination of diethylhexyladipate and acetyltributylcitrate in aqueous extracts after cloud point extraction coupled with microwave assisted back extraction and gas chromatographic separation",
        "value": "65b91c8006feb083f1b95c3c"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 3-(3,5-di-tert-butyl-4-hydroxyphenyl) propanoic acid, esters with C13-C15 branched and linear alcohols, CAS No. 171090-93-0, for use in food contact materials",
        "value": "65b91d1a06feb083f1b97d73"
      },
      {
        "label": "Evaluation of Bis (2-ethylhexyl) Phthalate (DEHP) in the PET Bottled Mineral Water of Different Brands and Impact of Heat by GC-MS/MS",
        "value": "65b91cc806feb083f1b96b9c"
      },
      {
        "label": "Trace element content in tea brewed in traditional metallic and stainless steel teapots",
        "value": "65b91dec06feb083f1b9acfe"
      },
      {
        "label": "Detection of residues of the epoxy adhesive component bisphenol A diglycidyl ether (BADGE) in microwave susceptors and its migration into food",
        "value": "65b91d1706feb083f1b97cc8"
      },
      {
        "label": "Effect of relative humidity on the migration of benzophenone from paperboard into the food simulant Tenax(R) and modelling hereof",
        "value": "65b91cd306feb083f1b96dfe"
      },
      {
        "label": "Migration of bisphenol A (BPA) from can coatings into a fatty-food simulant and tuna fish",
        "value": "65b91e3e06feb083f1b9bdf5"
      },
      {
        "label": "Determination of migration monomer styrene from GPPS (general purpose polystyrene) and HIPS (high impact polystyrene) cups to hot drinks",
        "value": "65b91c7006feb083f1b95897"
      },
      {
        "label": "Antioxidant content of and migration from commercial polyethylene, polypropylene, and polyvinyl chloride packages",
        "value": "65b91ccf06feb083f1b96d2c"
      },
      {
        "label": "Safety assessment of the substance fatty acid-coated nano precipitated calcium carbonate for use in plastic food contact materials",
        "value": "65b91d8806feb083f1b996df"
      },
      {
        "label": "Determination of paraffins in food simulants and packaging materials by liquid chromatography with evaporative mass detection and identification of paraffin type by liquid chromatography/gas chromatography and fourier transform infrared spectroscopy",
        "value": "65b91e3b06feb083f1b9bd31"
      },
      {
        "label": "Adsorption mechanism of polycyclic aromatic hydrocarbons on polythiophene-graphene covalent complex and its analytical application in food contact materials",
        "value": "65b91e4c06feb083f1b9c0f9"
      },
      {
        "label": "Mercosur 2015 List for cellulose-based articles for food contact during cooking or heating in oven",
        "value": "65b9114d0729b5ae4f62a047"
      },
      {
        "label": "Headspace sampling and gas chromatographic determination of styrene migration from food-contact polystyrene cups into beverages and food simulants",
        "value": "65b91c6d06feb083f1b9581e"
      },
      {
        "label": "Migration of epsilon-caprolactam residues in packaging intended for contact with fatty foods",
        "value": "65b91c9106feb083f1b95fc8"
      },
      {
        "label": "Scoping investigations on the release of metals from crystalware (in support of the revision of the Ceramics Directive 84/500/EEC)",
        "value": "65b91dec06feb083f1b9ad06"
      },
      {
        "label": "Determination of epsilon-Caprolactam Concentration in Water and a White Wine Simulant during Simulated Transport and Storage of Lidded Cups",
        "value": "65b91c9006feb083f1b95f96"
      },
      {
        "label": "Study of melamine and formaldehyde migration from melamine tableware",
        "value": "65b91c9f06feb083f1b962bf"
      },
      {
        "label": "Endocrine disruptor phthalates in bottled water: daily exposure and health risk assessment in pregnant and lactating women",
        "value": "65b91cc306feb083f1b96aa1"
      },
      {
        "label": "Migration of fluorochemical paper additives from food-contact paper into foods and food simulants",
        "value": "65b91d5e06feb083f1b98d55"
      },
      {
        "label": "Analysis of plastic residues in maple sap and syrup collected from tubing systems sanitized with isopropyl alcohol",
        "value": "65b91cc306feb083f1b96a93"
      },
      {
        "label": "Migration of a UV stabilizer from polyethylene terephthalate (PET) into food simulants",
        "value": "65b91de106feb083f1b9aa95"
      },
      {
        "label": "The Effect of Colorants on the Content of Heavy Metals in Recycled Corrugated Board Papers",
        "value": "65b91df506feb083f1b9af02"
      },
      {
        "label": "Factors influencing the migration of bisphenol A from cans",
        "value": "65b91e4006feb083f1b9be4b"
      },
      {
        "label": "Migration of antimony from polyethylene terephthalate used in mineral water bottles",
        "value": "65b91e0d06feb083f1b9b3fb"
      },
      {
        "label": "Aluminium in foodstuff and the influence of aluminium foil used for food preparation or short time storage",
        "value": "65b91dee06feb083f1b9ad71"
      },
      {
        "label": "Development of quantitative structure-retention relationship models to improve the identification of leachables in food packaging using non-targeted analysis",
        "value": "65b91cb706feb083f1b967ce"
      },
      {
        "label": "Effects of compression, stacking, vacuum packing and temperature on the migration of bisphenol A from polyvinyl chloride packaging sheeting into food simulants",
        "value": "65b91e3f06feb083f1b9be17"
      },
      {
        "label": "Identification and source analysis of volatile flavor compounds in paper packaged yogurt by headspace solid-phase microextraction-gas chromatography-mass spectrometry",
        "value": "65b91c7206feb083f1b958fe"
      },
      {
        "label": "Migration of styrene monomer, dimers and trimers from polystyrene to food simulants",
        "value": "65b91c9c06feb083f1b9620f"
      },
      {
        "label": "Effects of ?-irradiation on caprolactam level from multilayer PA-6 films for food packaging: Development and validation of a gas chromatographic method",
        "value": "65b91c9206feb083f1b95fe1"
      },
      {
        "label": "Saturated and aromatic mineral oil hydrocarbons from paperboard food packaging: estimation of long-term migration from contents in the paperboard and data on boxes from the market",
        "value": "65b91d7a06feb083f1b9938d"
      },
      {
        "label": "Is the use of paper food contact materials treated with per- and polyfluorinated alkyl substances safe for high-temperature applications? - Migration study in real food and food simulants",
        "value": "65b91d2c06feb083f1b981d5"
      },
      {
        "label": "Studies into the transfer and migration of phthalate esters from aluminium foil-paper laminates to butter and margarine",
        "value": "65b91cc106feb083f1b96a08"
      },
      {
        "label": "How Do We Measure Poly- and Perfluoroalkyl Substances (PFASs) at the Surface of Consumer Products?",
        "value": "65b91d1d06feb083f1b97e4b"
      },
      {
        "label": "HS-GC/MS method development and exposure assessment of volatile organic compounds from food packaging into food simulants",
        "value": "65b91c6c06feb083f1b957ee"
      },
      {
        "label": "Investigation of antimony, cobalt, and acetaldehyde migration into the drinking water in Turkey",
        "value": "65b91e0d06feb083f1b9b3e8"
      },
      {
        "label": "Thermal stability of polyethylene terephthalate food contact materials: formation of volatiles from retain samples and implications for recycling",
        "value": "65b91c8c06feb083f1b95ed9"
      },
      {
        "label": "The BIOSAFEPAPER project for in vitro toxicity assessments: Preparation, detailed chemical characterisation and testing of extracts from paper and board samples",
        "value": "65b91c7106feb083f1b958dd"
      },
      {
        "label": "New GC?MS and GC?ECD methods for the estimation of hexamethylenediamine migration from polyamide food contacting materials to foods",
        "value": "65b91ce106feb083f1b970f1"
      },
      {
        "label": "Migration test of Bisphenol A from polycarbonate cups using excitation-emission fluorescence data with parallel factor analysis",
        "value": "65b91e3d06feb083f1b9bd98"
      },
      {
        "label": "Highly sensitive photoelectrochemical sensing of bisphenol A based on zinc phthalocyanine/TiO2 nanorod arrays",
        "value": "65b91e4106feb083f1b9beab"
      },
      {
        "label": "Determination of 60 Migrant Substances in Plastic Food Contact Materials by Vortex-Assisted Liquid-Liquid Extraction and GC-Q-Orbitrap HRMS",
        "value": "65b91c6f06feb083f1b9586b"
      },
      {
        "label": "Migration of phthalates, alkylphenols, bisphenol A and di(2-ethylhexyl)adipate from food packaging",
        "value": "65b91c8406feb083f1b95cfb"
      },
      {
        "label": "Determination of perfluorocompounds in popcorn packaging by pressurised liquid extraction and ultra-performance liquid chromatography-tandem mass spectrometry",
        "value": "65b91d1e06feb083f1b97e78"
      },
      {
        "label": "Assessment of metal contaminations leaching out from recycling plastic bottles upon treatments",
        "value": "65b91def06feb083f1b9ad8c"
      },
      {
        "label": "Levels of BPA in makdous, a traditional Syrian food, using solid-phase extraction followed by HPLC",
        "value": "65b91e3c06feb083f1b9bd57"
      },
      {
        "label": "Exposure to antimony from polyethylene terephthalate (PET) trays used in ready-to-eat meals",
        "value": "65b91e0c06feb083f1b9b3bf"
      },
      {
        "label": "Safety assessment of the substance phosphorous acid, triphenyl ester, polymer with alpha-hydro-omega-hydroxypoly oxy(methyl-1,2-ethanediyl) , C10-16 alkyl esters, for use in food contact materials",
        "value": "65b91cdc06feb083f1b96fdb"
      },
      {
        "label": "ECHA endocrine disruptor assessment list",
        "value": "65b9114d0729b5ae4f62a065"
      },
      {
        "label": "Interaction of packaging materials and vegetable oils: oil stability",
        "value": "65b91ce906feb083f1b972aa"
      },
      {
        "label": "Neutron activation analysis of recycled paper and board in contact with food",
        "value": "65b91dec06feb083f1b9ad0b"
      },
      {
        "label": "MIGRATION OF IRON, ALUMINIUM, CALCIUM, MAGNESIUM AND SILICON FROM CERAMIC MATERIALS INTO FOOD SIMULANT",
        "value": "65b91def06feb083f1b9ad9b"
      },
      {
        "label": "Study of the influence of storage conditions on the quality and migration levels of antimony in polyethylene terephthalate-bottled water",
        "value": "65b91e0c06feb083f1b9b3a5"
      },
      {
        "label": "Epoxidharz-Beschichtungen von Konservendosen: Stoff?berg?nge in ?lhaltige Lebensmittel sind m?glich, Stellungnahme Nr. 022/2016",
        "value": "65b91d2b06feb083f1b981a4"
      },
      {
        "label": "Contribution of aluminum from packaging materials and cooking utensils to the daily aluminum intake",
        "value": "65b91dec06feb083f1b9ad17"
      },
      {
        "label": "Novel computer-assisted separation prediction strategy for online-enrichment-HPLC-FLD in simultaneous monitoring of bisphenols in children�s water bottles",
        "value": "65b91d0a06feb083f1b979ab"
      },
      {
        "label": "Widespread occurrence of bisphenol A in paper and paper products: implications for human exposure",
        "value": "65b91e3f06feb083f1b9be31"
      },
      {
        "label": "Zinc, aluminium, tin and Bis-phenol a in canned tuna fish commercialized in Lebanon and its human health risk assessment",
        "value": "65b91def06feb083f1b9adb3"
      },
      {
        "label": "Solvent Residues in Moon Cake Plastic Packaging Bags",
        "value": "65b91c6a06feb083f1b95795"
      },
      {
        "label": "Ultrasensitive determination of organotin compounds in plastic food packaging and edible oils by sheathless capillary electrophoresis-electrospray ionization-mass spectrometry",
        "value": "65b91cbc06feb083f1b968d3"
      },
      {
        "label": "Oligomers in polyethylene naphthalate and polybutylene terephthalate ? Identification and exploring migration",
        "value": "65b91d3206feb083f1b9831a"
      },
      {
        "label": "Migration of inorganic contaminants into dry food from packaging made from recycled paper and board",
        "value": "65b91df106feb083f1b9ae12"
      },
      {
        "label": "Rapid Analysis of Bisphenol A and Its Analogues in Food Packaging Products by Paper Spray Ionization Mass Spectrometry",
        "value": "65b91d0a06feb083f1b979b4"
      },
      {
        "label": "Evaluation of the migration of chemicals from baby bottles under standardised and duration testing conditions",
        "value": "65b91c8906feb083f1b95e27"
      },
      {
        "label": "The internal exposure of Taiwanese to phthalate?An evidence of intensive use of plastic materials",
        "value": "65b91cc606feb083f1b96b46"
      },
      {
        "label": "Short- and medium-chain chlorinated paraffins in green tea from 11 Chinese provinces and their migration from packaging",
        "value": "65b91e6906feb083f1b9c5d9"
      },
      {
        "label": "PlastChem",
        "value": "65b9114e0729b5ae4f62a09d"
      },
      {
        "label": "Ion-Mobility Quadrupole Time-of-Flight Mass Spectrometry: A Novel Technique Applied to Migration of Nonintentionally Added Substances from Polyethylene Films Intended for Use as Food Packaging",
        "value": "65b91c7a06feb083f1b95aed"
      },
      {
        "label": "Development of magnetic micro-solid phase extraction for analysis of phthalate esters in packaged food",
        "value": "65b91cc206feb083f1b96a55"
      },
      {
        "label": "Safety assessment of the substance bis(2-ethylhexyl)cyclohexane-1,4-dicarboxylate, for use in food contact materials",
        "value": "65b91c8b06feb083f1b95eaf"
      },
      {
        "label": "The characterization and influence factors of semi-volatile compounds from mechanically recycled polyethylene terephthalate (rPET) by combining GC×GC-TOFMS and chemometrics",
        "value": "65b91c6906feb083f1b95751"
      },
      {
        "label": "Scoping investigations on the release of metals from the rim area of decorated articles (in support of the revision of Ceramic Directive 84/500/EEC)",
        "value": "65b91dec06feb083f1b9ad02"
      },
      {
        "label": "Migration of metallic ions from food packages during long term storage. A case study: tomato paste",
        "value": "65b91df206feb083f1b9ae53"
      },
      {
        "label": "Modelling of simultaneous two-sided migration into water and olive oil from nylon food packaging",
        "value": "65b91c8e06feb083f1b95f62"
      },
      {
        "label": "Simultaneous analysis and exposure assessment of migrated bisphenol analogues, phenol, and p-tert-butylphenol from food contact materials",
        "value": "65b91ca306feb083f1b9638b"
      },
      {
        "label": "Migration of Plasticizers from Polyethylene Terephthalate and Low-Density Polyethylene Casing into Bottled Water: A Case Study From India",
        "value": "65b91cc206feb083f1b96a3e"
      },
      {
        "label": "Silver migration from silver-modified activated carbon applied as a water filtration medium in classic cartridges of jug filter systems",
        "value": "65b91e0506feb083f1b9b23c"
      },
      {
        "label": "Migration studies from paper and board food packaging materials. Part 2. Survey for residues of dialkylamino benzophenone UV-cure ink photoinitiators",
        "value": "65b91d9606feb083f1b99a4c"
      },
      {
        "label": "Three-phase hollow-fiber liquid-phase microextraction combined with HPLC-UV for the determination of isothiazolinone biocides in adhesives used for food packaging materials",
        "value": "65b91d4b06feb083f1b98909"
      },
      {
        "label": "Hydrogen-bonding recognition-induced aggregation of gold nanoparticles for the determination of the migration of melamine monomers using dynamic light scattering",
        "value": "65b91c9e06feb083f1b96291"
      },
      {
        "label": "Determination of pesticide and phthalate residues in tea by QuEChERS method and their fate in processing",
        "value": "65b91ced06feb083f1b9738d"
      },
      {
        "label": "Migration from can coatings: part 2. Identification and quantification of migrating cyclic oligoesters below 1000 Da",
        "value": "65b91c6806feb083f1b9570f"
      },
      {
        "label": "Validation of a liquid chromatography-mass spectrometry method for determining the migration of primary aromatic amines from cooking utensils and its application to actual samples",
        "value": "65b91c7706feb083f1b95a24"
      },
      {
        "label": "Simultaneous determination of 22 phthalate esters in polystyrene food-contact materials by ultra-performance convergence chromatography with tandem mass spectrometry",
        "value": "65b91cc506feb083f1b96b1c"
      },
      {
        "label": "Evidence of Absence: Estrogenicity Assessment of a New Food-Contact Coating and the Bisphenol Used in Its Synthesis",
        "value": "65b91d9906feb083f1b99adb"
      },
      {
        "label": "Stainless steel in simulated milk and whey protein solutions - Influence of grade on corrosion and metal release",
        "value": "65b91df206feb083f1b9ae65"
      },
      {
        "label": "Extraction procedure optimization for perfluorooctane sulfonate and perfluorooctanoic acid in food packaging determination by LC-MS/MS",
        "value": "65b91d1e06feb083f1b97e6d"
      },
      {
        "label": "Effect of high-dose electron beam irradiation on the migration of DOA and ATBC plasticizers from food-grade PVC and PVDC/PVC films, respectively, into olive oil",
        "value": "65b91cdf06feb083f1b97092"
      },
      {
        "label": "Integrated sample-pretreatment strategy for separation and enrichment of microplastics and primary aromatic amines in the migration of teabag",
        "value": "65b91c7906feb083f1b95a9e"
      },
      {
        "label": "Determination of three fluorescent whitening agents (FWAs) and their migration research in food contact plastic packaging containers and food simulants by UPLC-MS/MS method",
        "value": "65b91d0d06feb083f1b97a4e"
      },
      {
        "label": "Migration of dipropylene glycol diacrylate and tripropylene glycol diacrylate from paper packaging",
        "value": "65b91d8406feb083f1b995d9"
      },
      {
        "label": "ECHA PBT assessment list",
        "value": "65b9114d0729b5ae4f62a075"
      },
      {
        "label": "Phthalate and adipate esters in Australian packaging materials",
        "value": "65b91c8506feb083f1b95d58"
      },
      {
        "label": "A novel and label-free immunosensor for bisphenol A using rutin as the redox probe",
        "value": "65b91e4006feb083f1b9be72"
      },
      {
        "label": "Direct analysis in real time coupled with quadrupole-Orbitrap high-resolution mass spectrometry for rapid analysis of pyrethroid preservatives in wooden food contact materials",
        "value": "65b91d9506feb083f1b999dd"
      },
      {
        "label": "Radiolysis products of antioxidants from gamma-irradiated polyethylene resins",
        "value": "65b91cba06feb083f1b9684e"
      },
      {
        "label": "Model studies of migration from paper and board into fruit and vegetables and into Tenax as a food simulant",
        "value": "65b91cb306feb083f1b96707"
      },
      {
        "label": "Occurrence, potential release and health risks of heavy metals in popular take-out food containers from China",
        "value": "65b91df606feb083f1b9af3e"
      },
      {
        "label": "Styrene-acrylonitrile-copolymer and acrylonitrile-butadiene-styrene-copolymer: a study on extractable and migratable oligomers",
        "value": "65b91c6b06feb083f1b957d7"
      },
      {
        "label": "Determination of isopropyl-9H-thioxanthen-9-one in packaged beverages by solid-phase extraction clean-up and liquid chromatography with tandem mass spectrometry detection",
        "value": "65b91d9e06feb083f1b99c20"
      },
      {
        "label": "Safety and quality of food contact materials. Part 1: evaluation of analytical strategies to introduce migration testing into good manufacturing practice",
        "value": "65b91c7106feb083f1b958b8"
      },
      {
        "label": "Specific recognition and solid phase extraction of three primary aromatic amines based on molecularly imprinted polymer monolith for the migration detection in food contact materials",
        "value": "65b91c7806feb083f1b95a5d"
      },
      {
        "label": "Comparison of milk and ethanol/water mixtures with respect to monostyrene migration from a polystyrene packaging material",
        "value": "65b91c6e06feb083f1b95852"
      },
      {
        "label": "Evaluation of polyethylene terephthalate cyclic trimer migration from microwave food packaging using temperature-time profiles",
        "value": "65b91e2506feb083f1b9b88f"
      },
      {
        "label": "Back Propagation Artificial Neural Network Modeling and Migration Analysis of Siloxane D5 Migration from Selected Food Contact Materials",
        "value": "65b91d9a06feb083f1b99b26"
      },
      {
        "label": "Fully integrated battery-free and flexible electrochemical tag for on-demand wireless in situ monitoring of heavy metals",
        "value": "65b91df306feb083f1b9ae89"
      },
      {
        "label": "PRELIMINARY SURVEY ON 2,3,7,8-TCDD IN CELLULOSE-CONTAINING CONSUMER PRODUCTS ON THE ITALIAN MARKET",
        "value": "65b91d1c06feb083f1b97e13"
      },
      {
        "label": "Analysing Metals in Bottle-Grade Poly(ethylene terephthalate) by X-ray Fluorescence Spectrometry",
        "value": "65b91df006feb083f1b9adfc"
      },
      {
        "label": "Food-contact epoxy resin: co-variation between migration and degree of cross-linking",
        "value": "65b91cca06feb083f1b96c23"
      },
      {
        "label": "NEUTRON-ACTIVATION ANALYSIS FOR THE DETERMINATION OF CONTAMINANTS IN FOOD CONTACT MATERIALS",
        "value": "65b91cfa06feb083f1b9762f"
      },
      {
        "label": "Phthalates and Bisphenol-A Determination and Release from Different Beverage Plastic Containers by Dispersive Liquid-Liquid Microextraction and GC-IT/MS Analysis",
        "value": "65b91cc206feb083f1b96a26"
      },
      {
        "label": "Assays on the simultaneous determination and elimination of chloroanisoles and chlorophenols from contaminated cork samples",
        "value": "65b91d2106feb083f1b97f01"
      },
      {
        "label": "Determination of volatile organic compounds in materials from polystyrene intended for contact with food: comparison of HS-GS/MS and SPME-GC/MS techniques",
        "value": "65b91c6b06feb083f1b957db"
      },
      {
        "label": "Study of Migration and Safety Assessment of Manganese (Mn) from Food Contact Stainless-Steel Products in China",
        "value": "65b91dfb06feb083f1b9b02b"
      },
      {
        "label": "Quantitative Determination of Migrating compounds from Plastic Baby Bottles by Validated GC-QqQ-MS and LC-QqQ-MS Methods",
        "value": "65b91c8d06feb083f1b95f0f"
      },
      {
        "label": "Chloropropanols (3-MCPD, 1,3-DCP) from food contact materials: GC-MS method improvement, market survey and investigations on the effect of hot water extraction",
        "value": "65b91e8a06feb083f1b9cd23"
      },
      {
        "label": "Homogeneous Electrochemical Biosensor for Melamine Based on DNA Triplex Structure and Exonuclease III-Assisted Recycling Amplification",
        "value": "65b91c9f06feb083f1b962c5"
      },
      {
        "label": "Leaching of styrene and other aromatic compounds in drinking water from PS bottles",
        "value": "65b91c6c06feb083f1b95808"
      },
      {
        "label": "Effect of assay conditions on the migration of phthalates from polyvinyl chloride cling films used for food packaging in Mexico",
        "value": "65b91cc806feb083f1b96bc3"
      },
      {
        "label": "Primary aromatic amines in kitchenware: Determination by liquid chromatography-tandem mass spectrometry",
        "value": "65b91c7506feb083f1b959a3"
      },
      {
        "label": "Identification of non volatile migrant compounds and NIAS in polypropylene films used as food packaging characterized by UPLC-MS/QTOF",
        "value": "65b91c7406feb083f1b95973"
      },
      {
        "label": "Gas chromatographic determination of 1,1,1-trichloroethane in vinyl chloride polymers and in foods",
        "value": "65b91de506feb083f1b9ab78"
      },
      {
        "label": "Optimization of mass spectrometry acquisition parameters for determination of polycarbonate additives, degradation products, and colorants migrating from food contact materials to chocolate",
        "value": "65b91d3e06feb083f1b985eb"
      },
      {
        "label": "Ion mobility quadrupole time-of-flight high resolution mass spectrometry coupled to ultra-high pressure liquid chromatography for identification of non-intentionally added substances migrating from food cans",
        "value": "65b91c7b06feb083f1b95b3d"
      },
      {
        "label": "Determination of 18 photoinitiators in food paper packaging materials by FastPrep-based extraction combined with GC-MS",
        "value": "65b91c7d06feb083f1b95ba6"
      },
      {
        "label": "Migration of di(2-ethylhexyl) maleate from cardboard boxes into foods",
        "value": "65b91d0406feb083f1b97855"
      },
      {
        "label": "New Zealand Inventory of Chemicals",
        "value": "65b9114e0729b5ae4f62a091"
      },
      {
        "label": "Migration of chemicals specific to active and intelligent packaging",
        "value": "65b91c7606feb083f1b959fb"
      },
      {
        "label": "Gold nanostar as an ultrasensitive colorimetric probe for picomolar detection of lead ion",
        "value": "65b91df306feb083f1b9ae98"
      },
      {
        "label": "US EPA ToxVal database",
        "value": "65b9114d0729b5ae4f62a089"
      },
      {
        "label": "Characterization of Polyester Coatings Intended for Food Contact by Different Analytical Techniques and Migration Testing by LC-MSn",
        "value": "65b91c6906feb083f1b9571e"
      },
      {
        "label": "Health risks of population exposure to phthalic acid esters through the use of plastic containers for takeaway food in China",
        "value": "65b91cbf06feb083f1b969a3"
      },
      {
        "label": "Application of chromatographic analysis for detecting components from polymeric can coatings and further determination in beverage samples",
        "value": "65b91c7406feb083f1b9598b"
      },
      {
        "label": "A survey of organic contaminants in household and bottled drinking waters in Kuwait",
        "value": "65b91c6c06feb083f1b95805"
      },
      {
        "label": "FOOD AND DRUG ADMINISTRATION STUDIES OF HIGH-TEMPERATURE FOOD-PACKAGING",
        "value": "65b91c7006feb083f1b9589f"
      },
      {
        "label": "Mathematical model for the migration of plasticisers from food contact materials into solid food",
        "value": "65b91cc306feb083f1b96a8f"
      },
      {
        "label": "The last straw: Characterization of per- and polyfluoroalkyl substances in commercially-available plant-based drinking straws",
        "value": "65b91cd606feb083f1b96e9c"
      },
      {
        "label": "A forensic approach for distinguishing PFAS materials",
        "value": "65b91d1f06feb083f1b97e92"
      },
      {
        "label": "Development of a headspace solid-phase microextraction-gas chromatography-mass spectrometry method for the identification of odour-causing volatile compounds in packaging materials",
        "value": "65b91c9406feb083f1b96061"
      },
      {
        "label": "Phosphite additives and their transformation products in polyethylene packaging for gamma-irradiation",
        "value": "65b91d3106feb083f1b982c2"
      },
      {
        "label": "Disposable plastic materials release microplastics and harmful substances in hot water",
        "value": "65b91df506feb083f1b9af09"
      },
      {
        "label": "CoE List for ion-exchange resins in food contact",
        "value": "65b9114d0729b5ae4f62a04d"
      },
      {
        "label": "Migration of model contaminants (ethylene glycol, DEHA and DEHP) from PET bottles into Iranian yogurt drink",
        "value": "65b91c8406feb083f1b95d05"
      },
      {
        "label": "Simultaneous analysis of trace polymer additives in plastic beverage packaging by solvent sublation followed by high-performance liquid chromatography",
        "value": "65b91ccf06feb083f1b96d34"
      },
      {
        "label": "Determination of 22 alternative plasticizers in wrap film by solid phase extraction and ultra-high performance supercritical fluid chromatography-tandem mass spectrometry",
        "value": "65b91c8306feb083f1b95cc4"
      },
      {
        "label": "Protein protected gold nanoparticles as a fluorophore for the highly selective and ultrasensitive determination of bisphenol A in plastic samples",
        "value": "65b91e3c06feb083f1b9bd6f"
      },
      {
        "label": "Phthalate esters in bottled drinking water and their human exposure in Beijing, China",
        "value": "65b91cc306feb083f1b96a73"
      },
      {
        "label": "Frequency of use controls chemical leaching from drinking-water containers subject to disinfection",
        "value": "65b91e0e06feb083f1b9b408"
      },
      {
        "label": "Determination the set-off migration of ink in cardboard-cups used in coffee vending machines",
        "value": "65b91c7b06feb083f1b95b27"
      },
      {
        "label": "Toxicity of food contact paper evaluated by combined biological and chemical methods",
        "value": "65b91c7e06feb083f1b95baf"
      },
      {
        "label": "Contamination of dry foods with trimethyldiphenylmethanes by migration from recycled paper and board packaging",
        "value": "65b91d4006feb083f1b9864d"
      },
      {
        "label": "Non-targeted analysis and risk assessment of non-volatile compounds in polyamide food contact materials",
        "value": "65b91c8806feb083f1b95e0d"
      },
      {
        "label": "Investigating the use of deuterodiazomethane derivatization for enhancing trace gas chromatography mass spectrometry determination of halophenols in the presence of haloanisoles?",
        "value": "65b91ccd06feb083f1b96cb6"
      },
      {
        "label": "LC-MS/MS analytical procedure to quantify tris(nonylphenyl)phosphite, as a source of the endocrine disruptors 4-nonylphenols, in food packaging materials",
        "value": "65b91d4d06feb083f1b9895d"
      },
      {
        "label": "Migration of bisphenol A from epoxy-can malt drink under various storage conditions and evaluation of its health risk",
        "value": "65b91e3c06feb083f1b9bd7f"
      },
      {
        "label": "Assessment of phthalate migration in polyethylene food contact materials sold on the Ghanaian market",
        "value": "65b91cc806feb083f1b96baf"
      },
      {
        "label": "Mobility of alpha-tocopherol and BHT in LDPE in contact with fatty food simulants",
        "value": "65b91ce906feb083f1b9729a"
      },
      {
        "label": "Safety assessment of the substance zinc oxide, nanoparticles, for use in food contact materials",
        "value": "65b91d3e06feb083f1b985d1"
      },
      {
        "label": "SFC-FTIR SPECTROSCOPY AND SFC-MS OF NONVOLATILE EXTRACTANTS OF MICROWAVE SUSCEPTOR PACKAGING",
        "value": "65b91cb206feb083f1b966b5"
      },
      {
        "label": "GC-MS Studies on the Contaminants in Paper-plastic Food Packaging Materials",
        "value": "65b91cbf06feb083f1b9698e"
      },
      {
        "label": "Identification and quantification of unknown antioxidants in plastic materials by ultrasonic extraction and ultra-performance liquid chromatography coupled with quadrupole time-of-flight mass spectrometry",
        "value": "65b91d3b06feb083f1b9852d"
      },
      {
        "label": "Phthalate migration from packaging materials into food",
        "value": "65b91cc706feb083f1b96b80"
      },
      {
        "label": "Monitoring of contaminants in recycled paperboard for food contact applications",
        "value": "65b91cc406feb083f1b96ad6"
      },
      {
        "label": "Determination of Perfluorooctane Sulfonates (PFOS) in Four Chemical Materials by HPLC/MS/MS",
        "value": "65b91d1f06feb083f1b97e88"
      },
      {
        "label": "Safety assessment of the substance N,N-bis(2- hydroxyethyl)stearylamine partially esterified with saturated C16/C18 fatty acids, for use in food contact materials",
        "value": "65b91c7a06feb083f1b95af1"
      },
      {
        "label": "US EPA TSCA inventory list",
        "value": "65b9114e0729b5ae4f62a08f"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, N-(2-aminoethyl) ethanolamine, CAS No. 111-41-1, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91cab06feb083f1b96526"
      },
      {
        "label": "Migration of DEHP from plastic to food simulants under microwave heating",
        "value": "65b91cbf06feb083f1b9697e"
      },
      {
        "label": "The Impact of Storage on Phthalic Acid Esters Concentrations in Yogurts Packed in Plastic Cups",
        "value": "65b91cc306feb083f1b96aa4"
      },
      {
        "label": "A highly sensitive enzyme-linked immunosorbent assay for the detection of dipropyl phthalate in plastic food contact materials",
        "value": "65b91cf006feb083f1b97423"
      },
      {
        "label": "Influence of oxygen and long term storage on the profile of volatile compounds released from polymeric multilayer food contact materials sterilized by gamma irradiation",
        "value": "65b91c6b06feb083f1b957ad"
      },
      {
        "label": "GC/MS method for the determination of adipate plasticizers in ham sausage and its application to kinetic and penetration studies",
        "value": "65b91c8006feb083f1b95c45"
      },
      {
        "label": "Gas chromatography-triple quadrupole tandem mass spectrometry for successive single-surface migration study of phthalate esters from polythene film",
        "value": "65b91cbf06feb083f1b96985"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the active substances, sodium borohydride and palladium acetate for use in active food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91d1906feb083f1b97d32"
      },
      {
        "label": "Migration of perfluoroalkyl acids from food packaging to food simulants",
        "value": "65b91d1d06feb083f1b97e45"
      },
      {
        "label": "Evaluation of methods for determining heavy metal content in polyethylene terephthalate food packaging",
        "value": "65b91df606feb083f1b9af43"
      },
      {
        "label": "Set-off of non volatile compounds from printing inks in food packaging materials and the role of lacquers to avoid migration",
        "value": "65b91c8d06feb083f1b95f22"
      },
      {
        "label": "Alternative test methods to control the compliance of polyolefin food packaging materials with the European Union regulation: the case of aromatic antioxidants and of bis(ethanolamine) antistatics based on 1H-NMR and UV-visible spectrophotometry",
        "value": "65b91cb806feb083f1b9681e"
      },
      {
        "label": "Effect of sterilisation and storage conditions on the migration of bisphenol A from tinplate cans of the Lebanese market",
        "value": "65b91e3e06feb083f1b9bde6"
      },
      {
        "label": "Safety assessment of the substance dimethyl carbonate for use in food contact materials",
        "value": "65b91dbb06feb083f1b9a2aa"
      },
      {
        "label": "Dietary Exposure Estimation to Chemicals Transferred from Milk and Dairy Products Packaging Materials in Spanish Child and Adolescent Population",
        "value": "65b91c6f06feb083f1b95886"
      },
      {
        "label": "Quantitative determination of 22 primary aromatic amines by cation-exchange solid-phase extraction and liquid chromatography?mass spectrometry",
        "value": "65b91c7806feb083f1b95a7c"
      },
      {
        "label": "Studies on functional barriers to migration. 3. Migration of benzophenone and model ink components from cartonboard to food during frozen storage and microwave heating",
        "value": "65b91cd206feb083f1b96dbe"
      },
      {
        "label": "Determination of alkylphenols and phthalate esters in vegetables and migration studies from their packages by means of stir bar sorptive extraction coupled to gas chromatography?mass spectrometry",
        "value": "65b91c8a06feb083f1b95e61"
      },
      {
        "label": "Hydrogenated terphenyl contaminants in recycled paper",
        "value": "65b91c9e06feb083f1b96287"
      },
      {
        "label": "FSAP 2018 list",
        "value": "65b9114d0729b5ae4f62a057"
      },
      {
        "label": "Aptamer/derivatization-based surface-enhanced Raman scattering membrane assembly for selective analysis of melamine and formaldehyde in migration of melamine kitchenware",
        "value": "65b91c9f06feb083f1b962c2"
      },
      {
        "label": "Detection of phthalates migration from disposable tablewares to drinking water using hexafluoroisopropanol-induced catanionic surfactant coacervate extraction",
        "value": "65b91cc306feb083f1b96a76"
      },
      {
        "label": "Liquid chromatographic determination of residual isocyanate monomers in plastics intended for food contact use",
        "value": "65b91c7606feb083f1b95a0d"
      },
      {
        "label": "Perfluorinated compounds in food simulants after migration from fluorocarbon resin-coated frying pans, baking utensils, and non-stick baking papers on the Korean market",
        "value": "65b91d1506feb083f1b97c51"
      },
      {
        "label": "Emission of perfluoroalkyl carboxylic acids (PFCA) from heated surfaces made of polytetrafluoroethylene (PTFE) applied in food contact materials and consumer products",
        "value": "65b91d2c06feb083f1b981cc"
      },
      {
        "label": "Tannic acid functionalized N-doped graphene modified glassy carbon electrode for the determination of bisphenol A in food package",
        "value": "65b91e4006feb083f1b9be65"
      },
      {
        "label": "Measurement of styrene oxide in polystyrenes, estimation of migration to foods, and reaction kinetics and products in food simulants",
        "value": "65b91e8a06feb083f1b9cd07"
      },
      {
        "label": "French List of food contact colorants, non-binding",
        "value": "65b9114c0729b5ae4f62a02f"
      },
      {
        "label": "Specific migration of caprolactam and infrared characteristics of a polyamide/polyethylene composite film for food packaging under conditions of long-term storage before use",
        "value": "65b91c8f06feb083f1b95f7f"
      },
      {
        "label": "Migration of 4-nonylphenol from polyvinyl chloride food packaging films into food simulants and foods",
        "value": "65b91c8906feb083f1b95e3f"
      },
      {
        "label": "Determination of bisphenol A in, and its migration from, PVC stretch film used for food packaging",
        "value": "65b91e3f06feb083f1b9be1b"
      },
      {
        "label": "Migration Kinetics of Ethylene Glycol Monomer from Pet Bottles into Acidic Food Simulant: Effects of Nanoparticle Presence and Matrix Morphology",
        "value": "65b91c9906feb083f1b96163"
      },
      {
        "label": "Analytical tools for identification of non-intentionally added substances (NIAS) coming from polyurethane adhesives in multilayer packaging materials and their migration into food simulants",
        "value": "65b91c7a06feb083f1b95adb"
      },
      {
        "label": "Supercritical fluid extraction of potential migrants from paper and board intended for use as food packaging materials",
        "value": "65b91cc206feb083f1b96a32"
      },
      {
        "label": "Development and application of a non-targeted extraction method for the analysis of migrating compounds from plastic baby bottles by GC-MS",
        "value": "65b91c7f06feb083f1b95bfa"
      },
      {
        "label": "Contamination of Polyvinyl Chloride Cling Films from Cardboard Packaging",
        "value": "65b91e5a06feb083f1b9c364"
      },
      {
        "label": "Nanoparticle release from nano-silver antimicrobial food containers",
        "value": "65b91d0806feb083f1b97949"
      },
      {
        "label": "Comparison of migration from polyethersulphone and polycarbonate baby bottles",
        "value": "65b91ce806feb083f1b97282"
      },
      {
        "label": "Levels of heavy metals in candy packages and candies likely to be consumed by small children",
        "value": "65b91d2306feb083f1b97fa7"
      },
      {
        "label": "Testing approaches for the release of metals from ceramic articles - In support of the revision of the Ceramic Directive 84/500/EEC",
        "value": "65b91def06feb083f1b9ada9"
      },
      {
        "label": "Determination of primary aromatic amines in cold water extract of coloured paper napkin samples by liquid chromatography-tandem mass spectrometry",
        "value": "65b91c7606feb083f1b95a14"
      },
      {
        "label": "Simultaneous determination of 11 fluorescent whitening agents in food-contact paper and board by ion-pairing high-performance liquid chromatography with fluorescence detection",
        "value": "65b91cdb06feb083f1b96fa4"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance zinc oxide, nanoparticles, uncoated and coated with 3-(methacryloxy) propyl trimethoxysilane, for use in food contact materials",
        "value": "65b91e2306feb083f1b9b822"
      },
      {
        "label": "Fluorinated Compounds in U.S. Fast Food Packaging",
        "value": "65b91cd606feb083f1b96ea1"
      },
      {
        "label": "Potential migration release of volatile compounds from plastic containers destined for food use in microwave ovens",
        "value": "65b91c6b06feb083f1b957b8"
      },
      {
        "label": "Influence of cooking conditions on the migration of silicone oligomers from silicone rubber baking molds to food simulants",
        "value": "65b91c9a06feb083f1b96192"
      },
      {
        "label": "Permanently Positively Charged Stable Isotope Labeling Agents and Its Application in the Accurate Quantitation of Alkylphenols Migrated from Plastics to Edible Oils",
        "value": "65b91c8906feb083f1b95e2d"
      },
      {
        "label": "Safety assessment of paper and board food packaging: chemical analysis and genotoxicity of possible contaminants in packaging",
        "value": "65b91cb706feb083f1b967d9"
      },
      {
        "label": "Survey of the migration of melamine and formaldehyde from melamine food contact articles available on the UK market",
        "value": "65b91c9f06feb083f1b962e0"
      },
      {
        "label": "Target and suspect screening of substances liable to migrate from food contact paper and cardboard materials using liquid chromatography-high resolution tandem mass spectrometry",
        "value": "65b91c6a06feb083f1b95772"
      },
      {
        "label": "Covalent triazine-based frameworks for efficient solid-phase microextraction of phthalic acid esters from food-contacted plastics",
        "value": "65b91ced06feb083f1b97393"
      },
      {
        "label": "Distribution of PCDD/PCDFs in PE-coated paperboards an explanation for migration of PCDD/PCDFs from board products",
        "value": "65b91d1c06feb083f1b97e0f"
      },
      {
        "label": "Determination of epsilon-caprolactam migration from polyamide plastics: A new approach",
        "value": "65b91c8f06feb083f1b95f83"
      },
      {
        "label": "Antioxidant migration resistance of SiOx layer in SiOx/PLA coated film",
        "value": "65b91ceb06feb083f1b972f0"
      },
      {
        "label": "The validation of a method for determining the migration of trace elements from food packaging materials into food",
        "value": "65b91e0b06feb083f1b9b380"
      },
      {
        "label": "Migration of bisphenol A from epoxy coating to foodstuffs",
        "value": "65b91e4006feb083f1b9be57"
      },
      {
        "label": "Mercosur 2015 List for cellulose-based materials for hot cooking and filtration",
        "value": "65b9114d0729b5ae4f62a045"
      },
      {
        "label": "Plasticizer migration from children's toys, child care articles, art materials, and school supplies",
        "value": "65b91cc806feb083f1b96ba7"
      },
      {
        "label": "4-Nonylphenol (NP) in food-contact materials: analytical methodology and occurrence",
        "value": "65b91c8a06feb083f1b95e50"
      },
      {
        "label": "Migration modelling as a tool for quality assurance of food packaging",
        "value": "65b91d7b06feb083f1b993aa"
      },
      {
        "label": "Simultaneous migration of bisphenol compounds and trace metals in canned vegetable food",
        "value": "65b91d1706feb083f1b97cda"
      },
      {
        "label": "Migration of polyolefin oligomeric saturated hydrocarbons (POSH) into food",
        "value": "65b91d7b06feb083f1b993bb"
      },
      {
        "label": "Determination of phthalate concentrations in paper-based fast food packaging available on the US market",
        "value": "65b91cc606feb083f1b96b5e"
      },
      {
        "label": "The Analysis of Organic Contaminants in Printing Paper Food Packaging Materials",
        "value": "65b91cbf06feb083f1b96994"
      },
      {
        "label": "Styrene Oligomers from Food Commodities: Detection and Determination of Styrene Oligomers in Food Products and Simulants",
        "value": "65b91c7006feb083f1b958ae"
      },
      {
        "label": "Electrochemical determination of estrogenic compound bisphenol F in food packaging using carboxyl functionalized multi-walled carbon nanotubes modified glassy carbon electrode",
        "value": "65b91dbd06feb083f1b9a319"
      },
      {
        "label": "Characterization of newfound natural luminescent properties of melamine, and development and validation of a method of high performance liquid chromatography with fluorescence detection for its determination in kitchen plastic ware",
        "value": "65b91c9f06feb083f1b962c8"
      },
      {
        "label": "Towards suitable tests for the migration of metals from ceramic and crystal tableware: Work in support of the revision of the Ceramic Directive 84/500/EEC",
        "value": "65b91deb06feb083f1b9aceb"
      },
      {
        "label": "Migration from PVC cling films compared with their field of application",
        "value": "65b91c8206feb083f1b95c8f"
      },
      {
        "label": "Characterizing silver nanoparticles in beverages and following their release from silver-containing food containers using sector field single particle inductively coupled plasma mass spectrometry (SP-ICP-MS)",
        "value": "65b91e0606feb083f1b9b272"
      },
      {
        "label": "Determination of ink photoinitiators in packaged beverages by gas chromatography?mass spectrometry and liquid chromatography?mass spectrometry",
        "value": "65b91c7d06feb083f1b95b97"
      },
      {
        "label": "Influence of Temperature on the Quantity of Bisphenol A in Bottled Drinking Water",
        "value": "65b91e4106feb083f1b9be93"
      },
      {
        "label": "Migration of phthalates and 2, 6-diisopropylnaphthalene from cellulose food packaging",
        "value": "65b91cc606feb083f1b96b35"
      },
      {
        "label": "Migration Processes of Metal Elements from Carbon Steel Cylinders to Food Gases",
        "value": "65b91deb06feb083f1b9acda"
      },
      {
        "label": "Simultaneous determination of cyclic PET and PBT oligomers migrated from laminated steel cans for food",
        "value": "65b91c8006feb083f1b95c42"
      },
      {
        "label": "Extraction and gas chromatographic evaluation of plasticizers content in food packaging films",
        "value": "65b91c8506feb083f1b95d3e"
      },
      {
        "label": "Survey of plasticizers migrating from the gaskets of lids into oily food in glass jars: The second European enforcement campaign shows poor compliance work",
        "value": "65b91ca406feb083f1b963c5"
      },
      {
        "label": "FOOD CONTAMINATION BY DIISOPROPYLNAPHTHALENES FROM CARDBOARD PACKAGES",
        "value": "65b91d7a06feb083f1b9937d"
      },
      {
        "label": "Hplc-Dad And Parafac For The Determination Of Bisphenol-A And Another Four Bisphenols Migrating From Bpa-Free Polycarbonate Glasses",
        "value": "65b91d0b06feb083f1b979bd"
      },
      {
        "label": "Effect of y-radiation on migration of dioctyl adipate plasticizer from food grade PVC film into olive oil",
        "value": "65b91cdf06feb083f1b97098"
      },
      {
        "label": "Data on migration of poly-and perfluorinated compounds from Food Contact Materials into Food and Food simulants",
        "value": "65b91d6006feb083f1b98dc0"
      },
      {
        "label": "Migration from non-ovenable food contact materials at elevated temperatures",
        "value": "65b91c9206feb083f1b95fdb"
      },
      {
        "label": "Development of a solid-phase microextraction method for direct determination of pentachlorophenol in paper and board samples: Comparison with conventional extraction method",
        "value": "65b91e7106feb083f1b9c7b5"
      },
      {
        "label": "Determination of contamination pathways of phthalates in food products sold on the Belgian market",
        "value": "65b91cc006feb083f1b969bc"
      },
      {
        "label": "Metals migration between product and metallic package in canned meat",
        "value": "65b91df206feb083f1b9ae5a"
      },
      {
        "label": "Factors affecting lead leaching from microwavable plastic ware made with lead-containing pigments",
        "value": "65b91df606feb083f1b9af38"
      },
      {
        "label": "EU recognized EDC from REACH or biocide reg",
        "value": "65b9114d0729b5ae4f62a069"
      },
      {
        "label": "Effect of amines in the release of bisphenol A from polycarbonate baby bottles",
        "value": "65b91e3f06feb083f1b9be0c"
      },
      {
        "label": "An untargeted evaluation of food contact materials by flow injection analysis-mass spectrometry (FIA-MS) combined with independent components analysis (ICA)",
        "value": "65b91cc406feb083f1b96ac6"
      },
      {
        "label": "Extraction and Quantification of Bisphenol-A Level in Infant Polycarbonate Feeding Bottles using High Performance Liquid Chromatography Technique",
        "value": "65b91e4306feb083f1b9befe"
      },
      {
        "label": "UHPLC-high-resolution mass spectrometry determination of bisphenol A and plastic additives released by polycarbonate tableware: influence of ageing and surface damage",
        "value": "65b91d6306feb083f1b98e62"
      },
      {
        "label": "Parameters influencing the migration of trace metals in uncoated fruit cans",
        "value": "65b91df206feb083f1b9ae45"
      },
      {
        "label": "Potential risk of bisphenol A migration from polycarbonate containers after heating, boiling, and microwaving",
        "value": "65b91e3f06feb083f1b9be2e"
      },
      {
        "label": "Chlorinated paraffins leaking from hand blenders can lead to significant human exposures",
        "value": "65b91e6806feb083f1b9c5d5"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, Perfluoro (2-ethyloxy-ethoxy)acetic acid , ammonium salt, CAS No. 908020-52-0, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91e7606feb083f1b9c8b1"
      },
      {
        "label": "Evaluation of dispersive liquid-liquid microextraction for the simultaneous determination of chlorophenols and haloanisoles in wines and cork stoppers using gas chromatography-mass spectrometry",
        "value": "65b91c9606feb083f1b960d3"
      },
      {
        "label": "The determination of monomers and oligomers from polyester-based can coatings into foodstuffs over extended storage periods",
        "value": "65b91cd506feb083f1b96e6e"
      },
      {
        "label": "Analysis of phthalate migration from plastic containers to packaged cooking oil and mineral water",
        "value": "65b91cbf06feb083f1b9699c"
      },
      {
        "label": "Determination of antioxidant migration levels from low-density polyethylene films into food simulants",
        "value": "65b91ceb06feb083f1b97308"
      },
      {
        "label": "Determination of low-level ink photoinitiator residues in packaged milk by solid-phase extraction and LC-ESI/MS/MS using triple-quadrupole mass analyzer",
        "value": "65b91cce06feb083f1b96cf2"
      },
      {
        "label": "Development of a rapid screening method to determine primary aromatic amines in kitchen utensils using direct analysis in real time mass spectrometry (DART-MS)",
        "value": "65b91c7706feb083f1b95a44"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, methacrylic acid, 2-hydroxypropyl ester, CAS No 27813-02-1, for use in food contact materials",
        "value": "65b91d5506feb083f1b98b20"
      },
      {
        "label": "Feasibility study on the use of probabilistic migration modeling in support of exposure assessment from food contact materials",
        "value": "65b91d6406feb083f1b98ead"
      },
      {
        "label": "Contamination of wines and spirits by phthalates: types of contaminants present, contamination sources and means of prevention",
        "value": "65b91cc606feb083f1b96b4c"
      },
      {
        "label": "Primary aromatic amine migration from polyamide kitchen utensils: method development and product testing",
        "value": "65b91c7706feb083f1b95a4e"
      },
      {
        "label": "Survey of Food Can Coatings in Thailand - Their Use, Extractable Residues and Migrations",
        "value": "65b91e3d06feb083f1b9bdc2"
      },
      {
        "label": "Identification of chemicals that could migrate into foodstuffs from pigments and dyes and measurement of migration of these chemicals",
        "value": "65b91d9a06feb083f1b99b32"
      },
      {
        "label": "Linear and cyclic oligomers in PET, glycol-modified PET and Tritan (TM) used for food contact materials",
        "value": "65b91c6906feb083f1b9573a"
      },
      {
        "label": "Comparison of the migration of melamine from melamine-formaldehyde plastics ('melaware') into various food simulants and foods themselves",
        "value": "65b91c9f06feb083f1b962dc"
      },
      {
        "label": "Scientific opinion on the safety evaluation of the substance, 2-phenyl-3,3-bis(4-hydroxyphenyl) phthalimidine, CAS No. 6607-41-6, for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91dc006feb083f1b9a3da"
      },
      {
        "label": "Polyfluoroalkyl phosphate esters and perfluoroalkyl carboxylic acids in target food samples and packaging-method development and screening",
        "value": "65b91cbd06feb083f1b9691f"
      },
      {
        "label": "Release of Selected Non-Intentionally Added Substances (NIAS) from PET Food Contact Materials: A New Online SPE-UHPLC-MS/MS Multiresidue Method",
        "value": "65b91ccd06feb083f1b96c94"
      },
      {
        "label": "Migration of metal elements from polylactic acid dinner plate into acidic food simulant and its safety evaluation",
        "value": "65b91ded06feb083f1b9ad49"
      },
      {
        "label": "Release of non-intentionally added substances (NIAS) from food contact polycarbonate: Effect of ageing",
        "value": "65b91d3e06feb083f1b985e8"
      },
      {
        "label": "Identification of unexpected chemical contaminants in baby food coming from plastic packaging migration by high resolution accurate mass spectrometry",
        "value": "65b91c9206feb083f1b95fd7"
      },
      {
        "label": "Migration into food of polyethylene terephthalate (PET) cyclic oligomers from PET microwave susceptor packaging",
        "value": "65b91e2506feb083f1b9b892"
      },
      {
        "label": "Identification and quantification of the migration of chemicals from plastic baby bottles used as substitutes for polycarbonate",
        "value": "65b91c7b06feb083f1b95b10"
      },
      {
        "label": "Aluminium migration into beverages: Are dented cans safe?",
        "value": "65b91deb06feb083f1b9acd4"
      },
      {
        "label": "Chemical analysis and genotoxicological safety assessment of paper and paperboard used for food packaging",
        "value": "65b91cd106feb083f1b96d7f"
      },
      {
        "label": "ECHA GHS",
        "value": "65b9114d0729b5ae4f62a059"
      },
      {
        "label": "Evaluation of the Occurrence of Phthalates in Plastic Materials Used in Food Packaging",
        "value": "65b91cca06feb083f1b96c0c"
      },
      {
        "label": "Formaldehyde migration in aqueous extracts from paper and cardboard food packaging materials in Turkey",
        "value": "65b91d8e06feb083f1b9983f"
      },
      {
        "label": "New low viscous hydrophobic deep eutectic solvents in vortex-assisted liquid-liquid microextraction for the determination of phthalate esters from food-contacted plastics",
        "value": "65b91cca06feb083f1b96c1c"
      },
      {
        "label": "Target analysis of primary aromatic amines combined with a comprehensive screening of migrating substances in kitchen utensils by liquid chromatography-high resolution mass spectrometry",
        "value": "65b91c7506feb083f1b959a0"
      },
      {
        "label": "Safety assessment of the substance 2,4-di-tert-amylphenol, impurity and hydrolysis product of the substance phosphorous acid, mixed 2,4-bis(1,1-dimethylpropyl)phenyl and 4-(1,1-dimethylpropyl)phenyl triesters, for use in food contact materials",
        "value": "65b91cd906feb083f1b96f4b"
      },
      {
        "label": "Identification of print-related contaminants in food packaging",
        "value": "65b91c7206feb083f1b958f4"
      },
      {
        "label": "Determination of bisphenol-type endocrine disrupting compounds in food-contact recycled-paper materials by focused ultrasonic solid-liquid extraction and ultra performance liquid chromatography-high resolution mass spectrometry",
        "value": "65b91d1706feb083f1b97cd1"
      },
      {
        "label": "Migration from polyamide 'microwave and roasting bags' into roast chicken",
        "value": "65b91c9006feb083f1b95fa2"
      },
      {
        "label": "Migration of bisphenol A from can coatings--effects of damage, storage conditions and heating",
        "value": "65b91e4106feb083f1b9be8c"
      },
      {
        "label": "Migration of monomers from liquid crystalline poly(p-hydroxybenzoic acid-co-2-hydroxy-6-naphthoic acid)",
        "value": "65b91d1706feb083f1b97ca6"
      },
      {
        "label": "Evaluation of metals in tomato sauces stored in different types of packaging",
        "value": "65b91df506feb083f1b9aef7"
      },
      {
        "label": "Migration of nanoparticles from plastic packaging materials containing carbon black into foodstuffs",
        "value": "65b91cf706feb083f1b97593"
      },
      {
        "label": "Investigation of factors influencing the release of chloropropanols (3-MCPD and 1,3-DCP) from food contact paper",
        "value": "65b91e8a06feb083f1b9cd16"
      },
      {
        "label": "Migration of Residual Nonvolatile and Inorganic Compounds from Recycled Post-Consumer PET and HDPE",
        "value": "65b91cab06feb083f1b96534"
      },
      {
        "label": "Impact of Industrial Production and Packaging Processes on the Concentration of Per- and Polyfluorinated Compounds in Milk and Dairy Products",
        "value": "65b91d1e06feb083f1b97e5e"
      },
      {
        "label": "Migration of melamine from can coatings cross-linked with melamine-based resins, into food simulants and foods",
        "value": "65b91c9f06feb083f1b962d8"
      },
      {
        "label": "list of restricted or banned POPs (persistent organic pollutants) as ruled by the Stockholm convention",
        "value": "65b9114d0729b5ae4f62a06f"
      },
      {
        "label": "Determination of six phthalic acid esters in orange juice packaged by PVC bottle using SPE and HPLC-UV: application to the migration study",
        "value": "65b91cc406feb083f1b96ac9"
      },
      {
        "label": "Assessment of the Impact of Accelerated Migration Testing for Coated Food Cans Using Food Simulants",
        "value": "65b91d1706feb083f1b97cd4"
      },
      {
        "label": "Universal response quantification approach using a Corona Charged Aerosol Detector (CAD) - Application on linear and cyclic oligomers extractable from polycondensate plastics polyesters, polyamides and polyarylsulfones",
        "value": "65b91cbc06feb083f1b968bc"
      },
      {
        "label": "Exposure assessment of Bisphenol A intake from polymeric baby bottles in formula-fed infants aged less than one year",
        "value": "65b91e3e06feb083f1b9bdf8"
      },
      {
        "label": "Poly- and perfluoroalkyl substances (PFASs) in indoor dust and food packaging materials in Egypt: Trends in developed and developing countries",
        "value": "65b91d1e06feb083f1b97e62"
      },
      {
        "label": "Survey of counterfeit melamine tableware available on the market in Thailand, and its migration",
        "value": "65b91d8d06feb083f1b99816"
      },
      {
        "label": "Environmentally friendly method for the determination of acrylamide and trimethylolpropane in paper packaging materials by liquid chromatography with tandem mass spectrometry",
        "value": "65b91e3606feb083f1b9bc06"
      },
      {
        "label": "Rapid analysis of 14 ultraviolet absorbents in plastic food contact materials by supercritical fluid chromatography on Sub-2-micron particles",
        "value": "65b91c8606feb083f1b95da6"
      },
      {
        "label": "Safety assessment of the substance 1,2,3,4-tetrahydronaphthalene-2, 6-dicarboxylic acid, dimethyl ester for use in food contact materials",
        "value": "65b91d3f06feb083f1b9860d"
      },
      {
        "label": "Ausmaß der Migration unerwünschter Stoffe aus Verpackungsmaterialien aus Altpapier in Lebensmittel",
        "value": "65b91c6a06feb083f1b9576f"
      },
      {
        "label": "Determination of 24 primary aromatic amines in aqueous food simulants by combining solid phase extraction and salting-out assisted liquid?liquid extraction with liquid chromatography tandem mass spectrometry",
        "value": "65b91c7506feb083f1b9599c"
      },
      {
        "label": "Investigation Of The Significant Factors In Elemental Migration From Glass In Contact With Food",
        "value": "65b91ded06feb083f1b9ad4f"
      },
      {
        "label": "A novel and high sensitive MWCNTs-nickel carbide/hollow fiber-pencil graphite modified electrode for in situ ultra-trace analysis of bisphenol A",
        "value": "65b91e4106feb083f1b9be96"
      },
      {
        "label": "Determination of potential volatile compounds in polyethylene terephthalate (PET) bottles and their short- and long-term migration into food simulants and soft drink",
        "value": "65b91c7206feb083f1b95915"
      },
      {
        "label": "Occurrence of brominated flame retardants in black thermo cups and selected kitchen utensils purchased on the European market",
        "value": "65b91cbe06feb083f1b9695a"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 1,2-benzisothiazol-3(2H)-one 1,1-dioxide, sodium salt, CAS No. 128-44-9, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91cec06feb083f1b97340"
      },
      {
        "label": "Concentration of organochlorine pesticides in wine corks",
        "value": "65b91c7b06feb083f1b95b2d"
      },
      {
        "label": "Simultaneous determination of four organotins in food packaging by high-performance liquid chromatography?tandem mass spectrometry",
        "value": "65b91cbc06feb083f1b968d0"
      },
      {
        "label": "Determination of toxic elements in meat products from Serbia packaged in tinplate cans",
        "value": "65b91df406feb083f1b9aeb4"
      },
      {
        "label": "Primary aromatic amines (PAAs) in black nylon and other food-contact materials, 2004-2009",
        "value": "65b91c7606feb083f1b95a1a"
      },
      {
        "label": "Development and characterization of a new polyampholyte-surfactant complex applied to the solid phase extraction of bisphenol-A",
        "value": "65b91e3e06feb083f1b9be03"
      },
      {
        "label": "Effects of electron beam irradiation on the migration of antioxidants and their degradation products from commercial polypropylene into food simulating liquids",
        "value": "65b91d1406feb083f1b97be9"
      },
      {
        "label": "Determination of perfluorinated compounds (PFCs) in various foodstuff packaging materials used in the Greek market",
        "value": "65b91d1506feb083f1b97c41"
      },
      {
        "label": "Detection of 20 Phthalate Esters in Different Kinds of Food Packaging Materials by GC-MS/MS with Five Internal Standards",
        "value": "65b91cc606feb083f1b96b23"
      },
      {
        "label": "Influence of solvent absorption on the migration of Irganox 1076 from LDPE",
        "value": "65b91d3006feb083f1b98296"
      },
      {
        "label": "Studies on the usability of recycled PET for food packaging applications",
        "value": "65b91cfd06feb083f1b976f9"
      },
      {
        "label": "Migration of bisphenol A from plastic baby bottles, baby bottle liners and reusable polycarbonate drinking bottles",
        "value": "65b91df506feb083f1b9af16"
      },
      {
        "label": "Microplastics and other harmful substances released from disposable paper cups into hot water",
        "value": "65b91d0b06feb083f1b979c1"
      },
      {
        "label": "FPF Chemicals in plastic packaging database",
        "value": "65b9114d0729b5ae4f62a08d"
      },
      {
        "label": "Analysis for organic residues from aids to polymerization used to make plastics intended for food contact",
        "value": "65b91c8306feb083f1b95cf0"
      },
      {
        "label": "Ionic Liquid-Immobilized Expanded Perlite Solid-Phase Extraction for Separation/Analysis of Bisphenol A in Food Packaging Material",
        "value": "65b91e3f06feb083f1b9be21"
      },
      {
        "label": "Migration studies on fatty acid amide slip additives from plastics into food simulants",
        "value": "65b91c7406feb083f1b95979"
      },
      {
        "label": "Migration of dioctylphthalate and dioctyladipate plasticizers from polyvinylchloride films into olive oil",
        "value": "65b91cca06feb083f1b96c29"
      },
      {
        "label": "Release of volatile compounds from cooking plastic bags under different heating sources",
        "value": "65b91c8a06feb083f1b95e71"
      },
      {
        "label": "Linear and cyclic oligomers in polybutylene terephthalate for food contact materials",
        "value": "65b91c6906feb083f1b95742"
      },
      {
        "label": "Swelling as a promoter of migration of plastic additives in the interaction of fatty food simulants with polylactic acid- and polypropylene-based plastics",
        "value": "65b91cce06feb083f1b96cc8"
      },
      {
        "label": "Brominated flame retardants (BFRs) in contaminated food contact articles: identification using DART-HRMS and GC-MS",
        "value": "65b91cbf06feb083f1b96964"
      },
      {
        "label": "Migration from plastic packaging into meat",
        "value": "65b91ce506feb083f1b971d9"
      },
      {
        "label": "Report of the interlaboratory comparison organized by the Community Reference Laboratory Food Contact Material: Plasticisers in Gaskets and Oil",
        "value": "65b91ca506feb083f1b963e6"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 1,10-decanediamine, CAS No. 646-25-3, for use in food contact materials",
        "value": "65b91dd106feb083f1b9a75c"
      },
      {
        "label": "Simultaneous determination of di-(2-ethylhexyl) phthalate and five photoinitiators in food contact materials using ultrasonic-assisted extraction combined with supercritical fluid chromatography",
        "value": "65b91cc406feb083f1b96ab2"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 3,4-diacetoxy-1-butene, CAS No. 18085-02-4, for use in food contact materials",
        "value": "65b91cde06feb083f1b9706b"
      },
      {
        "label": "HEAVY METALS IN RECYCLED PASTRY PACKAGES AND PASTRIES",
        "value": "65b91df106feb083f1b9ae22"
      },
      {
        "label": "Assessment of baby Bibs. GC-MS screening, migration into saliva and insight of toxicity with QSAR tools",
        "value": "65b91c7506feb083f1b95998"
      },
      {
        "label": "Estudio del riesgo asociado a la migración de sustancias provenientes de bolsas de silicona para cocinado de alimentos",
        "value": "65b91c9a06feb083f1b9618b"
      },
      {
        "label": "Determination of 2,6-diisopropylnaphthalene (DIPN) and n-dibutylphthalate (DBP) in food and paper packaging materials from US marketplaces",
        "value": "65b91d4006feb083f1b98643"
      },
      {
        "label": "Migration studies of nickel and chromium from ceramic and glass tableware into food simulants",
        "value": "65b91dfe06feb083f1b9b0e4"
      },
      {
        "label": "Updated evaluation of the migration of styrene monomer and oligomers from polystyrene food contact materials to foods and food simulants",
        "value": "65b91c7006feb083f1b958a9"
      },
      {
        "label": "Oestrogenicity of paper and cardboard extracts used as food containers",
        "value": "65b91cc206feb083f1b96a5c"
      },
      {
        "label": "Periodic Table of the Elements",
        "value": "65b9114e0729b5ae4f62a0a1"
      },
      {
        "label": "Comparing different gas chromatographic methods for the quantification of bisphenol A (BPA) trace levels in paper and cardboard products from the market",
        "value": "65b91e4006feb083f1b9be5a"
      },
      {
        "label": "Interactions between poly(vinyl chloride) stabilised with epoxidised sunflower oil and food simulants",
        "value": "65b91d1106feb083f1b97b33"
      },
      {
        "label": "Automated on-line in-tube solid-phase microextraction coupled with high performance liquid chromatography for the analysis of bisphenol A, alkylphenols, and phthalate esters in foods contacted with plastics",
        "value": "65b91cc306feb083f1b96a9e"
      },
      {
        "label": "Validation a solid-phase extraction-HPLC method for determining the migration behaviour of five aromatic amines from packaging bags into seafood simulants",
        "value": "65b91c9506feb083f1b960b3"
      },
      {
        "label": "A direct solid sampling analysis method for the detection of silver nanoparticles in biological matrices",
        "value": "65b91e0506feb083f1b9b244"
      },
      {
        "label": "Effect of common consumer washing methods on bisphenol A release in tritan drinking bottles",
        "value": "65b91e4106feb083f1b9be7c"
      },
      {
        "label": "Atmospheric pressure gas chromatography coupled to quadrupole-time of flight mass spectrometry as a powerful tool for identification of non intentionally added substances in acrylic adhesives used in food packaging materials",
        "value": "65b91c7f06feb083f1b95c06"
      },
      {
        "label": "Migration of iron, lead, cadmium and tin from tinplate-coated cans into chickpeas",
        "value": "65b91df106feb083f1b9ae2f"
      },
      {
        "label": "Formation of monomer residues in PS, PC, PA-6 and PVC upon ?-irradiation",
        "value": "65b91c6c06feb083f1b9580d"
      },
      {
        "label": "Migration of aluminum from food contact materials to food-a health risk for consumers? Part II of III: migration of aluminum from drinking bottles and moka pots made of aluminum to beverages",
        "value": "65b91deb06feb083f1b9acde"
      },
      {
        "label": "Application of the liquid-liquid dispersed microextraction based on phase transition behavior of temperature sensitive polymer to rapidly detect 5 BPs in food packaging",
        "value": "65b91d0906feb083f1b9797e"
      },
      {
        "label": "Essential oils composition and bioactivities of two species leaves used as packaging materials in Xishuangbanna, China",
        "value": "65b91c7306feb083f1b9592c"
      },
      {
        "label": "Persistent organic pollutants in cork used for production of wine stoppers",
        "value": "65b91ccd06feb083f1b96cad"
      },
      {
        "label": "In situ quantitative analysis by ultrasonic extraction and nebulization combined with hydrogen flame ionization mass spectrometry: Diisopropylnaphthalene (DIPN), a marker of recycled paper in packing materials",
        "value": "65b91d7b06feb083f1b9939b"
      },
      {
        "label": "Study on Formaldehyde Migration Rules in Products of Tripolycyanamide for Food Packaging",
        "value": "65b91d8d06feb083f1b9980a"
      },
      {
        "label": "Ultra-high-performance liquid chromatography-atmospheric pressure ionization-tandem mass spectrometry method for the migration studies of primary aromatic amines from food contact materials",
        "value": "65b91c7506feb083f1b959c3"
      },
      {
        "label": "Identification of Diisobutyl Phthalate (DIBP) Suspected as Possible Contaminant in Recycled Cellulose for Take-away Pizza Boxes",
        "value": "65b91e5d06feb083f1b9c3b9"
      },
      {
        "label": "New UPLC coupled to mass spectrometry approaches for screening of non-volatile compounds as potential migrants from adhesives used in food packaging materials",
        "value": "65b91c9306feb083f1b96022"
      },
      {
        "label": "Migration of antioxidant additives from various polyolefinic plastics into oleaginous vehicles",
        "value": "65b91d6406feb083f1b98ec3"
      },
      {
        "label": "Naphthalene contamination of sterilized milk drinks contained in low-density polyethylene bottles. Part 1",
        "value": "65b91e7806feb083f1b9c920"
      },
      {
        "label": "Deinking flotation of recycled linerboard for food packaging applications.pdf",
        "value": "65b91d8d06feb083f1b99821"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, (perfluorobutyl) ethylene, CAS No. 19430-93-4, for use in food contact materials",
        "value": "65b91d2706feb083f1b980a0"
      },
      {
        "label": "Development and Validation of a Rapid Method for Identification and Quantitation of Benzophenone and Related 17 Derivatives in Paper and Cardboard Packaging Materials by Gas Chromatography-Mass Spectrometry",
        "value": "65b91c7f06feb083f1b95be6"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 1,4:3,6-dianhydrosorbitol, CAS No 652-67-5, for use in food contact materials",
        "value": "65b91dd306feb083f1b9a7ab"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 1,3,5-tris( 2,2-dimethylpropanamido) benzene, CAS No. 745070-61-5, for use in food contact materials EFSA Panel on Food Contact Materials",
        "value": "65b91e2506feb083f1b9b89d"
      },
      {
        "label": "Polycarbonate baby bottles: study of the release of Bisphenol A",
        "value": "65b91e3d06feb083f1b9bda5"
      },
      {
        "label": "The use of ion mobility time-of-flight mass spectrometry to assess the migration of polyamide 6 and polyamide 66 oligomers from kitchenware utensils to food",
        "value": "65b91c9106feb083f1b95fba"
      },
      {
        "label": "Determination of phthalates in foods and establishing methodology to distinguish their source",
        "value": "65b91cc506feb083f1b96af2"
      },
      {
        "label": "Benchmarking the in Vitro Toxicity and Chemical Composition of Plastic Consumer Products",
        "value": "65b91c6a06feb083f1b95792"
      },
      {
        "label": "Procedure to explore a ternary mixture diagram to find the appropriate gradient profile in liquid chromatography with fluorescence detector. Application to determine four primary aromatic amines in napkins",
        "value": "65b91c7806feb083f1b95a81"
      },
      {
        "label": "Electrochemical determination of bisphenol A based on PHD/MWCNTs modified glassy carbon electrode",
        "value": "65b91e3b06feb083f1b9bd49"
      },
      {
        "label": "Nanoclay migration from food packaging materials",
        "value": "65b91cf506feb083f1b9752d"
      },
      {
        "label": "Contaminant migration from food packaging laminates used for heat and eat meals",
        "value": "65b91c6806feb083f1b956ff"
      },
      {
        "label": "Migration of plasticizers phthalates, bisphenol A and alkylphenols from plastic containers and evaluation of risk",
        "value": "65b91c8906feb083f1b95e46"
      },
      {
        "label": "The detection and elucidation of oligomers migrating from biodegradable multilayer teacups using liquid chromatography coupled to ion mobility time-of-flight mass spectrometry and gas chromatography–mass spectrometry",
        "value": "65b91ce706feb083f1b9722d"
      },
      {
        "label": "Determination of Polymer Additives-Antioxidants, Ultraviolet Stabilizers, Plasticizers and Photoinitiators in Plastic Food Package by Accelerated Solvent Extraction Coupled with High-Performance Liquid Chromatography",
        "value": "65b91cc306feb083f1b96a7b"
      },
      {
        "label": "Mechanism of migration from agglomerated cork stoppers. Part 2: Safety assessment criteria of agglomerated cork stoppers for champagne wine cork producers, for users and for control laboratories",
        "value": "65b91c7406feb083f1b95985"
      },
      {
        "label": "Phthalates and food-contact materials: enforcing the 2008 European Union plastics legislation",
        "value": "65b91cc106feb083f1b96a00"
      },
      {
        "label": "Migration of dioctylphthalate and dioctyladipate plasticizers from food-grade PVC films into ground-meat products",
        "value": "65b91cca06feb083f1b96c26"
      },
      {
        "label": "Analytical approaches to identify potential migrants in polyester-polyurethane can coatings",
        "value": "65b91ce006feb083f1b970c3"
      },
      {
        "label": "Safety assessment of the substance, titanium dioxide surface treated with fluoride-modified alumina, for use in food contact materials",
        "value": "65b91d1906feb083f1b97d41"
      },
      {
        "label": "Mineral oil hydrocarbons: development/optimization of analytical methods, investigation of migration from food packaging into semolina and egg pasta, and occurrence in human tissues",
        "value": "65b91d7b06feb083f1b993c1"
      },
      {
        "label": "Migration of photoinitiators, phthalates and plasticizers from paper and cardboard materials into different simulants and foodstuffs",
        "value": "65b91c7e06feb083f1b95bda"
      },
      {
        "label": "Migration of silver from commercial plastic food containers and implications for consumer exposure assessment",
        "value": "65b91e0406feb083f1b9b204"
      },
      {
        "label": "Cyclic oligomers in polyamide for food contact material: quantification by HPLC-CLND and single-substance calibration",
        "value": "65b91c9006feb083f1b95f9f"
      },
      {
        "label": "EU PBT/vPvB assessments prior to 2006",
        "value": "65b9114d0729b5ae4f62a071"
      },
      {
        "label": "Screening and identification of per- and polyfluoroalkyl substances in microwave popcorn bags",
        "value": "65b91cbd06feb083f1b9691c"
      },
      {
        "label": "EXPOSURE TO THE PERSISTENT PERFLUORINATED COMPOUNDS PFCs IN GREECE",
        "value": "65b91d1506feb083f1b97c4d"
      },
      {
        "label": "Hexabromocyclododecane in polystyrene based consumer products: an evidence of unregulated use",
        "value": "65b91d7906feb083f1b99341"
      },
      {
        "label": "Comparison of the influence of citric acid and acetic acid as simulant for acidic food on the release of alloy constituents from stainless steel AISI 201",
        "value": "65b91df106feb083f1b9ae26"
      },
      {
        "label": "Factors Affecting the Leaching of Chloropropanols from Pulp Used in the Manufacture of Paper Food Packaging",
        "value": "65b91e8106feb083f1b9cb18"
      },
      {
        "label": "Use of atmospheric pressure solids analysis probe time-of-flight mass spectrometry to screen for plasticisers in gaskets used in contact with foods",
        "value": "65b91ca506feb083f1b963db"
      },
      {
        "label": "Migration of organic compounds from PET/clay nanocomposites: influences of clay type, content and dispersion state",
        "value": "65b91cc206feb083f1b96a43"
      },
      {
        "label": "Investigation of inorganic contaminants in packaging made from recycled paper and board",
        "value": "65b91cfa06feb083f1b97638"
      },
      {
        "label": "Migration of vinyl chloride into PVC-bottled drinking-water assessed by gas chromatography-mass spectrometry",
        "value": "65b91e2706feb083f1b9b90e"
      },
      {
        "label": "EPA PFAS master list of PFAS substances (PFASMASTER)",
        "value": "65b9114e0729b5ae4f62a093"
      },
      {
        "label": "Characterization of natural polymers as functional barriers for cellulose-based packaging materials",
        "value": "65b91cc006feb083f1b969ae"
      },
      {
        "label": "Thin-layer chromatography combined with surface-enhanced Raman scattering for rapid detection of benzidine and 4-aminobiphenyl in migration from food contact materials based on gold nanoparticle doped metal-organic framework",
        "value": "65b91e7c06feb083f1b9ca13"
      },
      {
        "label": "Phthalate ester migration into food: effect of packaging material and time",
        "value": "65b91cc806feb083f1b96bcb"
      },
      {
        "label": "Effects of Ultraviolet (UV) on Degradation of Irgafos 168 and Migration of Its Degradation Products from Polypropylene Films",
        "value": "65b91d6306feb083f1b98e8c"
      },
      {
        "label": "EuPIA List 2018 of photoinitiators for printing inks and varnishes",
        "value": "65b9114c0729b5ae4f62a037"
      },
      {
        "label": "Additives in polypropylene and polylactic acid food packaging: Chemical analysis and bioassays provide complementary tools for risk assessment",
        "value": "65b91c8606feb083f1b95d70"
      },
      {
        "label": "Report of an inter-laboratory comparison from the European Union Reference Laboratory for Food Contact Materials: ILC02 2015 ? Specific migration from a multilayer in food simulant A",
        "value": "65b91c8e06feb083f1b95f58"
      },
      {
        "label": "USA FDA Cumulative Estimated Daily Intake (CEDI) database",
        "value": "65b9114c0729b5ae4f62a013"
      },
      {
        "label": "Migration of polymer additives and radiolysis products from irradiated PET/PE films into a food simulant",
        "value": "65b91c8806feb083f1b95dfe"
      },
      {
        "label": "Analytical determination of bisphenol A (BPA) and bisphenol analogues in paper products by LC-MS/MS",
        "value": "65b91d0a06feb083f1b9799f"
      },
      {
        "label": "Characterization of VOCs and additives in Italian PET bottles and studies on potential functional aldehydes scavengers",
        "value": "65b91ca206feb083f1b96346"
      },
      {
        "label": "Qualitative impact of salinity, UV radiation and turbulence on leaching of organic plastic additives from four common plastics ? A lab experiment",
        "value": "65b91d1606feb083f1b97c81"
      },
      {
        "label": "COPPER AND CHROMIUM LEVELS IN CANDIES AND CANDY PACKAGES",
        "value": "65b91d2306feb083f1b97fa4"
      },
      {
        "label": "Perfluorochemicals: potential sources of and migration from food packaging",
        "value": "65b91d6b06feb083f1b99036"
      },
      {
        "label": "2-Isopropylthioxanthone (2-ITX) in food and food packaging materials on the German market",
        "value": "65b91d9e06feb083f1b99c37"
      },
      {
        "label": "Monitoring of endocrine disruptors by capillary electrophoresis amperometric detector",
        "value": "65b91d4806feb083f1b9882f"
      },
      {
        "label": "Migration of two antioxidants from packaging into a solid food and into Tenax?",
        "value": "65b91d2f06feb083f1b98269"
      },
      {
        "label": "Evaluation of different conditions of contact for caprolactam migration from multilayer polyamide films into food simulants",
        "value": "65b91c9106feb083f1b95faf"
      },
      {
        "label": "Verbraucherexposition durch Polyamid-Oligomere in Lebensmitteln",
        "value": "65b91c8e06feb083f1b95f52"
      },
      {
        "label": "Release of aluminium and thallium ions from uncoated food contact materials made of aluminium alloys into food and food simulant",
        "value": "65b91deb06feb083f1b9acf1"
      },
      {
        "label": "Study of the migration phenomena of specific metals in canned tomato paste before and after opening. Validation of a new quality indicator for opened cans",
        "value": "65b91df106feb083f1b9ae05"
      },
      {
        "label": "Contamination of Canadian and European bottled waters with antimony from PET containers",
        "value": "65b91e0b06feb083f1b9b38f"
      },
      {
        "label": "Chemical migration from adhesives used in food-contact materials and articles",
        "value": "65b91c7d06feb083f1b95b74"
      },
      {
        "label": "Characterization of Atypical Off-Flavor Compounds in Natural Cork Stoppers by Multidimensional Gas Chromatographic Techniques",
        "value": "65b91d3e06feb083f1b985d5"
      },
      {
        "label": "Migration of bisphenol a into food simulants and meat rations during initial time of storage",
        "value": "65b91e3d06feb083f1b9bd93"
      },
      {
        "label": "Performance of Electrode Materials During Food Processing by Pulsed Electric Fields",
        "value": "65b91ded06feb083f1b9ad4c"
      },
      {
        "label": "Non-target analysis of intentionally and non intentionally added substances from plastic packaging materials and their migration into food simulants",
        "value": "65b91c7606feb083f1b959e3"
      },
      {
        "label": "Headspace gas chromatographic determination of residual 1,3-butadiene in rubber-modified plastics and its migration from plastic containers into selected foods",
        "value": "65b91c9806feb083f1b96135"
      },
      {
        "label": "An effect-directed strategy for characterizing emerging chemicals in food contact materials made from paper and board",
        "value": "65b91d1b06feb083f1b97ddc"
      },
      {
        "label": "Effect of sunlight exposure on the release of intentionally and/or non-intentionally added substances from polyethylene terephthalate (PET) bottles into water: Chemical analysis and in vitro toxicity",
        "value": "65b91d8e06feb083f1b99852"
      },
      {
        "label": "Modelling of Migration from Printing Inks on Paper Packaging",
        "value": "65b91e3206feb083f1b9bb65"
      },
      {
        "label": "Modelling migration from paper into a food simulant",
        "value": "65b91c7206feb083f1b958ee"
      },
      {
        "label": "Composition of the adhesives used in food packaging multilayer materials and migration studies from packaging to food",
        "value": "65b91c7d06feb083f1b95b79"
      },
      {
        "label": "Analysis of plasticizers in cap-sealing resins for bottled foods",
        "value": "65b91c8306feb083f1b95cc1"
      },
      {
        "label": "Headspace sampling and gas-solid chromatographic determination and confirmation of greater than or equal to 1 ppb vinyl chloride residues in polyvinyl chloride food packaging",
        "value": "65b91d4806feb083f1b98842"
      },
      {
        "label": "Atmospheric pressure gas chromatography coupled to quadrupole-time of flight mass spectrometry as a tool for identification of volatile migrants from autoadhesive labels used for direct food contact",
        "value": "65b91cde06feb083f1b97040"
      },
      {
        "label": "Mechanism of alteration of the surface of lead crystal glass in contact with food: A chemical study of the surface layer",
        "value": "65b91df506feb083f1b9af12"
      },
      {
        "label": "Migration of lactic acid, lactide and oligomers from polylactide food-contact materials",
        "value": "65b91e3906feb083f1b9bcca"
      },
      {
        "label": "Exposure assessment to lead, cadmium, zinc and copper released from ceramic and glass wares intended to come into contact with food",
        "value": "65b91df506feb083f1b9af05"
      },
      {
        "label": "Report of the interlaboratory comparison organised by the European Reference Laboratory for Food Contact Materials: ILC02 2012- Primary Aromatic Amines in 3% acetic acid migration solution.",
        "value": "65b91c7806feb083f1b95a79"
      },
      {
        "label": "Combined chromatographic and mass spectrometric toolbox for fingerprinting migration from PET tray during microwave heating",
        "value": "65b91c6906feb083f1b95745"
      },
      {
        "label": "Scientific Opinion on 26th list of substances for food contact materials EFSA Scientific Opinion of the Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91d2006feb083f1b97eed"
      },
      {
        "label": "Two RP-HPLC sensitive methods to quantify and identify bisphenol A diglycidyl ether and its hydrolysis products .1. European union aqueous food simulants",
        "value": "65b91d1706feb083f1b97ce1"
      },
      {
        "label": "Effect of the cleaning procedure of Tenax on its reuse in the determination of plasticizers after migration by gas chromatography/mass spectrometry",
        "value": "65b91c8106feb083f1b95c75"
      },
      {
        "label": "Heavy metals and optical whitenings as quality parameters of recycled paper for food packaging",
        "value": "65b91d2406feb083f1b97fb0"
      },
      {
        "label": "Influence of pre-heating of food contact polypropylene cups on its physical structure and on the migration of additives",
        "value": "65b91ce006feb083f1b970be"
      },
      {
        "label": "Determination of Bisphenols and Related Compounds in Honey and Their Migration from Selected Food Contact Materials",
        "value": "65b91cf406feb083f1b974e6"
      },
      {
        "label": "2- and 3-Monochloropropanediols in paper products and their transfer to foods",
        "value": "65b91d8c06feb083f1b997db"
      },
      {
        "label": "Determination of the potential for transfer from secondary packaging to foods and development of guidelines to reduce transfer to levels of no concern",
        "value": "65b91c7b06feb083f1b95b1b"
      },
      {
        "label": "Effect of heat processing and storage time on migration of bisphenol A (BPA) and bisphenol A-diglycidyl ether (BADGE) to aqueous food simulant from Mexican can coatings",
        "value": "65b91d1706feb083f1b97cde"
      },
      {
        "label": "Antimony leaching from PET plastic into bottled water in Algerian market",
        "value": "65b91e0b06feb083f1b9b362"
      },
      {
        "label": "Ambient mass spectrometry as a tool for a rapid and simultaneous determination of migrants coming from a bamboo-based biopolymer packaging",
        "value": "65b91c7b06feb083f1b95b39"
      },
      {
        "label": "Migration assessment and the 'threshold of toxicological concern' applied to the safe design of an acrylic adhesive for food-contact laminates",
        "value": "65b91cad06feb083f1b9658d"
      },
      {
        "label": "Analysis of 16 phthalic acid esters in food simulants from plastic food contact materials by LC-ESI-MS/MS",
        "value": "65b91cc306feb083f1b96a6d"
      },
      {
        "label": "The 'vapour-phase' migration of styrene from general purpose polystyrene and high impact polystyrene into cooking oil",
        "value": "65b91c7006feb083f1b95889"
      },
      {
        "label": "Safety assessment of antimicrobials in food packaging paper based on LC-MS method",
        "value": "65b91d1c06feb083f1b97dfa"
      },
      {
        "label": "The migration of bisphenols from beverage cans and reusable sports bottles",
        "value": "65b91d0a06feb083f1b97995"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance ethylene-vinyl acetate copolymer wax, CAS No 24937-78-8 for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91d4606feb083f1b987ad"
      },
      {
        "label": "Soft plastic bread packaging: lead content and reuse by families",
        "value": "65b91df306feb083f1b9ae95"
      },
      {
        "label": "Bisphenol diglycidyl ethers and bisphenol A and their hydrolysis in drinking water",
        "value": "65b91d1806feb083f1b97cec"
      },
      {
        "label": "Photoinitiators use in printed baby bibs and their migration into Tenax® by gas chromatography–mass spectrometry",
        "value": "65b91c7e06feb083f1b95bc1"
      },
      {
        "label": "Determination of bisphenol-A in reusable polycarbonate food-contact plastics and migration to food-simulating liquids",
        "value": "65b91e4306feb083f1b9bef4"
      },
      {
        "label": "Oligomers in plastics packaging. Part 1: Migration tests for vinyl chloride tetramer",
        "value": "65b91d1d06feb083f1b97e35"
      },
      {
        "label": "Migration of antioxidants from food-contact rubber materials to food simulants",
        "value": "65b91d6f06feb083f1b99121"
      },
      {
        "label": "Determination of primary aromatic amines in water food simulant using solid-phase analytical derivatization followed by gas chromatography coupled with mass spectrometry",
        "value": "65b91c7806feb083f1b95a76"
      },
      {
        "label": "Report on the inter-laboratory comparison exercise organised by the European Union Reference Laboratory for Food Contact Materials: Determination of elements in acetic acid solutions and in migration from ceramic and glass tableware",
        "value": "65b91df606feb083f1b9af34"
      },
      {
        "label": "Determinat?on of bisphenol a migrating from canned food and beverages in markets",
        "value": "65b91e3c06feb083f1b9bd8b"
      },
      {
        "label": "Aromatic amines from polyurethane adhesives in food packaging: the challenge of identification and pattern recognition using Quadrupole-Time of Flight-Mass SpectrometryE",
        "value": "65b91c7706feb083f1b95a36"
      },
      {
        "label": "An evaluation of volatile compounds released from containers commonly used in circulation of sports beverages",
        "value": "65b91c8a06feb083f1b95e75"
      },
      {
        "label": "Studies of migration of potentially genotoxic compounds into water stored in PET bottles",
        "value": "65b91c6906feb083f1b95716"
      },
      {
        "label": "Organic Compounds in Paper and Plastic Food Packaging",
        "value": "65b91c6a06feb083f1b95798"
      },
      {
        "label": "Determination of volatile compounds and their sensory impact in a biopolymer based on polylactic acid (PLA) and polyester",
        "value": "65b91c8e06feb083f1b95f39"
      },
      {
        "label": "Evaluation of portable vibrational spectroscopy for identifying plasticizers in dairy tubing",
        "value": "65b91cbf06feb083f1b96991"
      },
      {
        "label": "THE MIGRATION OF PHTHALATES FROM PACKAGING INTO FOOD DEPENDING ON THE HEAT PROCESSING AND FAT CONTENT OF MEAT PRODUCTS",
        "value": "65b91cc706feb083f1b96b84"
      },
      {
        "label": "Migration of vinyl chloride monomer (VCM) and additives into PVC bottled drinking water",
        "value": "65b91ccb06feb083f1b96c3d"
      },
      {
        "label": "Food-contact epoxy resin: co-variation between migration and degree of cross-linking. Part II",
        "value": "65b91d1806feb083f1b97cf0"
      },
      {
        "label": "Migration of selected hydrocarbon contaminants into dry semolina and egg pasta packed in direct contact with virgin paperboard and polypropylene film",
        "value": "65b91d7b06feb083f1b993c7"
      },
      {
        "label": "Health risk assessment of dietary exposure to phthalates from plastic-coated paper among university students",
        "value": "65b91cc206feb083f1b96a59"
      },
      {
        "label": "Opinion of the Scientific Panel on food additives, flavourings, processing aids and materials in contact with food (AFC) on a request related to a 9th list of substances for food contact materials",
        "value": "65b91d6b06feb083f1b9903d"
      },
      {
        "label": "Method for analysis of 68 organic contaminants in food contact paper using gas and liquid chromatography coupled with tandem mass spectrometry",
        "value": "65b91c7d06feb083f1b95b81"
      },
      {
        "label": "Heavy Metals in the Glass and Enamels of Consumer Container Bottles",
        "value": "65b91df406feb083f1b9aeaf"
      },
      {
        "label": "PBT according to US EPA",
        "value": "65b9114d0729b5ae4f62a06d"
      },
      {
        "label": "Simultaneous determination of NOGE-related and BADGE-related compounds in canned food by ultra-performance liquid chromatography-tandem mass spectrometry",
        "value": "65b91cfc06feb083f1b976cd"
      },
      {
        "label": "Migration of trimellitic acid from epoxy anhydride can coatings into foods",
        "value": "65b91c9c06feb083f1b96226"
      },
      {
        "label": "Melamine and cyanuric acid in foodstuffs from the United States and their implications for human exposure",
        "value": "65b91c9e06feb083f1b9628d"
      },
      {
        "label": "Compositional GC-FID analysis of the additives to PVC, focusing on the gaskets of lids for glass jars",
        "value": "65b91c8506feb083f1b95d4d"
      },
      {
        "label": "Study of hotmelt adhesives used in food packaging multilayer laminates. Evaluation of the main factors affecting migration to food",
        "value": "65b91cbb06feb083f1b96895"
      },
      {
        "label": "Mineral elements in canned Spanish liver pat?",
        "value": "65b91df006feb083f1b9ade7"
      },
      {
        "label": "Printing inks for food packaging: study of the key parameters in the migration of photoinitiators",
        "value": "65b91c7206feb083f1b958f9"
      },
      {
        "label": "Fast and simple determination and exposure assessment of bisphenol A, phenol, p-tert-butylphenol, and diphenylcarbonate transferred from polycarbonate food-contact materials to food simulants",
        "value": "65b91c7a06feb083f1b95ae2"
      },
      {
        "label": "Study on Migration of Two Triazine Type Ultraviolet Absorbents from Polypropylene Packaging Materials to Food Simulant",
        "value": "65b91c9706feb083f1b960ec"
      },
      {
        "label": "Migration of mineral oil, photoinitiators and plasticisers from recycled paperboard into dry foods: a study under controlled conditions",
        "value": "65b91c7f06feb083f1b95bdf"
      },
      {
        "label": "In-process prepared deep eutectic solvent based homogeneous liquid-liquid microextraction for the determination of irgaphos 168 and irganox 1010 in polypropylene packed drinks",
        "value": "65b91d6406feb083f1b98eb5"
      },
      {
        "label": "Safety assessment of the substance, montmorillonite clay modified with hexadecyltrimethylammonium bromide, for use in food contact materials",
        "value": "65b91da706feb083f1b99e35"
      },
      {
        "label": "Identification of oligomers in polyethyleneterephthalate bottles for mineral water and fruit juice. Development and validation of a high-performance liquid chromatographic method for the determination of first series cyclic trimer",
        "value": "65b91d1206feb083f1b97b83"
      },
      {
        "label": "Geueke, B. et al. FCChumon",
        "value": "65b9114e0729b5ae4f62a09b"
      },
      {
        "label": "Safety assessment of the substance nano precipitated calcium carbonate for use in plastic food contact materials",
        "value": "65b91d8806feb083f1b996dc"
      },
      {
        "label": "Evaluation of Direct Analysis in Real Time - High Resolution Mass Spectrometry (DART-HRMS) for WEEE specific substance determination in polymeric samples",
        "value": "65b91cbe06feb083f1b96960"
      },
      {
        "label": "Determination of UV Absorbers and Light Stabilizers in Food Packing Bags by Magnetic Solid Phase Extraction Followed by High Performance Liquid Chromatography",
        "value": "65b91d4a06feb083f1b988cd"
      },
      {
        "label": "Migration of bisphenol A (BPA) from epoxy can coatings to jalapeno peppers and an acid food simulant",
        "value": "65b91e3e06feb083f1b9bdf1"
      },
      {
        "label": "Sources of N-nitrosamine contamination in foods",
        "value": "65b91c7406feb083f1b9596b"
      },
      {
        "label": "Occurrence of Di-(2-ethylhexyl) adipate and phthalate plasticizers in samples of meat, fish, and cheese and their packaging films",
        "value": "65b91c8506feb083f1b95d3a"
      },
      {
        "label": "A flow-batch manipulated Ag NPs based SPR sensor for colorimetric detection of copper ions (Cu(2+)) in water samples",
        "value": "65b91e1b06feb083f1b9b6a0"
      },
      {
        "label": "Evaluation of the migration of 15 photo-initiators from cardboard packaging into Tenax((R)) using ultra-performance liquid chromatography-tandem mass spectrometry (UPLC-MS/MS)",
        "value": "65b91c7d06feb083f1b95b85"
      },
      {
        "label": "Injector-internal thermal desorption from edible oils performed by programmed temperature vaporizing (PTV) injection",
        "value": "65b91c8406feb083f1b95d0d"
      },
      {
        "label": "DEVELOPMENT AND VALIDATION OF AN HPLC-MS-MS METHOD FOR THE DETERMINATION OF PRIMARY AROMATIC AMINES AND USE OF THIS FOR THE ASSESSMENT OF MIGRATION MODELS FOR FOOD WITH HIGH PACKAGING: FOOD MASS RATIOS (PROJECT A03060)",
        "value": "65b91c7506feb083f1b959bb"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, alkyl(C10-C21) sulphonic acid, esters with phenol, CAS No. 91082-17-6, for use in food contact materials1 EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91ca406feb083f1b96397"
      },
      {
        "label": "Non-targeted analysis with liquid chromatography - high resolution mass spectrometry for the identification of food packaging migrants",
        "value": "65b91c6a06feb083f1b95765"
      },
      {
        "label": "Role of gas chromatography–single quadrupole mass spectrometry in the identification of compounds migrating from polypropylene-based food contact plastics",
        "value": "65b91c7006feb083f1b95894"
      },
      {
        "label": "Impact of toasting oak barrels on the presence of polycyclic aromatic hydrocarbons in wine",
        "value": "65b91cd606feb083f1b96e8f"
      },
      {
        "label": "Argentation high performance liquid chromatography on-line coupled to gas chromatography for the analysis of monounsaturated polyolefin oligomers in packaging materials and foods",
        "value": "65b91d7a06feb083f1b99395"
      },
      {
        "label": "List of chemicals used to produce glass or controlled in food contact glass",
        "value": "65b9114d0729b5ae4f62a051"
      },
      {
        "label": "MIGRATION OF ORGANIC SUBSTANCES THROUGH BIO-COATED PAPER AND BOARD FOR FOOD PACKAGING",
        "value": "65b91c7306feb083f1b95938"
      },
      {
        "label": "Determination of Four Types of Hazardous Chemicals in Food Contact Materials by UHPLC-MS/MS",
        "value": "65b91c7806feb083f1b95a60"
      },
      {
        "label": "Migration of mineral hydrocarbons into foods. 5. Miscellaneous applications of mineral hydrocarbons in food contact materials",
        "value": "65b91e4806feb083f1b9bffd"
      },
      {
        "label": "Migration of Nonylphenols from Polymer Packaging Materials into Food Simulants",
        "value": "65b91d4706feb083f1b98813"
      },
      {
        "label": "Effect of sunlight exposure on phthalates migration from plastic containers to packaged juices",
        "value": "65b91cc106feb083f1b969ea"
      },
      {
        "label": "Non-targeted screening for contaminants in paper and board food-contact materials using effect-directed analysis and accurate mass spectrometry",
        "value": "65b91d6e06feb083f1b990d7"
      },
      {
        "label": "EC 2005 List for food contact plastics and coatings",
        "value": "65b9114c0729b5ae4f62a02d"
      },
      {
        "label": "Phthalates in Belgian cow's milk and the role of feed and other contamination pathways at farm level",
        "value": "65b91cc506feb083f1b96afb"
      },
      {
        "label": "Determination of compounds from epoxy resins in food simulants by HPLC-Fluorescence",
        "value": "65b91cfd06feb083f1b976e0"
      },
      {
        "label": "Assessment of the migration of perfluorinated compounds and primary aromatic amines from PTFE-coated non-stick cookware marketed in Turkey",
        "value": "65b91c7606feb083f1b95a1d"
      },
      {
        "label": "Study of metal release from stainless steels in simulated food contact by means of total reflection X-ray fluorescence",
        "value": "65b91dfb06feb083f1b9b04a"
      },
      {
        "label": "Migration Testing of Polyethylene Terephthalate: Comparison of Regulated Test Conditions with Migration into Real Food at the End of Shelf Life",
        "value": "65b91e0c06feb083f1b9b3c8"
      },
      {
        "label": "A green molecular imprinted solid-phase extraction protocol for bisphenol A monitoring with HPLC-UV to guarantee the quality and safety of walnuts under different storage conditions",
        "value": "65b91e4006feb083f1b9be4f"
      },
      {
        "label": "Characterization of plastic packaging additives: Food contact, stability and toxicity",
        "value": "65b91c7b06feb083f1b95b20"
      },
      {
        "label": "Enforcement of food packaging legislation",
        "value": "65b91c6b06feb083f1b957a8"
      },
      {
        "label": "Headspace sorptive extraction for the analysis of organotin compounds using thermal desorption and gas chromatography with mass spectrometry",
        "value": "65b91cad06feb083f1b965ac"
      },
      {
        "label": "Estudio de migración de tintas de impresión presentes en vajilla desechable",
        "value": "65b91ca706feb083f1b96446"
      },
      {
        "label": "Migration of Acrylic Monomers from Methacrylate Polymers - Establishing Parameters for Migration Modelling",
        "value": "65b91d0006feb083f1b977a4"
      },
      {
        "label": "Greek Food Code, Article 28 - Coatings of surfaces intended to come into contact with food",
        "value": "65b9114c0729b5ae4f62a025"
      },
      {
        "label": "Migration of epoxidized soya bean oil from plasticized PVC gaskets into baby food",
        "value": "65b91e4506feb083f1b9bf75"
      },
      {
        "label": "CoE List for paper and board materials in food contact",
        "value": "65b9114d0729b5ae4f62a041"
      },
      {
        "label": "Di(2-ethylhexyl) adipate migration from PVC-cling film into packaged sea bream (Sparus aurata) and rainbow trout (Oncorhynchus mykiss) fillets: kinetic study and control of compliance with EU specifications",
        "value": "65b91c8306feb083f1b95ce0"
      },
      {
        "label": "Iron migration from undamaged and dented juice tinplate cans",
        "value": "65b91df006feb083f1b9adec"
      },
      {
        "label": "The Effect of Microwave on Migration of Styrene Monomer Polystyrene Food Packaging and Compared to other Thermal Processes",
        "value": "65b91c6e06feb083f1b95841"
      },
      {
        "label": "Use of Scanning Electron Microscopy with Energy Dispersive Spectroscopy to detect metallic contamination in candies",
        "value": "65b91ded06feb083f1b9ad29"
      },
      {
        "label": "Bio-based and compostable polyesters in food contact: analysis of monomers and (in)organic fillers",
        "value": "65b91c6906feb083f1b95721"
      },
      {
        "label": "Effect of temperature on the release of intentionally and non-intentionally added substances from polyethylene terephthalate (PET) bottles into water: chemical analysis and potential toxicity",
        "value": "65b91d8e06feb083f1b99855"
      },
      {
        "label": "California Prop 65 list",
        "value": "65b9114d0729b5ae4f62a077"
      },
      {
        "label": "Bisphenol A migration from plastic materials: direct insight of ecotoxicity in Daphnia magna",
        "value": "65b91e3f06feb083f1b9be07"
      },
      {
        "label": "Migration studies of 3-chloro-1,2-propanediol (3-MCPD) in polyethylene extrusion-coated paperboard food packaging",
        "value": "65b91e8a06feb083f1b9cd1b"
      },
      {
        "label": "Identification and Quantitation Studies of Migrants from BPA Alternative Food-Contact Metal Can Coatings",
        "value": "65b91d9906feb083f1b99ad8"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance ethylene glycol dipalmitate, CAS No 624-03-3, for use in food contact materials",
        "value": "65b91dc006feb083f1b9a3c0"
      },
      {
        "label": "Investigation into the Potential Migration of Nanoparticles from Laponite-Polymer Nanocomposites",
        "value": "65b91d9706feb083f1b99a6c"
      },
      {
        "label": "IDENTIFICATION OF VOLATILE COMPOUNDS RESULTING FROM THE THERMAL-OXIDATION OF POLYETHYLENE",
        "value": "65b91c6a06feb083f1b9576c"
      },
      {
        "label": "Determination of primary aromatic amines from cooking utensils by capillary electrophoresis-tandem mass spectrometry",
        "value": "65b91c7706feb083f1b95a3c"
      },
      {
        "label": "EuPIA 2019, List of photoinitiators for printing inks",
        "value": "65b9114d0729b5ae4f62a039"
      },
      {
        "label": "The behaviour of Tenax as food simulant in the migration of polymer additives from food contact materials by means of gas chromatography/mass spectrometry and PARAFAC",
        "value": "65b91c8106feb083f1b95c6f"
      },
      {
        "label": "Cationic gemini surfactant templated magnetic cubic mesoporous silica and its application in the magnetic dispersive solid phase extraction of endocrine-disrupting compounds from the migrants of food contact materials",
        "value": "65b91c8906feb083f1b95e36"
      },
      {
        "label": "Application of capillary electrophoresis in migration studies of food contact materials?part 1: Substituted phenolic additives",
        "value": "65b91c8606feb083f1b95d8f"
      },
      {
        "label": "Concentrations and trends of perfluorinated chemicals in potential indoor sources from 2007 through 2011 in the US",
        "value": "65b91d2c06feb083f1b981d2"
      },
      {
        "label": "Migration testing of metallized polypropylene films treated with ionizing radiation",
        "value": "65b91d2706feb083f1b98077"
      },
      {
        "label": "The specific diffusion behaviour in paper and migration modelling from recycled board into dry foodstuffs",
        "value": "65b91c7306feb083f1b95934"
      },
      {
        "label": "LC-MS/MS analysis of BADGE, NOGEs, and their derivatives migrated from food and beverage metal cans",
        "value": "65b91d1006feb083f1b97b1b"
      },
      {
        "label": "Assessment of bisphenol A alternatives in paper products from the Chinese market and their dermal exposure in the general population",
        "value": "65b91d0f06feb083f1b97acb"
      },
      {
        "label": "Effect of electron beam and gamma radiation on the migration of plasticizers from flexible food packaging materials into foods and food simulants",
        "value": "65b91c8306feb083f1b95cda"
      },
      {
        "label": "Correlation of specific migration (Cf) of plastics additives with their initial concentration in the polymer (Cp)",
        "value": "65b91c8206feb083f1b95ca1"
      },
      {
        "label": "Determination of migrants in and migration from nylon food packaging",
        "value": "65b91c9106feb083f1b95fd3"
      },
      {
        "label": "Substances considered, by the evaluating National Authority, to have endocrine disrupting properties",
        "value": "65b9114d0729b5ae4f62a063"
      },
      {
        "label": "Migration of cyclo-diBA from coatings into canned food: Method of analysis, concentration determined in a survey and in silico hazard profiling",
        "value": "65b91d2b06feb083f1b981a1"
      },
      {
        "label": "Estimation of Dietary Exposure to Contaminants Transferred from the Packaging in Fatty Dry Foods Based on Cereals",
        "value": "65b91c7606feb083f1b95a05"
      },
      {
        "label": "UNEP EDC report, 2018",
        "value": "65b9114d0729b5ae4f62a067"
      },
      {
        "label": "Silicon Migration from High-barrier Coated Multilayer Polymeric Films to Selected Food Simulants after Microwave Processing Treatments",
        "value": "65b91dee06feb083f1b9ad7a"
      },
      {
        "label": "Dutch Packagings and Consumer Articles Regulation",
        "value": "65b9114c0729b5ae4f62a019"
      },
      {
        "label": "Opinion of the Scientific Panel on food additives, flavourings, processing aids and materials in contact with food (AFC) on a request related to a 18th list of substances for food contact materials Question No EFSA-Q-2007-167, EFSA-Q-2006-177, EFSA-Q-2005-152, EFSA-Q-2007-022, EFSA-Q-2007-004, EFSA-Q-2007-024",
        "value": "65b91c6906feb083f1b95748"
      },
      {
        "label": "Reactivity of Cork Extracts with (+)-Catechin and Malvidin-3-O-glucoside in Wine Model Solutions: Identification of a New Family of Ellagitannin-Derived Compounds (Corklins)",
        "value": "65b91cbc06feb083f1b968c2"
      },
      {
        "label": "The Effect of Storage Time, Temperature and Type of Packaging on the Release of Phthalate Esters into PackedAcidic Liquids",
        "value": "65b91cc006feb083f1b969e6"
      },
      {
        "label": "The content of heavy metals in food packaging paper boards: an atomic absorption spectroscopy investigation",
        "value": "65b91d2406feb083f1b97fac"
      },
      {
        "label": "Safety assessment of commercial antimicrobial food packaging: Triclosan and microplastics, a closer look",
        "value": "65b91d6d06feb083f1b990a0"
      },
      {
        "label": "Safety by design of printed multilayer materials intended for food packaging",
        "value": "65b91c6906feb083f1b95737"
      },
      {
        "label": "Schreier, V and Geueke, B oligomer lists",
        "value": "65b9114e0729b5ae4f62a097"
      },
      {
        "label": "Safety assessment of the substance trimellitic acid, tris (2-ethylhexyl) ester, for use in food contact materials",
        "value": "65b91c8b06feb083f1b95eb2"
      },
      {
        "label": "Safety assessment of the substance 'Tungsten Oxide' for use in food contact materials",
        "value": "65b91d7e06feb083f1b99469"
      },
      {
        "label": "Method of test and survey of caprolactam migration into foods packaged in nylon-6",
        "value": "65b91c9106feb083f1b95fc4"
      },
      {
        "label": "Migration of chlorinated paraffins from plastic food packaging into food simulants: Concentrations and differences in congener profiles",
        "value": "65b91e6906feb083f1b9c5df"
      },
      {
        "label": "Rapid determination of phthalates in paper products by supercritical fluid chromatography/tandem mass spectrometry",
        "value": "65b91cc106feb083f1b96a13"
      },
      {
        "label": "Migration of conventional and new plasticizers from PVC films into food simulants: A comparative study",
        "value": "65b91c8406feb083f1b95d2a"
      },
      {
        "label": "Temperature-dependent leaching of chemical elements from mineral water bottle materials",
        "value": "65b91cfa06feb083f1b97634"
      },
      {
        "label": "Study on element migration from plastic food packagings to simulating solutions",
        "value": "65b91e0b06feb083f1b9b384"
      },
      {
        "label": "Target and non-target analysis of migrants from PVC-coated cans using UHPLC-Q-Orbitrap MS: evaluation of long-term migration testing",
        "value": "65b91c8106feb083f1b95c54"
      },
      {
        "label": "Effect of recycled content and rPET quality on the properties of PET bottles, part III: Modelling of repetitive recycling",
        "value": "65b91e3106feb083f1b9bb3c"
      },
      {
        "label": "Assurance of safety of recycled paperboard for food packaging through comprehensive analysis of potential migrants is unrealistic",
        "value": "65b91c6a06feb083f1b95778"
      },
      {
        "label": "A novel method for the determination of migration of contaminants from food contact materials",
        "value": "65b91e0b06feb083f1b9b378"
      },
      {
        "label": "Contamination of U.S. butter with polybrominated diphenyl ethers from wrapping paper",
        "value": "65b91cbe06feb083f1b96956"
      },
      {
        "label": "An investigation into the reaction and breakdown products from starting substances used to produce food contact plastics",
        "value": "65b91c6c06feb083f1b957f8"
      },
      {
        "label": "A FTIR METHOD FOR THE DETERMINATION OF TOTAL SPECIFIC MIGRATION OF AROMATIC PLASTICIZERS FROM POLY(VINYL CHLORIDE)",
        "value": "65b91cc206feb083f1b96a46"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, trimethyl trimellitate, CAS No. 2459-10-1, for use in food contact materials",
        "value": "65b91d4306feb083f1b9871a"
      },
      {
        "label": "Is container type the biggest predictor of trace element and BPA leaching from drinking water bottles?",
        "value": "65b91df006feb083f1b9adf6"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 3,9- bis 2-( 3-( 3- tert- butyl- 4- hydroxy- 5- methylphenyl) propionyloxy)- 1,1dimethylethyl - 2,4,8,10- tetraoxaspiro 5,5 undecane, CAS No. 90498- 90- 1, for use in food contact materials1",
        "value": "65b91e7606feb083f1b9c8aa"
      },
      {
        "label": "Novel silver-based nanoclay as an antimicrobial in polylactic acid food packaging coatings",
        "value": "65b91e0606feb083f1b9b267"
      },
      {
        "label": "Lead, cadmium and cobalt (Pb, Cd, and Co) leaching of glass-clay containers by pH effect of food",
        "value": "65b91df306feb083f1b9aea2"
      },
      {
        "label": "A survey of styrene monomer levels in foods and plastic packaging by coupled mass spectrometry--automatic headspace gas chromatography",
        "value": "65b91c7006feb083f1b958a5"
      },
      {
        "label": "Quantitative determinations of seven fluorescent whitening agents in polystyrene and polyvinyl chloride plastics by ultrahigh performance liquid chromatography-tandem mass spectrometry",
        "value": "65b91c8806feb083f1b95dea"
      },
      {
        "label": "IDENTIFICATION OF ODOR-ACTIVE COMPOUNDS RESULTING FROM THERMAL-OXIDATION OF POLYETHYLENE",
        "value": "65b91ce206feb083f1b97126"
      },
      {
        "label": "Migration of mineral oil from party plates of recycled paperboard into foods: 1. Is recycled paperboard fit for the purpose? 2. Adequate testing procedure",
        "value": "65b91d7b06feb083f1b993a3"
      },
      {
        "label": "Assessment of migration regularity of phthalates from food packaging materials",
        "value": "65b91e5a06feb083f1b9c33d"
      },
      {
        "label": "Migration of bisphenol A from polycarbonate baby bottles purchased in the Spanish market by liquid chromatography and fluorescence detection",
        "value": "65b91e3d06feb083f1b9bda9"
      },
      {
        "label": "Printing ink compounds in foods: UK survey results",
        "value": "65b91c7e06feb083f1b95bd0"
      },
      {
        "label": "Identification of odorous compounds in oak wood using odor extract dilution analysis and two-dimensional gas chromatography-mass spectrometry/olfactometry",
        "value": "65b91c7306feb083f1b9593c"
      },
      {
        "label": "Migration studies of chloropropanols from paper straws: an improved method using GC-MS/MS",
        "value": "65b91dbb06feb083f1b9a2a5"
      },
      {
        "label": "alpha-dimethyl-3-(4'-hydroxy-3'-methoxyphenyl)propylsilyloxy, omega-3-dimethyl-3-(4'-hydroxy-3'-methoxyphenyl) propylsilylpolydimethylsiloxane, n=26-50, CAS No. 156065-00-8, for use in food contact materials",
        "value": "65b91cd906feb083f1b96f5b"
      },
      {
        "label": "Migration of 2378-TCDD/2378-TCDF from paper based food packaging and food contact products",
        "value": "65b91d1c06feb083f1b97e08"
      },
      {
        "label": "Multiple headspace-solid-phase microextraction as a powerful tool for the quantitative determination of volatile radiolysis products in a multilayer food packaging material sterilized with gamma-radiation",
        "value": "65b91c7606feb083f1b959f0"
      },
      {
        "label": "Identificación de sustancias migrantes derivadas del envasado y cocinado de alimentos en bolsas de silicona",
        "value": "65b91c9a06feb083f1b96195"
      },
      {
        "label": "Simultaneous Determination of Melamine and Formaldehyde after Migration from Food Contact Materials",
        "value": "65b91c9f06feb083f1b962d5"
      },
      {
        "label": "Leaching of elements from packaging material into canned foods marketed in India",
        "value": "65b91dec06feb083f1b9ad0f"
      },
      {
        "label": "Identification of 24 Unknown Substances (NIAS/IAS) from Food Contact Polycarbonate by LC-Orbitrap Tribrid HRMS-DDMS3: Safety Assessment",
        "value": "65b91c8006feb083f1b95c3f"
      },
      {
        "label": "Migration of bisphenol-A diglycidyl ether (BADGE) and its reaction products in canned foods",
        "value": "65b91cfd06feb083f1b976dc"
      },
      {
        "label": "Safety assessment of the substance montmorillonite clay modified by dimethyldialkyl(C16-C18) ammonium chloride for use in food contact materials",
        "value": "65b91cb706feb083f1b967b8"
      },
      {
        "label": "ECHA list of plastic additives",
        "value": "65b9114d0729b5ae4f62a08b"
      },
      {
        "label": "Danish EPA hazard assessment",
        "value": "65b9114d0729b5ae4f62a07f"
      },
      {
        "label": "A new approach to the characterization of volatile signatures of cork wine stoppers",
        "value": "65b91cda06feb083f1b96f68"
      },
      {
        "label": "Norden 2015 List of food contact metals",
        "value": "65b9114d0729b5ae4f62a04f"
      },
      {
        "label": "Bisphenol A is released from polycarbonate drinking bottles and mimics the neurotoxic actions of estrogen in developing cerebellar neurons",
        "value": "65b91e4006feb083f1b9be39"
      },
      {
        "label": "Modelling aluminium leaching into food from different foodware materials with multi-level factorial design of experiments",
        "value": "65b91dee06feb083f1b9ad66"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, cyclic oligomers of (butylene terephthalate), CAS No. 263244-54-8, for use in food contact materials",
        "value": "65b91d4c06feb083f1b98928"
      },
      {
        "label": "Lead Migration from Ceramicware in Contact with Foodstuff: Effect of Glaze, Temperature, pH and Food Simulant",
        "value": "65b91df706feb083f1b9af7e"
      },
      {
        "label": "Antimony Assessment in PET Bottles for Soft Drink",
        "value": "65b91e0c06feb083f1b9b3b6"
      },
      {
        "label": "Headspace-GC determination of volatile organic compounds from the printed paper packaging materials",
        "value": "65b91ca606feb083f1b96403"
      },
      {
        "label": "Migration of styrene from plastic packaging based on polystyrene into food simulants",
        "value": "65b91c6e06feb083f1b9584a"
      },
      {
        "label": "High-performance liquid chromatography - Ultraviolet method for the determination of total specific migration of nine ultraviolet absorbers in food simulants based on 1,1,3,3-Tetramethylguanidine and organic phase anion exchange solid phase extraction to remove glyceride",
        "value": "65b91cf306feb083f1b97491"
      },
      {
        "label": "Migration of formaldehyde from melamine-ware: UK 2008 survey results",
        "value": "65b91d8d06feb083f1b99813"
      },
      {
        "label": "Headspace analysis of benzene in food contact materials and its migration into foods from plastics cookware",
        "value": "65b91c6c06feb083f1b957ea"
      },
      {
        "label": "Structure and Chemical Durability of Lead Crystal Glass",
        "value": "65b91df806feb083f1b9af93"
      },
      {
        "label": "Determination of toxic metal release from metallic kitchen utensils and their health risks",
        "value": "65b91df506feb083f1b9af1a"
      },
      {
        "label": "On-line ionic liquid-based preconcentration system coupled to flame atomic absorption spectrometry for trace cadmium determination in plastic food packaging materials",
        "value": "65b91e1306feb083f1b9b513"
      },
      {
        "label": "DETERMINATION OF VOLATILE CHEMICALS RELEASED FROM MICROWAVE-HEAT-SUSCEPTOR FOOD-PACKAGING",
        "value": "65b91c7306feb083f1b95928"
      },
      {
        "label": "An evaluation of lead concentrations in imported hot sauces",
        "value": "65b91df806feb083f1b9af88"
      },
      {
        "label": "Acetaidehyde migration from polyethylene terephthalate bottles into carbonated beverages in Turkiye",
        "value": "65b91e2706feb083f1b9b91c"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance adipic acid dihydrazide, CAS No 1071-93-8, for use in food contact materials EFSA Panel on Food Contact Materials, Enzymes, Flavourings and Processing Aids (CEF)",
        "value": "65b91c9906feb083f1b96151"
      },
      {
        "label": "Migration of styrene oligomers from food contact materials: in silico prediction of possible genotoxicity",
        "value": "65b91c9c06feb083f1b96215"
      },
      {
        "label": "Safety assessment of the substance 2,4,4 '-trifluorobenzophenone, for use in food contact materials",
        "value": "65b91d6f06feb083f1b990fc"
      },
      {
        "label": "Study on the leaching of phthalates from polyethylene terephthalate bottles into mineral water",
        "value": "65b91cc306feb083f1b96a99"
      },
      {
        "label": "Report of the EFSA Scientific Cooperation (ESCO) working group on non-plastic food contact materials",
        "value": "65b9114c0729b5ae4f62a02b"
      },
      {
        "label": "EFFECT OF ENVIRONMENTAL CONDITIONS ON THE MIGRATION OF DI(2-ETHYLHEXYL)PTHALATE FROM PET BOTTLES INTO YOGURT DRINKS: INFLUENCE OF TIME, TEMPERATURE, AND FOOD SIMULANT",
        "value": "65b91cc506feb083f1b96b0e"
      },
      {
        "label": "Migration Testing of GPPS and HIPS Polymers: Swelling Effect Caused by Food Simulants Compared to Real Foods",
        "value": "65b91c7006feb083f1b958a2"
      },
      {
        "label": "Bisphenol determination in UHT milk and packaging by paper spray ionization mass spectrometry",
        "value": "65b91e3d06feb083f1b9bd9b"
      },
      {
        "label": "Effect of detergents in the release of bisphenol A from polycarbonate baby bottles",
        "value": "65b91e3f06feb083f1b9be0f"
      },
      {
        "label": "Migration of Irganox 1010, Irganox 1076, and Titanium dioxide into Doogh and corresponding food simulant from laminated packaging",
        "value": "65b91cf806feb083f1b975cf"
      },
      {
        "label": "Identification of potential migrants from a vinylic organosol varnish by gas chromatography-mass spectrometry and liquid chromatography-mass spectrometry",
        "value": "65b91d1806feb083f1b97d08"
      },
      {
        "label": "CPDat: chemicals labeled in categories 'food contact' or 'packing'",
        "value": "65b9114c0729b5ae4f62a009"
      },
      {
        "label": "Influence of Storage Time and Temperature on the Toxicity, Endocrine Potential, and Migration of Epoxy Resin Precursors in Extracts of Food Packaging Materials",
        "value": "65b91cfd06feb083f1b976d8"
      },
      {
        "label": "Quantification of PET cyclic and linear oligomers in teabags by a validated LC-MS method - In silico toxicity assessment and consumer's exposure",
        "value": "65b91d1206feb083f1b97b7f"
      },
      {
        "label": "Migration of fluorinated Telomer alcohols (FTOH) from food contact materials into food at elevated temperatures",
        "value": "65b91dd306feb083f1b9a792"
      },
      {
        "label": "Hydrocarbons as Food Contaminants: Studies on the Migration of Mineral Oil and Synthetic Hydrocarbons from Food Contact Materials",
        "value": "65b91dcb06feb083f1b9a604"
      },
      {
        "label": "European legal limits for migration from food packaging materials: 1. Food should prevail over simulants; 2. More realistic conversion from concentrations to limits per surface area. PVC cling films in contact with cheese as an example",
        "value": "65b91c8306feb083f1b95cd3"
      },
      {
        "label": "Migration from plasticized films into foods. 2. Migration of di-(2-ethylhexyl)adipate from PVC films used for retail food packaging",
        "value": "65b91c8506feb083f1b95d2f"
      },
      {
        "label": "Analysis of potential migration compounds from silicone molds for food contact by SPME-GC-MS",
        "value": "65b91c9a06feb083f1b961a4"
      },
      {
        "label": "Metal release in a stainless steel Pulsed Electric Field (PEF) system Part I. Effect of different pulse shapes; theory and experimental method",
        "value": "65b91df006feb083f1b9adf9"
      },
      {
        "label": "A RAPID HPLC METHOD FOR THE QUANTITATIVE-DETERMINATION OF TETRAMETHYLAMMONIUM CHLORIDE IN SOME AQUEOUS-SOLUTIONS USED AS FOOD SIMULANTS",
        "value": "65b91e2a06feb083f1b9b9af"
      },
      {
        "label": "Quality and safety aspects of reusable plastic food packaging materials: a European study to underpin future legislation",
        "value": "65b91c6906feb083f1b95731"
      },
      {
        "label": "Determination of chromium, cadmium and lead in food-packaging materials by axial inductively coupled plasma time-of-flight mass spectrometry",
        "value": "65b91df406feb083f1b9aeba"
      },
      {
        "label": "Screening for mineral oil saturated and aromatic hydrocarbons in paper and cardboard directly by planar solid phase extraction and by its coupling to gas chromatography",
        "value": "65b91d4006feb083f1b98647"
      },
      {
        "label": "Tea brewed in traditional metallic teapots as a significant source of lead, nickel and other chemical elements",
        "value": "65b91def06feb083f1b9ada0"
      },
      {
        "label": "Detection and Identification of Oestrogen-Active Substances in Plastic Food Packaging Migrates",
        "value": "65b91c7006feb083f1b95891"
      },
      {
        "label": "Sensory analysis of polystyrene packaging material taint in cocoa powder for drinks and chocolate flakes",
        "value": "65b91c6f06feb083f1b9587b"
      },
      {
        "label": "Perfluoroalkyl and polyfluoroalkyl substances in consumer products",
        "value": "65b91d1f06feb083f1b97e7b"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 2,4-bis(2,4-dimethylphenyl)-6-(2-hydroxy-4-n-octyloxyphenyl)-1,3,5triazi ne, CAS No. 2725-22-6, for use in food contact materials",
        "value": "65b91d5206feb083f1b98a96"
      },
      {
        "label": "Oxygenated carbon nanotubes cages coated solid-phase microextraction fiber for selective extraction of migrated aromatic amines from food contact materials",
        "value": "65b91cf806feb083f1b975b7"
      },
      {
        "label": "An optimized method for the determination of perfluorooctanoic acid, perfluorooctane sulfonate and other perfluorochemicals in different matrices using liquid chromatography/ion-trap mass spectrometry",
        "value": "65b91d1f06feb083f1b97e7e"
      },
      {
        "label": "Effects of storage temperature and duration on release of antimony and bisphenol A from polyethylene terephthalate drinking water bottles of China",
        "value": "65b91e0d06feb083f1b9b3de"
      },
      {
        "label": "Phthalates in paper and board packaging and their migration into Tenax and sugar",
        "value": "65b91c8606feb083f1b95d67"
      },
      {
        "label": "Is comprehensive analysis of potentially relevant migrants from recycled paperboard into foods feasible?",
        "value": "65b91dce06feb083f1b9a6c4"
      },
      {
        "label": "Migration from ovenable boards at high temperatures",
        "value": "65b91c7306feb083f1b9594b"
      },
      {
        "label": "Migration levels of PVC plasticisers: Effect of ionising radiation treatment",
        "value": "65b91c8006feb083f1b95c34"
      },
      {
        "label": "Development and validation of an improved method for the determination of chloropropanols in paperboard food packaging by GC-MS",
        "value": "65b91e8a06feb083f1b9cd1f"
      },
      {
        "label": "Metal release from coffee machines and electric kettles",
        "value": "65b91dec06feb083f1b9ad22"
      },
      {
        "label": "Modelling migration from high-density polyethylene containers into concentrated solutions used as food flavourings",
        "value": "65b91c7a06feb083f1b95ace"
      },
      {
        "label": "Identification of Nonvolatile Migrates from Food Contact Materials Using Ion Mobility-High-Resolution Mass Spectrometry and in Silico Prediction Tools",
        "value": "65b91c8106feb083f1b95c67"
      },
      {
        "label": "Optimization and application of accelerated solvent extraction for rapid quantification of PCBs in food packaging materials using GC-ECD",
        "value": "65b91d6306feb083f1b98e79"
      },
      {
        "label": "Non-target and suspected-target screening for potentially hazardous chemicals in food contact materials: investigation of paper straws",
        "value": "65b91c7806feb083f1b95a92"
      },
      {
        "label": "Effects of microwave heating on the migration of substances from melamine formaldehyde tableware",
        "value": "65b91d8d06feb083f1b9981e"
      },
      {
        "label": "Mercosur 2015 List for cellulose-based food contact materials",
        "value": "65b9114d0729b5ae4f62a043"
      },
      {
        "label": "Magnetic solid-phase extraction based on magnetic zeolitic imazolate framework-8 coupled with high performance liquid chromatography for the determination of polymer additives in drinks and foods packed with plastic",
        "value": "65b91ccf06feb083f1b96d19"
      },
      {
        "label": "Migration from plasticized films into foods. 3. Migration of phthalate, sebacate, citrate and phosphate esters from films used for retail food packaging",
        "value": "65b91ca506feb083f1b963e0"
      },
      {
        "label": "Analysis of volatile oxidized oligomers from polyolefins by off-line normal phase high performance liquid chromatography and one-dimensional and comprehensive two-dimensional gas chromatography",
        "value": "65b91c6806feb083f1b95703"
      },
      {
        "label": "Analytical methods for the screening of potential volatile migrants from acrylic-base adhesives used in food-contact materials",
        "value": "65b91c7206feb083f1b9591c"
      },
      {
        "label": "DEHA-plasticized PVC for retail packaging of fresh meat",
        "value": "65b91c8206feb083f1b95c89"
      },
      {
        "label": "Japanese Food Sanitation Act",
        "value": "65b9114c0729b5ae4f62a023"
      },
      {
        "label": "The Use of DART-HRMS for the Rapid Identification Chemical Substances in Food Contact Materials",
        "value": "65b91c7706feb083f1b95a41"
      },
      {
        "label": "Migration of styrene monomer from polystyrene packaging materials into food simulants",
        "value": "65b91c6f06feb083f1b95871"
      },
      {
        "label": "Migration of toluene through different plastic laminated films into food simulants",
        "value": "65b91ca206feb083f1b9634a"
      },
      {
        "label": "Non-targeted multi-component analytical surveillance of plastic food contact materials: Identification of substances not included in EU positive lists and their risk assessment",
        "value": "65b91c7406feb083f1b95995"
      },
      {
        "label": "Chemical migration from silicones used in connection with food contact materials and articles",
        "value": "65b91c8a06feb083f1b95e79"
      },
      {
        "label": "Twenty bisphenol analogues in take-out polystyrene-made food containers: concentration levels, simulated migration, and risk evaluation",
        "value": "65b91cbd06feb083f1b96904"
      },
      {
        "label": "Non-visible print set-off of photoinitiators in food packaging: detection by ambient ionisation mass spectrometry",
        "value": "65b91c7f06feb083f1b95be3"
      },
      {
        "label": "The application of ion mobility time of flight mass spectrometry to elucidate neo-formed compounds derived from polyurethane adhesives used in champagne cork stoppers",
        "value": "65b91ce106feb083f1b970f4"
      },
      {
        "label": "Genotoxicity of styrene oligomers extracted from polystyrene intended for use in contact with food",
        "value": "65b91c9c06feb083f1b96204"
      },
      {
        "label": "Polyamid-Küchenutensilien: Kontakt mit heißen Lebensmitteln möglichst kurz halten",
        "value": "65b91d1106feb083f1b97b59"
      },
      {
        "label": "Direct screening of food packaging materials for post-polymerization residues, degradation products and additives by liquid extraction surface analysis nanoelectrospray mass spectrometry (LESA-nESI-MS)",
        "value": "65b91d4906feb083f1b9886b"
      },
      {
        "label": "Survey of six metal contaminants and impurities and eleven metals and alloy components released from stainless-steel sheets on the Chinese market",
        "value": "65b91dec06feb083f1b9acfb"
      },
      {
        "label": "Effect of gamma irradiation on caprolactam migration from multilayer polyamide 6 films into food simulants: development and validation of a gas chromatographic method",
        "value": "65b91c9106feb083f1b95fb3"
      },
      {
        "label": "Exposure to bisphenol A and its substitutes, bisphenol F and bisphenol S from canned foods and beverages on Chinese market",
        "value": "65b91dbe06feb083f1b9a351"
      },
      {
        "label": "Commercial biodegradable material for food contact: methodology for assessment of service life",
        "value": "65b91d8f06feb083f1b99865"
      },
      {
        "label": "Investigation on mercury migration discipline in different paper-plastic food packaging containers",
        "value": "65b91dfc06feb083f1b9b079"
      },
      {
        "label": "UV photocrosslinked polymeric fluorescent sensor for bisphenol A",
        "value": "65b91e3c06feb083f1b9bd84"
      },
      {
        "label": "Increased migration levels of bisphenol A from polycarbonate baby bottles after dishwashing, boiling and brushing",
        "value": "65b91e4206feb083f1b9bee4"
      },
      {
        "label": "Migration of dioctyladipate plasticizer from food-grade WC film into chicken meat products: Effect of gamma-radiation",
        "value": "65b91cdf06feb083f1b97095"
      },
      {
        "label": "Determination of potential migrants present in Nylon 'microwave and roasting bags' and migration into olive oil",
        "value": "65b91c8f06feb083f1b95f71"
      },
      {
        "label": "Release of bisphenol A from food can lining upon heating",
        "value": "65b91e3c06feb083f1b9bd87"
      },
      {
        "label": "Detection of primary aromatic amines content in food packaging ink and migration from printed plastic bags",
        "value": "65b91c7506feb083f1b959ac"
      },
      {
        "label": "Interaction between aluminium cans and beverages: Influence of catalytic ions, alloy and coating in the corrosion process",
        "value": "65b91deb06feb083f1b9ace7"
      },
      {
        "label": "Study of the migration behavior of acetyl tributyl citrate from PVDC/PVC film into fish fillets as affected by intermediate doses of electron beam radiation",
        "value": "65b91e3206feb083f1b9bb5b"
      },
      {
        "label": "Microwave induced plasma optical emission spectrometry (MIP OES) and laser-induced breakdown spectroscopy (LIBS) for multi-element determination and location in ceramic tableware",
        "value": "65b91dec06feb083f1b9ad13"
      },
      {
        "label": "Migration of lead and arsenic from food contact paper into a food simulant and assessment of their consumer exposure safety",
        "value": "65b91df506feb083f1b9aeeb"
      },
      {
        "label": "Characterisation of flavour compounds formed by gamma-irradiation of polypropylene",
        "value": "65b91c8706feb083f1b95dd0"
      },
      {
        "label": "Static headspace gas chromatography of acetaldehyde in aqueous foods and polythene terephthalate",
        "value": "65b91e2706feb083f1b9b929"
      },
      {
        "label": "Migration of di-(2-ethylhexyl) adipate (DEHA) and acetyl tributyl citrate (ATBC) plasticizers from PVC film into the food stimulant of isooctane",
        "value": "65b91c8206feb083f1b95cbc"
      },
      {
        "label": "Migration of formaldehyde and melamine from melaware and other amino resin tableware in real life service",
        "value": "65b91c9e06feb083f1b962a1"
      },
      {
        "label": "Volatile non-intentionally added substances (NIAS) identified in recycled expanded polystyrene containers and their migration into food simulants",
        "value": "65b91c6906feb083f1b95754"
      },
      {
        "label": "Monitoring of polymer type and plastic additives in coating film of beer cans from 16 countries",
        "value": "65b91cc206feb083f1b96a23"
      },
      {
        "label": "Potential EDC according to TEDX",
        "value": "65b9114d0729b5ae4f62a083"
      },
      {
        "label": "Identification of substances migrating from plastic baby bottles using a combination of low-resolution and high-resolution mass spectrometric analysers coupled to gas and liquid chromatography",
        "value": "65b91ce806feb083f1b9724e"
      },
      {
        "label": "Formaldehyde and heavy metal migration from rubber and metallic packaging/utensils in Korea",
        "value": "65b91d8e06feb083f1b99831"
      },
      {
        "label": "Occurrence of 30 trace elements in foods from a multi-centre Sub-Saharan Africa Total Diet Study: Focus on Al, As, Cd, Hg, and Pb",
        "value": "65b91ded06feb083f1b9ad42"
      },
      {
        "label": "Evaluation of Long-Term Migration Testing from Can Coatings into Food Simulants: Polyester Coatings",
        "value": "65b91c6906feb083f1b95712"
      },
      {
        "label": "Mathematical modelling of migration of volatile compounds into packaged food via package free space. Part I: Cylindrical shaped food",
        "value": "65b91c7206feb083f1b95921"
      },
      {
        "label": "Gefäße aus Melamin-Formaldehyd-Harz wie \"Coffee to go\" Becher aus \"Bambusware\" können gesundheitlich bedenkliche Stoffe in heiße Lebensmittel abgeben",
        "value": "65b91c9e06feb083f1b9629a"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, 2,4,8,10-tetraoxaspiro 5.5 undecane-3,9-diethanol, beta 3, beta 3, beta 9, beta 9-tetramethyl-, CAS No 1455-42-1, for use in food contact materials",
        "value": "65b91d0806feb083f1b97919"
      },
      {
        "label": "Development and validation of a LC-MS/MS method for the analysis of bisphenol a in polyethylene terephthalate",
        "value": "65b91e4206feb083f1b9bebd"
      },
      {
        "label": "Migration of bisphenol A from can coatings to liquid infant formula during storage at room temperature",
        "value": "65b91e4206feb083f1b9bed8"
      },
      {
        "label": "Comparative study of ink photoinitiators in food packages using gas chromatography with vacuum ultraviolet detection and gas chromatography with mass spectrometry",
        "value": "65b91c7d06feb083f1b95b9b"
      },
      {
        "label": "Ubiquitous Occurrence of Fluorotelomer Alcohols in Eco-Friendly Paper-Made Food-Contact Materials and Their Implication for Human Exposure",
        "value": "65b91d1506feb083f1b97c45"
      },
      {
        "label": "Comparison of the test conditions of China and the Council of Europe on the release levels of metals from stainless-steel products from the Chinese market",
        "value": "65b91deb06feb083f1b9acc5"
      },
      {
        "label": "Identification and quantification of odorous compounds from adhesives used in food packaging materials by headspace solid phase extraction and headspace solid phase microextraction coupled to gas chromatography-olfactometry-mass spectrometry",
        "value": "65b91c6d06feb083f1b95817"
      },
      {
        "label": "23rd list of substances for food contact materials Scientific Opinion of the Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91c7f06feb083f1b95bf0"
      },
      {
        "label": "Migration of 18 trace elements from ceramic food contact material: Influence of pigment, pH, nature of acid and temperature",
        "value": "65b91dee06feb083f1b9ad7d"
      },
      {
        "label": "Empirical models to predict the effect of sterilisation and storage on bisphenols migration from metallic can coatings into food simulants",
        "value": "65b91d1806feb083f1b97d04"
      },
      {
        "label": "Thermal stability of polyethylene terephthalate (PET): Oligomer distribution and formation of volatiles",
        "value": "65b91c6906feb083f1b95734"
      },
      {
        "label": "High levels of migratable lead and cadmium on decorated drinking glassware",
        "value": "65b91df406feb083f1b9aeab"
      },
      {
        "label": "Solid-Phase Microextraction Coupled to Fast Gas Chromatography for the Determination of Migrants from Polystyrene-Packaging Materials into Yoghurt",
        "value": "65b91c6a06feb083f1b957a1"
      },
      {
        "label": "EURL-FCM-02-2016 proficiency test report : temperature control during migration test and quantification of migrated FCM No 500 by article filling",
        "value": "65b91de306feb083f1b9ab00"
      },
      {
        "label": "Physicochemical Processes Involved in Migration of Bisphenol A from Polycarbonate",
        "value": "65b91e3e06feb083f1b9bdfb"
      },
      {
        "label": "Safety assessment of the substance phosphoric acid, mixed esters with 2-hydroxyethyl methacrylate, for use in food contact materials",
        "value": "65b91d9506feb083f1b99a01"
      },
      {
        "label": "Migration of volatile degradation products into ozonated water from plastic packaging materials",
        "value": "65b91c9406feb083f1b96059"
      },
      {
        "label": "Determination of Bisphenol A in Paper Products by Synchronous Fluorescence Spectroscopy and Estimation of Daily Exposure",
        "value": "65b91e4106feb083f1b9be99"
      },
      {
        "label": "Assessment of silver release and biocidal capacity from silver nanocomposite food packaging materials",
        "value": "65b91d0806feb083f1b97944"
      },
      {
        "label": "Migration of formaldehyde from 'biobased' bamboo/melamine cups: A Dutch retail survey",
        "value": "65b91d8e06feb083f1b99849"
      },
      {
        "label": "Temperature and pH affect copper release kinetics from copper metal foil and commercial copperware to food simulants",
        "value": "65b91e1c06feb083f1b9b6bc"
      },
      {
        "label": "Occurrence of 2-ethylhexanoic acid in foods packed in glass jars",
        "value": "65b91d0b06feb083f1b979f7"
      },
      {
        "label": "USA FDA Inventory of Effective Food Contact Substance (FCS) Notifications (FCN)",
        "value": "65b9114c0729b5ae4f62a00f"
      },
      {
        "label": "Parts-per-trillion determination of styrene in yoghurt by purge-and-trap gas chromatography with mass spectrometry detection",
        "value": "65b91c6a06feb083f1b95781"
      },
      {
        "label": "Qualitative Analysis of Additives in Plastic Marine Debris and Its New Products",
        "value": "65b91c8806feb083f1b95e19"
      },
      {
        "label": "Optimization of pressurized liquid extraction (PLE) for rapid determination of mineral oil saturated (MOSH) and aromatic hydrocarbons (MOAH) in cardboard and paper intended for food contact",
        "value": "65b91d0806feb083f1b97928"
      },
      {
        "label": "Determination of volatile organic compounds (VOCs) from wrapping films and wrapped PDO Italian cheeses by using HS-SPME and GC/MS",
        "value": "65b91c6b06feb083f1b957b0"
      },
      {
        "label": "Identification of Volatile and Semi-Volatile Compounds in Polymeric Coatings Used in Metal Cans by GC-MS and SPME",
        "value": "65b91c7106feb083f1b958e8"
      },
      {
        "label": "Determination of Toluene and Other Residual Solvents in Various Food Packaging Materials by Gas Chromatography/Mass Spectrometry (GC/MS)",
        "value": "65b91ca006feb083f1b962fe"
      },
      {
        "label": "An attempt to estimate service terms of tableware made of amino resins",
        "value": "65b91d8d06feb083f1b9981a"
      },
      {
        "label": "Migration of volatile compounds from natural biomaterials and their safety evaluation as food contact materials",
        "value": "65b91c6a06feb083f1b9577b"
      },
      {
        "label": "A polypyrrole-chitosan cryogel stir-bead micro-solid phase extractor for the determination of phthalate esters in contact lenses storage solutions and in artificial saliva in contact with baby teethers",
        "value": "65b91cc206feb083f1b96a52"
      },
      {
        "label": "Phthalates and bisphenols migration in Mexican food cans and plastic food containers",
        "value": "65b91cc406feb083f1b96ad2"
      },
      {
        "label": "Identification of unknown compounds from polyester cans coatings that may potentially migrate into food or food simulants",
        "value": "65b91e0106feb083f1b9b17a"
      },
      {
        "label": "Cytotoxicological safety assessment of papers and boards used for food packaging",
        "value": "65b91c9906feb083f1b96171"
      },
      {
        "label": "Migration studies and chemical characterization of short chain cyclic polyester oligomers from food packaging laminate adhesives",
        "value": "65b91cd706feb083f1b96eca"
      },
      {
        "label": "CoE List for coatings in food contact",
        "value": "65b9114c0729b5ae4f62a027"
      },
      {
        "label": "PBT/vPvB status of the substances in the EU REACH Candidate List",
        "value": "65b9114d0729b5ae4f62a06b"
      },
      {
        "label": "Qualitative and quantitative analysis of monomers in polyesters for food contact materials",
        "value": "65b91c6906feb083f1b9573e"
      },
      {
        "label": "Development of a multimethod for the determination of photoinitiators in beverage packaging",
        "value": "65b91cd006feb083f1b96d67"
      },
      {
        "label": "Transfer of primary aromatic amines from coloured paper napkins into four different food matrices and into cold water extracts",
        "value": "65b91c7706feb083f1b95a4a"
      },
      {
        "label": "Occurrence, migration and health risk of phthalates in tap water, barreled water and bottled water in Tianjin, China",
        "value": "65b91cc006feb083f1b969a9"
      },
      {
        "label": "Survey of heavy metal contamination in recycled polyethylene terephthalate used for food packaging",
        "value": "65b91df306feb083f1b9ae8e"
      },
      {
        "label": "PVC cling film in contact with cheese: health aspects related to global migration and specific migration of DEHA",
        "value": "65b91c8106feb083f1b95c86"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 5-chloro-2-methyl-2H-isothiazol-3-one, mixture with 2-methyl-2Hisothiazol- 3-one (3: 1), CAS No. 55965-84-9, as a biocide for processing coatings and paper and boards",
        "value": "65b91d4c06feb083f1b98914"
      },
      {
        "label": "Determination of non-volatile radiolytic compounds in ethylene co-vinyl alcohol",
        "value": "65b91c9806feb083f1b96125"
      },
      {
        "label": "Epoxidized soy bean oil migrating from the gaskets of lids into food packed in glass jars. Analysis by on-line liquid chromatography-gas chromatography",
        "value": "65b91e4506feb083f1b9bf81"
      },
      {
        "label": "GC-MS Screening Analysis for the Identification of Potential Migrants in Plastic and Paper-Based Candy Wrappers",
        "value": "65b91c7c06feb083f1b95b65"
      },
      {
        "label": "The Effect of High-Pressure Processing on Tray Packages with Modified Atmosphere",
        "value": "65b91c8f06feb083f1b95f69"
      },
      {
        "label": "Safety assessment of diethyl[[3,5-bis(1,1-dimethylethyl)-4-hydroxyphenyl]methyl] phosphonate for use in a food contact material",
        "value": "65b91e8f06feb083f1b9ce11"
      },
      {
        "label": "Simplified sample preparation method for triclosan and methyltriclosan determination in biota and foodstuff samples",
        "value": "65b91d6d06feb083f1b990a4"
      },
      {
        "label": "Identification and quantification of (polymeric) hindered-amine light stabilizers in polymers using pyrolysis-gas chromatography-mass spectrometry and liquid chromatography-ultraviolet absorbance detection-evaporative light scattering detection",
        "value": "65b91d2206feb083f1b97f64"
      },
      {
        "label": "Corrosion behaviour of tinplate cans in contact with tomato puree and protective (inhibiting) substances",
        "value": "65b91df106feb083f1b9ae1a"
      },
      {
        "label": "Global and specific migration of antioxidants from polypropylene films into food simulants",
        "value": "65b91d3006feb083f1b9829e"
      },
      {
        "label": "Determination of siloxanes in silicone products and potential migration to milk, formula and liquid simulants",
        "value": "65b91c9a06feb083f1b961ac"
      },
      {
        "label": "Determination of melamine in food contact materials using an electrode modified with gold nanoparticles and reduced graphene oxide",
        "value": "65b91c9f06feb083f1b962d2"
      },
      {
        "label": "Partition and diffusion of volatile compounds from acrylic adhesives used for food packaging multilayers manufacturing",
        "value": "65b91c7306feb083f1b95946"
      },
      {
        "label": "Migration of di(2-ethylhexyl)phthalate (DEHP) and di-n-butylphthalate (DBP) from polypropylene food containers",
        "value": "65b91cc506feb083f1b96b19"
      },
      {
        "label": "EU list for food contact plastics",
        "value": "65b9114c0729b5ae4f62a015"
      },
      {
        "label": "European Commission's Register of substances for which a valid application for authorisation was submitted",
        "value": "65b9114d0729b5ae4f62a055"
      },
      {
        "label": "An Insight into the Growing Concerns of Styrene Monomer and Poly(Styrene) Fragment Migration into Food and Drink Simulants from Poly(Styrene) Packaging",
        "value": "65b91c7106feb083f1b958d6"
      },
      {
        "label": "Migration of mineral oil aromatic hydrocarbon (MOAH) from hot melt adhesives used in food packaging materials",
        "value": "65b91cae06feb083f1b965e0"
      },
      {
        "label": "Determination of radiolysis products in gamma-irradiated multilayer barrier food packaging films containing a middle layer of recycled LDPE",
        "value": "65b91ca606feb083f1b9642e"
      },
      {
        "label": "A Fast and Automated Strategy for the Identification and Risk Assessment of Unknown Substances (IAS/NIAS) in Plastic Food Contact Materials by GC-Q-Orbitrap HRMS: Recycled LDPE as a Proof-of-Concept",
        "value": "65b91c7a06feb083f1b95acb"
      },
      {
        "label": "Screening of chemicals migrating from plastic food contact materials for oven and microwave applications by liquid and gas chromatography - Orbitrap mass spectrometry",
        "value": "65b91c7a06feb083f1b95af4"
      },
      {
        "label": "Occurrence of 2,2,4-trimethyl-1,3-pentanediol monoisobutyrate (Texanol) in foods packed in polystyrene and polypropylene cups",
        "value": "65b91d2306feb083f1b97f93"
      },
      {
        "label": "Analysis and quantitative estimation of phenolic antioxidants in polypropylene packaging for fat products",
        "value": "65b91d6606feb083f1b98f0a"
      },
      {
        "label": "Metals in paints on chopsticks: Solubilization in simulated saliva, gastric, and food solutions and implication for human health",
        "value": "65b91df306feb083f1b9ae83"
      },
      {
        "label": "Determination of phthalates in food packing materials by electrokinetic chromatography with polymeric pseudostationary phase",
        "value": "65b91cc206feb083f1b96a29"
      },
      {
        "label": "Does the reuse of PET bottles during solar water disinfection pose a health risk due to the migration of plasticisers and other chemicals into the water?",
        "value": "65b91c8106feb083f1b95c6a"
      },
      {
        "label": "Combined GC and sniffing port analysis of volatile compounds in rubber rings mounted on beer bottles",
        "value": "65b91c7306feb083f1b95951"
      },
      {
        "label": "ChemSec SIN list",
        "value": "65b9114d0729b5ae4f62a087"
      },
      {
        "label": "Migration regularity of phthalates in polyethylene wrap film of food packaging",
        "value": "65b91cc206feb083f1b96a5f"
      },
      {
        "label": "Chinese Cooking with Ionic Seasonings May Enhance Migration of Perfluorooctanic acid from Food Contact Articles",
        "value": "65b91d6b06feb083f1b9902b"
      },
      {
        "label": "Fast sequential determination of antimony and lead in pewter alloys using high-resolution continuum source flame atomic absorption spectrometry",
        "value": "65b91df706feb083f1b9af67"
      },
      {
        "label": "High-resolution mass spectrometry with data independent acquisition for the comprehensive non-targeted analysis of migrating chemicals coming from multilayer plastic packaging materials used for fruit puree and juice",
        "value": "65b91c9006feb083f1b95fa5"
      },
      {
        "label": "Optimization of the post-acquisition data processing for the non-targeted screening of trace leachable residues from reusable plastic bottles by high performance liquid chromatography coupled to hybrid quadrupole time of flight mass spectrometry",
        "value": "65b91cbd06feb083f1b9690e"
      },
      {
        "label": "Application of radiometric method for element migration determination from plastic packaging to food",
        "value": "65b91e0b06feb083f1b9b387"
      },
      {
        "label": "Potential exposure to bisphenol A from food-contact use of polycarbonate resins",
        "value": "65b91e4106feb083f1b9be79"
      },
      {
        "label": "Intralaboratory validation of an analytical method for determining the migration of bis(2-ethylhexyl) adipate from packaging to fat foods",
        "value": "65b91c8506feb083f1b95d55"
      },
      {
        "label": "Esters of Phthalic Acid in Food and Packaging from the Market",
        "value": "65b91cc406feb083f1b96aa8"
      },
      {
        "label": "Determination of odour-causing volatile organic compounds in cork stoppers by multiple headspace solid-phase microextraction",
        "value": "65b91d2106feb083f1b97f04"
      },
      {
        "label": "Migration of bisphenol A from polycarbonate baby and water bottles into water under severe conditions",
        "value": "65b91e4206feb083f1b9bedb"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, furan-2,5-dicarboxylic acid, CAS No 3238-40-2, for use in food contact materials",
        "value": "65b91d6706feb083f1b98f45"
      },
      {
        "label": "Detection of tetramethylsuccinonitrile in food packaging containers of plastics",
        "value": "65b91d6906feb083f1b98fc1"
      },
      {
        "label": "Analysis and migration of phthalates in infant food packed in recycled paperboard",
        "value": "65b91cc406feb083f1b96ad9"
      },
      {
        "label": "ECHA 2021 Group assessment of bisphenols",
        "value": "65b9114e0729b5ae4f62a099"
      },
      {
        "label": "Levels of bisphenol-A in different paper products in Guangzhou, China, and assessment of human exposure via dermal contact",
        "value": "65b91e4206feb083f1b9beb1"
      },
      {
        "label": "Report of an interlaboratory comparison organised by the European Reference Laboratory for Food Contact Materials : Formaldehyde in 3% acetic acid migration solution -follow up exercise",
        "value": "65b91d8e06feb083f1b9984f"
      },
      {
        "label": "Migration Of Dipropylene And Tripropylene Glycol Diacrylate From Packaging Materials And Screening Of Potential Risks For Paper Packagings.pdf",
        "value": "65b91c7c06feb083f1b95b4f"
      },
      {
        "label": "Rapid multi-analyte quantification of benzophenone, 4-methylbenzophenone and related derivatives from paperboard food packaging",
        "value": "65b91cbc06feb083f1b968dc"
      },
      {
        "label": "Migration Detection of Residual Solvents from Composite Packaging Film to Food by GC-Ms",
        "value": "65b91ca206feb083f1b96341"
      },
      {
        "label": "Migration of contaminants by gas phase transfer from carton board and corrugated board box secondary packaging into foods",
        "value": "65b91cd206feb083f1b96dc2"
      },
      {
        "label": "Survey on the occurrence of photo-initiators and amine synergists in cartonboard packaging on the German market and their migration into the packaged foodstuffs",
        "value": "65b91c7e06feb083f1b95bb2"
      },
      {
        "label": "Rapid determination of methanol content in paper materials by alkaline extraction, coupled with headspace analysis",
        "value": "65b91dd906feb083f1b9a8e5"
      },
      {
        "label": "MIGRATION OF ACRYLONITRILE FROM STYRENE ACRYLONITRILE COPOLYMERS INTO FOOD-SIMULATING LIQUIDS",
        "value": "65b91c9806feb083f1b96149"
      },
      {
        "label": "Acrylonitrile in food contact materials-two different legislative approaches: comparison of direct determination with indirect evaluation using migration into food simulants",
        "value": "65b91c9806feb083f1b96142"
      },
      {
        "label": "Occurrence and migration of phthalates in adhesive materials to fruits and vegetables",
        "value": "65b91cc406feb083f1b96ab5"
      },
      {
        "label": "CEPE list of dual use substances (monomer/additive) used in food contact coatings",
        "value": "65b9114c0729b5ae4f62a029"
      },
      {
        "label": "ICP OES Determination of Contaminant Elements Leached from Food Packaging Films",
        "value": "65b91df706feb083f1b9af5b"
      },
      {
        "label": "Evaluation of release of antioxidant from high density polyethylene by planar chromatography",
        "value": "65b91d1906feb083f1b97d5f"
      },
      {
        "label": "Analysis of acrylonitrile, 1,3-butadiene, and related compounds in acrylonitrile-butadiene-styrene copolymers for kitchen utensils and children's toys by headspace gas chromatography/mass spectrometry",
        "value": "65b91c6a06feb083f1b9578b"
      },
      {
        "label": "Porous Aromatic Framework 48/Gel Hybrid Material Coated Solid-Phase Microextraction Fiber for the Determination of the Migration of Styrene from Polystyrene Food Contact Materials",
        "value": "65b91c6c06feb083f1b957e4"
      },
      {
        "label": "Suspect Screening of Chemicals in Food Packaging Plastic Film by Comprehensive Two-Dimensional Gas Chromatography Coupled to Time-of-Flight Mass Spectrometry",
        "value": "65b91c7106feb083f1b958e2"
      },
      {
        "label": "PAHs in polystyrene food contact materials: An unintended consequence",
        "value": "65b91cd606feb083f1b96e7c"
      },
      {
        "label": "Simultaneous screening and determination eight phthalates in plastic products for food use by sonication-assisted extraction/GC-MS methods",
        "value": "65b91cc006feb083f1b969da"
      },
      {
        "label": "The effect of fat contents and conditions of contact in actual use on styrene monomer migrated from general-purpose polystyrene into selected fatty dishes and beverage",
        "value": "65b91c6f06feb083f1b95867"
      },
      {
        "label": "Detection of aluminium residue in sauces packaged in aluminium pouches",
        "value": "65b91ded06feb083f1b9ad3f"
      },
      {
        "label": "Determination of non-volatile components of a biodegradable food packaging material based on polyester and polylactic acid (PLA) and its migration to food simulants",
        "value": "65b91c9706feb083f1b96112"
      },
      {
        "label": "Dialkylketones in Paperboard Food Contact Materials-Method of Analysis in Fatty Foods and Comparative Migration into Liquid Simulants Versus Foodstuffs",
        "value": "65b91d8f06feb083f1b9988c"
      },
      {
        "label": "Buntbedruckte Bäckertüten, Servietten & Co. können gesundheitsgefährdende Stoffe freisetzen",
        "value": "65b91d2b06feb083f1b9817b"
      },
      {
        "label": "Determination of migration of n-butyltins and n-octyltins to food simulants by gas chromatography-mass spectrometry",
        "value": "65b91cad06feb083f1b965a7"
      },
      {
        "label": "Migration modelling of phthalate from non-alcoholic beer bottles by adaptive neuro-fuzzy inference system",
        "value": "65b91cc106feb083f1b969f0"
      },
      {
        "label": "MIGRATION OF AN EPOXY ADHESIVE COMPOUND INTO A FOOD-SIMULATING LIQUID AND FOOD FROM MICROWAVE SUSCEPTOR PACKAGING",
        "value": "65b91d1806feb083f1b97d12"
      },
      {
        "label": "Migration of bisphenol A into canned tomatoes produced in Italy: Dependence on temperature and storage conditions",
        "value": "65b91e4206feb083f1b9beb4"
      },
      {
        "label": "Migration studies and chemical characterization of low molecular weight cyclic polyester oligomers from food packaging lamination adhesives",
        "value": "65b91cf706feb083f1b97583"
      },
      {
        "label": "Bisphenol s, a new bisphenol analogue, in paper products and currency bills and its association with bisphenol a residues",
        "value": "65b91e4406feb083f1b9bf42"
      },
      {
        "label": "Migration of toxic metals from ceramic food packaging materials into acid food simulants",
        "value": "65b91df706feb083f1b9af61"
      },
      {
        "label": "Disposable optical sensor for Al(III) ions determination by coupled colorimetric solid-phase extraction-reflectance spectroscopy in leachates from cookware, antacids and hygienic care products",
        "value": "65b91def06feb083f1b9ad95"
      },
      {
        "label": "Phthalates leaching from plastic food and pharmaceutical contact materials by FTIR and GC-MS",
        "value": "65b91cc806feb083f1b96bbb"
      },
      {
        "label": "Rapid and sensitive solid phase extraction-large volume injection-gas chromatography for the analysis of mineral oil saturated and aromatic hydrocarbons in cardboard and dried foods",
        "value": "65b91d7a06feb083f1b99385"
      },
      {
        "label": "Progress in mass spectrometry for the analysis of set-off phenomena in plastic food packaging materials",
        "value": "65b91c9706feb083f1b96109"
      },
      {
        "label": "Chemometric tools to highlight non-intentionally added substances (NIAS) in polyethylene terephthalate (PET)",
        "value": "65b91c6b06feb083f1b957e1"
      },
      {
        "label": "Risk assessment derived from migrants identified in several adhesives commonly used in food contact materials",
        "value": "65b91c7106feb083f1b958cf"
      },
      {
        "label": "Aldehyde contamination of mineral water stored in PET bottles",
        "value": "65b91d8e06feb083f1b99842"
      },
      {
        "label": "Determination of phthalate acid esters plasticizers in polyethylene terephthalate bottles and its correlation with some physicochemical properties",
        "value": "65b91cee06feb083f1b973a3"
      },
      {
        "label": "Occurrence of extractable ink residuals in packaging materials used in the Czech Republic",
        "value": "65b91c7106feb083f1b958c1"
      },
      {
        "label": "Determination of BFRs in food contact articles: An analytical approach using DART-HRMS, XFR and HPLC-MS/MS",
        "value": "65b91cbf06feb083f1b96968"
      },
      {
        "label": "Alternative fatty food simulants and diffusion kinetics of nylon 12 food packaging",
        "value": "65b91c8f06feb083f1b95f66"
      },
      {
        "label": "Isolation and identification of some unknown substances in disposable nitrile-butadiene rubber gloves used for food handling",
        "value": "65b91cc206feb083f1b96a3b"
      },
      {
        "label": "Optimization of a headspace solid-phase microextraction-gas chromatography-mass spectrometry procedure for odor compounds from polyolefin resin used in plastic food packaging",
        "value": "65b91c6a06feb083f1b9575b"
      },
      {
        "label": "Determination of suspected endocrine disruptors in foods and food packaging",
        "value": "65b91c8206feb083f1b95cb2"
      },
      {
        "label": "Long-term wear effects on nanosilver release from commercially available food contact materials",
        "value": "65b91e0406feb083f1b9b223"
      },
      {
        "label": "Migration of metals from ceramic food contact materials. 1: Effects of pH, temperature, food simulant, contact duration and repeated-use",
        "value": "65b91ded06feb083f1b9ad36"
      },
      {
        "label": "Ambient ionization-accurate mass spectrometry (AMI-AMS) for the identification of nonvisible set-off in food-contact materials",
        "value": "65b91c7406feb083f1b9598f"
      },
      {
        "label": "Improved method for rapid detection of phthalates in bottled water by gas chromatography-mass spectrometry",
        "value": "65b91cc106feb083f1b96a16"
      },
      {
        "label": "Oligomers: Hidden sources of bisphenol A from reusable food contact materials",
        "value": "65b91cf706feb083f1b9758b"
      },
      {
        "label": "Migration of di-(2-ethylhexylexyl)Adipate Plasticizer from Food-Grade Polyvinyl Chloride Film into Hard and Soft Cheeses",
        "value": "65b91c8306feb083f1b95ce3"
      },
      {
        "label": "Targeted and untargeted data-dependent experiments for characterization of polycarbonate food-contact plastics by ultra high performance chromatography coupled to quadrupole orbitrap tandem mass spectrometry",
        "value": "65b91cd006feb083f1b96d3a"
      },
      {
        "label": "Leaching behavior and evaluation of zebrafish embryo toxicity of microplastics and phthalates in take-away plastic containers",
        "value": "65b91cbf06feb083f1b96998"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substances, kaolin and polyacrylic acid, sodium salt, for use in food contact materials",
        "value": "65b91cf706feb083f1b97587"
      },
      {
        "label": "Electro-enhanced solid-phase microextraction with covalent organic framework modified stainless steel fiber for efficient adsorption of bisphenol A",
        "value": "65b91e3e06feb083f1b9bdd3"
      },
      {
        "label": "Migration of 17 Photoinitiators from Printing Inks and Cardboard into Packaged Food – Results of a Belgian Market Survey",
        "value": "65b9114e0729b5ae4f62a09f"
      },
      {
        "label": "Analytical protocol to study the food safety of (multiple-)recycled high-density polyethylene (HDPE) and polypropylene (PP) crates: Influence of recycling on the migration and formation of degradation products",
        "value": "65b91c9406feb083f1b96075"
      },
      {
        "label": "Japan GHS",
        "value": "65b9114d0729b5ae4f62a05b"
      },
      {
        "label": "Migration of odorous compounds from adhesives used in market samples of food packaging materials by chromatography olfactometry and mass spectrometry (GC-O-MS)",
        "value": "65b91c6d06feb083f1b9581a"
      },
      {
        "label": "?-Caprolactam Migration from Irradiated PA-6 Food Packaging: Kinetic Simulation and Measurement",
        "value": "65b91c8f06feb083f1b95f7b"
      },
      {
        "label": "Estimates of dietary exposure of Spanish population to packaging contaminants from cereal based foods contained in plastic materials",
        "value": "65b91c8306feb083f1b95ce6"
      },
      {
        "label": "Migration of dehydroabietic and abietic acids from paper and paperboard food packaging into food-simulating solvents and Tenax TA",
        "value": "65b91d1b06feb083f1b97de4"
      },
      {
        "label": "Occurrence of per- and polyfluorinated compounds in paper and board packaging materials and migration to food simulants and foodstuffs",
        "value": "65b91d5006feb083f1b98a09"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, 3H-perfluoro-3- (3-methoxy-propoxy) propanoic acid , ammonium salt, CAS No. 958445-44-8, for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91e8806feb083f1b9cc97"
      },
      {
        "label": "Phthalates' releasing pattern in low pH beverages of fermented milk, fruit juice, and soft drink packaged in plastic bottles",
        "value": "65b91cc806feb083f1b96bd1"
      },
      {
        "label": "Identification, determination, and biological evaluation of a novel styrene trimer contained in polystyrene food containers. 2",
        "value": "65b91d1606feb083f1b97c77"
      },
      {
        "label": "Study on Phthalates Plasticizers Detection of Food Paper Packaging",
        "value": "65b91cc306feb083f1b96a66"
      },
      {
        "label": "Migration of di-(2-ethylhexyl)adipate and acetyltributyl citrate plasticizers from food-grade PVC film into isooctane: Effect of gamma radiation",
        "value": "65b91c8006feb083f1b95c38"
      },
      {
        "label": "Migration of aluminum from food contact materials to food-a health risk for consumers? Part III of III: migration of aluminum to food from camping dishes and utensils made of aluminum",
        "value": "65b91deb06feb083f1b9ace2"
      },
      {
        "label": "Diffusion behaviour of the acetaldehyde scavenger 2-aminobenzamide in polyethylene terephthalate for beverage bottles",
        "value": "65b91e7306feb083f1b9c808"
      },
      {
        "label": "Analysis of the epoxidised soya bean oil additive in plastics by gas chromatography",
        "value": "65b91e4606feb083f1b9bf92"
      },
      {
        "label": "Endocrine disrupting chemicals in commercially available cling film brands in South Africa",
        "value": "65b91c8406feb083f1b95d26"
      },
      {
        "label": "French List of antifungals for use in wood food contact materials",
        "value": "65b9114d0729b5ae4f62a04b"
      },
      {
        "label": "3D metal-organic framework as highly efficient biosensing platform for ultrasensitive and rapid detection of bisphenol A",
        "value": "65b91e3c06feb083f1b9bd5c"
      },
      {
        "label": "Behavior of some solid food simulants in contact with several plastics used in microwave ovens",
        "value": "65b91c6b06feb083f1b957c0"
      },
      {
        "label": "Migration of styrene and ethylbenzene from virgin and recycled expanded polystyrene containers and discrimination of these two kinds of polystyrene by principal component analysis",
        "value": "65b91c6b06feb083f1b957d1"
      },
      {
        "label": "USA FDA TOR: Inventory of substances",
        "value": "65b9114c0729b5ae4f62a011"
      },
      {
        "label": "Fast and simple determination of perfluorinated compounds and their potential precursors in different packaging materials",
        "value": "65b91d5006feb083f1b98a0d"
      },
      {
        "label": "Determination of benzene in polypropylene food-packaging materials and food-contact paraffin waxes",
        "value": "65b91de406feb083f1b9ab42"
      },
      {
        "label": "A new approach to calibration and determination of selected trace elements in food contact polymers by LA-ICP-MS",
        "value": "65b91df306feb083f1b9ae9b"
      },
      {
        "label": "Study of the effect of the presence of silver nanoparticles on migration of bisphenol A from polycarbonate glasses into food simulants",
        "value": "65b91e3d06feb083f1b9bdbc"
      },
      {
        "label": "Persistent substances, Arp et al. 2019",
        "value": "65b9114d0729b5ae4f62a085"
      },
      {
        "label": "Migration of epoxidized soybean oil from polyvinyl chloride/polyvinylidene chloride food packaging wraps into food simulants",
        "value": "65b91e4606feb083f1b9bf8f"
      },
      {
        "label": "Effect of autoclave sterilization, gamma irradiation and high-pressure processing on the migration of 4,4 '-MDA and its isomers in laminated food packaging bags",
        "value": "65b91c7706feb083f1b95a53"
      },
      {
        "label": "Widespread occurrence of phthalates in popular take-out food containers from China and the implications for human exposure",
        "value": "65b91cc406feb083f1b96abe"
      },
      {
        "label": "Targeted multidimensional gas chromatography using a heart-cutting device and cryogenic focusing for the determination of benzophenone derivatives in foodstuffs",
        "value": "65b91cd306feb083f1b96de8"
      },
      {
        "label": "Determination of haloanisoles in paper samples for food packaging by solid-phase microextraction and gas chromatography",
        "value": "65b91d2106feb083f1b97efe"
      },
      {
        "label": "Migration of monomers, plastic additives, and non-intentionally added substances from food utensils made of melamine�formaldehyde resin following ultraviolet sterilization",
        "value": "65b91c6906feb083f1b95725"
      },
      {
        "label": "Development of a SPME-GC-MS/MS method for the determination of some contaminants from food contact material in beverages",
        "value": "65b91c8b06feb083f1b95e9a"
      },
      {
        "label": "Influence of high-pressure processing on selected polymeric materials and on the migration of a pressure-transmitting fluid",
        "value": "65b91dab06feb083f1b99f21"
      },
      {
        "label": "Migration of N-nitrosamines, N-nitrosatable substances and 2-mercaptobenzthiazol from baby bottle teats and soothers: a Dutch retail survey",
        "value": "65b91c7406feb083f1b9596e"
      },
      {
        "label": "EU REACH",
        "value": "65b9114d0729b5ae4f62a05d"
      },
      {
        "label": "Efficiency of whole and skimmed powdered milk for trapping volatile compounds released from plastic containers in high-temperature applications",
        "value": "65b91c6b06feb083f1b957cc"
      },
      {
        "label": "Development and validation of a fast gas chromatography mass spectrometry method for the quantification of selected non-intentionally added substances and polystyrene/polyurethane oligomers in liquid food simulants",
        "value": "65b91c6d06feb083f1b95827"
      },
      {
        "label": "Safety assessment of the substance (triethanolamine-perchlorate, sodium salt) dimer, for use in food contact materials",
        "value": "65b91c7b06feb083f1b95b36"
      },
      {
        "label": "Green, bioanalytically validated chromatographic method for the determination and quantification of photoinitiators in saliva in contact with baby bibs, teethers and pacifiers",
        "value": "65b91c7e06feb083f1b95bb7"
      },
      {
        "label": "Migration of plasticisers from Tritan and polycarbonate bottles and toxicological evaluation",
        "value": "65b91c8306feb083f1b95ccb"
      },
      {
        "label": "Identification of intentionally and non-intentionally added substances in plastic packaging materials and their migration into food products",
        "value": "65b91c7606feb083f1b95a09"
      },
      {
        "label": "A common surfactant used in food packaging found to be toxic for reproduction in mammals",
        "value": "65b91c8c06feb083f1b95ebf"
      },
      {
        "label": "Simultaneous Determination of Antioxidants and Ultraviolet Absorbers by Ultra-Performance Liquid Chromatography in Food Simulants",
        "value": "65b91c8706feb083f1b95da9"
      },
      {
        "label": "Concentration changes of polymer additives and radiolysis products in polyethylene resins irradiated at doses applicable to fresh produce",
        "value": "65b91cba06feb083f1b96851"
      },
      {
        "label": "Simultaneous determination of fluorescent whitening agents (FWAs) and photoinitiators (PIs) in food packaging coated paper products by the UPLC-MS/MS method using ESI positive and negative switching modes",
        "value": "65b91c8706feb083f1b95de6"
      },
      {
        "label": "Kinetic migration studies using Porapak as solid-food simulant to assess the safety of paper and board as food-packaging materials",
        "value": "65b91cc206feb083f1b96a2c"
      },
      {
        "label": "Chemical contaminants migration from food contact materials into aqueous extracts",
        "value": "65b91d8d06feb083f1b9980d"
      },
      {
        "label": "Substances migration from ion-exchange resins",
        "value": "65b91c7006feb083f1b958b2"
      },
      {
        "label": "The Influence of High-pressure Processing on the Migration of Irganox 1076 from Polyethylene Films",
        "value": "65b91d2f06feb083f1b98252"
      },
      {
        "label": "Toxic Metals in Paper and Paperboard Food Packagings",
        "value": "65b91dec06feb083f1b9ad25"
      },
      {
        "label": "Consumer use effects on nanoparticle release from commercially available ceramic cookware",
        "value": "65b91def06feb083f1b9adb6"
      },
      {
        "label": "Effects of gamma irradiation on polyethylene, polypropylene, and polystyrene",
        "value": "65b91c6b06feb083f1b957de"
      },
      {
        "label": "Phthalate acid esters in pickled vegetables packaged in polyethylene terephthalate container: Occurrence, migration, and estrogenic activity-associated risk assessment",
        "value": "65b91cc606feb083f1b96b42"
      },
      {
        "label": "The effects of gamma irradiation on the fate of polymer additives and the implications for migration from plastic food contact materials",
        "value": "65b91d1a06feb083f1b97d6e"
      },
      {
        "label": "Release of bisphenols from can coatings into canned beer in China market",
        "value": "65b91cbd06feb083f1b96909"
      },
      {
        "label": "Metal-organic framework mixed-matrix membrane-based extraction combined HPLC for determination of bisphenol A in milk and milk packaging",
        "value": "65b91e3b06feb083f1b9bd44"
      },
      {
        "label": "Identification of non-volatile migrants from baby bottles by UPLC-Q-TOF-MS",
        "value": "65b91c6a06feb083f1b95788"
      },
      {
        "label": "Polyethylene/Polycaprolactone Nanocomposite Films for Food Packaging Modified with Magnetite and Casein: Oxygen Barrier, Mechanical, and Thermal Properties",
        "value": "65b91df006feb083f1b9ae00"
      },
      {
        "label": "Occurrence of 2-phenylphenol in food paper packages",
        "value": "65b91e7506feb083f1b9c898"
      },
      {
        "label": "Corrosion-induced release of chromium and iron from ferritic stainless steel grade AISI 430 in simulated food contact",
        "value": "65b91df106feb083f1b9ae32"
      },
      {
        "label": "Migration model of toxic metals from ceramic food contact materials into acid food",
        "value": "65b91df706feb083f1b9af64"
      },
      {
        "label": "US FDA approved phthalates as of May 2022",
        "value": "65b9114e0729b5ae4f62a095"
      },
      {
        "label": "Determination of dihydroxybenzenes in plastic food packaging materials and in food simulating liquids using capillary electrophoresis",
        "value": "65b91c9e06feb083f1b9626f"
      },
      {
        "label": "An untargeted evaluation of the volatile and semi-volatile compounds migrating into food simulants from polypropylene food containers by comprehensive two-dimensional gas chromatography-time-of-flight mass spectrometry",
        "value": "65b91c8c06feb083f1b95edf"
      },
      {
        "label": "Concentrations of several phthalates contaminants in Egyptian bottled water: Effects of storage conditions and estimate of human exposure",
        "value": "65b91cbf06feb083f1b96982"
      },
      {
        "label": "A Comparison of Soxhlet Extraction and Ultrasonic-Assisted Extraction for Analysis of Photoinitiators in Printed Cartonboard Food-Packaging Materials",
        "value": "65b91c7e06feb083f1b95bc7"
      },
      {
        "label": "Antimony leaching from polyethylene terephthalate (PET) plastic used for bottled drinking water",
        "value": "65b91e0b06feb083f1b9b36a"
      },
      {
        "label": "Validation and application of an LC-MS/MS method for the determination of cyclic oligomers originating from polyamide 6 and polyamide 66 in food simulant",
        "value": "65b91d1106feb083f1b97b5c"
      },
      {
        "label": "STYRENE AND ETHYLBENZENE MIGRATION FROM POLYSTYRENE INTO DAIRY-PRODUCTS BY DYNAMIC PURGE-AND-TRAP GAS-CHROMATOGRAPHY",
        "value": "65b91c7106feb083f1b958be"
      },
      {
        "label": "Investigation into the application of food-contact laminated materials: examition of migration potential and recommendations for safe use",
        "value": "65b91d1806feb083f1b97d00"
      },
      {
        "label": "Evaluation of a UV absorber added to PET bottles for edible oil packaging",
        "value": "65b91d7d06feb083f1b99435"
      },
      {
        "label": "Determination of the diglycidyl ether of bisphenol A and its derivatives in canned foods",
        "value": "65b91cfd06feb083f1b976e6"
      },
      {
        "label": "Characterisation, release and migration of phenolic compounds from resoles used in polyester-phenol coatings intended for food contact materials",
        "value": "65b91e2d06feb083f1b9ba84"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance glycolic acid for use in food contact materials EFSA Panel on food contact materials, enzymes, flavourings and processing aids (CEF)",
        "value": "65b91e3906feb083f1b9bcc1"
      },
      {
        "label": "Analysis of Bisphenol A migration from microwaveable polycarbonate cups into coffee during microwave heating",
        "value": "65b91e4306feb083f1b9bf09"
      },
      {
        "label": "Evidence of waste electrical and electronic equipment (WEEE) relevant substances in polymeric food-contact articles sold on the European market",
        "value": "65b91cbe06feb083f1b9695d"
      },
      {
        "label": "Reliable liquid chromatography-mass spectrometry method for investigation of primary aromatic amines migration from food packaging and during industrial curing of multilayer plastic laminates",
        "value": "65b91c7506feb083f1b959b2"
      },
      {
        "label": "Irradiation of a barrier film: Analysis of some mass transfer aspects",
        "value": "65b91c8e06feb083f1b95f35"
      },
      {
        "label": "Determination of perfluorooctane sulfonate and perfluorooctanoic acid in food packaging using liquid chromatography coupled with tandem mass spectrometry",
        "value": "65b91d1e06feb083f1b97e70"
      },
      {
        "label": "Effect of high-pressure processing on the migration of ?-caprolactam from multilayer polyamide packaging in contact with food simulants",
        "value": "65b91c8f06feb083f1b95f8d"
      },
      {
        "label": "Studies on the Volatile Compounds Generated on Irradiation of Flexible Films for Spice Packaging",
        "value": "65b91cb406feb083f1b9670f"
      },
      {
        "label": "Migration law of lead and cadmium from Chinese pots during the cooking process",
        "value": "65b91df306feb083f1b9ae7e"
      },
      {
        "label": "Variation of baking oils and baking methods on altering the contents of cyclosiloxane in food simulants and cakes migrated from silicone rubber baking moulds",
        "value": "65b91d9906feb083f1b99b09"
      },
      {
        "label": "Di(2-ethylhexyl) phthalate contamination of retail packed lunches caused by PVC gloves used in the preparation of foods",
        "value": "65b91c8106feb083f1b95c5b"
      },
      {
        "label": "Tentative identification of BADGE derivatives in epoxy type coatings in a model sample: a beverage can",
        "value": "65b91d2b06feb083f1b9819d"
      },
      {
        "label": "The effect of microwave energy on specific migration from food contact plastics",
        "value": "65b91c6c06feb083f1b957e7"
      },
      {
        "label": "Development of an Extraction Method Using Mixture Design for the Evaluation of Migration of Non-target Compounds and Dibutyl Phthalate from Baby Bottles",
        "value": "65b91cb206feb083f1b966ca"
      },
      {
        "label": "Kinetic Migration of Chemical Elements from Ceramic Packaging into Simulated Foods and Mature Vinegar",
        "value": "65b91cfa06feb083f1b9763c"
      },
      {
        "label": "Gas Purge Microsyringe Extraction Coupled with Dispersive Liquid-Liquid Microextraction for the Determination of Acidic Compounds in Food Packaging Materials",
        "value": "65b91c9b06feb083f1b961d7"
      },
      {
        "label": "Elemental concentration of tomato paste and respective packages through particle-induced X-ray emission",
        "value": "65b91dee06feb083f1b9ad80"
      },
      {
        "label": "A method of test for residual isophorone diisocyanate trimer in new polyester-polyurethane coatings on light metal packaging using liquid chromatography with tandem mass spectrometric detection",
        "value": "65b91d8006feb083f1b994d5"
      },
      {
        "label": "Nontargeted multicomponent analytical screening of plastic food contact materials using fast interpretation of deliverables via expert structure-activity relationship software",
        "value": "65b91c7b06feb083f1b95b15"
      },
      {
        "label": "Assessment of bisphenol A released from reusable plastic, aluminium and stainless steel water bottles",
        "value": "65b91e4206feb083f1b9bec5"
      },
      {
        "label": "Compliance work for polyolefins in food contact: Results of an official control campaign",
        "value": "65b91cb006feb083f1b9666b"
      },
      {
        "label": "Analysis of phthalates in food products and packaging materials sold on the Belgian market",
        "value": "65b91cc506feb083f1b96afe"
      },
      {
        "label": "Study on migration of two acrylate monomers in plastic food contact materials",
        "value": "65b91d2106feb083f1b97f23"
      },
      {
        "label": "Liquid chromatography-tandem mass spectrometric analysis of acetyl tributyl citrate for migration testing of food contact materials",
        "value": "65b91e3306feb083f1b9bb92"
      },
      {
        "label": "An analytical survey of benzotriazole UV stabilizers in plastic products and their endocrine-disrupting potential via human estrogen and androgen receptors",
        "value": "65b91d3706feb083f1b9841f"
      },
      {
        "label": "Analysis of polycyclic aromatic hydrocarbons migrating from polystyrene/divinylbenzene-based food contact materials",
        "value": "65b91cd606feb083f1b96e77"
      },
      {
        "label": "Migration of epoxidised soybean oil from PVC gaskets of commercial lids: simulation of migration under various conditions and screening of food products from Czech markets",
        "value": "65b91c8306feb083f1b95cc7"
      },
      {
        "label": "Analysis of migrants from nylon 6 packaging films into boiling water",
        "value": "65b91c9206feb083f1b95fde"
      },
      {
        "label": "French 2019 List for food contact rubber articles for infants and young children",
        "value": "65b9114d0729b5ae4f62a03b"
      },
      {
        "label": "Ultrasensitive detection and risk assessment of di(2-ethylhexyl) phthalate migrated from daily-use plastic products using a nanostructured electrochemical aptasensor",
        "value": "65b91cc306feb083f1b96a89"
      },
      {
        "label": "Benzophenone in cartonboard packaging materials and the factors that influence its migration into food",
        "value": "65b91cd306feb083f1b96e03"
      },
      {
        "label": "Effects of ionizing radiation on food contact materials",
        "value": "65b91c6906feb083f1b9571a"
      },
      {
        "label": "Liquid chromatography-full scan-high resolution mass spectrometry-based method towards the comprehensive analysis of migration of primary aromatic amines from food packaging",
        "value": "65b91c7506feb083f1b959a8"
      },
      {
        "label": "Molecularly imprinted solid-phase extraction for selective extraction of bisphenol analogues in beverages and canned food",
        "value": "65b91d0906feb083f1b97979"
      },
      {
        "label": "Plasticizers from printing inks in a selection of food packagings and their migration to food",
        "value": "65b91c8206feb083f1b95caa"
      },
      {
        "label": "Consumer exposure to phthalates from paper packaging: an integrated approach",
        "value": "65b91cc106feb083f1b969fb"
      },
      {
        "label": "PFOA and PFOS levels in microwave paper packaging between 2005 and 2018",
        "value": "65b91d1f06feb083f1b97e8f"
      },
      {
        "label": "Tyrosinase-based biosensor for determination of bisphenol A in a flow-batch system",
        "value": "65b91e4006feb083f1b9be46"
      },
      {
        "label": "N-nitrosamines migrating from food contact materials into food simulants: analysis and quantification by means of HPLC-APCI-MS/MS",
        "value": "65b91c7406feb083f1b95965"
      },
      {
        "label": "Determination of aromatic amines in food products and composite food packaging bags by capillary electrophoresis coupled with transient isotachophoretic stacking",
        "value": "65b91c7606feb083f1b95a17"
      },
      {
        "label": "Determination of extractable biocides in paper food packaging materials using micellar electrokinetic chromatography",
        "value": "65b91c7a06feb083f1b95aff"
      },
      {
        "label": "Testing the influence of various conditions on the migration of epoxidised soybean oil from polyvinylchloride gaskets",
        "value": "65b91e4506feb083f1b9bf72"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance, dodecanoic acid, 12-amino-, polymer with ethene, 2,5-furandione, alpha-hydro-omega-hydroxypoly (oxy-1,2-ethanediyl) and 1-propene, CAS No 287916-86-3, for use in food contact materials",
        "value": "65b91ce006feb083f1b970af"
      },
      {
        "label": "Residues of volatile halocarbons in margarines",
        "value": "65b91ce806feb083f1b9725c"
      },
      {
        "label": "Scientific Opinion on the safety evaluation of the substance, (methyl methacrylate, butyl acrylate, styrene, glycidyl methacrylate) copolymer, CAS No. 37953-21-2 for use in food contact materials",
        "value": "65b91c6e06feb083f1b95856"
      },
      {
        "label": "Some observations on the determination of monomer residues in foods",
        "value": "65b91e2706feb083f1b9b90b"
      },
      {
        "label": "Migration of styrene from polystyrene foam food-contact articles",
        "value": "65b91c6f06feb083f1b95880"
      },
      {
        "label": "Acetaldehyde residue in polyethylene terephthalate (PET) bottles",
        "value": "65b91e2806feb083f1b9b93f"
      },
      {
        "label": "New FDA migration cell used to study migration of styrene from polystyrene into various solvents",
        "value": "65b91c6e06feb083f1b95838"
      },
      {
        "label": "Migration of (non-) intentionally added substances and microplastics from microwavable plastic food containers",
        "value": "65b91cb906feb083f1b9682b"
      },
      {
        "label": "Effect of bottling and storage on the migration of plastic constituents in Spanish bottled waters",
        "value": "65b91c8306feb083f1b95cce"
      },
      {
        "label": "Kinetic migration studies of bisphenol-A-related compounds from can coatings into food simulant and oily foods",
        "value": "65b91d1806feb083f1b97ce8"
      },
      {
        "label": "Corrosion in Aluminium Cans with Grape Juice - Influence of Mechanical Damage",
        "value": "65b91dee06feb083f1b9ad85"
      },
      {
        "label": "Identification of non-volatile compounds and their migration from hot melt adhesives used in food packaging materials characterized by ultra-performance liquid chromatography coupled to quadrupole time-of-flight mass spectrometry",
        "value": "65b91d1306feb083f1b97bbd"
      },
      {
        "label": "Risk Assessment of Using Aluminum Foil in Food Preparation",
        "value": "65b91def06feb083f1b9adad"
      },
      {
        "label": "Migration of melamine and formaldehyde from tableware made of melamine resin",
        "value": "65b91c9f06feb083f1b962bc"
      },
      {
        "label": "Plasticizer residues by HRGC-MS in espresso coffees from capsules, pods and moka pots",
        "value": "65b91c8406feb083f1b95d23"
      },
      {
        "label": "Simultaneous Determination of Antioxidants and Ultraviolet Stabilizers in Polypropylene Food Packaging and Food Simulants by High-Performance Liquid Chromatography",
        "value": "65b91cea06feb083f1b972e2"
      },
      {
        "label": "Migration of bisphenol A from polyvinyl chloride plastics to solvents of different polarities and packaged food in China",
        "value": "65b91e3c06feb083f1b9bd62"
      },
      {
        "label": "Monodisperse pillar[5]arene-based polymeric sub-microsphere for on-line extraction coupling with high-performance liquid chromatography to determine antioxidants in the migration of food contact materials",
        "value": "65b91ccf06feb083f1b96d21"
      },
      {
        "label": "Non-target screening for the identification of migrating compounds from reusable plastic bottles into drinking water",
        "value": "65b91c8606feb083f1b95d8b"
      },
      {
        "label": "Simultaneous enrichment of bisphenols and polyfluoroalkyl substances by cyclodextrin-fluorinated covalent organic frameworks membrane in food packaging samples",
        "value": "65b91d0a06feb083f1b979af"
      },
      {
        "label": "Migration of bisphenol A into water from polycarbonate baby bottles during microwave heating",
        "value": "65b91e4206feb083f1b9beba"
      },
      {
        "label": "UPLC-ESI-Q-TOF-MS(E) and GC-MS identification and quantification of non-intentionally added substances coming from biodegradable food packaging",
        "value": "65b91ca806feb083f1b9649e"
      },
      {
        "label": "Migration of dibenzoate plasticizers and polyethylene terephthalate cyclic oligomers from microwave susceptor packaging into food-simulating liquids and food",
        "value": "65b91cd706feb083f1b96ee1"
      },
      {
        "label": "Studies of migration of styrene monomer from polystyrene packaging into the food stimulant",
        "value": "65b91c7106feb083f1b958d2"
      },
      {
        "label": "STUDY THE CONTAMINATION OF SOME CHEMICAL POLLUTANTS IN HOT FOODS STORED IN PLASTIC BAGS AND CONTAINERS",
        "value": "65b91df806feb083f1b9af96"
      },
      {
        "label": "Identification of Potential Migrants in Polyethylene Terephthalate Samples of Ecuadorian Market",
        "value": "65b91c6a06feb083f1b95768"
      },
      {
        "label": "Xenoestrogens released from lacquer coatings in food cans",
        "value": "65b91e4206feb083f1b9bee1"
      },
      {
        "label": "An amperometric sensor for the determination of benzophenone in food packaging materials based on the electropolymerized molecularly imprinted poly-o-phenylenediamine film",
        "value": "65b91cd206feb083f1b96da2"
      },
      {
        "label": "Identification of chemicals in a polyvinyl chloride/polyethylene multilayer film by ultra-high-performance liquid chromatography/quadrupole time-of-flight mass spectrometry and their migration into solution",
        "value": "65b91c7a06feb083f1b95ae8"
      },
      {
        "label": "Analytical determination of bisphenol A (BPA) and bisphenol analogues in paper products by GC-MS/MS",
        "value": "65b91cbd06feb083f1b96912"
      },
      {
        "label": "GC-MS Screening for the Identification of Potential Migrants Present in Polymeric Coatings of Food Cans",
        "value": "65b91c7606feb083f1b959f3"
      },
      {
        "label": "ECHA list of substances evaluated within CoRAP",
        "value": "65b9114d0729b5ae4f62a079"
      },
      {
        "label": "Polyamid-Oligomere: Kunststoffbestandteile aus Küchenutensilien, Stellungnahme Nr. 014/2018",
        "value": "65b91c9106feb083f1b95fd0"
      },
      {
        "label": "Migration of epoxidized soybean oil (ESBO) and phthalates from twist closures into food and enforcement of the overall migration limit",
        "value": "65b91c8206feb083f1b95c95"
      },
      {
        "label": "A study on the equivalence of olive oil and the EU official substitute test media for migration testing at high temperatures",
        "value": "65b91cba06feb083f1b96862"
      },
      {
        "label": "Impact of Storage on Levels of Phthalates in Sachet and Bottled Water Brands in Enugu State, Nigeria",
        "value": "65b91cef06feb083f1b973c3"
      },
      {
        "label": "Migration of non intentionally added substances from adhesives by UPLC-Q-TOF/MS and the role of EVOH to avoid migration in multilayer packaging materials",
        "value": "65b91cae06feb083f1b965f0"
      },
      {
        "label": "Migration from printing inks in multilayer food packaging materials by GC-MS analysis and pattern recognition with chemometrics",
        "value": "65b91c7c06feb083f1b95b6c"
      },
      {
        "label": "Study of solvent sublation for concentration of trace phthalate esters in plastic beverage packaging and analysis by gas chromatography-mass spectrometry",
        "value": "65b91cc606feb083f1b96b56"
      },
      {
        "label": "Report of the interlaboratory comparison organised by the European Reference Laboratory for Food Contact Materials: ILC01 2012- Formaldehyde and Melamine in 3% acetic acid migration solution.",
        "value": "65b91ca006feb083f1b962e3"
      },
      {
        "label": "Development of an analytical method for the determination of photoinitiators used for food packaging materials with potential to migrate into milk",
        "value": "65b91cd006feb083f1b96d6c"
      },
      {
        "label": "Database of BfR Recommendations on Food Contact Materials",
        "value": "65b9114c0729b5ae4f62a01b"
      },
      {
        "label": "Ubiquitous Occurrence of Chlorinated Byproducts of Bisphenol A and Nonylphenol in Bleached Food Contacting Papers and Their Implications for Human Exposure",
        "value": "65b91d4706feb083f1b9880e"
      },
      {
        "label": "Safety assessment of polylactide (PLA) for use as a food-contact polymer",
        "value": "65b91d8f06feb083f1b99868"
      },
      {
        "label": "Dispersive solid-phase extraction of bisphenols migrated from plastic food packaging materials with cetyltrimethylammonium bromide-intercalated zinc oxide",
        "value": "65b91d0a06feb083f1b97992"
      },
      {
        "label": "Investigation of the primary plasticisers present in polyvinyl chloride (PVC) products currently authorised as food contact materials",
        "value": "65b91c8506feb083f1b95d36"
      },
      {
        "label": "Simultaneous determination of phthalates and bisphenols from plastic bottled water samples by dispersive solid phase extraction with multiwalled carbon nanotubes and liquid chromatography -atmospheric pressure photoionization high resolution mass spectrometry",
        "value": "65b91cc006feb083f1b969cf"
      },
      {
        "label": "Contamination of aluminium from cooking utensils and yogurt containers",
        "value": "65b91deb06feb083f1b9acc8"
      },
      {
        "label": "Release of formaldehyde and melamine from tableware made of melamine — formaldehyde resin",
        "value": "65b91c9e06feb083f1b96297"
      },
      {
        "label": "USA FDA Inventory of Indirect Additives used in Food Contact Substances",
        "value": "65b9114c0729b5ae4f62a00d"
      },
      {
        "label": "Safety evaluation of an ionized multilayer plastic film used for vacuum cooking and meat preservation",
        "value": "65b91c7506feb083f1b959d9"
      },
      {
        "label": "Scientific Opinion on the safety assessment of the substance 1,7-octadience CAS No 3710-34-3, for use in food contact materials",
        "value": "65b91d7306feb083f1b9920b"
      },
      {
        "label": "Analysis of Selected Endocrine Disrupters Fraction Including Bisphenols Extracted from Daily Products, Food Packaging and Treated Wastewater Using Optimized Solid-Phase Extraction and Temperature-Dependent Inclusion Chromatography",
        "value": "65b91e4006feb083f1b9be54"
      },
      {
        "label": "Migration of epoxidised soya bean oil into foods from retail packaging materials and from plasticised PVC film used in the home",
        "value": "65b91e4606feb083f1b9bf96"
      },
      {
        "label": "Bisphenol A and its alternatives in Austrian thermal paper receipts, and the migration from reusable plastic drinking bottles into water and artificial saliva using UHPLC-MS/MS",
        "value": "65b91cfb06feb083f1b97661"
      },
      {
        "label": "Migration potential from coffee filter papers",
        "value": "65b91c8406feb083f1b95cf5"
      },
      {
        "label": "Migration of monomers and plasticizers from packed foods and heated microwave foods using QuEChERS sample preparation and gas chromatography/mass spectrometry",
        "value": "65b91c8406feb083f1b95cf8"
      },
      {
        "label": "A Salting-out Liquid-Liquid extraction (SALLE) for the analysis of caprolactam and 2,4-di-tert butyl phenol in water and food simulants. Study of the salinity effect to specific migration from food contact materials",
        "value": "65b91c8e06feb083f1b95f5b"
      },
      {
        "label": "THE THERMOSTABILITY OF PAPERBOARD FOOD PACKING",
        "value": "65b91ccb06feb083f1b96c43"
      },
      {
        "label": "Human health risk assessment of bisphenol A released from polycarbonate drinking water bottles and carbonated drinks exposed to sunlight in Nigeria",
        "value": "65b91e3c06feb083f1b9bd7c"
      },
      {
        "label": "FACET release 2018: List for food contact materials",
        "value": "65b9114c0729b5ae4f62a00b"
      },
      {
        "label": "US EPA Safer chemical ingredients list",
        "value": "65b9114d0729b5ae4f62a07d"
      },
      {
        "label": "Gas chromatography with mass spectrometry for the determination of phthalates preconcentrated by microextraction based on an ionic liquid",
        "value": "65b91cc706feb083f1b96b69"
      },
      {
        "label": "Migration of phenolic brominated flame retardants from contaminated food contact articles into food simulants and foods",
        "value": "65b91ccd06feb083f1b96cbb"
      },
      {
        "label": "Simple On-Line Preconcentration Spectrophotometry for Detecting Lead Contamination from Drinking Water Coolers and Glazed Bowl Samples",
        "value": "65b91d0506feb083f1b97894"
      },
      {
        "label": "Detecting the release of plastic particles in packaged drinking water under simulated light irradiation using surface-enhanced Raman spectroscopy",
        "value": "65b91e7406feb083f1b9c832"
      },
      {
        "label": "Analysis of vinylidene chloride and 1-chlorobutane in foods packaged with polyvinylidene chloride casing films by headspace gas chromatography/mass spectrometry (GC/MS)",
        "value": "65b91ca606feb083f1b96415"
      },
      {
        "label": "Assessment and detection of the potential contaminants from oil palm empty fruit bunch fiber-based biodegradable tray",
        "value": "65b91cc206feb083f1b96a38"
      },
      {
        "label": "Novel ethylene/norbornene copolymers as nonreleasing antioxidants for food-contact polyolefinic materials",
        "value": "65b91ce906feb083f1b972b1"
      },
      {
        "label": "Non-targeted screening of chemicals migrating from paper-based food packaging by GC-Orbitrap mass spectrometry",
        "value": "65b91c8606feb083f1b95d88"
      },
      {
        "label": "Investigation of carbonyl compounds in bottled waters from Poland",
        "value": "65b91d8d06feb083f1b99825"
      },
      {
        "label": "Evaluation of Short-Term and Long-Term Migration Testing from Can Coatings into Food Simulants: Epoxy and Acrylic-Phenolic Coatings",
        "value": "65b91d2b06feb083f1b98197"
      },
      {
        "label": "Application of an improved hollow fiber liquid phase microextraction technique coupled to LC-MS/MS to studying migration of fluorescent whitening agents from plastic food contact materials",
        "value": "65b91c8706feb083f1b95de3"
      },
      {
        "label": "Leaching of antimony from polyethylene terephthalate (PET) bottles into mineral water",
        "value": "65b91e0c06feb083f1b9b3bb"
      }
    ],
    sourceRegions: [
      {
        label: 'China',
        value: 'China'
      },
      {
        label: 'Denmark',
        value: 'Denmark'
      },
      {
        label: 'EU',
        value: 'EU'
      },
      {
        label: 'Europe',
        value: 'Europe'
      },
      {
        label: 'France',
        value: 'France'
      },
      {
        label: 'Germany',
        value: 'Germany'
      },
      {
        label: 'Greece',
        value: 'Greece'
      },
      {
        label: 'Japan',
        value: 'Japan'
      },
      {
        label: 'Mercosur',
        value: 'Mercosur'
      },
      {
        label: 'Netherlands',
        value: 'Netherlands'
      },
      {
        label: 'New Zealand',
        value: 'New Zealand'
      },
      {
        label: 'Norden',
        value: 'Norden'
      },
      {
        label: 'Switzerland',
        value: 'Switzerland'
      },
      {
        label: 'USA',
        value: 'USA'
      },
    ],
    sourceTypes: [
      {
        label: 'Book',
        value: 'Book'
      },
      {
        label: 'Computer Program',
        value: 'Computer Program'
      },
      {
        label: 'Dataset',
        value: 'Dataset'
      },
      {
        label: 'Government Document',
        value: 'Government Document'
      },
      {
        label: 'Journal Article',
        value: 'Journal Article'
      },
      {
        label: 'Legal rule or regulation',
        value: 'Legal rule or regulation'
      },
      {
        label: 'Online Database',
        value: 'Online Database'
      },
      {
        label: 'Online Multimedia',
        value: 'Online Multimedia'
      },
      {
        label: 'Report',
        value: 'Report'
      },
      {
        label: 'Web Page',
        value: 'Web Page'
      }
    ],
    sourceTypesAll: [
      {
        label: 'Aggregated Database',
        value: 'Aggregated Database'
      },
      {
        label: 'Ancient text',
        value: 'Ancient text'
      },
      {
        label: 'Artwork',
        value: 'Artwork'
      },
      {
        label: 'Audiovisual Material',
        value: 'Audiovisual Material'
      },
      {
        label: 'Bill',
        value: 'Bill'
      },
      {
        label: 'Blog',
        value: 'Blog'
      },
      {
        label: 'Book',
        value: 'Book'
      },
      {
        label: 'Book Section',
        value: 'Book Section'
      },
      {
        label: 'Case',
        value: 'Case'
      },
      {
        label: 'Catalog',
        value: 'Catalog'
      },
      {
        label: 'Chart or Table',
        value: 'Chart or Table'
      },
      {
        label: 'Classical work',
        value: 'Classical work'
      },
      {
        label: 'Computer Program',
        value: 'Computer Program'
      },
      {
        label: 'Conference Paper',
        value: 'Conference Paper'
      },
      {
        label: 'Conference Proceedings',
        value: 'Conference Proceedings'
      },
      {
        label: 'Dataset',
        value: 'Dataset'
      },
      {
        label: 'Dictionary',
        value: 'Dictionary'
      },
      {
        label: 'Discussion forum',
        value: 'Discussion forum'
      },
      {
        label: 'Edited Book',
        value: 'Edited Book'
      },
      {
        label: 'Electronic Article',
        value: 'Electronic Article'
      },
      {
        label: 'Electronic Book',
        value: 'Electronic Book'
      },
      {
        label: 'Electronic Book Section',
        value: 'Electronic Book Section'
      },
      {
        label: 'Encyclopedia',
        value: 'Encyclopedia'
      },
      {
        label: 'Equation',
        value: 'Equation'
      },
      {
        label: 'Figure',
        value: 'Figure'
      },
      {
        label: 'Film or Broadcast',
        value: 'Film or Broadcast'
      },
      {
        label: 'Generic',
        value: 'Generic'
      },
      {
        label: 'Government Document',
        value: 'Government Document'
      },
      {
        label: 'Grant',
        value: 'Grant'
      },
      {
        label: 'Hearing',
        value: 'Hearing'
      },
      {
        label: 'Interview',
        value: 'Interview'
      },
      {
        label: 'Journal Article',
        value: 'Journal Article'
      },
      {
        label: 'Legal rule or regulation',
        value: 'Legal rule or regulation'
      },
      {
        label: 'Magazine Article',
        value: 'Magazine Article'
      },
      {
        label: 'Manuscript',
        value: 'Manuscript'
      },
      {
        label: 'Map',
        value: 'Map'
      },
      {
        label: 'Multimedia application',
        value: 'Multimedia application'
      },
      {
        label: 'Music',
        value: 'Music'
      },
      {
        label: 'Newspaper Article',
        value: 'Newspaper Article'
      },
      {
        label: 'Online Database',
        value: 'Online Database'
      },
      {
        label: 'Online Multimedia',
        value: 'Online Multimedia'
      },
      {
        label: 'Pamphlet',
        value: 'Pamphlet'
      },
      {
        label: 'Patent',
        value: 'Patent'
      },
      {
        label: 'Personal Communication',
        value: 'Personal Communication'
      },
      {
        label: 'Podcast',
        value: 'Podcast'
      },
      {
        label: 'Press Release',
        value: 'Press Release'
      },
      {
        label: 'Report',
        value: 'Report'
      },
      {
        label: 'Serial',
        value: 'Serial'
      },
      {
        label: 'Social media',
        value: 'Social media'
      },
      {
        label: 'Standard',
        value: 'Standard'
      },
      {
        label: 'Statute',
        value: 'Statute'
      },
      {
        label: 'Television Broadcast',
        value: 'Television Broadcast'
      },
      {
        label: 'Thesis',
        value: 'Thesis'
      },
      {
        label: 'Unpublished work',
        value: 'Unpublished work'
      },
      {
        label: 'Web Page',
        value: 'Web Page'
      }
    ],
    sourceProjects: [
      {
        label: 'FCCdb',
        value: '636048755a80ee211cae2b36'
      },
      {
        label: 'FCCmigex',
        value: '636048755a80ee211cae2b38'
      }
    ],
    sourceNotUsedProjects: [
      {
        label: 'FCChelix',
        value: '636048755a80ee211cae2b39'
      },
      {
        label: 'FCChumon',
        value: '636048755a80ee211cae2b3a'
      }
      ],
    regionOptions:  [
      {
        value: 'EU',
        label: 'EU',
        children: [
          {
            value: 'Austria',
            label: 'Austria'
          },
          {
            value: 'Belgium',
            label: 'Belgium'
          }]
      },
      {
        value: 'Council of Europe (CoE)',
        label: 'Council of Europe (CoE)',
        children: [
          {
            value: 'basic',
            label: 'Basic',
          },
          {
            value: 'notice',
            label: 'Notice'
          },
          {
            value: 'navigation',
            label: 'Navigation'
          }, {
          value: 'others',
          label: 'Others'
        }]
      },
      {
        value: 'Norden (Nordic Council of Ministers)',
        label: 'Norden (Nordic Council of Ministers)',
        children: [
          {
            value: 'axure',
            label: 'Axure Components'
          },
          {
            value: 'sketch',
            label: 'Sketch Templates'
          },
          {
            value: 'docs',
            label: 'Design Documentation'
          }]
      },
      {
        value: 'Mercosur',
        label: 'Mercosur',
        children: [
          {
            value: 'axure',
            label: 'Axure Components'
          },
          {
            value: 'sketch',
            label: 'Sketch Templates'
          },
          {
            value: 'docs',
            label: 'Design Documentation'
          }]
    },
      {
        value: 'ASEAN',
        label: 'ASEAN',
        children: [
          {
            value: 'axure',
            label: 'Axure Components'
          },
          {
            value: 'sketch',
            label: 'Sketch Templates'
          },
          {
            value: 'docs',
            label: 'Design Documentation'
          }]
      }
    ],
    ghsSignalWords: [
      {
        "label": "Warning",
        "value": "Warning"
      },
      {
        "label": "Not submitted by participant",
        "value": "Not submitted by participant"
      },
      {
        "label": "Danger",
        "value": "Danger"
      }
    ],
    // TODO get rid only for testing
    fcmInventories2: [
      {
        label: 'plastics',
        value: false
      },
      {
        label: 'coatings',
        value: false
      },
      {
        label: 'rubber',
        value: false
      },
    ],
    chemGroups2: [
      {
        label: 'bisphenol',
        value: false
      },
      {
        label: 'oligomer',
        value: false
      },
      {
        label: 'PFAS',
        value: false
      },
      {
        label: 'phthalate',
        value: false
      },
      {
        label: 'PFAS2',
        value: false
      },
    ],
    facets: {
      sources: [
        "S14"
      ],
    }
  },
  notes: {
    fulltext: {
      title: 'Chemical name',
      content: 'Chemical name, search includes multiple synonyms per chemical. Case insensitive. Must use the full word.<br>' +
          'Search operators include:<br>' +
          'OR: Matches either condition (default)<br>' +
          'AND: Requires both conditions to be true<br>' +
          '- : Excludes a term from results.<br>' +
          '" ": Searches for an exact phrase.'
    },
    casId: {
      title: 'CAS registry numbers(s)',
      content: 'Search by single or multiple CAS registry number(s). Currently, CAS must be entered one at a time.'
    },
    chemGroup: {
      title: 'Chemical group',
      content: '<span class="text-primary">UNDER DEVELOPMENT</span><br>' +
        'Chemicals with similar structural characteristics.<br>' +
        '<strong>This is not comprehensive.</strong> To see the sources used to make tags, visit the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.<br>' +
        'If you know of sources to help expand these tags, please click “contribute” above and send us a link! '
    },
    chemFunction: {
      title: 'Chemical function',
      content: '<span class="text-primary">UNDER DEVELOPMENT</span><br>' +
        'Chemicals with similar uses within a food contact material.<br>' +
        '<strong>This is not comprehensive.</strong> To see the sources used to make tags, visit the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.<br>' +
        'If you know of sources to help expand these tags, please click “contribute” above and send us a link!'
    },
    ghsSignalWord: {
      title: 'GHS signal word',
      content: 'GHS (Globally Harmonized System) is an internationally recognized framework for classifying, labeling, and communicating chemical hazards to promote global safety and standardization.<br>' +
        'Signal words are labels indicating the severity of chemical hazards. Two primary signal words are used: "Danger" signifies severe hazards, requiring extreme caution. "Warning" indicates less severe hazards, but precautions are still necessary.'
    },
    materialInventory: {
      title: 'Listed uses',
      content: 'Substances that could be assigned to at least one ' +
        'type of food contact material according to FCCdb regulatory or industry source(s).<br>' +
        'These inventories provide an overview of intentionally added substances used in food contact materials.<br>' +
        'Read more on the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.'
    },
    materialFCAs: {
      title: 'Usage type',
      content: 'Filter applies to chemicals measured directly from commercial food contact articles.<br>' +
          'Read more on the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.'
    },
    detectedFCMMain: {
      title: 'Measured in',
      content: 'Material(s) investigated for chemical transfer (migration a/o extraction).<br>' +
        'Several materials may be used to make a single food contact article, e.g., single-serve yogurt may be sold in a ' +
        'plastic cup with a metal lid that is held together by adhesive, and printed with ink. ' +
        'Filter currently only includes the main materials.<br>' +
        'Read more on the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.'
    },
    detectedMaterialGroup: {
      title: 'Measured in',
      content: 'Which type of material was investigated for chemical migration. ' +
          'Several materials may be used to make a single food contact article, e.g., single-serve yogurt may be sold in a ' +
          'plastic cup with a metal lid that is held together by adhesive, and printed with ink. ' +
          'Filter currently only includes the main materials.<br>' +
          'Read more: https://www.foodpackagingforum.org/fccmigex.'
    },
    sourceName: {
      title: 'Name',
      content: 'From FCCDb: Regulatory and industry lists. Provides an overview of intentionally added substances used in food contact.<br>' +
          'From FCCmigex: peer reviewed studies and gray literature pertaining to chemicals in food contact materials<br>' +
          'Read more on the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.'
    },
    sourceType: {
      title: 'Type',
      content: 'Every information source included in FCX is assigned a “source type” following standard citation software structure (e.g., EndNote, Mendeley)'
    },
    sourceRegion: {
      title: 'Regulatory region',
      content: 'Some of the regulatory and industry sources used to develop the “listed uses” above are specific to a country or region.'
    },
    fpfProject: {
      title: 'FPF Project',
      content: 'The Food Packaging Forum (FPF) Food Contact Chemicals and Human Health project is gathering four distinct collections of information on food contact chemicals. ' +
          'Two are currently available.<br>' +
          'Read more on the <a target="blank" rel="noopener" href="#/data-description">data description</a> page.'
    },
  }
};

import API from "@/js/api/project.js";

const defaultState = {
  currentProject: {},
  projects: []
};

const actions = {
  GET_PROJECT(context, data) {
   Logger.debug("GET_PROJECT", data);
    return API.getProject(data)
      .then(response => {
       Logger.debug("PROJECT ACTION GET_PROJECT success", response);
        // commit mutation
        context.commit("setCurrentProject", response);
      })
      .catch(error => {
       Logger.debug("PROJECT ACTION GET_PROJECT error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  GET_PROJECTS: (context, data) => {
   Logger.debug("GET_PROJECTS", data);
    return API.getProjects(data)
      .then(response => {
       Logger.debug("PROJECT ACTION GET_PROJECTS success", response);
        // commit mutation
        context.commit("setProjects", response);
        return response;
        // you have your token, now log in your user :)
        //dispatch(USER_REQUEST)
      })
      .catch(error => {
       Logger.debug("PROJECT ACTION GET_PROJECTS Error", error);
        //Logger.error("GET_FRONTEND_USER Error ", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
  PUT_PROJECT(context, data) {
   Logger.debug("PUT_PROJECT",data);
    return API.updateProject(data)
      .then(response => {
       Logger.debug("PROJECT ACTION PUT_PROJECT success", response);
        // commit mutation
        context.commit("setCurrentProject", response);
        return response;
      })
      .catch(error => {
       Logger.debug("PROJECT ACTION PUT_PROJECT error", error);
        throw error;
      })
      .finally(() => {
        //this.state.loading = false;
      });
  },
};

const mutations = {
  setCurrentProject(state, payload) {
    state.currentProject = payload;
  },
  setDefaultProject(state, payload) {
    if (state.projects && payload && payload.id) {
      try {
        const item = state.projects.find(item => item._id === payload.id);
        Object.assign(item, payload);
        state.currentProject = item;
      } catch (error) {
       Logger.debug("error project", error);
        state.currentProject = {};
      }
    } else {
      state.currentProject = {};
    }
  },
  setProjects(state, payload) {
    state.projects = payload;
  }
};

const getters = {
  currentProject: state => {
    return state.currentProject;
  },
  projects: state => {
    return state.projects;
  },
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};

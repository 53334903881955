<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              FAQ
            </h3>
            <p>

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FAQ',
  bodyClass: 'static-page',
  components: {

  },
  data() {
    return {}
  }
}
</script>
<style>
</style>

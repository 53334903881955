<template>
  <div class="wrapper main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              Imprint
            </h3>
            <h4>Website Owner</h4>
            <p>
              Food Packaging Forum Foundation
            </p>
            <h4>Registered Address</h4>
            <FPFAddress />
            <p>
              Telephone: <FPFPhone /><br>
              Email: <FPFEmail />
            </p>

            <h3>Disclaimer</h3>
            <p>
              While every effort is made by the <FPF /> (FPF) to ensure that accurate information
              is disseminated through the Food Contact eXplorer, the <b><FPF /> (FPF) makes no representation about
                the content and suitability of this information for any purpose</b>.
              In no event shall FPF be liable for any special indirect or consequential
              damages or any damages whatsoever arising in connection with the use or performance of
              the information from this website or the Food Contact eXplorer. See the <router-link to="/terms">FCX Terms and Conditions</router-link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FPFAddress from "@/components/Fcx/static/FPFAddress.vue";
import FPFPhone from "@/components/Fcx/static/FPFPhone.vue";
import FPFEmail from "@/components/Fcx/static/FPFEmail.vue";
import FPF from "@/components/Fcx/static/FPF.vue";

export default {
  name: 'Imprint',
  bodyClass: 'static-page',
  components: {FPF, FPFEmail, FPFPhone, FPFAddress},
  data() {
    return {}
  }
}
</script>
<style>
</style>

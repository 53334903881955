<template>
  <div class="row">
    <div class="col-1 text-center">
      <input
        type="checkbox"
        class="form-check-input"
        v-model="ItemObj.isSelected"
        :inline="true"
        @change="handleSelectItem"
      />
    </div>
    <router-link
      :to="'/detail/' + Slug"
      class="hover13 col-11"
      :title="Name"
    >
      <card
        class="list-item"
        type="item"
        plain
        v-if="IsCardView"
      >
        <h6 class="card-title text-truncate">
          {{ Name }}
        </h6>

        <p>
          CAS {{ CasId }}
        </p>

        <div class="card-footer">
          <div class="row">
            <div class="col-md-6">
            <span class="text-truncate">
              {{ ChemFamily }}
            </span>
            </div>
            <div class="col-md-6">
            <span class="text-truncate">
              {{ ChemFunction }}
            </span>

            </div>
          </div>
        </div>
      </card>
      <card
        class="list-item"
        type="item"
        plain
        v-else
      >
        <div class="row">
          <div class="col-9">
            <h6 class="card-title text-truncate">
              {{ Name }}
            </h6>
          </div>
          <div class="col-3 text-right">
          <span class="text-truncate">
            CAS {{ CasId }}
          </span>
          </div>
        </div>
        <div class="row row-addition">
          <div class="col-6">
            <span class="text-truncate" v-if="ChemFamily">
              ChemGroup: {{ ChemFamily }}
            </span>
          </div>
          <div class="col-6  text-right">
            <span class="text-truncate" v-if="ChemFunction">
              ChemFunction: {{ ChemFunction }}
            </span>
          </div>
        </div>
      </card>
    </router-link>
  </div>
</template>

<script>

import { Tooltip } from 'element-ui'
import {Button, Card} from "@/components/index";

export default {
  name: "ListItem",
  components: {[Tooltip.name]: Tooltip,[Button.name]: Button, Card},
  props: {
    itemObj: {
      type: Object,
      required: false,
      default: function() {
        return {};
      }
    },
    view: {
      type: String,
      required: false,
      default: "table"
    }
  },
  data: function() {
    return {};
  },
  computed: {
    ItemObj() {
      return this.itemObj;
    },
    View() {
      return this.view;
    },
    IsCardView() {
      return this.View === "card";
    },
    Slug() {
      return this.ItemObj["slug"];
    },
    Id() {
      return this.ItemObj["_id"];
    },
    Name() {
      return this.ItemObj && this.ItemObj.name;
    },
    CasId() {
      return this.ItemObj && this.ItemObj.cas && this.ItemObj.cas.id;
    },
    ChemFamily() {
      let chemFamily = this.ChemFamily1;
      /*
      if(chemFamily && this.ChemFamily2) {
        chemFamily += " | " + this.ChemFamily2;
      }*/
      chemFamily = chemFamily.join(",");
      return chemFamily;
    },
    ChemFamily1() {
      return this.ItemObj && this.ItemObj.chemFamily;
    },
    ChemFamily2() {
      return this.ItemObj && this.ItemObj.chemFamily2;
    },
    ChemFunction() {
      let chemFunction = this.ChemFunction1;
      /*
      if(chemFunction && this.ChemFunction2) {
        chemFunction += " | " + this.ChemFunction2;
      }*/
      chemFunction = chemFunction.join(",");
      return chemFunction;
    },
    ChemFunction1() {
      return  this.ItemObj && this.ItemObj.chemFunction;
    },
    ChemFunction2() {
      return this.ItemObj && this.ItemObj.chemFunction2;
    },
    Status() {
      return this.ItemObj && this.ItemObj.status;
    },
    StatusType() {
      let badgeType = "primary";
      // 'excluded', 'orphaned', 'inactive'
      if(this.Status === "active") {
        badgeType = "primary";
      } else if( this.Status === "excluded") {
        badgeType = "danger";
      } else if( this.Status === "orphaned") {
        badgeType = "warning";
      } else if( this.Status === "inactive") {
        badgeType = "info";
      } else {
        badgeType = "default";
      }
      return badgeType;
    }
  },
  created: function() {},
  methods: {
    handleSelectItem() {
      //augment ItemObj
      //this.ItemObj.isSelected = this.IsItemSelected;
      this.$emit("onSelectItem", this.ItemObj);
    }
  }
};
</script>

<style lang="scss">
.ribbon.top-right {
  margin-bottom: 0;
}

.card.card-item {
  .card-body {
    padding: 0;
    .card-title {
      //min-height: 50px;
      margin-top: 0px;
      margin-bottom: 0.25rem;
      font-size: 1em;
    }
    p {
      font-size: 0.9em;
      margin-bottom: 0;
    }
    .row.row-addition {
      font-size: 0.7rem;
      margin-top: 0;
    }

    .card-footer {
      font-size: 0.7rem;
      margin-top: 0;
      div {
        display: flex;
      }
    }
  }
  .n-badge {
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    background-color: rgba(244, 173, 32, 0.1);
  }
}

</style>

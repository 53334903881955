<template>
  <div class="page-header signup-page section-image">
    <div
      class="page-header-image"
      style="background-image: url('img/background/FCX-bg.jpg')"
    />
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-4 ml-auto mr-auto">
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="fa fa-book" />
              </div>
              <div class="description">
                <h5 class="info-title">
                  Knowledge
                </h5>
                <p class="description">
                  The universe of food contact chemicals. In one place.
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-primary">
                <i class="fa fa-bell" />
              </div>
              <div class="description">
                <h5 class="info-title">
                  Up to date
                </h5>
                <p class="description">
                  Fetch the latest and most comprehensive information about food contact chemicals.
                </p>
              </div>
            </div>
            <div class="info info-horizontal">
              <div class="icon icon-info">
                <i class="fa fa-code" />
              </div>
              <div class="description">
                <h5 class="info-title">
                  Benefitted by technology
                </h5>
                <p class="description">
                  We've developed the website with HTML5 and CSS3. Additionally, you can request REST-API access and
                  documentation in swaggerUI.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mr-auto">
            <div class="card card-signup">
              <div class="card-body">
                <h4 class="card-title text-center">
                  Register
                </h4>
                <!--
                <div class="social text-center">
                  <n-button
                    round
                    icon
                    class="btn-twitter"
                  >
                    <i class="fab fa-twitter" />
                  </n-button>
                  <n-button
                    round
                    icon
                    class="btn-dribbble"
                  >
                    <i class="fab fa-dribbble" />
                  </n-button>
                  <n-button
                    round
                    icon
                    class="btn-facebook"
                  >
                    <i class="fab fa-facebook" />
                  </n-button>
                  <h5 class="card-description">
                    or be classical
                  </h5>
                </div>
                -->
                <form @submit.prevent="register">
                  <div class="form-group">
                    <fg-input
                      v-model="form.organisation"
                      :required="true"
                      minlength="3"
                      maxlength="150"
                      addon-left-icon="now-ui-icons business_badge"
                      placeholder="Organisation..."
                    />
                  </div>

                  <div class="form-group">

                    <el-select
                      :required="true"
                      v-model="form.type"
                      class="fcx-select"
                      placeholder="Organisation type..."
                    >
                      <div class="input-group-prepend" slot="prefix">
                        <span class="el-input__suffix-inner" >
                        <i class="el-input__icon  now-ui-icons objects_planet"></i>
                      </span>
                      </div>

                      <el-option
                        v-for="option in ORGANISATION_TYPES"
                        :key="option.label"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                      />
                    </el-select>
                  </div>
                  <div class="form-group">
                    <fg-input
                      v-model="form.name"
                      :required="true"
                      minlength="3"
                      maxlength="50"
                      addon-left-icon="now-ui-icons users_circle-08"
                      placeholder="Name..."
                    />
                  </div>
                  <div class="form-group">
                    <fg-input
                      v-model="form.email"
                      :required="true"
                      minlength="5"
                      maxlength="255"
                      addon-left-icon="now-ui-icons ui-1_email-85"
                      placeholder="Email..."
                    />
                  </div>
                  <div class="form-group">
                    <fg-input
                      v-model="form.password"
                      :required="true"
                      minlength="5"
                      maxlength="255"
                      type="password"
                      addon-left-icon="now-ui-icons text_caps-small"
                      placeholder="Password..."
                    />
                  </div>

                  <n-checkbox v-model="form.acceptedTerms" :required="true">
                    I agree to the <router-link to="/terms">terms and conditions</router-link>.
                  </n-checkbox>
                  <div class="card-footer text-center">
                    <n-button
                      native-type="submit"
                      class="btn btn-fcx-yellow btn-round btn-lg"
                    >
                      Register
                    </n-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Button, FormGroupInput, Checkbox } from '@/components';
  import {Select, Option} from 'element-ui';
  import Constants from "@/js/const";
  export default {
    name: 'SignupPage',
    bodyClass: 'signup-page',
    components: {
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [FormGroupInput.name]: FormGroupInput,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        form: {
          organisation: "",
          type: "",
          name: "",
          email: "",
          password: "",
          acceptedTerms: false
        },
      }
    },
    computed: {
      ORGANISATION_TYPES() {
        return Constants.forms.organisationTypes;
      },
    },
    methods: {
      register: function() {
        this.LoadingX = true;
        this.$store
          .dispatch("SIGN_UP", this.form)
          .then(res => {
            this.$notify({
              title: "Registration successful",
              message: "Please wait for the admin to approve your registration. You will receive an email once your account is approved.",
              type: "success",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
            //this.$router.push({name: 'login'});
            this.$router.push({name: 'successSignup'});
         })
          .catch(error => {
            //this.$errorHandler.defaultLLocalApiErrorHandler(this, error, "expire catalog item");
            Logger.debug("error", error);

            this.$notify({
              title: "Registration failed",
              message:'Please get in touch with the admin.',
              type: "danger",
              timeout: 3000,
              horizontalAlign: "right",
              verticalAlign: "top",
              icon: 'nc-icon nc-app',
            });
          })
          .finally(() => {
            // using "finally" so even if there are errors, it stops "loading"
            //Logger.trace("disableItemGeneric finally");
            this.LoadingX = false;
          });
      }
    }
  }
</script>
<style scope lang="scss">

</style>

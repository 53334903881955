<template>
  <div
    class="page-header header-filter"
    filter-color="orange"
  >
    <div
      class="page-header-image"
      style="background-image: url('img/background/FCX-bg.jpg')"
    />
    <div class="content-center">
      <div class="container">
        <div class="col-12 col-md-8 ml-auto mr-auto">
          <card
            plain
          >
            <div
              slot="header"
              class="logo-container d-none d-md-block"
            >
              <img
                v-lazy="'img/logo/FCX-Logo-signet.png'"
                alt=""
                class="img-fluid"
                width="50"
              >
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="card-title text-center">
                  Thank you for registering!
                </h4>
                <div class="card-text">
                  You will not have immediate access to FCX.<br>
                  At this time, manual confirmation is required to ensure the security and integrity of our platform.<br><br>

                  You should receive a registration confirmation email shortly and an access confirmation within one business day. Please check your spam folder if you do not see any emails. If your access is not confirmed within one business day please contact us. We appreciate your patience during this process.
                </div>
              </div>
            </div>

            <template slot="raw-content">
              <div class="pull-left">
                <h6>
                  <router-link
                    class="link footer-link"
                    to="/login"
                  >
                    <i class="fa fa-mobile" /> Login
                  </router-link>
                </h6>
              </div>
              <div class="pull-right">
                <h6>
                  <router-link
                    class="link footer-link"
                    to="/contact"
                  >
                    <i class="fa fa-question-circle" /> Need Help?
                  </router-link>

                </h6>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card } from '@/components';

export default {
  name: 'SuccessSignupPage',
  bodyClass: 'login-page',
  components: {
    Card,
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>
<style>
</style>



<template>
  <div id="app">
    <PageLoader></PageLoader>
    <notifications class="vue-notifyjs" transition-name="notification-list" transition-mode="out-in">
    </notifications>
    <router-view name="header" />
    <div>
      <router-view />
    </div>
    <router-view name="footer" />

    <vue-cookie-accept-decline
      :debug="false"
      :disableDecline="false"
      :showPostponeButton="false"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
      @clicked-postpone="cookieClickedPostpone"
      @removed-cookie="cookieRemovedCookie"
      @status="onCookieStatus"
      elementId="cookiePanel"
      position="bottom-left"
      ref="cookiePanel"
      transitionName="slideFromBottom"
      type="bar"
    >
      <!-- Optional -->
      <template #postponeContent>&times;</template>

      <!-- Optional -->
      <template #message>
        This site uses cookies, including third-party cookies. By accepting, you agree to our use of cookies.
        <router-link to="/cookie" target="_blank">Read more</router-link>

      </template>

      <!-- Optional -->
      <template #declineContent>Decline</template>

      <!-- Optional -->
      <template #acceptContent>Accept All</template>
    </vue-cookie-accept-decline>
  </div>
</template>
<script>
  // Loading some plugin css asynchronously
  import 'vue-notifyjs/themes/default.css'
  import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
  import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
  import PageLoader from "@/layout/PageLoader.vue";
  export default {
    components: {
      PageLoader,
      VueCookieAcceptDecline
    },
    methods: {
      cookieClickedAccept() {
        Logger.debug("cookieClickedAccept");
        this.CookieStatusX = 'accept';
      },
      cookieClickedDecline() {
        Logger.debug("cookieClickedDecline");
        this.CookieStatusX = 'decline';
      },
      cookieClickedPostpone() {
        Logger.debug("cookieClickedPostpone");
        this.CookieStatusX = 'postpone';
      },
      cookieRemovedCookie() {
        Logger.debug("cookieRemovedCookie");
        this.CookieStatusX = null;
        this.removeCookies();
        this.$refs.cookiePanel.init();
      },
      onCookieStatus(status) {
        Logger.debug("cookieStatus", status);
        this.CookieStatusX = status;
      },
      removeCookies() {
        Logger.debug("removeCookies");
        let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
          let d = window.location.hostname.split(".");
          while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            let p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            };
            d.shift();
          }
        }
      }
    }
  }
</script>

<style>
#cookiePanel {
  bottom: 0;
}

</style>

